import { Table } from "antd";
import React, { useState, useCallback, useEffect, useMemo } from "react";

import { reportsService } from "../../services/reports";
import { useFetch } from "../../utils/hooks/useFetch";
import { Filters } from "./components/Filters";
import { TableTitle } from "./components/TableTitle";
import { NestedTable } from "./components/NestedTable";
import styles from "./Reports.module.css";
import moment from "moment";
import { GraphView } from "./components/GraphView";
import { startColumns, endColumns } from "./utils/columnsList";
import { CpcCol } from "./components/CpcCol";
import { numberWithSpaces } from "./utils";
import { CustomPagination } from "./components/CustomPagination";

export const Reports = () => {
  const [chartStats, setChartStats] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [tableColumns, setTableColumns] = useState([]);
  const [pageConfig, setPageConfig] = useState({ currentPage: 1, pageSize: 30 });
  const pageSizeOptions = [10, 20, 30, 50, 100, 300];
  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [countries, setCountries] = useState([]);
  const [sorting, setSorting] = useState({
    spent: "descend",
  });
  const [hasCampaign, setHasCampaign] = useState(true);
  const [cpcValue, setCpcValue] = useState();

  const campaignId = Number(new URLSearchParams(window.location.search).get("id"));
  const creativeId = Number(new URLSearchParams(window.location.search).get("creative_id"));

  // const [sorting, setSorting] = useState({
  //   field: null,
  //   order: null
  // });

  const cpcColumn = useMemo(() => {
    return {
      value: "base_price",
      text: "CPC",
      align: "center",
      tooltip: "CPC - Cost Per Click, this is the price you pay each time a user clicks on your ad",
      render: (cpc, record) => {
        return (
          <CpcCol
            cpc={cpc}
            item={record}
            campaignId={record.campaign_id}
            setCpcValue={setCpcValue}
          />
        );
      },
    };
  }, []);

  const [columnsData] = useState([...startColumns, cpcColumn, ...endColumns]);

  const sumOfDataRefactor = () => {
    if (sumOfData) {
      if (sumOfData[0]) {
        let sumOfDataRefactored = { ...sumOfData[0] };
        if (sumOfData[0].spent) {
          sumOfDataRefactored.spent = "$ " + parseFloat(sumOfData[0].spent).toFixed(4);
        }
        if (sumOfData[0].cr) {
          sumOfDataRefactored.cr = parseFloat(sumOfData[0].cr).toFixed(2) + " %";
        }
        if (sumOfData[0].cpm) {
          sumOfDataRefactored.cpm = "$ " + parseFloat(sumOfData[0].cpm).toFixed(2);
        }
        if (sumOfData[0].cpa) {
          sumOfDataRefactored.cpa = "$ " + parseFloat(sumOfData[0].cpa).toFixed(4);
        }
        if (sumOfData[0].ecpa) {
          sumOfDataRefactored.ecpa = "$ " + parseFloat(sumOfData[0].ecpa).toFixed(2);
        }
        if (sumOfData[0].ctr) {
          sumOfDataRefactored.ctr = parseFloat(sumOfData[0].ctr).toFixed(2) + " %";
        }
        if (sumOfData[0].impressions) {
          sumOfDataRefactored.impressions = numberWithSpaces(sumOfData[0].impressions);
        }
        if (sumOfData[0].clicks) {
          sumOfDataRefactored.clicks = numberWithSpaces(sumOfData[0].clicks);
        }
        if (sumOfData[0].spamClicks) {
          sumOfDataRefactored.spamClicks = numberWithSpaces(sumOfData[0].spamClicks);
        }
        if (sumOfData[0].leads) {
          sumOfDataRefactored.leads = numberWithSpaces(sumOfData[0].leads);
        }
        return sumOfDataRefactored;
      }
    }
  };

  const refactorTableData = () => {
    const tableDataRefactored = tableData
      ? tableData.map((el, id) => {
          const refactor = { ...el };
          refactor.cpm = refactor.cpm && "$ " + refactor.cpm.toFixed(4);
          refactor.ecpa = refactor.ecpa && "$ " + refactor.ecpa.toFixed(4);
          refactor.expected_revenue =
            refactor.expected_revenue && "$ " + refactor.expected_revenue.toFixed(2);
          refactor.revenue = refactor.revenue && "$ " + refactor.revenue.toFixed(4);
          refactor.cpa = refactor.cpa && "$ " + refactor.cpa.toFixed(4);
          refactor.spent = refactor.spent && "$ " + refactor.spent.toFixed(4);
          // refactor.roi = refactor.roi && '$ ' + refactor.roi.toFixed(4);
          refactor.cr = refactor.cr && refactor.cr.toFixed(2) + " %";
          refactor.ctr = refactor.ctr && refactor.ctr.toFixed(2) + " %";
          refactor.date = moment(refactor.date, "YYYYMMDD").format("YYYY-MM-DD");
          refactor.country = refactor.country && (
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                alt={refactor.country.toLowerCase()}
                style={{ marginRight: "3px" }}
                src={`https://advertiser.coinis.com/media/countries/${refactor.country.toLowerCase()}.png`}
              />
              <span>{refactor.country}</span>
            </span>
          );
          return {
            key:
              "" +
              Object.values(el).join("-") +
              id +
              selectedFilters.dateRange.from.toString() +
              selectedFilters.dateRange.to.toString(),
            ...refactor,
          };
        })
      : "";
    return tableDataRefactored;
  };

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting]
  );

  const updateColumns = useCallback(
    (value) => {
      if (sorting.spent && !value.checkedColumns.spent) {
        setSorting({
          field: null,
          order: null,
        });
      }
      setTableColumns(() => {
        let newTableColumn = [];
        setHasCampaign(!!value.checkedColumns.campaign_name);
        columnsData
          .filter((column) => value.checkedColumns[column["value"]])
          .forEach(function ({ text, value, tooltip, render, className }) {
            newTableColumn.push({
              title: (
                <TableTitle
                  text={text}
                  order={sorting[value] ? sorting[value] : false}
                  value={value}
                  tooltip={tooltip}
                  onSortingClick={handleSortingClick}
                />
              ),
              dataIndex: value,
              render,
              className,
            });
          });
        return newTableColumn;
      });
    },
    [sorting, columnsData, handleSortingClick]
  );

  const loadZoneData = (cancelToken) => {
    if (chartStats) {
      return reportsService.getChartStatsPaged({
        selectedFilters,
        currentPage: pageConfig.currentPage,
        pageSize: pageConfig.pageSize,
        sorting,
        cancelToken,
      });
    } else {
      return reportsService.getStatsPaged({
        selectedFilters,
        currentPage: pageConfig.currentPage,
        pageSize: pageConfig.pageSize,
        sorting,
        cancelToken,
        action: false,
      });
    }
  };

  const total = [];
  const table = [];
  const rows = 0;

  const [
    {
      data: { table: tableData, total: sumOfData },
      isFetching,
    },
    getZoneData,
  ] = useFetch(loadZoneData, { table, total, rows });
  const loading = isFetching;

  // useFetch CHART destruct
  const chartResponse = useFetch(loadZoneData);
  const chartData = chartResponse[0].data;
  const loadingChart = chartResponse[0].isFetching;
  const getZoneDataChart = chartResponse[1];

  useEffect(() => {
    setPageConfig({ currentPage: 1, pageSize: 30 });
  }, []);

  useEffect(() => {
    if (selectedFilters.filters) {
      if (chartStats) {
        getZoneDataChart();
      } else {
        getZoneData();
      }
    }
  }, [sorting, selectedFilters, getZoneData, pageConfig, chartStats, getZoneDataChart]);

  const handleExport = (filters) => {
    reportsService.exportReports(filters, pageConfig.currentPage, pageConfig.pageSize, sorting);
  };

  return (
    <div className={styles.reportsContainer}>
      <h4>Reports</h4>
      <Filters
        handleExport={handleExport}
        onSubmit={(value) => {
          updateColumns(value);
          setSelectedFilters(value);
        }}
        allColumns={columnsData}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setChartStats={setChartStats}
        chartStats={chartStats}
        countries={countries}
        setCountries={setCountries}
        campaignId={campaignId}
        creativeId={creativeId}
      />
      {!chartStats ? (
        <Table
          className={styles.mainTableContainer}
          style={{ marginTop: "2%" }}
          columns={tableColumns}
          rowKey={({ key }) => key}
          dataSource={refactorTableData()}
          loading={loading}
          // expandRowByClick={true}
          expandedRowRender={
            hasCampaign
              ? (item) => (
                  <div key={item.key}>
                    <NestedTable
                      cpcValue={cpcValue}
                      item={item}
                      dateRange={dateRange}
                      selectedDateRange={selectedFilters.dateRange}
                    />
                  </div>
                )
              : false
          }
          scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
          pagination={false}
          footer={() => {
            return (
              <CustomPagination
                pageConfig={pageConfig}
                setPageConfig={setPageConfig}
                pageSizeOptions={pageSizeOptions}
                disabledNext={tableData && tableData.length < pageConfig.pageSize}
              />
            );
          }}
          summary={() => {
            const sumOfDataRefactored = sumOfDataRefactor();
            const sum =
              sumOfData && sumOfData[0] ? (
                <tr className="ant-table-row">
                  {hasCampaign && <td className="ant-table-cell"></td>}
                  {tableColumns.map((el, index) => (
                    <td className="ant-table-cell" key={index} index={index}>
                      {sumOfDataRefactored[el.dataIndex]}
                    </td>
                  ))}
                </tr>
              ) : null;
            return sum;
          }}
        />
      ) : (
        <GraphView selectedColumns={tableColumns} loading={loadingChart} data={chartData} />
      )}
    </div>
  );
};
