const weekdaysIndexes = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};

export const getTimeInitialValues = () => {
  const timeInitialValues = [];
  for (let day = 0; day < 7; day++) {
    for (let hour = 0; hour < 24; hour++) {
      if (!timeInitialValues[day]) {
        timeInitialValues.push([false]);
      } else {
        timeInitialValues[day] = [...timeInitialValues[day], false];
      }
    }
  }
  return timeInitialValues;
};

export const parseTimeToTable = (value) => {
  const tableValues = getTimeInitialValues();
  const parsedValues = value.items.map((item) => {
    const substring = item.slice(2);
    return substring.split(" * * ");
  });
  parsedValues.forEach((item) => {
    const hours = item[0].split(",");
    const days = item[1].split(",");
    days.forEach((day) => {
      const parsedDay = Number(day);
      hours.forEach((hour) => {
        tableValues[parsedDay === 0 ? 6 : parsedDay - 1][hour] = true;
      });
    });
  });
  return { ...value, items: tableValues };
};

export const parseOldTimeFormat = (days, hours) => {
  const tableValues = getTimeInitialValues();
  switch (!!days + "," + !!hours) {
    case "true,false":
      days.item.forEach((day) => {
        for (let i = 0; i < 24; i++) {
          tableValues[weekdaysIndexes[day]][i] = true;
        }
      });
      break;
    case "false,true":
      hours.item.forEach((hour) => {
        for (let i = 0; i < 7; i++) {
          tableValues[i][Number(hour)] = true;
        }
      });
      break;
    default:
      days.item.forEach((day) => {
        hours.item.forEach((hour) => {
          tableValues[weekdaysIndexes[day]][Number(hour)] = true;
        });
      });
  }
  return { item: tableValues, op: "in" };
};

export const weekdaysMap = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
