import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Switch } from "antd";
import styles from "../CreativeBuilder.module.css";
import { ImageSingleUploader } from "./ImageSingleUploader";
import { IconUploader } from "./IconUploader";
import { isNotValid, MACROS } from "../../../../../utils";
import { openWarningNotification } from "../../../../../../../../../utils/notifications";
import { MacroInfoModal } from "./MacroInfoModal";
import { CampaignContext } from "../../../../../../../../../contexts/campaignContext";
import { AiGeneratorModal } from "../components/AiGeneratorModal";
import { CallToActionSingle } from "./CallToActionSingle";

export const SingleCreativeForm = ({
  type,
  singleImage,
  setSingleImage,
  singleIcon,
  setSingleIcon,
  singleC2aIcon,
  setSingleC2aIcon,
  setImageModalVisible,
  editCreative,
  currentCreative,
  genCreatives,
  generatorVisible,
  handleClearAll,
  setGeneratorVisible,
  headlineDescriptionAIGeneratorVisible,
  setHeadlineDescriptionAIGeneratorVisible,
  isReturnHeadlinesAndDescription,
  setIsReturnHeadlinesAndDescription,
}) => {
  const [formSingle] = Form.useForm();
  const { dispatchCreatives } = useContext(CampaignContext);

  const [whereTheCursorWas, setWhereTheCursorWas] = useState(undefined);

  const [macroInfoVisible, setMacroInfoVisible] = useState(false);

  const [campaignId, setCampaignId] = useState(false);
  const [formAIHeadline, setFormAIHeadline] = useState("");
  const [formAIDescription, setFormAIDescription] = useState("");

  const [callToAction, setCallToAction] = useState(false);

  useEffect(() => {
    if (!callToAction) {
      formSingle.setFieldsValue({ c2aText: null, c2aUrl: null });
      setSingleC2aIcon([]);
    }
  }, [callToAction, formSingle, setSingleC2aIcon]);

  useEffect(() => {
    formSingle.submit();
  }, [singleC2aIcon, singleIcon, singleImage, formSingle]);

  const onFinishSingle = (values) => {
    const actions =
      values.c2aText || values.c2aUrl
        ? {
            actions: [
              {
                title: { en: values.c2aText },
                icon: singleC2aIcon[0] ? singleC2aIcon[0] : "",
                url: values.c2aUrl,
              },
            ],
          }
        : {};
    editCreative(currentCreative, {
      title: { en: values.title },
      description: { en: values.description },
      image: {
        en: singleImage[0] ? singleImage[0] : "",
      },
      landing_url: values.landing_url,
      icon: singleIcon[0] ? singleIcon[0] : "",
      ...actions,
    });
  };

  const handleEditSave = () => {
    const values = formSingle.getFieldsValue();
    const actions =
      values.c2aText || values.c2aUrl
        ? {
            actions: [
              {
                title: { en: values.c2aText },
                icon: singleC2aIcon[0],
                url: values.c2aUrl,
              },
            ],
          }
        : {};
    const editObject = {
      ...generatorVisible,
      title: { en: values.title },
      description: { en: values.description },
      image: {
        en: singleImage[0] ? singleImage[0] : "",
      },
      landing_url: values.landing_url,
      icon: singleIcon[0],
      ...actions,
    };
    if (!callToAction) {
      delete editObject.actions;
    }
    const validation = isNotValid(editObject, type);
    if (validation.notValid) {
      openWarningNotification({ message: validation.message });
    } else {
      if (
        generatorVisible.gen_type === "clone" ||
        generatorVisible.gen_type === "clone_new"
      ) {
        delete editObject.gen_type;
        dispatchCreatives({ type: "create", payload: editObject });
      } else if (generatorVisible.id) {
        dispatchCreatives({ type: "update", payload: editObject });
      } else {
        dispatchCreatives({ type: "update_new", payload: editObject });
      }
      setGeneratorVisible(false);
      handleClearAll();
    }
  };

  useEffect(() => {
    const currentCreativeObj = currentCreative
      ? genCreatives.filter((item) => item.gen_id === currentCreative)[0]
      : null;
    if (currentCreativeObj) {
      formSingle.setFieldsValue({
        title: currentCreativeObj.title && currentCreativeObj.title.en,
        description:
          currentCreativeObj.description && currentCreativeObj.description.en,
        landing_url: currentCreativeObj.landing_url,
        c2aText:
          currentCreativeObj?.actions &&
          currentCreativeObj.actions[0]?.title?.en,
        c2aUrl:
          currentCreativeObj?.actions && currentCreativeObj.actions[0]?.url,
      });
      if (
        (currentCreativeObj?.actions &&
          currentCreativeObj.actions[0]?.title?.en) ||
        (currentCreativeObj?.actions && currentCreativeObj.actions[0]?.url)
      ) {
        setCallToAction(true);
      } else {
        setCallToAction(false);
      }

      if (currentCreativeObj.image) {
        setSingleImage([currentCreativeObj.image.en]);
      }
      if (currentCreativeObj?.actions && currentCreativeObj?.actions[0]?.icon) {
        setSingleC2aIcon([currentCreativeObj?.actions[0]?.icon]);
      }
      if (currentCreativeObj.icon) {
        setSingleIcon([currentCreativeObj.icon]);
      }
      if (currentCreativeObj.id) {
        setCampaignId(currentCreativeObj.id);
      }
    } // eslint-disable-next-line
  }, [currentCreative]);

  useEffect(() => {
    formSingle.submit();
  }, [singleImage, singleIcon, formSingle]);

  const handleMacrosClick = (macroContent) => {
    const landingUrl = formSingle.getFieldValue("landing_url");
    const newValue = whereTheCursorWas
      ? landingUrl.substring(0, whereTheCursorWas) +
        macroContent +
        landingUrl.substring(whereTheCursorWas, landingUrl.length)
      : landingUrl + macroContent;

    if (whereTheCursorWas !== undefined) {
      setWhereTheCursorWas((prevState) => prevState + macroContent.length);
    }
    formSingle.setFieldsValue({ landing_url: newValue });
  };

  const countMacrosInLandingUrl = (macro) => {
    const landingUrl = formSingle.getFieldValue("landing_url")
      ? formSingle.getFieldValue("landing_url")
      : "";
    return landingUrl.split(macro).length - 1;
  };

  const titleCount = formSingle.getFieldValue("title")
    ? formSingle.getFieldValue("title").length
    : 0;
  const descriptionCount = formSingle.getFieldValue("description")
    ? formSingle.getFieldValue("description").length
    : 0;

  useEffect(() => {
    if (formAIHeadline) {
      const values = formSingle.getFieldsValue();
      formSingle.setFieldsValue({
        title: formAIHeadline,
      });
      editCreative(currentCreative, {
        title: { en: formAIHeadline },
        description: { en: values.description },
        image: {
          en: singleImage[0] ? singleImage[0] : "",
        },
        landing_url: values.landing_url,
        icon: singleIcon[0] ? singleIcon[0] : "",
      });
    }

    if (formAIDescription) {
      const values = formSingle.getFieldsValue();
      formSingle.setFieldsValue({
        description: formAIDescription,
      });
      editCreative(currentCreative, {
        title: { en: values.title },
        description: { en: formAIDescription },
        image: {
          en: singleImage[0] ? singleImage[0] : "",
        },
        landing_url: values.landing_url,
        icon: singleIcon[0] ? singleIcon[0] : "",
      });
    }

    // eslint-disable-next-line
  }, [formAIHeadline, formAIDescription]);

  return (
    <div>
      <Form
        form={formSingle}
        name="dynamic_form_item"
        onChange={() => formSingle.submit()}
        onFinish={onFinishSingle}
      >
        <p className={styles.builderLabel}>
          <span className={styles.requiredMark}>* </span> Ad Headline{" "}
          <span
            className={titleCount > 100 ? styles.dangerTextCounter : undefined}
          >
            {titleCount}/100
          </span>
        </p>
        <div>
          <Form.Item name="title">
            <Input
              placeholder="Ad Title"
              className={titleCount > 100 ? styles.dangerText : undefined}
            />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            size="default"
            type="primary"
            ghost
            style={{ borderRadius: "5px" }}
            onClick={() => {
              setIsReturnHeadlinesAndDescription({
                multiHeadlines: false,
                headlines: true,
                description: false,
              });
              setHeadlineDescriptionAIGeneratorVisible(true);
            }}
          >
            Use AI tool to generate ad headline
          </Button>
        </div>
        <p className={styles.builderLabel}>
          Ad Description{" "}
          <span
            className={
              descriptionCount > 200 ? styles.dangerTextCounter : undefined
            }
          >
            {descriptionCount}/200
          </span>
        </p>
        <div>
          <Form.Item name="description">
            <Input
              placeholder="Ad Brand Text"
              className={descriptionCount > 200 ? styles.dangerText : undefined}
            />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            size="default"
            type="primary"
            ghost
            style={{ borderRadius: "5px" }}
            onClick={() => {
              setIsReturnHeadlinesAndDescription({
                multiHeadlines: false,
                headlines: false,
                description: true,
              });
              setHeadlineDescriptionAIGeneratorVisible(true);
            }}
          >
            Use AI tool to generate ad description
          </Button>
        </div>

        <ImageSingleUploader
          singleImage={singleImage}
          setSingleImage={setSingleImage}
          setImageModalVisible={setImageModalVisible}
        />
        {(type === "Push" || type === "Premium") && (
          <>
            <IconUploader
              uploadType="single_icon"
              icon={singleIcon}
              setIcon={setSingleIcon}
              setImageModalVisible={setImageModalVisible}
            />
            <div style={{ marginTop: 20 }} />
            <Switch
              checked={callToAction}
              onChange={() => setCallToAction((prevValue) => !prevValue)}
            />
            <span
              className={styles.builderLabel}
              style={{ fontWeight: "bold" }}
            >
              &nbsp;&nbsp;Call to Action
            </span>
            {callToAction && (
              <CallToActionSingle
                singleC2aIcon={singleC2aIcon}
                setSingleC2aIcon={setSingleC2aIcon}
                setImageModalVisible={setImageModalVisible}
              />
            )}
          </>
        )}

        <p className={styles.builderLabel}>
          <span className={styles.requiredMark}>* </span>Landing URL{" "}
          <span>- full click url including https://</span>
        </p>
        <div>
          <Form.Item name="landing_url">
            <Input
              placeholder="https://examplelandingpage.com"
              // style={{ borderRadius: "5px" }}
            />
          </Form.Item>
        </div>
        <p className={styles.builderLabel}>
          <span className={styles.requiredMark}></span>Add Macros{" "}
          <span>- add supported macros to your URL</span>
        </p>
        <div style={{ margin: "0" }}>
          {MACROS.map((macro) => (
            <div
              key={macro.value}
              className={
                countMacrosInLandingUrl(macro.value)
                  ? styles.macroItemSelected
                  : styles.macroItem
              }
              onClick={() => handleMacrosClick(macro.value)}
            >
              {macro.value}
            </div>
          ))}
        </div>
        <Button
          className={styles.macroInfoBtn}
          size="small"
          type="link"
          onClick={() => setMacroInfoVisible(true)}
        >
          Learn what macros do.
        </Button>
        <MacroInfoModal
          setVisible={setMacroInfoVisible}
          visible={macroInfoVisible}
        />
      </Form>
      {generatorVisible !== "NEW" && (
        <Button
          className={styles.editSaveBtn}
          type="primary"
          onClick={handleEditSave}
        >
          Save Creative
        </Button>
      )}
      <AiGeneratorModal
        campaignId={campaignId}
        setVisible={setHeadlineDescriptionAIGeneratorVisible}
        visible={headlineDescriptionAIGeneratorVisible}
        isReturnHeadlinesAndDescription={isReturnHeadlinesAndDescription}
        setHeadlines={setFormAIHeadline}
        setDescription={setFormAIDescription}
      />
    </div>
  );
};
