import React from "react";

import styles from "./RuleForm.module.css";
import { Input, InputNumber, Select, Row, Col, Radio, Button, Tooltip, Drawer } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  ReloadOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { campaignOptions } from "../utils/ruleUtils";
import { RULE_OBJ } from "../utils/ruleUtils";

export const RuleForm = ({ formLoading, currentRule, setCurrentRule, handleRuleSave }) => {
  const handleAddSubRule = () => {
    setCurrentRule((prevCurrentRule) => ({
      ...prevCurrentRule,
      sub_rules: [
        ...prevCurrentRule.sub_rules,
        {
          metric: "",
          num: "",
          condition: "",
        },
      ],
    }));
  };
  const handleDeleteSubRule = (subRuleIndex) => {
    const updatedSubRules = currentRule.sub_rules.filter(
      (_, subIndex) => subIndex !== subRuleIndex
    );
    const updatedSelectedMetrics = currentRule.selected_metrics.filter(
      (_, metricIndex) => metricIndex !== subRuleIndex
    );

    setCurrentRule((prevCurrentRule) => ({
      ...prevCurrentRule,
      sub_rules: updatedSubRules,
      selected_metrics: updatedSelectedMetrics,
    }));
  };

  const handleResetRule = (ruleIndex) => {
    setCurrentRule({ ...RULE_OBJ() });
  };

  const handleChangeRule = (name, value, subRuleIndex) => {
    if (subRuleIndex !== undefined) {
      // Update subrule field
      const parsedRule = { ...currentRule };
      parsedRule.error = null;
      const subRule = parsedRule.sub_rules[subRuleIndex];

      if (name === "metric") {
        // Remove the previously selected metric if it exists
        const prevMetricIndex = parsedRule.selected_metrics.indexOf(subRule.metric);
        if (prevMetricIndex !== -1) {
          parsedRule.selected_metrics.splice(prevMetricIndex, 1);
        }
        // Add the newly selected metric
        parsedRule.selected_metrics.push(value);
      }

      // Update the subrule field
      subRule[name] = value;
      setCurrentRule(parsedRule);
    } else {
      // Update main rule field
      const alterObj =
        name === "action" && value === "pause" ? { action_limit: null, action_value: null } : {};
      setCurrentRule((prevCurrentRule) => ({ ...prevCurrentRule, [name]: value, ...alterObj }));
    }
  };

  if (!currentRule) return null;

  return (
    <Drawer
      title="Rule Preset"
      placement="right"
      size="large"
      onClose={() => setCurrentRule(null)}
      visible={!!currentRule}
      footer={
        <div className={styles.ruleFooterBtns}>
          <Button type="primary" loading={formLoading} onClick={handleRuleSave}>
            Save rule preset
          </Button>
          <Button disabled={formLoading} onClick={() => setCurrentRule(null)}>
            Cancel
          </Button>
        </div>
      }
    >
      <div className={styles.generalWrapper}>
        <div className={styles.ruleWrapper}>
          <Row gutter={[16, 8]}>
            <Input
              placeholder="Rule Name"
              value={currentRule.name}
              onChange={(e) => setCurrentRule({ ...currentRule, name: e.target.value })}
            />
          </Row>
          <Row gutter={[16, 8]}>
            <Col className={styles.flexCustomWidth}>
              <span>If</span>
              <Input value={"Campaign"} disabled className={styles.ruleInput} />
            </Col>
          </Row>
          {currentRule.sub_rules.map((subRule, subRuleIndex) => (
            <Row wrap={true} justify="start" gutter={[16, 8]} key={subRuleIndex}>
              <Col className={styles.flexCustomWidth}>
                <span>has</span>
                <Select
                  placeholder="Select metric"
                  value={subRule.metric ? subRule.metric : undefined}
                  name={`metric-${subRuleIndex}`}
                  className={styles.ruleInput}
                  onChange={(value) => handleChangeRule("metric", value, subRuleIndex)}
                  options={campaignOptions.metrics(currentRule)}
                />
              </Col>
              <Col flex="auto" style={{ paddingLeft: 0, paddingRight: 0 }} className={styles.start}>
                <Col xs={7} sm={5} md={5} lg={6} xl={5} xxl={4}>
                  <span>with values</span>
                </Col>
                <Col xs={7} sm={5} md={5} lg={5} xl={5} xxl={6}>
                  <Radio.Group
                    value={subRule.condition}
                    name={`condition-${subRuleIndex}`}
                    size="middle"
                    onChange={(e) => handleChangeRule("condition", e.target.value, subRuleIndex)}
                  >
                    <Radio.Button type="primary" value="lower">
                      <LeftOutlined />
                    </Radio.Button>
                    <Radio.Button type="primary" value="greater">
                      <RightOutlined />
                    </Radio.Button>
                  </Radio.Group>
                </Col>
                <Col xs={9} sm={6} md={6} lg={5} xl={6} xxl={5}>
                  <span>than</span>
                  <InputNumber
                    controls={false}
                    // status={!subRule.num && "error"}
                    placeholder="Value"
                    value={subRule.num}
                    name={`num-${subRuleIndex}`}
                    className={styles.ruleInput}
                    onChange={(value) => handleChangeRule("num", value, subRuleIndex)}
                  />
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={9} className={styles.end}>
                  <Button
                    onClick={() => handleDeleteSubRule(subRuleIndex)}
                    disabled={currentRule.sub_rules.length === 1}
                  >
                    <DeleteOutlined />
                  </Button>
                  {subRuleIndex === currentRule.sub_rules.length - 1 && (
                    <Button
                      className={styles.addSubRule}
                      onClick={() => handleAddSubRule()}
                      disabled={
                        currentRule.sub_rules.length === 6 ||
                        subRule.metric === "" ||
                        subRule.num === "" ||
                        subRule.condition === ""
                      }
                    >
                      + ADD
                    </Button>
                  )}
                </Col>
              </Col>
            </Row>
          ))}
          <Row wrap={true} justify="start" gutter={[16, 8]} className={styles.start}>
            <Col className={styles.flexCustomWidth}>
              <span>within</span>
              <Select
                value={currentRule.time_range}
                name={`time_range`}
                className={styles.ruleInput}
                onChange={(value) => handleChangeRule("time_range", value)}
                options={campaignOptions.timeRanges}
              />
            </Col>
            <Col style={{ paddingLeft: 0, paddingRight: 0 }} className={styles.start}>
              <Col xs={4} sm={2} md={3} lg={3} xl={5} xxl={4}>
                <span>then</span>
              </Col>
              <Col xs={20} sm={9} md={11} lg={11} xl={9}>
                <Select
                  value={currentRule.action}
                  name={`action`}
                  className={styles.ruleInput}
                  onChange={(value) => handleChangeRule("action", value)}
                  options={campaignOptions.actions}
                />
              </Col>
              {(currentRule.action === "increase_bid" || currentRule.action === "decrease_bid") && (
                <Col xs={24} sm={12} md={10} lg={10} xl={6}>
                  <div className={styles.bidUpdateContainer}>
                    <Tooltip title="-5%">
                      <Button
                        size="medium"
                        className={styles.buttonLeft}
                        disabled={currentRule.action_value <= 5}
                        onClick={() =>
                          handleChangeRule("action_value", currentRule.action_value - 5)
                        }
                      >
                        <MinusOutlined />
                      </Button>
                    </Tooltip>
                    <div className={styles.bidBox}>
                      <InputNumber
                        min={5}
                        max={currentRule.action === "decrease_bid" ? 99 : 100}
                        value={currentRule.action_value}
                        // className={styles.errorBid}
                        onChange={(value) => handleChangeRule("action_value", value)}
                      />
                    </div>
                    <Tooltip title="+5%">
                      <Button
                        size="medium"
                        className={styles.buttonRight}
                        disabled={
                          currentRule.action_value >=
                          (currentRule.action === "decrease_bid" ? 95 : 96)
                        }
                        onClick={() =>
                          handleChangeRule("action_value", currentRule.action_value + 5)
                        }
                      >
                        <PlusOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                </Col>
              )}
            </Col>
          </Row>
          <Row
            wrap={true}
            justify="end"
            gutter={[16, 8]}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <Col className={styles.customOffset} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Col
                className={currentRule.action === "pause" && styles.hiddenTxt}
                xs={5}
                sm={4}
                md={4}
                lg={5}
                xl={5}
                xxl={4}
              >
                <span>stop at</span>
              </Col>
              {currentRule.action !== "pause" && (
                <Col xs={7} sm={8} lg={13} xl={13}>
                  <InputNumber
                    prefix="$"
                    controls={false}
                    value={currentRule.action_limit}
                    name={`action_limit`}
                    onChange={(value) => handleChangeRule("action_limit", value)}
                  />
                </Col>
              )}
              <Col xs={12} sm={12} lg={6} xl={6} xxl={7} className={styles.end}>
                <Tooltip title="Reset rule">
                  <Button onClick={() => handleResetRule()}>
                    <ReloadOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Col>
          </Row>
          {currentRule.error && (
            <div className={styles.errorRule}>
              <p>
                <ExclamationCircleOutlined /> {currentRule.error}
              </p>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};
