import React, { useContext, useMemo } from "react";
import { Button, Spin } from "antd";

import { ContentForm } from "./components/ContentForm";
import { Preview } from "./components/Preview";
import { TableOfContents } from "./components/TableOfContents";

import styles from "./campaign-form.module.css";
import { TargetingContext } from "../../contexts/targetingContext";
import { CampaignContext } from "../../contexts/campaignContext";
import { Link } from "react-router-dom";
import { CampaignTypeModal } from "./components/CampaignTypeModal";
import UserContext from "../../contexts/userContext";
import { useEffect } from "react";

export const CampaignForm = () => {
  const { userInfo } = useContext(UserContext);
  const { loading } = useContext(TargetingContext);
  const {
    editLoading,
    formRef,
    editId,
    campaignType,
    dispatchCampaign,
    isClone,
  } = useContext(CampaignContext);

  const noModalFlag = useMemo(() => {
    if (!userInfo?.permissions) {
      return false;
    }
    const permissionCount = Object.values(userInfo.permissions).filter(
      item => item
    );
    if (permissionCount.length < 2) {
      if (userInfo.permissions.push) {
        return "Push";
      } else if (userInfo.permissions.premium) {
        return "Premium";
      } else if (userInfo.permissions.contextual) {
        return "Contextual";
      } else if (userInfo.permissions.smartlink) {
        return "Smartlink";
      } else {
        return "Push";
      }
    }
    return false;
  }, [userInfo.permissions]);

  useEffect(() => {
    if (!editId && !campaignType) {
      if (noModalFlag) {
        dispatchCampaign({ type: "type", payload: noModalFlag });
      }
    }
  }, [noModalFlag, editId, campaignType, dispatchCampaign]);

  return (
    <>
      <Spin
        spinning={loading || editLoading}
        wrapperClassName={styles.formContainer}
      >
        <div className={styles.formContainerHead}>
          <span className={styles.sideWidth}>
            {isClone
              ? "Clone campaign"
              : editId
                ? "Edit Campaign"
                : "Create New Campaign"}
          </span>
          <div style={{ flex: 5 }}>
            {isClone && (
              <div className={styles.cloneMessage}>
                Cloning the campaign will remove all related individual source
                bids, regardless of budget & bid changes.
              </div>
            )}
          </div>
          {/* <div className={styles.sideWidth}></div> */}
        </div>
        <div className={styles.formContainerBody}>
          <TableOfContents />
          <ContentForm isClone={isClone} loading={loading} />
          <Preview />
        </div>
        <div className={styles.formContainerFoot}>
          <span className={styles.sideWidth}></span>
          <div style={{ flex: 3 }}></div>
          <div className={styles.sideWidth}>
            <Button
              type="primary"
              className={styles.saveBtn}
              onClick={formRef.submit}
              loading={loading}
            >
              Save Campaign
            </Button>
            <Link to="/campaigns" className={styles.leaveCreatingBtnContainer}>
              <Button className={styles.leaveCreatingBtn}>
                Leave {editId ? "Editing" : "Creating"} Mode
              </Button>
            </Link>
          </div>
        </div>
        <CampaignTypeModal
          campaignType={campaignType}
          editId={editId}
        />
      </Spin>
    </>
  );
};
