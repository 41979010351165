import { Radio, Space, Select, message } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CampaignContext } from "../../../contexts/campaignContext";
import { TargetingContext } from "../../../contexts/targetingContext";
import { SpecificCopyPaste } from "../components/SpecificCopyPaste";
import { ListSelect } from "../components/ListSelect";
import styles from "./SubidPlacements.module.css";

const { Option } = Select;

export const SubidPlacements = () => {
  const { editId, targeting, dispatchTargeting } = useContext(CampaignContext);
  const { subidListAll } = useContext(TargetingContext);

  const [selectedSubidAll, setSelectedSubidAll] = useState(true);

  const handleTargetingUpdate = (value) => {
    const payload = {
      ...value,
      items: value.items.map((subid) => subid + ".*"),
    };
    dispatchTargeting({ type: "subids", payload });
  };

  const handleListTargetingUpdate = (lists) => {
    const payload = {
      ...targeting.subids,
      lists,
    };
    dispatchTargeting({ type: "subids", payload });
  };

  const blacklistId = useMemo(() => {
    const blacklist = subidListAll.find((item) => item.is_global);
    return blacklist?.id;
  }, [subidListAll]);

  useEffect(() => {
    if (targeting.subids.items.length || targeting.subids.lists.length) {
      setSelectedSubidAll(false);
    }
  }, [targeting.subids, blacklistId]);

  useEffect(() => {
    !editId &&
      blacklistId &&
      !JSON.parse(localStorage.getItem("campaign_targeting")) &&
      dispatchTargeting({
        type: "subids",
        payload: { type: "in", items: [], lists: [blacklistId] },
      });
  }, [editId, blacklistId, dispatchTargeting]);

  return (
    <div>
      <div>
        <div className={styles.radioButtonSelection}>
          <Radio.Group
            value={selectedSubidAll}
            onChange={(e) => {
              if (e.target.value) {
                dispatchTargeting({
                  type: "subids",
                  payload: { type: "in", items: [], lists: [] },
                });
              }
              setSelectedSubidAll(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Radio value={true}>Target all SubIDs & Placements</Radio>
              <Radio value={false}>Target specific SubIDs & Placements</Radio>
            </Space>
          </Radio.Group>
        </div>
        {!selectedSubidAll && (
          <>
            <div className={styles.opTargeting}>
              <span>I want to </span>
              <Select
                value={targeting.subids.op}
                onChange={(op) => {
                  if (op === "in" && targeting.subids.lists.includes(blacklistId)) {
                    message.warning(
                      "Global blacklist removed, including blacklist is not allowed."
                    );
                    handleListTargetingUpdate(
                      targeting.subids.lists.filter((item) => item !== blacklistId)
                    );
                  }
                  handleTargetingUpdate({
                    op,
                    items: targeting.subids.items.map((item) => item.replace(/\.\*$/, "")),
                  });
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value="in">Include</Option>
                <Option value="nin">Exclude</Option>
              </Select>
              the following SubIDs and lists in targeting:
            </div>
            <ListSelect
              type="subid"
              op={targeting.subids.op}
              value={targeting.subids.lists}
              onChange={(value) => handleListTargetingUpdate(value)}
            />
            <SpecificCopyPaste
              type="subid"
              value={{
                ...targeting.subids,
                items: targeting.subids.items.map((item) => item.replace(/\.\*$/, "")),
                lists: targeting.subids.lists,
              }}
              onChange={(value) => handleTargetingUpdate(value)}
            />
          </>
        )}
      </div>
    </div>
  );
};
