import { Modal, Table } from "antd";
import React from "react";


export const ColumnInfo = ({ visible, setVisible, allColumns }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Description",
      dataIndex: "tooltip",
      key: "tooltip",
    },
  ];
  return (
    <Modal
      style={{ top: 40 }}
      title="All columns"
      visible={visible}
      footer={false}
      onCancel={() => setVisible(false)}
      width={700}
    >
      <Table
        rowKey={item => item.value}
        dataSource={allColumns}
        columns={columns}
        pagination={false}
        bordered
        scroll={{ y: "70vh" }}
      />
    </Modal>
  );
};