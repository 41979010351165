import React, { useContext, useEffect } from "react";
import { Table, Button, Space, Popconfirm, Tooltip, Spin, DatePicker } from "antd";
import {
  DeleteOutlined,
  BarChartOutlined,
  CopyOutlined,
  EditOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

import styles from "./Creatives.module.css";
import { Link } from "react-router-dom";
import { CampaignContext, parseCreativeToClone } from "../../../../../contexts/campaignContext";
import { calculateId } from "../utils";
import { reportsService } from "../../../../../services/reports";
import { useState } from "react";
import moment from "moment";
import { openErrorNotification, openSuccessNotification } from "../../../../../utils/notifications";

const { RangePicker } = DatePicker;

export const TableCreatives = ({
  isCampaignEdit,
  creatives,
  creativesLeft,
  type,
  handleCreativeDelete,
  setGeneratorVisible,
  handleCreativeClone,
  setSmartlinkVisible,
  setContextualVisible,
}) => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(false);

  const [parsedCreatives, setParsedCreatives] = useState([]);

  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });

  const { dispatchCreatives, editId, campaign } = useContext(CampaignContext);

  const handlePausePlay = async (id, status) => {
    setUpdateLoading(true);
    try {
      const res = await reportsService.updateCreatives({
        creative_ids: [id],
        status,
        campaign_id: campaign.general.id
      });
      if (res.success) {
        openSuccessNotification({ message: "Status changed successfully!" });
        dispatchCreatives({ type: "pause_play", id, payload: { id, status } });
      } else {
        throw new Error();
      }
    } catch (e) {
      openErrorNotification({ message: "Error, failed to update!" });
    } finally {
      setUpdateLoading(false);
    }
  };
  let columns = [
    {
      title: "#",
      dataIndex: "",
      align: "left",
      render: (_, __, id) => {
        return <span>{id + 1}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "left",
      render: (text = "inactive") => {
        return <span className={styles[`status${text}`]}>{text}</span>;
      },
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      align: "left",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      align: "left",
      render: (stat) => stat || "-",
    },
    {
      title: "Conversions",
      dataIndex: "conversions",
      align: "left",
      render: (stat) => stat || "-",
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      align: "left",
      render: (stat) => (stat ? parseFloat(stat).toFixed(2) + " %" : "-"),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      fixed: "right",
      align: "left",
      render: (id, creative) => {
        return (
          <Space>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                if (type === "Smartlink") {
                  setSmartlinkVisible(creative);
                } else if (type === "Contextual Ad") {
                  setContextualVisible(creative);
                } else {
                  setGeneratorVisible(creative);
                }
              }}
            />
            <Link to={`/reports?creative_id=${id}`} target="_blank">
              <Button icon={<BarChartOutlined />} disabled={creative.gen_id || !isCampaignEdit} />
            </Link>
            <Button
              disabled={creativesLeft < 1}
              icon={<CopyOutlined />}
              onClick={() => {
                const cpCreative = { ...creative };
                const gen_id = calculateId();

                if (type === "Smartlink") {
                  setSmartlinkVisible({
                    ...parseCreativeToClone(cpCreative),
                    gen_id,
                    gen_type: "clone",
                  });
                } else if (type === "Contextual Ad") {
                  setContextualVisible({
                    ...parseCreativeToClone(cpCreative),
                    gen_id,
                    gen_type: "clone",
                  });
                } else {
                  setGeneratorVisible({
                    ...parseCreativeToClone(cpCreative),
                    gen_id,
                    gen_type: "clone",
                  });
                }
              }}
            />
            <Button
              icon={creative.status === "active" ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
              disabled={!["active", "paused"].includes(creative.status) || !isCampaignEdit}
              onClick={() => {
                handlePausePlay(
                  id,
                  creative.status === "active"
                    ? "paused"
                    : creative.status === "paused"
                    ? "active"
                    : creative.status
                );
              }}
            />
            <Popconfirm
              placement="topRight"
              title="Creative will be deleted, are you sure you want to proceed?"
              onConfirm={() => {
                if (id) {
                  handleCreativeDelete(id, "delete");
                } else {
                  handleCreativeDelete(creative.gen_id, "delete_new");
                }
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const titleColumn = {
    title: "Ad Headline",
    dataIndex: "title",
    align: "left",
    render: (data) => (
      <div className={styles.columnWidth}>
        {data && data.en ? <Tooltip title={data.en}>{data.en}</Tooltip> : "-"}
      </div>
    ),
  };
  const imageColumn = {
    title: "Image",
    dataIndex: "image",
    render: (text) => {
      return (
        <img
          className={styles.tableImgPreview}
          style={{ objectFit: "cover" }}
          width="89px"
          height="50px"
          src={text && text.en}
          alt="image1"
        />
      );
    },
  };
  const iconColumn = {
    title: "Icon",
    dataIndex: "icon",
    render: (text) => {
      return (
        <img className={styles.tableImgPreview} width="50px" height="50px" src={text} alt="icon" />
      );
    },
  };
  const urlColumn = {
    title: "Landing Url",
    dataIndex: "landing_url",
    render: (data) => (
      <div className={styles.columnWidth}>
        {data ? <Tooltip title={data}>{data}</Tooltip> : "-"}
      </div>
    ),
  };
  const descriptionColumn = {
    title: type.toLowerCase() === "push" ? "Ad Description" : "Description",
    dataIndex: "description",
    render: (data) => (
      <div className={styles.columnWidth}>
        {data && data.en ? <Tooltip title={data.en}>{data.en}</Tooltip> : "-"}
      </div>
    ),
  };
  const editColumn = {
    title: "ID",
    dataIndex: "id",
    align: "center",
    render: (id) => {
      return <div className={styles.campaignTypeColumn}>{id ? `#${id}` : "-"} </div>;
    },
  };

  switch (type.toLowerCase()) {
    case "push":
      columns.splice(2, 0, titleColumn);
      columns.splice(3, 0, descriptionColumn);
      columns.splice(4, 0, imageColumn);
      columns.splice(5, 0, iconColumn);
      columns.splice(6, 0, urlColumn);
      break;
    case "native":
      columns.splice(2, 0, titleColumn);
      columns.splice(3, 0, imageColumn);
      columns.splice(4, 0, urlColumn);
      break;
    case "smartlink":
      columns.splice(2, 0, urlColumn);
      break;
    case "contextual ad":
      columns.splice(2, 0, titleColumn);
      columns.splice(3, 0, urlColumn);
      break;
    default:
      columns.splice(2, 1, urlColumn);
  }

  if (isCampaignEdit) {
    columns.splice(1, 0, editColumn);
  }

  useEffect(() => {
    const getCreativeStatistics = async (campaign_id) => {
      try {
        setStatsLoading(true);
        const statistics = await reportsService.getCreativesStatsPaged({
          dateRange,
          checkedColumns: ["creative_id", "impressions", "clicks", "conversions", "ctr"],
          campaign_id,
        });
        if (statistics?.table) {
          setParsedCreatives(
            creatives
              .filter((item) => item.action !== "delete")
              .map((item) => {
                const found = statistics.table?.find((stat) => stat.creative_id === item.id);
                if (found) {
                  return { ...item, ...found };
                }
                return item;
              })
          );
        } else {
          setParsedCreatives(creatives.filter((item) => item.action !== "delete"));
        }
      } catch (e) {
        openErrorNotification({ message: "Error, failed to load creative statistics!" });
        setParsedCreatives(creatives.filter((item) => item.action !== "delete"));
      } finally {
        setStatsLoading(false);
      }
    };
    if (creatives.length) {
      if (editId) {
        getCreativeStatistics(editId);
      } else {
        setParsedCreatives(creatives.filter((item) => item.action !== "delete"));
      }
    }
  }, [dateRange, creatives, editId]);

  return (
    <>
      <div className={styles.rangeDatePicker}>
        <RangePicker
          allowClear={false}
          onChange={(momentDates, dates) => {
            setDateRange({ from: momentDates[0], to: momentDates[1] });
          }}
          value={[moment(dateRange.from), moment(dateRange.to)]}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        />
        <p>Select dates to view statistics by creatives.</p>
      </div>
      <Spin spinning={updateLoading || statsLoading}>
        <Table
          rowKey={({ gen_id, id }) => id || gen_id}
          className={styles.creativesTable}
          dataSource={parsedCreatives}
          columns={columns}
          scroll={{ x: "max-content" }}
          pagination={false}
        />
      </Spin>
    </>
  );
};
