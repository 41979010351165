import React, { useContext, useState } from "react";
import { Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";

import styles from "./NewCreatives.module.css";
import { TableCreatives } from "./components/TableCreatives";

import { Generator } from "./components/Generator";
import { SmartlinkModal } from "./components/SmartlinkModal";
import { calculateId } from "./utils";
import { ContextualGen } from "./components/ContextualGen";
import {
  CampaignContext,
  parseCreativeToClone,
} from "../../../../contexts/campaignContext";
import { URLModal } from "./components/URLModal";

const MAX_CREATIVES = 15;

export const CreativesTab = ({ type, isCampaignEdit }) => {
  const { creatives, dispatchCreatives, isClone } = useContext(CampaignContext);
  const [generatorVisible, setGeneratorVisible] = useState(false);
  const [contextualVisible, setContextualVisible] = useState(false);
  const [smartlinkVisible, setSmartlinkVisible] = useState(false);
  const [urlModalVisible, setUrlModalVisible] = useState(false);

  /* 
     When we delete previously created creative we are not removing it from the array, we are just marking it for deletion.
     With that in mind we need to filter marked creatives and get real length of creatives.
  */
  const getRealCreativesLength = (creatives) =>
    creatives.filter((item) => item.action !== "delete").length;

  const handleCreativeDelete = (payload, type) => {
    dispatchCreatives({ type, payload });
  };
  const handleCreativeUrlUpdate = (payload) => {
    dispatchCreatives({ type: "bulk_update_url", payload });
  };
  const handleCreativeClone = (id, type) => {
    const stamp = calculateId();
    dispatchCreatives({ type, payload: { id, stamp } });
    return stamp;
  };

  return (
    <div>
      <div className={styles.creativesContentWrapper}>
        <p>Build Creatives for your {type.toLowerCase()} campaign</p>
        <TableCreatives
          isCampaignEdit={isCampaignEdit}
          creatives={creatives}
          type={type}
          creativesLeft={MAX_CREATIVES - getRealCreativesLength(creatives)}
          handleCreativeDelete={handleCreativeDelete}
          handleCreativeClone={handleCreativeClone}
          setGeneratorVisible={setGeneratorVisible}
          setContextualVisible={setContextualVisible}
          setSmartlinkVisible={setSmartlinkVisible}
        />
        <p style={{ marginTop: "20px", marginBottom: "0px" }}>
          Columns <i>Impressions</i>, <i>Clicks</i> and <i>Conversion</i> show
          all time stats, starting from the moment the campaign had been created
          and ending with the last update. You can find more detailed stats on{" "}
          <a target="_blank" rel="noopener noreferrer" href="/reports">
            Reports
          </a>{" "}
          page.
        </p>
        <div className={styles.buttonsContainer}>
          <div>
            <Button
              disabled={MAX_CREATIVES - getRealCreativesLength(creatives) < 1}
              size="default"
              className={styles.newCreativeButton}
              onClick={() => {
                if (type === "Smartlink") {
                  setSmartlinkVisible(true);
                } else if (type === "Contextual Ad") {
                  setContextualVisible("NEW");
                } else {
                  setGeneratorVisible("NEW");
                }
              }}
            >
              Add New Creatives
            </Button>
            <Button
              disabled={MAX_CREATIVES - getRealCreativesLength(creatives) < 1}
              size="default"
              className={styles.newCreativeButtonSecond}
              onClick={() => {
                const gen_id = calculateId();
                if (type === "Smartlink") {
                  setSmartlinkVisible({
                    ...parseCreativeToClone({}),
                    gen_id,
                    gen_type: "clone",
                  });
                } else if (type === "Contextual Ad") {
                  setContextualVisible({
                    ...parseCreativeToClone({}),
                    gen_id,
                    gen_type: "clone",
                  });
                } else {
                  setGeneratorVisible({
                    ...parseCreativeToClone({}),
                    gen_id,
                    gen_type: "clone",
                  });
                }
              }}
            >
              Add Single Creative
            </Button>
          </div>
          <Button
            size="default"
            icon={<LinkOutlined />}
            className={styles.newCreativeButtonSecond}
            onClick={() => {
              setUrlModalVisible(true);
            }}
          >
            Change all creatives URL
          </Button>
        </div>
      </div>

      <Generator
        type={type}
        creativesLeft={MAX_CREATIVES - getRealCreativesLength(creatives)}
        MAX_CREATIVES={MAX_CREATIVES}
        generatorVisible={generatorVisible}
        setGeneratorVisible={setGeneratorVisible}
      />
      <ContextualGen
        contextualVisible={contextualVisible}
        creativesLeft={MAX_CREATIVES - getRealCreativesLength(creatives)}
        setContextualVisible={setContextualVisible}
      />
      <SmartlinkModal
        smartlinkVisible={smartlinkVisible}
        setSmartlinkVisible={setSmartlinkVisible}
      />
      <URLModal
        isClone={isClone}
        urlModalVisible={urlModalVisible}
        setUrlModalVisible={setUrlModalVisible}
        handleCreativeUrlUpdate={handleCreativeUrlUpdate}
      />
    </div>
  );
};
