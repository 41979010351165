import React, { useMemo } from "react";
import { useContext } from "react";
import { CampaignContext } from "../../../contexts/campaignContext";
import { TargetingContext } from "../../../contexts/targetingContext";
import {
  renderBrowsers,
  renderCategories,
  renderCountries,
  renderDeviceManufacturers,
  renderTrafficTypes,
  renderDeviceTypes,
  renderIpIsp,
  renderOS,
  renderSubIDPlacements,
  renderRegions,
  renderAges,
} from "../utils/previewRender";
import styles from "./Preview.module.css";

export const Preview = () => {
  const {
    countriesAll,
    osAll,
    browserAll,
    categoriesAll,
    typeDeviceAll,
    manufacturerAll,
    regionsAll,
    agesAll,
    subidListAll,
    ipListAll,
  } = useContext(TargetingContext);

  const { targeting, campaignType } = useContext(CampaignContext);

  const availableRegions = useMemo(() => {
    if (targeting.countries?.items?.length && regionsAll[targeting.countries?.items?.[0]]) {
      const countryCode = targeting.countries?.items?.[0];
      return regionsAll[countryCode];
    }
    return [];
  }, [regionsAll, targeting.countries]);

  return (
    <div className={styles.previewContainer}>
      <h2>Included in targeting</h2>
      <div className={styles.previewSection}>
        <h3 className={styles.targetingInfoHeader}>Geo Targeting</h3>
        {targeting.countries &&
        targeting.countries?.items &&
        targeting.countries?.items?.length > 0 ? (
          renderCountries(targeting.countries, countriesAll)
        ) : (
          <div className={styles.targetingInfoValue}>All Countries</div>
        )}
      </div>
      <div className={styles.previewSection}>
        <h3 className={styles.targetingInfoHeader}>Country Regions</h3>
        {targeting.regions && targeting.regions.items && targeting.regions.items.length > 0 ? (
          renderRegions(targeting.regions, availableRegions)
        ) : (
          <div className={styles.targetingInfoValue}>All Country Regions</div>
        )}
      </div>
      <div className={styles.previewSection}>
        <h3 className={styles.targetingInfoHeader}>Device Types</h3>
        {targeting.device_types &&
        targeting.device_types.items.length > 0 &&
        targeting.device_types.items.length !== typeDeviceAll.length ? (
          renderDeviceTypes(targeting.device_types, typeDeviceAll)
        ) : (
          <span className={styles.targetingInfoValue}>All Types</span>
        )}
      </div>
      <div className={styles.previewSection}>
        <h3 className={styles.targetingInfoHeader}>Operating Systems</h3>
        <div>
          {targeting.os.items && targeting.os.items.length > 0 ? (
            renderOS(targeting.os.items, osAll)
          ) : (
            <div className={styles.targetingInfoValue}>All Operating Systems</div>
          )}
        </div>
      </div>
      <div className={styles.previewSection}>
        <h3 className={styles.targetingInfoHeader}>Browsers</h3>
        <div>
          {targeting.browsers && targeting.browsers.items.length > 0 ? (
            renderBrowsers(targeting.browsers.items, browserAll)
          ) : (
            <div className={styles.targetingInfoValue}>All Browsers</div>
          )}
        </div>
      </div>

      {campaignType === "Push" ? (
        <div className={styles.previewSection}>
          <h3 className={styles.targetingInfoHeader}>User Freshness</h3>
          {targeting.freshness_type === "hour" ? (
            <span className={styles.targetingInfoValue}>
              {targeting.user_age.from} - {targeting.user_age.to} hour
            </span>
          ) : targeting.age && targeting.age.items.length > 0 ? (
            renderAges(targeting.age, agesAll)
          ) : (
            <span className={styles.targetingInfoValue}>All Users</span>
          )}
        </div>
      ) : null}

      {campaignType === "Contextual Ad" && (
        <div className={styles.previewSection}>
          <h3 className={styles.targetingInfoHeader}>Traffic Categories</h3>
          {(targeting.keyword?.items?.[0]?.split("|") || []).length > 0 &&
          targeting.keyword?.items?.[0] !== "" ? (
            renderCategories(targeting.keyword, categoriesAll)
          ) : (
            <span className={styles.targetingInfoValue}>All Categories</span>
          )}
        </div>
      )}
      <div className={styles.previewSection}>
        <h3 className={styles.targetingInfoHeader}>SubID & Placements</h3>
        {targeting.subids &&
        targeting.subids.items &&
        targeting.subids.lists &&
        (targeting.subids.items.length > 0 || targeting.subids.lists.length > 0) ? (
          renderSubIDPlacements(targeting.subids, subidListAll)
        ) : (
          <span className={styles.targetingInfoValue}>All SubID & Placements</span>
        )}
      </div>
      <div className={styles.previewSection}>
        <h3 className={styles.targetingInfoHeader}>IP & ISP</h3>
        {targeting.ip &&
        targeting.ip.items &&
        targeting.ip.lists &&
        (targeting.ip.items.length > 0 || targeting.ip.lists.length > 0) ? (
          renderIpIsp(targeting.ip, ipListAll)
        ) : (
          <div className={styles.targetingInfoValue}>All IPs & ISPs</div>
        )}
      </div>

      <div className={styles.previewSection}>
        <h3 className={styles.targetingInfoHeader}>Device Manufacturers</h3>
        {targeting.device_manufacturers && targeting.device_manufacturers.items.length > 0 ? (
          renderDeviceManufacturers(targeting.device_manufacturers, manufacturerAll)
        ) : (
          <span className={styles.targetingInfoValue}>All Manufacturers</span>
        )}
      </div>
      {targeting.traffic_type && (
        <div className={styles.previewSection}>
          <h3 className={styles.targetingInfoHeader}>Traffic Types</h3>
          {targeting.traffic_type && renderTrafficTypes(targeting.traffic_type)}
        </div>
      )}
    </div>
  );
};
