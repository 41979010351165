import React from "react";
import "../layout.css";

export const Logo = ({ collapsed }) => {
  return (
    <div className="logoWrapper">
      <img
        src={collapsed ? "/images/logo-small.png" : "/images/logo.svg"}
        alt="Logo"
      />
      <span>Advertiser</span>
    </div>
  );
};
