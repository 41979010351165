import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import { Table } from "./components/Table";

import { mediaCampaignsService } from "../../services/campaigns";
import moment from "moment";
import { useDebounce, useFetch, useMount } from "../../utils/hooks";
import { BudgetModal } from "./components/BudgetModal";
import { CampaignTypeModal } from "./components/CampaignTypeModal";
import { useMemo } from "react";
import UserContext from "../../contexts/userContext";

const initialColumns = {
  budget: true,
  base_price: true,
  impressions: true,
  name: true,
  leads: true,
  spent: true,
  created: true,
  clicks: true,
  ctr: true,
};

export const MediaCampaigns = () => {
  const { userInfo } = useContext(UserContext);
  const [searchValue, setSearchValue] = useState("");
  const [pageConfig, setPageConfig] = useState({ currentPage: 1, pageSize: 30 });
  const [selectedFilters, setSelectedFilters] = useState({});
  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [checkedColumns, setCheckedColumns] = useState(initialColumns);
  const debouncedSearchValue = useDebounce(searchValue);
  // const urlId = props.match.params.id;
  const [sorting, setSorting] = useState({});

  const [campaignId, setCampaignId] = useState();
  const [budgetInfo, setBudgetInfo] = useState("");
  const [budgetModalVisible, setBudgetModalVisible] = useState(false);

  const [campaignModalVisible, setCampaignModalVisible] = useState(false);

  const location = useLocation();

  const noModalFlag = useMemo(() => {
    if (!userInfo?.permissions) {
      return false;
    }
    const permissionCount = Object.values(userInfo.permissions).filter((item) => item);
    if (permissionCount.length < 2) {
      if (userInfo.permissions.push) {
        return "Push";
      } else if (userInfo.permissions.premium) {
        return "Premium";
      } else if (userInfo.permissions.contextual) {
        return "Contextual";
      } else if (userInfo.permissions.smartlink) {
        return "Smartlink";
      } else {
        return "Push";
      }
    }
    return false;
  }, [userInfo.permissions]);

  useMount(() => {
    if (location.state) {
      const { status } = location.state;
      setSelectedFilters((prevState) => ({
        ...prevState,
        filters: { ...prevState.filters, status },
      }));
    }
  });

  const loadMediaCampaigns = (cancelToken) =>
    mediaCampaignsService.getCampaignsPaged({
      selectedFilters,
      sorting,
      checkedColumns,
      currentPage: pageConfig.currentPage,
      pageSize: pageConfig.pageSize,
      searchValue: debouncedSearchValue,
      cancelToken,
    });

  const [
    {
      data: { results: campaigns, summary: sumOfData, count: totalRows },
      isFetching,
    },
    getMediaCampaigns,
  ] = useFetch(loadMediaCampaigns);

  useEffect(() => setPageConfig({ currentPage: 1, pageSize: 30 }), [debouncedSearchValue]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getMediaCampaigns();
    }
  }, [debouncedSearchValue, pageConfig, sorting, getMediaCampaigns, selectedFilters]);

  const exportCampaings = (filters, dateRange) => {
    mediaCampaignsService.exportMediaCampaigns({
      filters,
      dateRange,
      checkedColumns,
    });
  };

  return (
    <div>
      <Table
        exportCampaings={exportCampaings}
        onSubmit={(filters, dateRange) => {
          setSelectedFilters({ filters, dateRange });
        }}
        sorting={sorting}
        setSorting={setSorting}
        dateRange={dateRange}
        setDateRange={setDateRange}
        campaigns={campaigns}
        sumOfData={sumOfData}
        totalRows={totalRows}
        pageConfig={pageConfig}
        loadingCampaigns={isFetching}
        getMediaCampaigns={getMediaCampaigns}
        searchValue={searchValue}
        setPageConfig={setPageConfig}
        onSearch={(search) => setSearchValue(search)}
        onActivate={getMediaCampaigns}
        onPause={getMediaCampaigns}
        onDelete={getMediaCampaigns}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        selectedFilters={selectedFilters}
        onActivateAllCampaigns={getMediaCampaigns}
        setCampaignId={setCampaignId}
        setBudgetInfo={setBudgetInfo}
        setBudgetModalVisible={setBudgetModalVisible}
        noModalFlag={noModalFlag}
        setCampaignModalVisible={setCampaignModalVisible}
      />
      <CampaignTypeModal
        visible={campaignModalVisible}
        setVisible={setCampaignModalVisible}
        noModalFlag={noModalFlag}
      />
      <BudgetModal
        budgetModalVisible={budgetModalVisible}
        setBudgetModalVisible={setBudgetModalVisible}
        campaignId={campaignId}
        budgetInfo={budgetInfo}
        onUpdate={getMediaCampaigns}
      />
    </div>
  );
};
