import React, { useState, useContext, useReducer } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Radio,
  Spin,
  notification,
  Switch,
  Row,
} from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import styles from "../CreativeBuilder.module.css";
import { CampaignContext } from "../../../../../../../../../contexts/campaignContext";
import { aiCreativeHeaderDescriptionGeneratorService } from "../../../../../../../../../services/aiCreativeHeaderDescriptionGeneratorService";
import { openWarningNotification } from "../../../../../../../../../utils/notifications";
import { reducerGenAiSwitchKeywords } from "../../../../../utils/reducers";
import Lottie from "react-lottie";
import coinisSmallLoader from "../../../../../../../../../components/coinis_loading_small.json";

export const AiGeneratorModal = ({
  campaignId,
  visible,
  setVisible,
  isReturnHeadlinesAndDescription,
  setHeadlines,
  setDescription,
}) => {
  const [formSingle] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { campaign } = useContext(CampaignContext);
  const [genAiSwitchKeywords, dispatchGenAiSwitchKeywords] = useReducer(
    reducerGenAiSwitchKeywords,
    []
  );

  const handleGenerateHeadlinesAndDescription = async (formValues) => {
    try {
      setLoading(true);
      const data = {
        attributes: genAiSwitchKeywords ? genAiSwitchKeywords : [],
        headlines:
          isReturnHeadlinesAndDescription.headlines &&
          isReturnHeadlinesAndDescription.multiheadlines &&
          formValues.numberOfHeadlines
            ? Number(formValues.numberOfHeadlines)
            : isReturnHeadlinesAndDescription.headlines &&
              !isReturnHeadlinesAndDescription.multiheadlines
            ? 1
            : 0,
        description: isReturnHeadlinesAndDescription.description ? true : false,
        language: formValues.language ? formValues.language : "",
        emojis: formValues.includeEmoji ? true : false,
        campaign_id: campaign.general.id ? Number(campaign.general.id) : null,
        offer_description: formValues.offer_keywords
          ? formValues.offer_keywords
          : "",
      };
      const response =
        await aiCreativeHeaderDescriptionGeneratorService.getHeaderAndDescription(
          data
        );
      if (response.success === false) {
        if (
          response.message &&
          response.message.includes("little bit tired now")
        ) {
          notification.open({
            description: response.message,
            duration: 5,
          });
        } else {
          openWarningNotification({ message: response.message, icon: false, duration: 5 });
        }
      } else {
        if (response.headlines && isReturnHeadlinesAndDescription.headlines) {
          const headlineResponses = response.headlines.map((item) => {
            return item.slice(0, 100);
          });
          headlineResponses.length > 1
            ? setHeadlines(headlineResponses)
            : isReturnHeadlinesAndDescription.multiheadlines ? setHeadlines(headlineResponses) : setHeadlines(headlineResponses[0]);
        }
        if (
          response.description &&
          isReturnHeadlinesAndDescription.description
        ) {
          setDescription(response.description.slice(0, 200));
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      formSingle.resetFields();
      dispatchGenAiSwitchKeywords({ type: "empty_ai_keywords" });
      setVisible(false);
    }
  };

  return (
    <Modal
      className={styles.aiGeneratorModal}
      title={
        isReturnHeadlinesAndDescription.headlines &&
        isReturnHeadlinesAndDescription.description
          ? "Generate ad headline and ad description"
          : isReturnHeadlinesAndDescription.headlines
          ? "Generate ad headline"
          : isReturnHeadlinesAndDescription.description
          ? "Generate ad description"
          : ""
      }
      visible={visible}
      footer={false}
      onCancel={() => {
        formSingle.resetFields();
        setVisible(false);
        dispatchGenAiSwitchKeywords({ type: "empty_ai_keywords" });
      }}
      width={700}
    >
      <Spin
        spinning={loading}
        indicator={
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: coinisSmallLoader,
            }}
            width={"auto"}
            height={"50px"}
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        }
        tip="Bear with us, AI minions are working hard on your request..."
      >
        <p className={styles.aiGeneratorModalDescription}>
          Generating new ad headlines and description will replace current
          information. You can generate your ad head lines and description up to
          5 times per campaign, so make sure to double-check the entered
          information to get the most accurate results.
        </p>
        <Form
          form={formSingle}
          layout="vertical"
          initialValues={{
            includeEmoji: true,
            language: "en",
            numberOfHeadlines:
              isReturnHeadlinesAndDescription.headlines &&
              isReturnHeadlinesAndDescription.multiheadlines
                ? "5"
                : "1",
          }}
          onFinish={handleGenerateHeadlinesAndDescription}
        >
          {isReturnHeadlinesAndDescription.headlines &&
            isReturnHeadlinesAndDescription.multiheadlines && (
              <Form.Item
                label="Number of ad headlines"
                name="numberOfHeadlines"
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="1">1</Radio.Button>
                  <Radio.Button value="2">2</Radio.Button>
                  <Radio.Button value="3">3</Radio.Button>
                  <Radio.Button value="4">4</Radio.Button>
                  <Radio.Button value="5">5</Radio.Button>
                </Radio.Group>
              </Form.Item>
            )}
          <Form.Item
            label="Offer keyword"
            name="offer_keywords"
            rules={[
              {
                required: true,
                message: "Please enter offer keyword!",
              },
            ]}
            style={{ marginBottom: "0" }}
          >
            <Input placeholder="Enter keywords here" />
          </Form.Item>
          <p className={styles.offerKeywordDescription}>
            We suggest to fill this field with up to <strong>5 words</strong>{" "}
            long. Make sure to enter keywords relevant to your offer to receive
            the most precise headlines and description. This can be something
            simple, for example{" "}
            <code className={styles.codeBlock}>cruise deals</code>.
          </p>
          <Form.Item label="Language" name="language">
            <Radio.Group className={styles.formLanguageRadioGroup}>
              <Radio.Button value="en">English</Radio.Button>
              <Radio.Button value="de">German</Radio.Button>
              <Radio.Button value="fr">French</Radio.Button>
              <Radio.Button value="dk">Danish</Radio.Button>
              <Radio.Button value="fi">Finnish</Radio.Button>
              <Radio.Button value="it">Italian</Radio.Button>
              <Radio.Button value="nl">Dutch</Radio.Button>
              <Radio.Button value="no">Norwegian</Radio.Button>
              <Radio.Button value="es">Spanish</Radio.Button>
              <Radio.Button value="se">Swedish</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Include emojis?" name="includeEmoji">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Row className={styles.formInputInline}>
            <Form.Item
              label="Highly convertible"
              tooltip={{
                title:
                  "Highly convertible creatives are ads that generate a high conversion rate by using effective headlines, descriptions, and CTAs to grab attention, communicate a message, and motivate action.",
                icon: <InfoCircleTwoTone />,
              }}
              valuePropName="checked1"
            >
              <Switch
                checked={genAiSwitchKeywords.includes("highly convertible")}
                onChange={(checked) => {
                  const payload = "highly convertible";
                  checked
                    ? dispatchGenAiSwitchKeywords({
                        type: "add_ai_keyword",
                        payload,
                      })
                    : dispatchGenAiSwitchKeywords({
                        type: "remove_ai_keyword",
                        payload,
                      });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Highly clickable"
              tooltip={{
                title:
                  "Highly clickable creatives are ads that have a high CTR by using attention-grabbing headlines, compelling descriptions, and strong CTAs to encourage users to click and engage with the ad.",
                icon: <InfoCircleTwoTone />,
              }}
              valuePropName="checked2"
            >
              <Switch
                checked={genAiSwitchKeywords.includes("highly clickable")}
                onChange={(checked) => {
                  const payload = "highly clickable";
                  checked
                    ? dispatchGenAiSwitchKeywords({
                        type: "add_ai_keyword",
                        payload,
                      })
                    : dispatchGenAiSwitchKeywords({
                        type: "remove_ai_keyword",
                        payload,
                      });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Fully compliant"
              tooltip={{
                title:
                  "Fully compliant ad creatives follow all advertising rules and guidelines to avoid legal and reputational issues and build trust with the audience.",
                icon: <InfoCircleTwoTone />,
              }}
              valuePropName="checked3"
            >
              <Switch
                checked={genAiSwitchKeywords.includes("fully compliant")}
                onChange={(checked) => {
                  const payload = "fully compliant";
                  checked
                    ? dispatchGenAiSwitchKeywords({
                        type: "add_ai_keyword",
                        payload,
                      })
                    : dispatchGenAiSwitchKeywords({
                        type: "remove_ai_keyword",
                        payload,
                      });
                }}
              />
            </Form.Item>
          </Row>

          <Form.Item style={{ marginBottom: "0px" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                type="ghost"
                onClick={(e) => {
                  e.preventDefault();
                  formSingle.resetFields();
                  setVisible(false);
                  dispatchGenAiSwitchKeywords({ type: "empty_ai_keywords" });
                }}
                style={{ borderRadius: "3px" }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.aiGeneratorFormSubmitButton}
              >
                {isReturnHeadlinesAndDescription.headlines &&
                isReturnHeadlinesAndDescription.description
                  ? "Generate ad headline and description"
                  : isReturnHeadlinesAndDescription.headlines
                  ? "Generate ad headline"
                  : isReturnHeadlinesAndDescription.description
                  ? "Generate ad description"
                  : "Generate ad headline and description"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
