import { matchPath } from "react-router-dom";
import { appRoutes } from "../../routes/appRoutes";

const routesWithHiddenSidebarAndHeader = appRoutes
  .filter(r => r.hideSidebarAndHeader)
  .map(r => r.path);

export const isHeaderAndSidebarHidden = location => {
  let sidebarIsHidden = false;

  routesWithHiddenSidebarAndHeader.forEach(path => {
    sidebarIsHidden =
      sidebarIsHidden || !!matchPath(location.pathname, { path, exact: true });
  });

  return sidebarIsHidden;
};
