import { Checkbox } from "antd";
import React from "react";
import { TimeRow } from "./TimeRow";
import { dayHours } from "../utils";
import styles from "./TimesTable.module.css";

export const TimesTable = ({
  handleChangeSpecificHour,
  handleChangeHour,
  handleChangeDay,
  targeting,
}) => {
  return (
    <div className={styles.timesTableWrapper}>
      <table className={styles.timeTable}>
        <thead>
          <tr>
            <th colSpan="3"></th>
            <th></th>
            {dayHours.map((item) => (
              <td key={item} align="center">
                {("0" + item).slice(-2)}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="3"></td>
            <td></td>
            {dayHours.map((hours, id) => (
              <td className={styles.hoursCheckbox} key={id} align="center">
                <Checkbox
                  checked={targeting.time.items.map((item) => item[id]).every((item) => item)}
                  onChange={(e) => handleChangeHour(id, e.target.checked)}
                />
              </td>
            ))}
          </tr>
          {targeting.time.items.map((hours, id) => (
            <TimeRow
              key={id}
              dayId={id}
              handleChangeSpecificHour={handleChangeSpecificHour}
              handleChangeDay={handleChangeDay}
              hours={hours}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
