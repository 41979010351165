import React, { useEffect, useState } from "react";
import { Select, Button, Drawer, Space, Form, Input, Typography, Row, Col, Table } from "antd";
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";

import styles from "../Lists.module.css";
import { listsService } from "../../../services/lists";
import { openSuccessNotification, openWarningNotification } from "../../../utils/notifications";
import { CampaignTypeCol } from "../components/CampaignTypeCol";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import useMediaQuery from "use-media-antd-query";

const { Text } = Typography;

export const ListForm = ({
  formVisible,
  setFormVisible,
  isEdit,
  isGlobal,
  onCreate,
  onEdit,
  listType,
  listId,
  listName,
  listContent,
  listCampaigns,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const screenSize = useMediaQuery();

  const subidListContent = Form.useWatch("content", form);

  const handleCreate = () => {
    form.validateFields().then(async (values) => {
      setLoading(true);
      try {
        const res = await listsService.addList({
          ...values,
          type: listType,
          content: listType === "subid" ? values.content.map((id) => id + ".*") : values.content,
        });
        if (res.success) {
          openSuccessNotification({ message: res.message });
          form.resetFields();
          setFormVisible(false);
          onCreate();
        } else {
          openWarningNotification({ message: res.message });
        }
      } catch (e) {
        openWarningNotification({ message: e.response.data.detail.content[0] });
        console.log(e);
      } finally {
        setLoading(false);
      }
    });
  };

  const handleEdit = () => {
    form.validateFields().then(async (values) => {
      setLoading(true);
      try {
        const res = isGlobal
          ? await listsService.editGlobalList({
              ...values,
              type: listType,
              content:
                listType === "subid" ? values.content.map((id) => id + ".*") : values.content,
            })
          : await listsService.editList(listId, {
              ...values,
              type: listType,
              content:
                listType === "subid" ? values.content.map((id) => id + ".*") : values.content,
            });
        if (res.success) {
          openSuccessNotification({ message: res.message, duration: 7 });
          form.resetFields();
          setFormVisible(false);
          onEdit();
        } else {
          openWarningNotification({ message: res.message, duration: 5 });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setFormVisible(false);
  };

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        name: listName,
        content: listContent,
      });
    }
  }, [isEdit, form, listName, listContent]);

  return (
    <Drawer
      className={styles.listFormDrawer}
      closable={false}
      title={
        <h3 className={styles.drawerTitle}>
          {isEdit ? "Edit" : "Create"} {isGlobal && "Global"}{" "}
          {listType === "subid" ? "SubID" : "IP"} List
        </h3>
      }
      width={screenSize === "sm" || screenSize === "xs" ? "100%" : isEdit ? "70%" : "35%"}
      onClose={handleCancel}
      footer={
        <div>
          <Button onClick={handleCancel} type="primary" ghost>
            Cancel
          </Button>
          <Button loading={loading} onClick={isEdit ? handleEdit : handleCreate} type="primary">
            {isEdit ? "Save" : "Create New"} {isGlobal ? "Global" : " "}{" "}
            {listType === "subid" ? "SubID" : "IP"} List
          </Button>
        </div>
      }
      visible={formVisible}
      placement="right"
      extra={
        <Space>
          <CloseOutlined
            style={{ color: "#D1D1D1", transform: "scale(1)" }}
            onClick={() => {
              form.resetFields();
              setFormVisible(false);
            }}
          />
        </Space>
      }
    >
      <Row gutter={24}>
        <Col sm={24} md={isEdit ? 12 : 24}>
          <h3>List details</h3>
          <div className={styles.listFormWrapper}>
            <Form layout="vertical" form={form}>
              <Form.Item
                label="List name"
                name="name"
                rules={
                  !isGlobal && [
                    {
                      required: true,
                      message: "Please enter list name!",
                    },
                  ]
                }
              >
                <Input
                  disabled={isGlobal}
                  showCount={!isGlobal}
                  maxLength={128}
                  placeholder="Enter list name here"
                />
              </Form.Item>
              <div>
                <h4>Add New {listType === "subid" ? "SubIDs" : "IPs"}</h4>
                <p className={styles.subidInfo}>
                  You can bulk insert your {listType === "subid" ? "SubIDs" : "IPs"} by pasting them
                  below. Delimiters are <Text keyboard>Space</Text> , <Text keyboard>,</Text> ,{" "}
                  <Text keyboard>;</Text> , <Text keyboard>↵</Text>. You can add up to 100{" "}
                  {listType === "subid" ? "SubIDs" : "IPs"}.
                </p>
                {listType === "ip" && (
                  <p>
                    Make sure that all rows are correctly formatted, e.g.:{" "}
                    <Text keyboard>192.168.1.1</Text>. You can input entire range by filling in a
                    row with this format: <Text keyboard>192.168.1.1/25</Text>. All IPs between{" "}
                    <Text keyboard>192.168.1.1</Text> and <Text keyboard>192.168.1.25</Text> will be
                    included.
                  </p>
                )}
                <div className={styles.inputWrapper}>
                  <div className={styles.subidInput}>
                    <div className={styles.buttonsWrapper}>
                      <CopyToClipboard
                        text={subidListContent && subidListContent.join(",")}
                        onCopy={() =>
                          openSuccessNotification({
                            message: "Successfully copied to clipboard!",
                          })
                        }
                      >
                        <Button
                          type="primary"
                          ghost
                          icon={<CopyOutlined />}
                          className={styles.copyBtn}
                        />
                      </CopyToClipboard>
                      <Button
                        type="primary"
                        ghost
                        className={styles.clearBtn}
                        onClick={() => form.setFieldsValue({ content: [] })}
                      >
                        Clear All
                      </Button>
                    </div>
                    <Form.Item
                      name="content"
                      rules={[
                        {
                          validator: (_, value) =>
                            value.length <= 500 ? Promise.resolve() : Promise.reject(new Error("")),
                          message: "You can add up to 500 items",
                        },
                      ]}
                    >
                      <Select
                        maxTagTextLength={20}
                        mode="tags"
                        tokenSeparators={[" ", ",", ";", "/n"]}
                        placeholder={`Type or paste ${
                          listType === "subid" ? "SubIDs" : "IPs"
                        } here`}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Col>
        {isEdit && (
          <Col sm={24} md={12}>
            <h3>Campaigns</h3>
            <div className={styles.campaignsWrapper}>
              <Table
                columns={[
                  {
                    title: "Campaign details",
                    dataIndex: "name",
                    render: (__, record) => {
                      return (
                        <>
                          <Link to={`/campaigns/${record.id}`} target="_blank">
                            <div className={styles.campaignName}>{record.name}</div>
                          </Link>
                          <div className={styles.campaignIdAndType}>
                            <span>{record.id}</span>
                            <CampaignTypeCol campaignType={record.type} />
                          </div>
                        </>
                      );
                    },
                  },
                ]}
                dataSource={listCampaigns}
                pagination={false}
                rowKey={({ id }) => id}
                // sticky={{ offsetScroll: 60 }}
                scroll={{
                  y: "max-content",
                  x: "max-content",
                  scrollToFirstRowOnChange: true,
                }}
              />
            </div>
          </Col>
        )}
      </Row>
    </Drawer>
  );
};
