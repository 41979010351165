import { callApi } from "./api";
import { getQueryString, getOffsetLimitFromPagination } from "../utils/query";

const handleOrdering = (sorting) => {
  const ordering = [];
  for (const key in sorting) {
    const val = sorting[key];
    if (val) {
      if (val === "ascend") {
        ordering.push("-" + key);
      } else {
        ordering.push(key);
      }
    }
  }
  return ordering;
};

class MediaCampaignsService {
  async getCampaignsPaged({
    selectedFilters,
    sorting,
    currentPage,
    checkedColumns,
    pageSize,
    searchValue,
    cancelToken,
  }) {
    const { filters, dateRange } = selectedFilters;
    let colParameter = "status,id,type";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      search: searchValue,
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      cmp_ids: filters.campaigns && filters.campaigns.join(","),
      status: filters.status && filters.status,
      type: filters.type && filters.type,
      ordering: ordering && ordering.join(","),
    });
    const response = await callApi({
      url: `/api/campaigns/${query}`,
      cancelToken,
    });
    return {
      results: response.data.items,
      summary: response.data.totals,
      count: response.data.total,
    };
    // return await { results: data, count: 5 };
  }

  async exportMediaCampaigns(selectedFilters) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "status,id,type";
    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });
    const query = getQueryString({
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      cmp_ids: filters.campaigns && filters.campaigns.join(","),
      export: "csv",
      columns: colParameter,
    });
    window.location = `/api/campaigns/${query}`;
  }

  async getAllCampaignsAutocomplete() {
    return await callApi({ url: "/api/campaigns/autocomplete/" });
  }

  async getAllCampaigns({ fields, status, columns } = "id, channel") {
    const query = getQueryString({ fields, status, columns });
    return await callApi({ url: `/api/campaigns/${query}` });
  }

  async getFormattedCampaign(id) {
    return await callApi({ url: `/api/media_campaigns_formatted/${id}/` });
  }

  async getIdentities() {
    return await callApi({ url: "/api/identities/" });
  }

  async getCampaignTypes() {
    return await callApi({ url: "/api/media_campaigns/types/" });
  }

  async getCampaignStatuses() {
    return await callApi({ url: "/api/media_campaigns/statuses/" });
  }

  async getLinkGenerateMethods() {
    return await callApi({
      url: "/api/media_campaigns/get_link_generate_methods/",
    });
  }

  async getSubdomains() {
    return await callApi({ url: "/api/redirect_subdomains/" });
  }

  async getS2sTypes() {
    return await callApi({ url: "/api/media_campaigns/s2s_types/" });
  }

  async getFilterRules() {
    return await callApi({ url: "/api/media_campaigns/filter_actions/" });
  }

  async getExternalUrl() {
    return await callApi({ url: "/api/external_urls/?fields=id,name" });
  }

  async getRuleTypes() {
    return await callApi({ url: "/api/media_campaigns/rule_types/" });
  }

  async getRuleMetrics() {
    return await callApi({ url: "/api/media_campaigns/rule_metrics/" });
  }

  async getCampaignPatternTypes() {
    return await callApi({
      url: "/api/media_campaigns/campaign_pattern_type/",
    });
  }

  async getProducts() {
    return await callApi({ url: "/api/products/" });
  }

  async getFormatedTargetingList(id) {
    return await callApi({ url: `/api/targeting_lists_formatted/${id}` });
  }

  async getCampaignsInfo(id) {
    return await callApi({ url: `/api/campaigns/${id}/` });
  }

  async addMediaCampaign(data) {
    return await callApi({
      url: "/api/media_campaigns_formatted/",
      method: "POST",
      data,
    });
  }

  async activateCampaign(id) {
    return await callApi({
      url: "/api/campaigns/status/",
      data: {
        campaign_id: id,
        status: "active",
      },
      method: "POST",
    });
  }

  async pauseCampaign(id) {
    return await callApi({
      url: "/api/campaigns/status/",
      data: {
        campaign_id: id,
        status: "paused",
      },
      method: "POST",
    });
  }

  // async unlockPattern(id) {
  //   return await callApi({
  //     url: `/api/media_campaigns_formatted/${id}/unlock_pattern_targeting/`,
  //     method: 'POST'
  //   });
  // }

  // async loadFromGlobalList(id, data) {
  //   return await callApi({
  //     url: `/api/media_campaigns_formatted/${id}/load_from_another_list/`,
  //     method: 'POST',
  //     data
  //   });
  // }

  async disabledCampaigns() {
    return await callApi({ url: "/api/campaigns/disabled/" });
  }

  async activateAllDisabled() {
    return await callApi({
      url: "api/campaigns/enable/?all=true",
      method: "POST",
    });
  }

  async editMediaCampaign(id, data) {
    return await callApi({
      url: `/api/media_campaigns_formatted/${id}/`,
      method: "PUT",
      data,
    });
  }

  async deleteCampaign(id) {
    return await callApi({
      url: `/api/campaigns/${id}/delete/`,
      method: "DELETE",
    });
  }
  async cloneCampaign(id) {
    return await callApi({
      url: `/api/campaigns/${id}/clone/`,
      method: "POST",
    });
  }

  async uploadCreativeImg(data) {
    return await callApi({
      url: "/api/campaigns/upload/",
      method: "POST",
      data,
    });
  }

  async changeAllCreativesUrl(campaign_id, data) {
    return await callApi({
      url: `/api/campaigns/${campaign_id}/creatives/url_update/`,
      method: "POST",
      data,
    });
  }

  async getAllLanguages() {
    return await callApi({ url: "/api/languages" });
  }

  generateOptions = (arr) => {
    return arr.map((campaign) => {
      return {
        label: campaign.name,
        value: campaign.id,
      };
    });
  };
}

export const mediaCampaignsService = new MediaCampaignsService();
