import React from "react";
import { Select } from "antd";
const { Option } = Select;

export const generateCountries = (arr) => {
  return arr.map((country) => (
    <Option key={country.code} value={country.code}>
      {country.name}
    </Option>
  ));
};
export const generateCampagins = (arr) => {
  return arr.map((campaign) => (
    <Option key={campaign.id} value={campaign.id}>
      {campaign.name}
    </Option>
  ));
};
const formatCampaignTypes = (input) => {
  const mapping = {
      push: { label: "Push", value: "Push" },
      contextual: { label: "Contextual Ad", value: "Contextual Ad" },
      smartlink: { label: "Smartlink", value: "Smartlink" },
      premium: { label: "Premium Push", value: "Premium" }
  };
  const result = [];
  for (const key in input) {
      if (input[key] && mapping[key]) {
          result.push(mapping[key]);
      }
  }
  return result;
}
export const generateCampaignTypes = (permissions) => {
  return formatCampaignTypes(permissions).map((campaign) => (
    <Option key={campaign.value} value={campaign.value}>
      {campaign.label}
    </Option>
  ));
};
export const generateOperatingSystems = (arr) => {
  return arr.map((campaign) => (
    <Option key={campaign.id} value={campaign.id}>
      {campaign.name}
    </Option>
  ));
};
export const generateBrowsers = (arr) => {
  return arr.map((campaign) => (
    <Option key={campaign.id} value={campaign.id}>
      {campaign.name}
    </Option>
  ));
};
