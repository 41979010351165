import React, { useState } from 'react';
import { InputNumber, Button, Tooltip } from 'antd';
import { PlusOutlined, MinusOutlined, CheckOutlined } from '@ant-design/icons';

import { newCampaignService } from '../../../services/newCampaign';
import { openErrorNotification, openSuccessNotification } from '../../../utils/notifications';

import styles from '../Reports.module.css';

export const CpcCol = ({ cpc, item, campaignId, setCpcValue }) => {

  const [changeActive, setChangeActive] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [priceDisp, setPriceDisp] = useState(parseFloat(cpc).toFixed(3));

  const handlePriceUpdate = async (value) => {
    setCpcValue(value);
    setDisabled(true);
    const data = {
      pricing: {
        price: value
      }
    };
    let newValue = parseFloat(value).toFixed(3);
    try {
      const res = await newCampaignService.updateCampaign(data, campaignId);
      if (res.success) {
        openSuccessNotification({ message: res.message });
        setPriceDisp(newValue);
      } else {
        openErrorNotification({ message: res.message });
        setPriceDisp(parseFloat(cpc).toFixed(3));
      }
    } catch (e) {
      console.log(e);
      openErrorNotification({ message: 'Something went wrong!' });
    } finally {
      setChangeActive(false);
      setDisabled(false);
    }
  };

  return (
    <div className={styles.pricingColumn} style={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled && 'none' }}>
      <div className={styles.priceUpdateContainer}>
        <Tooltip title="-10%">
          <Button size='medium' onClick={() => handlePriceUpdate(priceDisp * 0.9)}>
            <MinusOutlined />
          </Button>
        </Tooltip>
        {item.base_price && (
          <div className={styles.mainPriceBox}>
            <Tooltip title="Make sure you press enter or click checkmark to save CPC">
              <InputNumber
                prefix="$"
                value={priceDisp}
                onChange={(value) => {
                  setChangeActive(true);
                  setPriceDisp(value);
                }}
                onPressEnter={(e) => handlePriceUpdate(e.target.value.substring(1) * 1)}
              />
            </Tooltip>
          </div>
        )}
        <Tooltip title="+10%">
          <Button size='medium' onClick={() => handlePriceUpdate(priceDisp * 1.1)}>
            <PlusOutlined />
          </Button>
        </Tooltip>
      </div>
      <Tooltip title="Save CPC">
        <Button disabled={!changeActive} className={changeActive ? styles.confirmButtonGreen : styles.confirmButtonDisabled} size="small" icon={<CheckOutlined />} onClick={() => handlePriceUpdate(priceDisp)} />
      </Tooltip>
    </div>
  );
};
