import React from 'react';
import { Button, Tooltip, Modal } from 'antd';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import styles from '../Lists.module.css';
import { openErrorNotification, openSuccessNotification } from '../../../utils/notifications';
import { listsService } from '../../../services/lists';

export const ActionsTableCol = ({
    id,
    setListId,
    name,
    setListName,
    content,
    setListContent,
    campaigns,
    setListCampaigns,
    onDelete,
    setIsEdit,
    isGlobal,
    editable,
    setIsGlobal,
    toggleActionPending,
    setFormVisible,
    listType
}) => {

    const handleEditList = () => {
        setIsEdit(true);
        setIsGlobal(isGlobal)
        setListId(id);
        setListName(name);
        if (listType === 'subid') {
            setListContent(content.map((id) => id.substring(0, id.length - 2)));
        } else {
            setListContent(content);
        }
        setListCampaigns(campaigns);
        setFormVisible(true);
    }

    const deleteList = async () => {
        try {
            toggleActionPending(true);
            await listsService.deleteList(id);
            openSuccessNotification({ message: 'List successfully deleted!' });
            await onDelete();
        } catch (e) {
            openErrorNotification({ message: 'Could not delete a list!' });
        } finally {
            toggleActionPending(false);
        }
    };

    const handleDeleteList = () => {
        Modal.confirm({
            centered: true,
            maskClosable: true,
            title: 'Are you sure you want to proceed?',
            content: `Deleting this list will remove all of its ${listType === 'subid' ? 'SubIDs' : 'IPs'} from linked campaigns.`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => deleteList(id),
            onCancel() { }
        });
    };

    return (
        <div className={styles.actions}>
            <Tooltip title={editable ? "Edit" : "Your campaigns are being updated, please wait."}>
                <Button disabled={!editable} onClick={handleEditList} style={{ color: '#737373' }} icon={<EditOutlined />}></Button>
            </Tooltip>
            <Tooltip title="Delete">
                <Button disabled={isGlobal} onClick={handleDeleteList} style={{ color: '#737373' }} icon={<DeleteOutlined />}></Button>
            </Tooltip>
        </div >
    );
};
