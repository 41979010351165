import React, { useState } from "react";
import { CopyOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, Spin, Modal, Collapse, Tag } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import { openSuccessNotification } from '../../../utils/notifications';
import { activationService } from "../../services/activation";
import styles from "./account-activation.module.css";
import {
  openSuccessNotification,
  openWarningNotification,
} from "../../utils/notifications";
import { userProfileService } from "../../services/userProfile";

const { Panel } = Collapse;

export const AccountActivation = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [authenticationModalVisible, setAuthenticationModalVisible] = useState(false);
  const [backupCodesModalVisible, setBackupCodesModalVisible] = useState(false);
  const [backupCodes, setBackupCodes] = useState([]);
  const [error, setError] = useState(false);
  const [loadingActivation2FA, setLoadingActivation2FA] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loadingQR, setLoadingQR] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [emailSent, setEmailSent] = useState(null);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [code, setCode] = useState(['', '', '', '', '', '']);

  const onSubmit = async values => {
    const passObj = { ...values, login: !!values.login };

    setLoading(true);
    try {
      const res = await activationService.activateUserProfile(passObj);
      if (res.success) {
        openSuccessNotification({ message: res.message });
        if (!values.login) {
          window.location.href = "/login/";
        } else {
          setAuthenticationModalVisible(true);
        }
      } else {
        openWarningNotification({ message: res.message });
      }
      // openSuccessNotification({ message: 'Password changed successfully!' });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const validatePasswordConfirm = () => {
    let newPassword = form.getFieldValue("password");
    let confirmPassword = form.getFieldValue("confirm_password");
    if (newPassword !== confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  const validateNewPassword = value => {
    return /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/.test(value);
  };

  const handlePanelChange = async (key) => {
    setSelectedValue(key);
  };

  const showQRCode = async (selectedValue) => {
    setLoadingQR(true);
    try {
      const res = await userProfileService.activateMethod(selectedValue);
      if (res.details) {
        const qr = await userProfileService.generateQR({ qr_link: res.details })
        if (qr.success) {
          setQrCode(qr.data);
        } else {
          setAuthenticationModalVisible(false);
          openWarningNotification({ message: qr.message });
          setTimeout(() => {
            window.location.href = "/";
          }, 2000)
        }
      } else {
        setAuthenticationModalVisible(false);
        openWarningNotification({ message: 'Something went wrong, try again later.' });
        setTimeout(() => {
          window.location.href = "/";
        }, 2000)
      }
    } catch (e) {
      setAuthenticationModalVisible(false);
      openWarningNotification({ message: 'Something went wrong, try again later.' });
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
      console.log(e);
    } finally {
      setLoadingQR(false);
    }
  }

  const sendEmail = async (selectedValue) => {
    setLoadingEmail(true);
    try {
      const res = await userProfileService.activateMethod(selectedValue);
      setEmailSent(res.details);
    } catch (e) {
      openWarningNotification({ message: 'Something went wrong, try again later.' });
      setTimeout(() => {
        window.location.href = "/";
      }, 2000)
      console.log(e);
    } finally {
      setLoadingEmail(false);
    }
  }

  const handleInputChange = (index, value) => {
    if (value >= 0 && value <= 9) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Move focus to the next input field if available
      if (index < 5 && value !== '') {
        document.getElementById(`input-${index + 1}`).focus();
      }
    }
  };

  const handleInputKeyDown = (index, e) => {
    if (e.key === 'Backspace' && code[index] === '') {
      // Move focus to the previous input field on backspace if available
      if (index > 0) {
        document.getElementById(`input-${index - 1}`).focus();
      }
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text');

    // Check if pasted data is a 6-digit string
    if (/^\d{6}$/.test(pastedData)) {
      const newCode = pastedData.split('').slice(0, 6);
      setCode(newCode);
    } else if (pastedData.length === 1) {
      // If pasted data is a single digit, paste it into the current input field
      const currentInput = document.activeElement;
      const currentIndex = parseInt(currentInput.id.replace('input-', ''), 10);

      if (!isNaN(currentIndex) && currentIndex >= 0 && currentIndex <= 5) {
        handleInputChange(currentIndex, pastedData);
      }
    }

    e.preventDefault(); // Prevent default paste behavior
  };

  const downloadText = (text, filename) => {
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  const activate2FA = async (selectedValue) => {
    if (!selectedValue) {
      openWarningNotification({ message: 'Please select 2FA method.' });
    } else {
      setLoadingActivation2FA(true);
      try {
        const res = await userProfileService.confirmActivation({ code: code.join('') }, selectedValue);
        if (res.backup_codes) {
          setBackupCodes(res.backup_codes);
          openSuccessNotification({ message: 'Activation successful!' });
          setAuthenticationModalVisible(false);
          setBackupCodesModalVisible(true);
        } else {
          openWarningNotification({ message: res.code });
        }
      } catch (e) {
        setError(true)
        openWarningNotification({ message: e.response.data.code });
        console.log(e);
      } finally {
        setLoadingActivation2FA(false);
        setTimeout(() => {
          setError(false)
        }, 1000)
      }
    }
  }

  const displayCodes = (codes) => {
    return (
      <div className={styles.codesWrapper}>
        {codes.map((code, index) => (
          <span key={index}>{code}</span>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className={styles.activationSection}>
        <div className={styles.activation}>
          <div className={styles.activationLeft}>
            <div className={styles.logoLight}>
              <a href="/">
                <img src="images/logo-light.svg" alt="logo-light" />
              </a>
              <p>Activate Account</p>
            </div>
            <img
              className={styles.leftBg}
              src="images/activate-bg.png"
              alt="activate-bg"
            />
          </div>
          <div className={styles.activationRight}>
            <div className={styles.center}>
              <Spin spinning={loading}>
                <div className={styles.activationForm}>
                  <img src="images/activate-logo.svg" alt="logo" />
                  <h1>Activate Account</h1>
                </div>
                <Form
                  form={form}
                  name="userForm"
                  onFinish={onSubmit}
                  onFinishFailed={({ errorFields }) => {
                    form.scrollToField(errorFields[0].name);
                  }}
                >
                  {/* <p className={styles.formLabels}>* Password</p> */}
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        validator: async (rule, value) => {
                          if (!validateNewPassword(value))
                            throw new Error(
                              "Password must contain uppercase letter, lowercase letter and a number!"
                            );
                        },
                      },
                      {
                        required: true,
                        message: "Please type new password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter password"
                      size="middle"
                      iconRender={visible =>
                        visible ? (
                          <img alt="showpass" src="../images/show.png" />
                        ) : (
                          <img alt="hidepass" src="../images/hide.png" />
                        )
                      }
                    />
                  </Form.Item>
                  {/* <p className={styles.formLabels}>* Confirm Password</p> */}
                  <Form.Item
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: "Please type password confirmation!",
                      },
                      {
                        validator: async (rule, value) => {
                          if (validatePasswordConfirm())
                            throw new Error("Passwords don't match!");
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter identical password"
                      size="middle"
                      iconRender={visible =>
                        visible ? (
                          <img alt="showpass" src="../images/show.png" />
                        ) : (
                          <img alt="hidepass" src="../images/hide.png" />
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item name="login" valuePropName="checked">
                    <Checkbox>Sign in using these credentials</Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Activate
                    </Button>
                  </Form.Item>
                </Form>
              </Spin>
            </div>
          </div>
        </div>
      </div>
      <Modal
        wrapClassName={styles.modal2FA}
        closable={false}
        visible={authenticationModalVisible}
        zIndex={1200}
        footer={
          <>
            <Button className={styles.skipButton} onClick={() => window.location.href = "/"}>
              Skip for now
            </Button>
            <Button
              className={error ? styles.errorCode : ''}
              loading={loadingActivation2FA}
              disabled={!(code.every((value) => value !== ''))}
              type="primary"
              onClick={() => activate2FA(selectedValue)}
            >
              Confirm & activate 2FA
            </Button>
          </>
        }
      >
        <div className={styles.twoFactorContainer}>
          <h2>Set up 2-Factor authentication</h2>
          <div className={styles.firstStep}>
            <h3>1. Select an authentication method</h3>
            <p>Select your preferred authentication method from the options below.</p>
            <Collapse
              accordion
              onChange={handlePanelChange}
              activeKey={selectedValue}
            >
              <Panel
                header={
                  <>
                    <h3>
                      Authentication app
                      <Tag className={styles.recommendedTag} >RECOMMENDED</Tag>
                    </h3>
                    <p>Use the Google Authenticator app to generate one-time security codes.</p>
                  </>
                }
                key="app"
              >
                {!qrCode ?
                  <Button
                    loading={loadingQR}
                    type="primary"
                    ghost
                    onClick={() => showQRCode(selectedValue)}
                  >
                    Show QR code
                  </Button>
                  :
                  <div className={styles.qrContainer}>
                    <div className={styles.imageWrapper}>
                      <img src={qrCode.qr_path} alt="qr code" />
                      <p>Scan this QR code with Google Authenticator.</p>
                    </div>
                    <div className={styles.keyWrapper}>
                      <p>If you have any issues with scanning the QR code, enter the code below into the app manually.</p>
                      <Input disabled value={qrCode.secret_key} />
                      <CopyToClipboard
                        text={qrCode.secret_key}
                        onCopy={() =>
                          openSuccessNotification({
                            message: "Successfully copied to clipboard!",
                          })
                        }
                      >
                        <span className={styles.copyLabel} type="primary">Copy code</span>
                      </CopyToClipboard>
                      <p>Once you’re done, proceed to next step.</p>
                    </div>
                  </div>}
              </Panel>
            </Collapse>
            <Collapse
              accordion
              onChange={handlePanelChange}
              activeKey={selectedValue}
            >
              <Panel
                header={
                  <>
                    <h3>E-mail verification</h3>
                    <p>Security codes will be sent to registered email.</p>
                  </>
                }
                key="email"
              >
                <Button
                  loading={loadingEmail}
                  type="primary"
                  ghost
                  onClick={() => sendEmail(selectedValue)}
                >
                  {emailSent ? 'Resend' : 'Send'} e-mail
                </Button>
                <p className={styles.emailSentStyle}>{emailSent}</p>
              </Panel>
            </Collapse>
          </div>
          <div className={styles.secondStep}>
            <h3>2. Enter 6-digit code</h3>
            <p>After you receive a 6-digit code via your preferred method, enter it below.</p>
            <div className={styles.codeWrapper} >
              <div>
                {code.map((value, index) => (
                  <Input
                    key={index}
                    value={value}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyDown={(e) => handleInputKeyDown(index, e)}
                    maxLength={1}
                    id={`input-${index}`}
                    onPaste={handlePaste}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        wrapClassName={styles.modalBackupCodes}
        closable={false}
        visible={backupCodesModalVisible}
        zIndex={1200}
        width={700}
        footer={
          <>
            <div>
              <CopyToClipboard
                text={backupCodes.join(', ')}
                onCopy={() =>
                  openSuccessNotification({
                    message: "Successfully copied to clipboard!",
                  })
                }
              >
                <Button type="primary" ghost icon={<CopyOutlined />}>
                  Copy codes
                </Button>
              </CopyToClipboard>
              <Button type="primary" ghost onClick={() => {
                const codesText = backupCodes.join('\n');
                downloadText(codesText, `backup_codes_${selectedValue}.txt`);
              }}>
                Download codes
              </Button>
            </div>
            <Button type="primary" onClick={() => window.location.href = "/"}>
              Go To Platform
            </Button>
          </>
        }
      >
        <div className={styles.backupCodesContainer}>
          <h2>Backup Codes</h2>
          <p>You can find your back-up below. If you are for any reason unable to log in with available authentication methods, you can enter one of these codes.</p>
          <p className={styles.note}>Make sure to copy them or download the info provided below, because you will not be able to access these exact codes again.</p>
          {displayCodes(backupCodes)}
        </div>
      </Modal>
    </>
  );
};
