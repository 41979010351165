import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Spin, Button } from "antd";
import styles from "./Payment.module.css";
import { financeService } from "../../../services/finance";
import { PaymentCompleted } from "./components/PaymetCompleted";
import { PaymentFailed } from "./components/PaymentFailed";
import { PaymentCanceled } from "./components/PaymentCanceled";

import { mediaCampaignsService } from "../../../services/campaigns";

export const PaymentDetails = () => {
  const { id } = useParams();
  const [payDetails, setPayDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [countDisabled, setCountDisabled] = useState();
  const [campaignsLoading, setCampaignsLoading] = useState(false);

  useEffect(() => {
    const getDisabledCampaigns = async () => {
      try {
        setCampaignsLoading(true);
        const disabledCampaigns = await mediaCampaignsService.getAllCampaigns({
          status: "disabled",
          columns: "created",
        });
        if (disabledCampaigns.success) {
          setCountDisabled(disabledCampaigns.data.total);
        }
      } finally {
        setCampaignsLoading(false);
      }
    };
    getDisabledCampaigns();
  }, []);

  useEffect(() => {
    const asyncFunc = async () => {
      setLoading(true);
      try {
        const data = await financeService.getPaymentDetails(id);
        setPayDetails(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    Number(id) > -1 && asyncFunc();
  }, [id]);

  const renderPaymentDetails = details => {
    switch (details.transaction_status) {
      case "completed":
        return <PaymentCompleted details={details} />;
      case "failed":
        return <PaymentFailed details={details} />;
      case "cancelled":
        return <PaymentCanceled details={details} />;
      default:
        return <Spin size="large"></Spin>;
    }
  };

  return (
    <Spin spinning={loading} wrapperClassName={styles.paymentDetailsContainer}>
      {Number(id) < 0 ? (
        <div className={styles.failPaymentContainer}>
          <img src="/images/something-went-wrong-bug.png" alt="" />
          <h4>Something Went Wrong</h4>
          <p>
            An error was recorded and your payment was unable to go through.
            We're sorry for the inconvenience. You can contact our customer
            service team for further assistance.
          </p>
          <p>Thank you for your patience and understanding.</p>
          <Link to={"/finance/add"}>
            <Button type="primary" className={styles.goBack}>
              Back to Add Funds
            </Button>
          </Link>
        </div>
      ) : (
        <>
          {renderPaymentDetails(payDetails)}
          {payDetails.transaction_status === "completed" ? (
            <Spin spinning={campaignsLoading} style={{ height: "150px" }}>
              {countDisabled > 0 ? (
                <div className={styles.enableCampaigns}>
                  <h3>You have campaigns you’re able to run</h3>
                  <p>
                    A few of your campaigns have been <b>disabled</b> while
                    you’ve had to funds to them, but they’re ready to go now.
                  </p>
                  <Link
                    to={{
                      pathname: "/campaigns",
                      state: { status: "disabled" },
                    }}
                  >
                    <Button type="primary">See Campaigns You Can Run</Button>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </Spin>
          ) : (
            ""
          )}
        </>
      )}
    </Spin>
  );
};
