import React, { useState, useEffect, useReducer, useCallback } from "react";
import moment from "moment";

import { Table } from "./components/Table";
import styles from "./BillingAccountStatements.module.css";
import { CampaignFilters } from "./components/CampaignsFilters";
import { filtersReducer } from "./utils/reducers";
import { financeAccountStatementsService } from "../../../services/financeAccountStatements";
import { useFetch } from "../../../utils/hooks";
import { TableTitle } from "../components/TableTitle";

export const AccountStatements = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectedFilters, dispatchSelectedFilters] = useReducer(filtersReducer, {});
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(30, "days"),
    to: moment(),
  });
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState({});

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting, setSorting]
  );

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date'
    },
    {
      title: (
        <TableTitle
          text={'Debit'}
          order={sorting['debit'] ? sorting['debit'] : false}
          value={'debit'}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: 'debit'
    },
    {
      title: (
        <TableTitle
          text={'Credit'}
          order={sorting['credit'] ? sorting['credit'] : false}
          value={'credit'}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: 'credit'
    },
    {
      title: (
        <TableTitle
          text={'Balance'}
          order={sorting['balance'] ? sorting['balance'] : false}
          value={'balance'}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: 'balance'
    },
    {
      title: 'Note',
      dataIndex: 'note'
    }
  ];

  const loadMediaCampaigns = (cancelToken) =>
    financeAccountStatementsService.getStatementsPaged({
      selectedFilters,
      columns,
      currentPage,
      pageSize,
      cancelToken,
      sorting
    });

  const [
    {
      data: { results: campaigns, summary: sumOfData, count: totalRows },
      isFetching,
    },
    getMediaCampaigns,
  ] = useFetch(loadMediaCampaigns);

  useEffect(() => {
    dispatchSelectedFilters({
      payload: {
        filters: {},
        dateRange: { from: moment().subtract(30, "days"), to: moment() },
      },
    });
  }, []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getMediaCampaigns();
    }
  }, [currentPage, pageSize, getMediaCampaigns, selectedFilters, sorting]);

  return (
    <div className={styles.campaignsTable}>
      <h4>Account Statement</h4>
      <p>Follow your daily revenue</p>
      <CampaignFilters
        submit={(filters) => {
          dispatchSelectedFilters({
            type: "",
            payload: { filters, dateRange },
          });
        }}
        filters={filters}
        setFilters={setFilters}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Table
        tableColumns={columns}
        campaigns={campaigns}
        sumOfData={sumOfData}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getMediaCampaigns={getMediaCampaigns}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        selectedFilters={selectedFilters}
        date
      />
    </div>
  );
};
