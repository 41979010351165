import { Checkbox } from "antd";
import React, { useMemo } from "react";
import styles from "./CategoryKeyword.module.css";

export const CategoryKeyword = ({
  selectedValues,
  setSelectedCategories,
  label,
  value,
}) => {
  const isChecked = useMemo(
    () => selectedValues.includes(value),
    [selectedValues, value]
  );

  const onChange = e => {
    if (e.target.checked) {
      const newSelectedValues = [...selectedValues, value];
      setSelectedCategories(newSelectedValues.join("|"));
    } else {
      const newSelectedValues = selectedValues.filter(item => item !== value);
      setSelectedCategories(newSelectedValues.join("|"));
    }
    // setSelectedValues((oldValues) => {
    //   return { ...oldValues, [category_name]: list };
    // });

    // console.log(list, value);
    // setSelectedCategories(list, value);
  };

  return (
    <div className={styles.categoryContainer}>
      <Checkbox onChange={onChange} checked={isChecked}>
        {label}
      </Checkbox>
    </div>
  );
};
