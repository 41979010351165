import "./layout.css";

import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import Media from "react-media";

import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { isHeaderAndSidebarHidden } from "./utils/hiddenSidebar";
// import { CampaignContextProvider } from "../contexts/campaignContext";
// import { TargetingContextProvider } from "../contexts/targetingContext";
import { BroadcastNotification } from './components/BroadcastNotification';
import { appRoutes } from "../routes/appRoutes";
import { AppRoutes } from "../routes/Routes";
import { UserContextProvider } from "../contexts/userContext";
import { TargetingContextProvider } from "../contexts/targetingContext";
import { NotificationContextProvider } from '../contexts/notificationContext';
import { AgreementModal } from './components/AgreementModal';
import { InterstitialNotification } from "./components/InterstitialNotification";

export const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSidebarVisible = !isHeaderAndSidebarHidden(location);
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/campaigns");
    }
  }, [navigate, location.pathname]);

  const wasCollapsed = localStorage.getItem("collapse");
  const [collapsed, setCollapsed] = useState(
    wasCollapsed ? wasCollapsed === "true" : window.innerWidth < 1400
  );
  const [agreementModalVisible, setAgreementModalVisible] = useState(false);

  return (
    <Layout style={{ minHeight: "100vh", maxHeight: "100vh", zIndex: "2" }}>
      {isSidebarVisible && (
        <Media queries={{ small: { minWidth: 599 } }}>
          {matches => (
            <Sidebar
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              isSmallScreen={!matches.small}
              selectedKey={location.pathname}
            />
          )}
        </Media>
      )}
      <Layout>
        <TargetingContextProvider>
          <UserContextProvider>
            <NotificationContextProvider>
              {isSidebarVisible && (
                <Media queries={{ small: { minWidth: 599 } }}>
                  {matches => (
                    <Header
                      collapsed={collapsed}
                      setCollapsed={setCollapsed}
                      isSmallScreen={!matches.small}
                      setAgreementModalVisible={setAgreementModalVisible}
                    />
                  )}
                </Media>
              )}
              <BroadcastNotification />
              <Layout.Content style={{ margin: isSidebarVisible ? "20px" : 0 }}>
                <div
                  style={{
                    padding:
                      isSidebarVisible &&
                        !location.pathname.includes("campaigns/")
                        ? 24
                        : 0,
                    background: "#fff",
                    minHeight: "87vh",
                  }}
                >
                  <AppRoutes routes={appRoutes} />
                </div>
                <AgreementModal
                  agreementModalVisible={agreementModalVisible}
                  setAgreementModalVisible={setAgreementModalVisible}
                />
              </Layout.Content>
              <InterstitialNotification />
            </NotificationContextProvider>
          </UserContextProvider>
        </TargetingContextProvider>
      </Layout>
    </Layout>
  );
};
