import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Radio } from "antd";
import { GlobalBlacklist } from './components/GlobalBlacklist';
import { UserLists } from './components/UserLists';
import styles from './Lists.module.css';

import moment from 'moment';
import { listsService } from '../../services/lists';
import { useFetch } from '../../utils/hooks';
import { ActionsTableCol } from './components/ActionTableCol';

import { ListForm } from './components/ListForm';
import { TableTitle } from './components/TableTitle';

export const Lists = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [actionPending, toggleActionPending] = useState(false);

    const [formVisible, setFormVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isGlobal, setIsGlobal] = useState(false);

    const [listType, setListType] = useState('subid');
    const [listId, setListId] = useState();
    const [listName, setListName] = useState();
    const [listContent, setListContent] = useState([]);
    const [listCampaigns, setListCampaigns] = useState([]);
    const [sorting, setSorting] = useState({});

    const handleSortingClick = useCallback(
        (value, order, multi = false) => {
            setSorting(() => {
                if (!multi) {
                    return { [value]: order };
                }
                if (sorting[value] === order) {
                    const newOrdering = { ...sorting };
                    delete newOrdering[value];
                    return newOrdering;
                }
                return { ...sorting, [value]: order };
            });
        },
        [sorting, setSorting]
    );

    const loadUserLists = (cancelToken) =>
        listsService.getListsPaged({
            currentPage,
            pageSize,
            cancelToken,
            listType,
            sorting
        });

    const [
        {
            data: { results: lists, global_list: globalBlacklist, count: totalRows },
            isFetching
        },
        getUserLists
    ] = useFetch(loadUserLists);

    useEffect(() => setCurrentPage(0), []);

    useEffect(() => {
        getUserLists();
    }, [currentPage, pageSize, getUserLists, listType, sorting]);

    const blacklistColumns = [
        {
            title: 'List Name',
            dataIndex: 'name',
            width: 350,
            render: (name) => {
                return <p className={styles.overflowName}>{name}</p>;
            }
        },
        {
            title: 'Number of Items',
            dataIndex: 'content',
            render: (content) => {
                return content.length;
            }
        },
        {
            title: 'Number of Campaigns',
            dataIndex: 'campaigns_count',
        },
        {
            title: 'Date Created',
            dataIndex: 'date_created',
            render: (date_created) => <span>{date_created && moment(date_created).format('DD MMM YYYY hh:mm:ss')}</span>
        }
    ];

    const columns = [
        {
            title: 'List Name',
            dataIndex: 'name',
            width: 350,
            render: (name) => {
                return <p className={styles.overflowName}>{name}</p>;
            }
        },
        {
            title: (
                <TableTitle
                    text={'Number of Items'}
                    order={sorting['content_count'] ? sorting['content_count'] : false}
                    value={'content_count'}
                    onSortingClick={handleSortingClick}
                />
            ),
            dataIndex: 'content',
            render: (content) => {
                return content.length;
            }
        },
        {
            title: (
                <TableTitle
                    text={'Number of Campaigns'}
                    order={sorting['campaigns_count'] ? sorting['campaigns_count'] : false}
                    value={'campaigns_count'}
                    onSortingClick={handleSortingClick}
                />
            ),
            dataIndex: 'campaigns_count',
        },
        {
            title: (
                <TableTitle
                    text={'Date Created'}
                    order={sorting['date_created'] ? sorting['date_created'] : false}
                    value={'date_created'}
                    onSortingClick={handleSortingClick}
                />
            ),
            dataIndex: 'date_created',
            render: (date_created) => <span>{date_created && moment(date_created).format('DD MMM YYYY hh:mm:ss')}</span>
        }
    ];

    const actionTableColumn = useMemo(() => {
        return {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            align: 'center',
            render: ({ id, name, content, campaigns, is_global, editable }) => {
                return (
                    <ActionsTableCol
                        id={id}
                        setListId={setListId}
                        name={name}
                        setListName={setListName}
                        content={content}
                        setListContent={setListContent}
                        campaigns={campaigns}
                        setListCampaigns={setListCampaigns}
                        onDelete={getUserLists}
                        setIsEdit={setIsEdit}
                        isGlobal={is_global}
                        editable={editable}
                        setIsGlobal={setIsGlobal}
                        toggleActionPending={toggleActionPending}
                        setFormVisible={setFormVisible}
                        listType={listType}
                    />
                );
            }
        };
    }, [getUserLists, listType]);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.titleContainer}>
                <h4>Lists</h4>
            </div>
            <Radio.Group
                value={listType}
                size="large"
                onChange={(e) => setListType(e.target.value)}
            >
                <Radio.Button value="subid">SubIDs</Radio.Button>
                <Radio.Button value="ip">IPs</Radio.Button>
            </Radio.Group>
            <GlobalBlacklist
                columns={[...blacklistColumns, actionTableColumn]}
                list={globalBlacklist}
                isFetching={isFetching}
                actionPending={actionPending}
                listType={listType}
            />
            <UserLists
                columns={[...columns, actionTableColumn]}
                lists={lists}
                isFetching={isFetching}
                actionPending={actionPending}
                totalRows={totalRows}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setIsEdit={setIsEdit}
                setIsGlobal={setIsGlobal}
                setFormVisible={setFormVisible}
                listType={listType}
            />
            <ListForm
                formVisible={formVisible}
                setFormVisible={setFormVisible}
                isEdit={isEdit}
                isGlobal={isGlobal}
                onCreate={getUserLists}
                onEdit={getUserLists}
                listType={listType}
                listId={listId}
                listName={listName}
                listContent={listContent}
                setListContent={setListContent}
                listCampaigns={listCampaigns}
            />
        </div>
    );
};
