import { Button, Checkbox, Radio, Select, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CampaignContext } from "../../../contexts/campaignContext";
import { TargetingContext } from "../../../contexts/targetingContext";
import { VersionItem } from "../components/VersionItem";
import { hasVersions } from "../utils";
import styles from "./BrowserTargeting.module.css";

const { Option } = Select;

export const BrowserTargeting = () => {
  const { browserAll } = useContext(TargetingContext);
  const { targeting, dispatchTargeting } = useContext(CampaignContext);

  const [selectedAll, setSelectedAll] = useState(true);
  const [showVersions, setShowVersions] = useState(false);

  useEffect(() => {
    targeting.browsers.items.length && setSelectedAll(false);
    hasVersions(targeting.browsers.items) && setShowVersions(true);
  }, [targeting.browsers.items]);

  const handleBrowserUpdate = (browserIds) => {
    const items = browserIds.map((item) => {
      const existingBrowser = targeting.browsers.items.find((browser) => browser.id === item);
      return existingBrowser || { id: item };
    });
    dispatchTargeting({ type: "browsers", payload: { items } });
  };

  const deleteItem = (id) => {
    const items = targeting.browsers.items.filter((item) => item.id !== id);
    dispatchTargeting({ type: "browsers", payload: { items } });
  };

  const updateVersions = (selectedVersions, listIndex, op) => {
    const items = [...targeting.browsers.items];
    const versions = op === "gte" ? [selectedVersions] : selectedVersions;
    items[listIndex] = { ...items[listIndex], versions };
    dispatchTargeting({ type: "browsers", payload: { items } });
  };

  const updateVersionType = (op, listIndex) => {
    const items = [...targeting.browsers.items];
    items[listIndex] = { ...items[listIndex], op, versions: [] };
    if (op === "all") {
      delete items[listIndex].op;
      delete items[listIndex].versions;
    }
    dispatchTargeting({ type: "browsers", payload: { items } });
  };

  return (
    <div>
      <div className={styles.radioButtonSelection}>
        <Radio.Group
          value={selectedAll}
          onChange={(e) => {
            if (e.target.value) {
              dispatchTargeting({
                type: "os",
                payload: { items: [] },
              });
            }
            setSelectedAll(e.target.value);
          }}
        >
          <Space direction="vertical">
            <Radio value={true}>Target all browsers</Radio>
            <Radio value={false}>Target specific browsers</Radio>
          </Space>
        </Radio.Group>
      </div>
      {!selectedAll && (
        <div className={styles.specificItemSelect}>
          <Select
            mode="multiple"
            value={targeting.browsers.items.map((item) => item.id.toString())}
            onChange={handleBrowserUpdate}
            showSearch
            style={{ width: "50%" }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            getPopupContainer={(trigger) => trigger.parentNode}
            maxTagCount="responsive"
          >
            {browserAll.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
          <Checkbox
            checked={showVersions}
            disabled={hasVersions(targeting.browsers.items)}
            onChange={(e) => setShowVersions(e.target.checked)}
          >
            Show versions
          </Checkbox>
          <Button
            onClick={() => dispatchTargeting({ type: "browsers", payload: { items: [] } })}
            className={styles.resetBtnTargeting}
          >
            Reset Targeting
          </Button>
        </div>
      )}
      {!selectedAll && showVersions && (
        <div>
          {targeting.browsers.items.map((item, listIndex) => (
            <VersionItem
              key={item.id}
              item={item}
              allItems={browserAll}
              listIndex={listIndex}
              updateVersions={updateVersions}
              updateVersionType={updateVersionType}
              deleteItem={deleteItem}
            />
          ))}
        </div>
      )}
    </div>
  );
};
