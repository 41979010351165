import { Modal, Select } from "antd";

const { Option } = Select;

export const RulePresetModal = ({
  presetRuleModalVisible,
  setPresetRuleModalVisible,
  presetRules,
  addRulePreset,
}) => {
  return (
    <Modal
      visible={presetRuleModalVisible}
      title="Preset Rules"
      onCancel={() => setPresetRuleModalVisible(false)}
    >
      <div>
        <p>Rule Preset Name</p>
        <Select
          value={null}
          style={{ width: "100%" }}
          placeholder="Select Preset"
          onChange={(value) => {
            const selectedRule = presetRules.find((rule) => rule.id === value);
            addRulePreset(selectedRule);
            setPresetRuleModalVisible(false);
          }}
        >
          {presetRules.map((rule) => {
            return (
              <Option onClick={() => addRulePreset(rule)} key={rule.id} value={rule.id}>
                {rule.name}
              </Option>
            );
          })}
        </Select>
      </div>
    </Modal>
  );
};
