import { BrowserRouter, Route, Routes } from "react-router-dom";
import "antd/dist/antd.min.css";
import { AppLayout } from "./layout";
import { AccountReset } from "./pages/account-reset";
import { AccountActivation } from "./pages/account-activation";
import { AccountLock } from "./pages/account-lock";
import { ProtectedRoute } from "./routes/protectedRoute";
import { PageNotFound } from "./pages/page-not-found";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/reset-password" element={<AccountReset />} />
        <Route path="/activation" element={<AccountActivation />}></Route>
        <Route path="/lock" element={<AccountLock />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
