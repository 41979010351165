import { Tooltip } from "antd";
import React from "react";

import styles from "../Reports.module.css";

export const CampaignTypeCol = ({ campaignType }) => {
  const convertToName = (type) => {
    switch (type) {
      case "Push":
        return "images/push_cpc.svg";
      case "Premium":
        return "images/premium.svg";
      case "Native":
        return "images/native_cpc.svg";
      case "Smartlink":
        return "images/smartlink_cpa.svg";
      case "Contextual Ad":
        return "images/contextualAd.svg";
      default:
        return "images/smartlink_cpa.svg";
    }
  };
  const convertToClass = {
    push_cpa: "PushCpa",
    Push: "PushCpc",
    Native: "NativeCpc",
    Smartlink: "SmartlinkCpa",
  };
  return (
    <div
      className={`${styles.campaignType} ${styles["campaignType" + convertToClass[campaignType]]}`}
    >
      <span>
        {" "}
        &nbsp;{" "}
        <Tooltip title={campaignType}>
          <img src={convertToName(campaignType)} alt={campaignType} />
        </Tooltip>
      </span>
    </div>
  );
};
