export const reducerGenCreatives = (state, action) => {
  switch (action.type) {
    case "delete":
      return state.filter((item) => item.gen_id !== action.payload);
    case "create":
      return [...state, action.payload];
    case "edit":
      const arrayId = state.findIndex((item) => item.gen_id === action.id);
      const parsed = [...state];
      parsed[arrayId] = { ...action.payload, gen_id: action.id };
      return parsed;
    case "generate":
      return action.payload;
    case "generator_update":
      const actions = action.payload.c2aText || action.payload.c2aUrl ? {
        actions: [
          {
            title: { en: action.payload.c2aText },
            icon: action.payload.c2aIcon,
            url: action.payload.c2aUrl,
          },
        ],
      }
        : {};
      return state.map((item) => {
        return {
          ...item,
          description: { en: action.payload.description },
          landing_url: action.payload.landingUrl,
          icon: action.payload.icon,
          ...actions
        };
      });
    case "reset":
      return [];
    default:
      return state;
  }
};

export const reducerGenAiSwitchKeywords = (state, action) => {
  switch (action.type) {
    case "remove_ai_keyword":
      return state.filter((item) => item !== action.payload);
    case "add_ai_keyword":
      return [...state, action.payload];
    case "get_ai_keywords":
      return action.payload;
    case "empty_ai_keywords":
      return [];
    default:
      return state;
  }
};
