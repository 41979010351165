import React from "react";
import { Input } from "antd";
import styles from "../CreativeBuilder.module.css";

import { IconUploader } from "./IconUploader";

export const CallToAction = ({
  setImageModalVisible,
  c2aText,
  setC2aText,
  c2aUrl,
  setC2aUrl,
  c2aIcon,
  setC2aIcon,
}) => {
  return (
    <div className={styles.callToAction}>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}>* </span>Action button text
      </p>
      <div style={{ margin: "0 0 0.8rem 0" }}>
        <Input
          placeholder="Button text"
          value={c2aText}
          style={{ borderRadius: "5px" }}
          onChange={(e) => setC2aText(e.target.value)}
        />
      </div>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}>* </span>Action button URL
      </p>
      <div style={{ margin: "0 0 0.8rem 0" }}>
        <Input
          placeholder="Button URL"
          value={c2aUrl}
          style={{ borderRadius: "5px" }}
          onChange={(e) => setC2aUrl(e.target.value)}
        />
      </div>
      <IconUploader
        optional={true}
        uploadType="bulk_action_icon"
        icon={c2aIcon}
        setIcon={setC2aIcon}
        setImageModalVisible={setImageModalVisible}
      />
    </div>
  );
};
