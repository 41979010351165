import React, { useState, useEffect } from "react";
import { Button, Drawer, Form, Input, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./ContextualGen.module.css";
import { ContextualPreview } from "./components/ContextualPreview";
import { ContextualBuilder } from "./components/ContextualBuilder";
import { v4 as uuidv4 } from "uuid";
import { openWarningNotification } from "../../../../../../utils/notifications";
import { useContext } from "react";
import { isNotValid, isValidDomain } from "../../utils";
import { CampaignContext } from "../../../../../../contexts/campaignContext";

export const ContextualGen = ({
  contextualVisible,
  setContextualVisible,
  creativesLeft,
}) => {
  const { dispatchCreatives } = useContext(CampaignContext);
  const [contextualAds, setContextualAds] = useState([
    { headline: "", url: "", gen_id: uuidv4() },
  ]);
  const [formMultiple] = Form.useForm();
  const [formSingle] = Form.useForm();

  const handleCreativesCreate = contextualAds => {
    const payload = parseAdsToCreatives(contextualAds);

    let incompletedCreativesCount = 0;
    payload.forEach(item => {
      if (isNotValid(item, "Contextual Ad").notValid) {
        incompletedCreativesCount += 1;
      }
    });
    if (incompletedCreativesCount === 0) {
      dispatchCreatives({ type: "bulk_create", payload });
      setContextualVisible(false);
      setContextualAds([{ headline: "", url: "", gen_id: uuidv4() }]);
    } else {
      if (payload.length === incompletedCreativesCount) {
        openWarningNotification({
          message: `You have ${incompletedCreativesCount} invalid creatives, please fix issues!`,
        });
      } else {
        Modal.confirm({
          title: "Invalid Creatives",
          icon: <ExclamationCircleOutlined />,
          onOk: () => {
            const validCreatives = payload.filter(
              item => !isNotValid(item, "Contextual Ad").notValid
            );
            dispatchCreatives({ type: "bulk_create", payload: validCreatives });
            setContextualVisible(false);
          },
          content: `You have ${
            payload.length - incompletedCreativesCount
          } valid creatives, do you want to save valid creatives and remove rest (${incompletedCreativesCount}) of them?`,
          okText: "Yes",
          cancelText: "No",
        });
      }
    }
  };

  const parseAdsToCreatives = ads =>
    ads.map(ad => {
      return {
        gen_id: ad.gen_id,
        title: {
          en: ad.headline,
        },
        landing_url: ad.url,
      };
    });

  const handleSaveCreative = values => {
    if (contextualVisible && contextualVisible !== "NEW") {
      const editObject = {
        ...contextualVisible,
        landing_url: values.landing_url,
        title: { en: values.title },
      };
      if (
        contextualVisible.gen_type === "clone" ||
        contextualVisible.gen_type === "clone_new"
      ) {
        delete editObject.gen_type;
        dispatchCreatives({ type: "create", payload: editObject });
      } else if (contextualVisible.id) {
        dispatchCreatives({ type: "update", payload: editObject });
      } else {
        dispatchCreatives({ type: "update_new", payload: editObject });
      }
    }
    // else {
    //   const parsedValues = values.landing_url
    //     .filter((item) => isValidDomain(item))
    //     .map((item) => {
    //       return {
    //         landing_url: item,
    //         gen_id: calculateId()
    //       };
    //     });
    //   if (parsedValues.length > 0) {
    //     dispatchCreatives({ type: 'bulk_create', payload: parsedValues });
    //   }
    //   if (parsedValues.length < values.landing_url.length) {
    //     openWarningNotification({ message: 'Invalid Landing URLs have been removed!' });
    //   }
    // }
    formSingle.resetFields();
    setContextualVisible(false);
  };

  useEffect(() => {
    if (contextualVisible && contextualVisible !== "NEW") {
      formSingle.setFieldsValue({
        title: contextualVisible.title.en,
        landing_url: contextualVisible.landing_url,
      });
    }
  }, [contextualVisible, formSingle]);

  return (
    <>
      <Drawer
        title="Generate Creatives"
        visible={contextualVisible === "NEW"}
        placement="right"
        width={window.innerWidth - 200}
        footer={false}
        onClose={() => setContextualVisible(false)}
      >
        <div className={styles.creativeGridContainer}>
          <ContextualBuilder
            formMultiple={formMultiple}
            creativesLeft={creativesLeft}
            contextualAds={contextualAds}
            setContextualAds={setContextualAds}
          />
          <ContextualPreview contextualAds={contextualAds} />
          <div className={styles.finalContainer}>
            <p>
              Creative preview above is displayed in our most popular ad style.
              Appearance of live widget may vary according to publisher’s
              design, selected type, number of chosen creatives to display and
              other parameters.
            </p>
            <Button
              type="primary"
              size="large"
              onClick={() => handleCreativesCreate(contextualAds)}
            >
              Save
            </Button>
          </div>
        </div>
      </Drawer>
      <Modal
        title=""
        visible={contextualVisible && contextualVisible !== "NEW"}
        onCancel={() => setContextualVisible(false)}
        footer={null}
      >
        <Form form={formSingle} layout="vertical" onFinish={handleSaveCreative}>
          <Form.Item
            label="Name"
            name="title"
            rules={[
              {
                required: true,
                message: "Please enter ad headline!",
              },
              {
                validator: async (rule, value) => {
                  if (value && value.length > 30) {
                    throw new Error("Max 30 chars allowed!");
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="URL"
            name="landing_url"
            rules={[
              {
                validator: async (rule, value) => {
                  if (!isValidDomain(value)) {
                    throw new Error("Invalid landing URL!");
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="solid" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
