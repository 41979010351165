const ACTION_MAPPER = {
  pause: "Pause",
  increase_bid: "Increase bid by",
  decrease_bid: "Decrease bid by",
};

const METRIC_MAPPER = {
  roi: "ROI",
  e_cpa: "eCPA",
  clicks: "Clicks",
  spent: "Spent",
  conversions: "Conversions",
  impressions: "Impressions",
};

export const campaignOptions = {
  metrics: (rule) => [
    {
      value: "roi",
      label: "ROI",
      disabled: rule.selected_metrics.includes("roi"),
    },
    {
      value: "e_cpa",
      label: "eCPA",
      disabled: rule.selected_metrics.includes("e_cpa"),
    },
    {
      value: "clicks",
      label: "Clicks",
      disabled: rule.selected_metrics.includes("clicks"),
    },
    {
      value: "spent",
      label: "Spent",
      disabled: rule.selected_metrics.includes("spent"),
    },
    {
      value: "conversions",
      label: "Conversions",
      disabled: rule.selected_metrics.includes("conversions"),
    },
    {
      value: "impressions",
      label: "Impressions",
      disabled: rule.selected_metrics.includes("impressions"),
    },
  ],
  timeRanges: [
    {
      value: "1h",
      label: "Last hour",
    },
    {
      value: "24h",
      label: "Last 24 hours",
    },
    {
      value: "7d",
      label: "Last 7 days",
    },
    {
      value: "30d",
      label: "Last 30 days",
    },
  ],
  actions: [
    {
      value: "pause",
      label: "Pause",
    },
    {
      value: "increase_bid",
      label: "Increase bid by n%",
    },
    {
      value: "decrease_bid",
      label: "Decrease bid by n%",
    },
  ],
};

export const parseRulesForRequest = (originalRule) => {
  const outputItem = {
    name: originalRule.name,
    clicks_num: null,
    clicks_condition: null,
    roi_num: null,
    roi_condition: null,
    e_cpa_num: null,
    e_cpa_condition: null,
    spend_num: null,
    spend_condition: null,
    conversions_num: null,
    conversions_condition: null,
    impressions_num: null,
    impressions_condition: null,
    action: originalRule.action,
    action_value: originalRule.action !== "pause" ? originalRule.action_value : null,
    action_limit: originalRule.action !== "pause" ? originalRule.action_limit : null,
    time_range: originalRule.time_range,
    preset: true,
    id: originalRule.id || null,
  };

  originalRule.sub_rules.forEach((subRule) => {
    const metricName = subRule.metric;
    const condition = subRule.condition;
    const num = subRule.num;

    if (metricName !== "") {
      outputItem[`${metricName}_num`] = num;
      outputItem[`${metricName}_condition`] = condition;
    }
  });

  return outputItem;
};

export const parseRuleForForm = (rule) => {
  const subRules = [];
  const selectedMetrics = [];

  for (const key in rule) {
    if (key.endsWith("_num")) {
      const metricName = key.replace("_num", "");
      const condition = rule[`${metricName}_condition`];
      const num = rule[key];

      if (num !== null && condition !== null) {
        subRules.push({
          metric: metricName,
          condition: condition,
          num: num,
        });
        selectedMetrics.push(metricName);
      }
    }
  }

  return {
    sub_rules: subRules,
    selected_metrics: selectedMetrics,
    time_range: rule.time_range || "24h",
    action: rule.action,
    name: rule.name,
    id: rule.id,
    action_value: parseFloat(rule.action_value) || null,
    action_limit: parseFloat(rule.action_limit) || null,
    preset: true,
  };
};

const isSubruleCompleted = (subrules) => {
  let completed = true;
  subrules.forEach((subrule) => {
    if (!subrule.metric || !subrule.num || !subrule.condition) {
      completed = false;
    }
  });
  return completed;
};

export const isSingleRuleValid = (rule, setRule) => {
  let valid = true;
  let prevRule = { ...rule };
  if (!prevRule.name) {
    valid = false;
    setRule({ ...prevRule, error: "Rule name is required!" });
  } else if (!prevRule.selected_metrics.length) {
    valid = false;
    setRule({ ...prevRule, error: "At least one metric must be selected!" });
  } else if (!prevRule.action) {
    valid = false;
    setRule({ ...prevRule, error: "Action is required!" });
  } else if (prevRule.action !== "pause" && !prevRule.action_limit) {
    valid = false;
    setRule({ ...prevRule, error: "Action limit is required!" });
  } else if (!isSubruleCompleted(prevRule.sub_rules)) {
    valid = false;
    setRule({ ...prevRule, error: "Rule conditions are not completed!" });
  }
  return valid;
};

export const SUB_RULE_OBJ = () => ({
  metric: "",
  num: "",
  condition: "",
});

export const RULE_OBJ = () => ({
  sub_rules: [SUB_RULE_OBJ()],
  selected_metrics: [],
  time_range: "24h",
  action: "pause",
  action_value: 10,
  action_limit: null,
});

export const parseRuleActions = (record) => {
  if (record.action === "pause") {
    return "Pause";
  } else {
    return `${ACTION_MAPPER[record.action]} ${record.action_value}%`;
  }
};

export const parseRuleConditions = (record) => {
  const conditions = [];
  for (const key in record) {
    if (key.endsWith("_num")) {
      const metricName = key.replace("_num", "");
      const condition = record[`${metricName}_condition`] === "lower" ? "<" : ">";
      const num = record[key];
      if (num !== null && condition !== null) {
        conditions.push(<span>{`${METRIC_MAPPER[metricName]} ${condition} ${num}`}</span>);
      }
    }
  }
  return (
    <div>
      {conditions.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
};
