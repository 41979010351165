import React, { useContext } from "react";
import { Button, Select } from "antd";
import styles from "./ListSelect.module.css";
import { GlobalOutlined } from "@ant-design/icons";
import { TargetingContext } from "../../../contexts/targetingContext";

const { Option } = Select;

export const ListSelect = ({ type, op, value, onChange }) => {
  const { subidListAll, ipListAll } = useContext(TargetingContext);

  const itemsList = type === "subid" ? subidListAll : ipListAll;

  return (
    <div>
      <div className={styles.targetingBox}>
        <Select
          mode={"multiple"}
          clearIcon={<GlobalOutlined />}
          style={{ width: "100%" }}
          value={value}
          onChange={(list) => onChange(list)}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {itemsList.map((item) => (
            <Option key={item.id} value={item.id} disabled={op === "in" && item.is_global}>
              {item.name}
            </Option>
          ))}
        </Select>
        <Button className={styles.clearBtn} onClick={() => onChange([])}>
          Clear All
        </Button>
      </div>
    </div>
  );
};
