import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputNumber, Select, Tooltip } from "antd";
import { InfoCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import styles from "./BudgetAndBids.module.css";
import { CampaignContext } from "../../../contexts/campaignContext";
import { newCampaignService } from "../../../services/newCampaign";

const { Option } = Select;

export const BudgetAndBids = () => {
  const {
    campaign,
    targeting,
    campaignType,
    formRef,
    editId,
    isClone,
    bidData,
    setBidData,
    bidDataLoading,
    setBidDataLoading,
    dispatchCampaign,
  } = useContext(CampaignContext);
  const [visible, setVisible] = useState("first_render");

  useEffect(() => {
    let callback = (entries) => {
      const entry = entries[0];
      if ((entry.isVisible, entry.isIntersecting)) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    const observer = new IntersectionObserver(callback, {
      threshold: 0.6,
    });

    let target = document.querySelector("#budget-bids-intersection");
    observer.observe(target);
  }, []);

  useEffect(() => {
    (async () => {
      if (!visible || campaignType !== "Push") return;
      setBidDataLoading(true);
      const targetingRefactored = { ...targeting };
      if (targeting.freshness_type === "hour") {
        targetingRefactored.age = null;
      } else {
        targetingRefactored.user_age = null;
      }
      const editObj = editId && !isClone ? { campaign_id: editId } : {};
      try {
        const res = await newCampaignService.getSuggestedBid({
          targeting: targetingRefactored,
          ...editObj,
        });
        setBidData(res.data);
      } catch (e) {
        console.log(e);
      } finally {
        setBidDataLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [visible, targeting.traffic_type]);

  useEffect(() => {
    if (!!campaign.limit) {
      formRef.setFieldsValue({
        ...campaign.limit,
        limit_value: campaign.limit.limit_value,
        period: campaign.limit.period ? campaign.limit.period : "day",
      });
    }
  }, [campaign.limit, formRef]);

  useEffect(() => {
    formRef.setFieldsValue({
      price: campaign.pricing.price,
    });
  }, [campaign.pricing, formRef]);

  const setSuggestedBid = () => {
    if (bidData.suggested_bid) {
      const price = bidData.suggested_bid.toFixed(3);
      formRef.setFieldsValue({
        price: parseFloat(price),
      });
      dispatchCampaign({ type: "pricing", payload: { price: parseFloat(price) } });
    }
  };

  return (
    <div className={styles.generalWrapper} id="budget-bids-intersection">
      <div className={styles.generalContentWrapper}>
        <div className={styles.useSuggestedBid}>
          <span className={styles.formLabel}>
            Ad bid{" "}
            <Tooltip
              title={
                <p>
                  Here you can set your base CPC - the amount you pay each time a user clicks on
                  your ad. You can adjust your CPC later by source or location on the Reports page.
                  The floor amount depends on your GEO targeting, while the maximum amount is $1000.
                  {/* Here you can set your base CPC - the amount you pay each time
                  a user clicks on your ad. You can adjust your CPC later by
                  source or location on Reports page. The floor amount you can
                  enter is $
                  {campaignType === "Push" && bidData.floor_bid
                    ? bidData.floor_bid.toFixed(3)
                    : 0.001}
                  , while maximum amount is $1000. */}
                </p>
              }
            >
              <InfoCircleTwoTone style={{ marginRight: "0.1rem" }} />
            </Tooltip>
          </span>
          <Form.Item
            name="price"
            className={styles.campaignBid}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: async (rule, value) => {
                  if ((campaignType === "Push" || campaignType === "Premium") && bidData.floor_bid && value < bidData.floor_bid) {
                    throw new Error(
                      `Please enter ad bid greater than or equal to floor bid: ${bidData.floor_bid}.`
                    );
                  }
                },
              },
            ]}
          >
            <InputNumber
              addonBefore="$"
              step={0.001}
              max={1000}
              onChange={(value) => {
                if (value !== null) {
                  const stringValue = value.toString();
                  const truncatedValue = parseFloat(
                    stringValue.slice(0, stringValue.indexOf(".") + 4)
                  );
                  dispatchCampaign({ type: "pricing", payload: { price: truncatedValue } });
                } else {
                  dispatchCampaign({ type: "pricing", payload: { price: null } });
                }
              }}
              // min={
              //   campaignType === "Push" && bidData.floor_bid
              //     ? bidData.floor_bid.toFixed(3)
              //     : 0.001
              // }
              placeholder="Not set"
            />
          </Form.Item>
          {(campaignType === "Push" || campaignType === "Premium") ? (
            <Button type="primary" ghost onClick={setSuggestedBid} disabled={bidDataLoading}>
              Use suggested bid:{" "}
              {bidDataLoading ? (
                <LoadingOutlined />
              ) : (
                bidData.suggested_bid && bidData.suggested_bid.toFixed(3)
              )}
            </Button>
          ) : (
            ""
          )}
        </div>
        <div className={styles.budgetAndPeriod}>
          <Form.Item
            label="Campaign Budget"
            name="limit_value"
            className={styles.campaignBudget}
            rules={[
              {
                required: true,
                message: "Please select campaign budget",
              },
            ]}
          >
            <InputNumber
              addonBefore="$"
              style={{ width: "100%" }}
              step={1}
              min={1}
              placeholder="Not set"
              onChange={(value) => {
                if (value !== null) {
                  // const stringValue = value.toString();
                  // const truncatedValue = parseFloat(stringValue.slice(0, stringValue.indexOf('.') + 4));
                  // dispatchCampaign({ type: "limit", payload: { ...campaign.limit, limit_value: truncatedValue } })
                  if (Number.isInteger(value)) {
                    dispatchCampaign({
                      type: "limit",
                      payload: { ...campaign.limit, limit_value: value },
                    });
                  } else {
                    dispatchCampaign({
                      type: "limit",
                      payload: { ...campaign.limit, limit_value: parseInt(value) },
                    });
                  }
                } else {
                  dispatchCampaign({
                    type: "limit",
                    payload: { ...campaign.limit, limit_value: null },
                  });
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Period"
            name="period"
            className={styles.campaignBudgetPeriod}
            rules={[
              {
                required: true,
                message: "Please select period",
              },
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) =>
                dispatchCampaign({ type: "limit", payload: { ...campaign.limit, period: value } })
              }
            >
              <Option value="hour">Hour</Option>
              <Option value="day">Day</Option>
              <Option value="month">Month</Option>
            </Select>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};
