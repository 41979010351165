import React from "react";
import { CloseOutlined, CopyOutlined, WarningFilled } from "@ant-design/icons";
import styles from "../CreativeVariations.module.css";
import { calculateId, isNotValid } from "../../../../../utils";
import { Tooltip } from "antd";

export const CreativeSample = ({
  type,
  creative,
  dispatchGenCreatives,
  setCurrentCreative,
  currentCreative,
  limitExceeded,
  setCurrentLastCreative,
}) => {
  const cloneCreativeSample = (e) => {
    e.stopPropagation();
    const gen_id = calculateId();

    dispatchGenCreatives({ type: "create", payload: { ...creative, gen_id } });
    setCurrentCreative(gen_id);
  };
  const deleteCreativeSample = (e) => {
    e.stopPropagation();
    if (currentCreative === creative.gen_id) {
      setCurrentLastCreative();
    } else if (currentCreative === false) {
      setCurrentCreative(null);
    }
    dispatchGenCreatives({ type: "delete", payload: creative.gen_id });
  };
  const handleCreativeSelect = (e) => {
    e.stopPropagation();
    setCurrentCreative(creative.gen_id);
  };

  const displayValidationMessage = (creative, type) => {
    const validation = isNotValid(creative, type);
    if (!validation.notValid) {
      return;
    } else {
      return (
        <Tooltip title={validation.message}>
          <WarningFilled style={{ color: "#e0bf14", marginTop: "2.2rem" }} />
        </Tooltip>
      );
    }
  };

  return (
    <div
      className={`${styles.sampleCreative} ${
        currentCreative === creative.gen_id ? styles.selectedCreative : ""
      } ${limitExceeded && styles.limitExceededSample}`}
      onClick={handleCreativeSelect}
    >
      <div className={styles.sampleCreativeContent}>
        <div className={styles.imageSamplePreview}>
          {creative.icon && (
            <div>
              <img
                src={creative.icon}
                className={styles.iconVariation}
                alt="creative_img"
              />
              <div style={{ display: "inline-grid" }}>
                <p className={styles.sampleTitle}>
                  {creative.title ? creative.title.en : ""}
                </p>
                <span className={styles.sampleDesc}>
                  {creative.description ? creative.description.en : ""}
                </span>
              </div>
            </div>
          )}
          <img
            src={creative.image ? creative.image.en : ""}
            style={!creative.icon ? { width: "100%" } : {}}
            alt="creative_img"
          />
        </div>
        {!creative.icon && (
          <>
            <p className={styles.sampleTitle}>
              {creative.title ? creative.title.en : ""}
            </p>
            <span className={styles.sampleDesc}>
              {creative.description ? creative.description.en : ""}
            </span>
          </>
        )}
      </div>
      <div className={styles.sampleCreativeActions}>
        <div>
          <CloseOutlined
            className={styles.actionIcon}
            onClick={deleteCreativeSample}
          />
          <CopyOutlined
            className={styles.actionIcon}
            onClick={cloneCreativeSample}
          />
        </div>
        {displayValidationMessage(creative, type)}
      </div>
    </div>
  );
};
