import React from "react";
import { Input, Button } from "antd";
// import { SwapOutlined } from '@ant-design/icons';
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "./TrackingInfo.module.css";
import { openSuccessNotification } from "../../../utils/notifications";

export const TrackingInfo = ({ trackingUrls, trackerType, trackerValue }) => {
  const placeholder = (allTrackers, trackerType, trackerValue) => {
    if (trackerValue) {
      switch (trackerType) {
        case "Default":
          return allTrackers.manual.general;
        case "Voluum":
          return allTrackers.manual.voluum;
        case "Affise":
          return allTrackers.manual.affise;
        case "Binom":
          return allTrackers.manual.binom;
        default:
          return null;
      }
    } else {
      switch (trackerType) {
        case "Default":
          return allTrackers.auto.general;
        case "Voluum":
          return allTrackers.auto.voluum;
        case "Affise":
          return allTrackers.auto.affise;
        case "Binom":
          return allTrackers.auto.binom;
        default:
          return null;
      }
    }
  };

  const getS2sUrl = () => {
    let selectedTracker = placeholder(trackingUrls, trackerType, trackerValue);
    if (trackerValue) {
      selectedTracker = selectedTracker.replace("{payout}", trackerValue);
      selectedTracker = selectedTracker.replace("{sum}", trackerValue);
    }
    return selectedTracker;
  };

  return (
    <div>
      {/* <h3 className={styles.generalInfoHeader}>
        <SwapOutlined /> Bid goal tracking
      </h3> */}
      <p className={styles.trackingParagraph}>
        Copy this S2S Postback URL and paste to the relevant field in your
        tracker or CPA Network account
      </p>
      <Input
        className={styles.trackingUrl}
        suffix={
          <CopyToClipboard
            text={trackingUrls && getS2sUrl()}
            onCopy={() =>
              openSuccessNotification({
                message: "Successfully copied to clipboard!",
              })
            }
          >
            <Button type="primary">Copy</Button>
          </CopyToClipboard>
        }
        value={trackingUrls && getS2sUrl()}
        readOnly
      />
      <p className={styles.trackingParagraph}>
        Do not forget to change{" "}
        <span className={styles.trackingParamSpan}>
          params in curly brackets
        </span>{" "}
        to proper parameter names.
      </p>
    </div>
  );
};
