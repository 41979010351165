import { Button, Input } from "antd";
import React, { useEffect, useRef } from "react";
import styles from "../CreativeBuilder.module.css";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

export const Headlines = ({ headlines, setHeadlines }) => {
  const lastInput = useRef();

  useEffect(() => {
    if (lastInput.current !== null) {
      lastInput.current.focus();
    }
  }, [headlines.length]);

  const handleAddItem = () => {
    setHeadlines(oldHeadlines =>
      oldHeadlines.length < 5 ? [...oldHeadlines, ""] : oldHeadlines
    );
  };

  const handleEnterPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddItem();
    }
  };

  const handleDeleteItem = id => {
    const newHeadlines = [...headlines];
    newHeadlines.splice(id, 1);
    setHeadlines(newHeadlines);
  };

  const handleInputChange = (e, id) => {
    const newHeadlines = [...headlines];
    newHeadlines[id] = e.target.value;
    setHeadlines(newHeadlines);
  };

  return (
    <>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}>*</span> Ad Headline{" "}
        <span>- you may add up to 5 headlines</span>
      </p>
      {headlines.map((item, id) => {
        return (
          <div key={id} className={styles.headlinesBox}>
            <Input
              value={headlines[id]}
              onChange={e => handleInputChange(e, id)}
              ref={lastInput}
              onKeyPress={handleEnterPress}
              placeholder="Enter a headline"
              className={
                headlines[id] && headlines[id].length > 100 && styles.dangerText
              }
              suffix={
                Number(id) !== 0 ? (
                  <CloseOutlined
                    style={{ fontSize: "0.7rem" }}
                    onClick={() => handleDeleteItem(id)}
                  />
                ) : (
                  ""
                )
              }
            />
            <span
              className={`${
                headlines[id] && headlines[id].length > 100
                  ? styles.dangerTextCounter
                  : ""
              } ${styles.counterBox}`}
            >
              {headlines[id] ? headlines[id].length : 0}/100
            </span>
          </div>
        );
      })}
      {headlines.length < 5 && (
        <div className={styles.buttonWrapper}>
          <Button onClick={handleAddItem} size="small">
            <PlusOutlined />
            New Headline
          </Button>
        </div>
      )}
    </>
  );
};
