import React from 'react';
import { Tooltip, Modal, Button } from 'antd';
import { PauseCircleOutlined, PlayCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { mediaCampaignsService } from "../../../services/campaigns";
import {
  openSuccessNotification,
  openErrorNotification,
} from "../../../utils/notifications";

import styles from './Show.module.css';

export const StatusTableCol = ({ status, id, statusAfterCap, onActivate, onPause }) => {

  let statusTitle = '';

  switch (status) {
    case 'daily-limit':
    case 'hourly-limit':
    case 'monthly-limit':
      statusTitle = 'Budget cap';
      break;
    default:
      statusTitle = status;
  }

  const activateCampaign = async () => {
    try {
      const res = await mediaCampaignsService.activateCampaign(id);
      if (res.success) {
        openSuccessNotification({ message: res.message });
      }
      else {
        openErrorNotification({ message: res.message })
      }
      await onActivate();
    } catch (e) {
      openErrorNotification({ message: "Could not activate a campaign!" });
    }
  };

  const pauseCampaign = async () => {
    try {
      const res = await mediaCampaignsService.pauseCampaign(id);
      if (res.success) {
        openSuccessNotification({ message: res.message });
      }
      else {
        openErrorNotification({ message: res.message })
      }
      await onPause();
    } catch (e) {
      openErrorNotification({ message: "Could not pause a campaign!" });
    }
  };

  const confirmActivateCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: "Are you sure you want this campaign to be activated once the budget cap period ends?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => activateCampaign(),
      onCancel() { },
    });
  };

  const confirmPauseCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want this campaign to be paused once the budget cap period ends?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => pauseCampaign(),
      onCancel() { },
    });
  };

  return <div className={statusAfterCap && styles.budgetCapContainer}>
    <Tooltip placement="topLeft" title={statusTitle === 'disabled' ? 'This campaign is unable to run because you don’t have sufficient funds. As soon as you top up your balance, you’ll be able to run it immediately.' : ''}>
      <div className={`${styles.status} ${styles['status' + status]}`}>
        {statusTitle}
      </div>
    </Tooltip>
    <div>
      {statusTitle === 'Budget cap' && statusAfterCap ?
        <>
          {statusAfterCap.toLowerCase() === "active" ? (
            <Tooltip title="Pause">
              <Button
                style={{ color: "#737373" }}
                icon={<PauseCircleOutlined />}
                onClick={confirmPauseCampaign}
              ></Button>
            </Tooltip>
          ) : (
            <Tooltip title={"Activate"}>
              <Button
                style={{ color: "#737373" }}
                icon={<PlayCircleOutlined />}
                onClick={confirmActivateCampaign}
              ></Button>
            </Tooltip>
          )}
          <Tooltip title={`Once the budget cap period ends, the campaign will move to status ${statusAfterCap.toLowerCase() === "active" ? 'active' : 'paused'}. You can change this by clicking on the ${statusAfterCap.toLowerCase() === "active" ? 'pause' : 'activate'} button.`}>
            <InfoCircleTwoTone style={{ marginRight: "0.1rem" }} />
          </Tooltip>
        </>
        : null}
    </div>
  </div>;
};
