import React, { useEffect, useState } from "react";
import { Switch, Typography, Table, Spin } from "antd";
import styles from "../Api.module.css";
import { apiIntegrationService } from "../../../services/apiIntegration";
const { Text } = Typography;

export const StatsApi = () => {
  const [statsApiIntegration, switchStatsApiIntegration] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  useEffect(() => {
    setLoading(true);
    const fetchStats = async () => {
      try {
        const res = await apiIntegrationService.getCompanies();
        res[0].api_key ? switchStatsApiIntegration(true) : switchStatsApiIntegration(false);
        setApiKey(res[0].api_key);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchStats();
  }, []);

  const dataSource = [
    {
      key: "1",
      name: "key",
      type: "string",
      description: "Required parameter. API key is unique for each user.",
    },
    {
      key: "2",
      name: "date_from",
      type: "string",
      description: "Format: yyyy-mm-dd",
    },
    {
      key: "3",
      name: "date_to",
      type: "string",
      description: "Format: yyyy-mm-dd",
    },
    {
      key: "4",
      name: "resType",
      type: "string",
      description: "Specifies type of response. - json - csv",
    },
    {
      key: "5",
      name: "page",
      type: "int",
      description:
        "Determines which page of content you are currently viewing. Example: If 'page=2', it means you are viewing the second page of content.",
    },
    {
      key: "6",
      name: "limit",
      type: "int",
      description:
        "Specifies the maximum number of items you want to display per page. Example: If 'limit=10', it means you want to display 10 items per page.",
    },
    {
      key: "7",
      name: "columns",
      type: "string",
      description: (
        <div>
          <p>
            Labels that you expect in response. It contains multiple values, separated by a comma.
            Possible values:
          </p>
          <ul style={{ listStyle: "none" }}>
            <li>- date</li>
            <li>
              - hour{" "}
              <span className={styles.columnDescription}>
                (only available if the selected date range is less than three days)
              </span>
            </li>
            <li>- campaign_id</li>
            <li>- campaign_name</li>
            <li>- campaign_status</li>
            <li>- os</li>
            <li>- os_version</li>
            <li>- browser</li>
            <li>- browser_version</li>
            <li>- country</li>
            <li>- source</li>
            <li>- creative_id</li>
            <li>- creative_name</li>
            <li>- campaign_type</li>
            <li>- iab_id</li>
            <li>- iab_name</li>
            <li>- region_id</li>
            <li>- region_name</li>
            <li>- impressions</li>
            <li>- clicks</li>
            <li>- leads</li>
            <li>- spam_clicks</li>
            <li>- spent</li>
            <li>- expected_revenue</li>
            <li>- cr</li>
            <li>- ctr</li>
            <li>- cpa</li>
          </ul>
        </div>
      ),
    },
    {
      key: "8",
      name: "ordering",
      type: "string",
      description: (
        <div>
          <p style={{ margin: 0 }}>
            One of the values from columns parameter. For ascending just use value (e.g.
            ordering=hour).
          </p>{" "}
          <p style={{ margin: 0 }}>For descending use -value (e.g. ordering=-hour)</p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "60%",
    },
  ];

  return (
    <Spin spinning={loading}>
      <div className={styles.apiTabWrapper}>
        <h2>
          <span>{"</>"}</span> Stats API
        </h2>
        {!statsApiIntegration && (
          <p>To be able to use Statistic API, please contact your account manager.</p>
        )}
        <div className={styles.switchBlock}>
          <Switch checked={statsApiIntegration} />
          <span className={styles.apiTabCheckboxTitle}>
            {!statsApiIntegration
              ? "Disabled SSP Coinis Stats API."
              : " Enabled SSP Coinis Stats API"}
          </span>
        </div>
        <>
          {statsApiIntegration ? (
            <p>
              Your API Key is: <Text code>{apiKey}</Text>
            </p>
          ) : null}
          <div className={styles.urlStructure}>
            <h4>URL Structure</h4>
            <ul>
              <li>
                URL:{" "}
                <Text className={styles.codeDisplayColor}>
                  {window.location.href.slice(0, -15).concat("api/reports")}
                </Text>
              </li>
              <li>
                HTTP Method: <Text className={styles.codeDisplayColor}>GET</Text>
              </li>
            </ul>
          </div>
          <h4>Parameters:</h4>
          <Table columns={columns} dataSource={dataSource} pagination={false} />

          <h4>Example request:</h4>
          <Text className={styles.codeDisplayColor}>
            https://advertiser.coinis.com/api/reports/?key=&#123;YourAPIkey&#125;&resType=json&columns=date,hour,campaign_id,impressions,clicks,cr&
            date_from=2020-10-01&date_to=2020-10-01&page=1&limit=5&ordering=hour
          </Text>

          <h4>Example response:</h4>
          <pre className={styles.responseCodeExample}>
            {`
  {
    table: [
      {
        date: 20201001,
        hour: 23,
        campaign_id: 1042272,
        impressions: 22,
        clicks: 0,
        cr: 0
      },
      {
        date: 20201001,
        hour: 23,
        campaign_id: 1042272,
        impressions: 31,
        clicks: 0,
        cr: 0
      },
      {
        date: 20201001,
        hour: 23,
        campaign_id: 1042272,
        impressions: 1,
        clicks: 0,
        cr: 0
      },
      {
        date: 20201001,
        hour: 23,
        campaign_id: 1042272,
        impressions: 40,
        clicks: 0,
        cr: 0
      },
      {
        date: 20201001,
        hour: 23,
        campaign_id: 1042272,
        impressions: 40,
        clicks: 0,
        cr: 0
      },
      {
        date: 20201001,
        hour: 23,
        campaign_id: 1042272,
        impressions: 40,
        clicks: 0,
        cr: 0
      },
      {
        date: 20201001,
        hour: 23,
        campaign_id: 1042272,
        impressions: 40,
        clicks: 0,
        cr: 0
      },
      {
        date: 20201001,
        hour: 23,
        campaign_id: 1042272,
        impressions: 40,
        clicks: 0,
        cr: 0
      }
    ],
    rows: 26717
  }
            `}
          </pre>
          {/* <ul>
            <li>pid: Your account name ([USERNAME]) </li>
            <li>key: Your API key</li>
            <li> from: start date of report in format YYYY-MM-DD</li>
            <li> to: (optional) end date of report in format YYYY-MM-DD</li>
            <li> geo: (optional) Filter by specific geo</li>
            <li> os: (optional) Filter by specific operating system </li>
            <li> browser: (optional) Filter by specific browser</li>
            <li> subid: (optional) Filter by specific subid</li>
            <li> sortby: (optional) Sort by specific field [subid/geo/os/browser/date]</li>
            <li> format: (optional) output format. xml (default) or csv</li>
          </ul>
          <h3>
            <b>Example Request:</b>
          </h3>
          <Text code>
            https://api.coinis.com/publishers/statistics-report?pid=PubNAME&weeafa32gae34g3qag5733732eafssaw2&from=2019-11-16
          </Text>
          <h3>
            <b>Example Response:</b>
          </h3>
          <Text code> {'<report>'} </Text> <br />
          <Text code> {'<dates value=“2019-11-16 - 2019-11-16”>'}</Text> <br />
          <Text code>{'<date name=“subid_1”>   '}</Text> <br />
          <Text code>{'      <revenue>31.2</revenue>   '}</Text> <br />
          <Text code>{'      <requests>1589</requests>   '}</Text> <br />
          <Text code>{'       <clicks>500</clicks>   '}</Text> <br />
          <Text code>{'      </date>   '}</Text> <br />
          <Text code>{'        <date name=“subid_2”>   '}</Text> <br />
          <Text code>{'         <revenue>31.2</revenue>    '}</Text> <br />
          <Text code>{'      <requests>1589</requests>   '}</Text> <br />
          <Text code>{'        <clicks>500</clicks>   '}</Text> <br />
          <Text code>{'        </date>   '}</Text> <br />
          <Text code>{'         <date name=“subid_3”>   '}</Text> <br />
          <Text code>{'       <revenue>31.2</revenue>   '}</Text> <br />
          <Text code>{'        <requests>1589</requests>   '}</Text> <br />
          <Text code>{'          <clicks>500</clicks>  '}</Text> <br />
          <Text code>{'         </date>   '}</Text> <br />
          <Text code>{'      </dates> '}</Text> <br />
          <Text code>{'</report>'} </Text> */}
        </>
      </div>
    </Spin>
  );
};
