import { Button, Modal, Row, Col, Spin } from "antd";
import React, { useContext, useState } from "react";
import { StarFilled } from "@ant-design/icons";
import UserContext from "../../../contexts/userContext";
import { openWarningNotification } from "../../../utils/notifications";
import styles from "./CampaignTypeModal.module.css";
import { CampaignContext } from "../../../contexts/campaignContext";
import { useNavigate } from "react-router";

export const CampaignTypeModal = ({ campaignType, editId }) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const { dispatchCampaign, hasDraft, isDiscardedNew, setIsDiscardedNew } = useContext(CampaignContext);
  const [visible, setVisible] = useState(true);

  const balanceInvalid = () => {
    const limit = parseFloat(userInfo.credit_limit);
    const balance = parseFloat(userInfo.total_balance);
    if (!(limit ? limit - 10 > -balance : balance > 10)) {
      return "Please top up your credit in order to start campaigns.";
    }
    return false;
  };

  const handleCampaignSelect = payload => {
    dispatchCampaign({ type: "type", payload });
    setIsDiscardedNew(true);
  };

  const handleDiscard = () => {
    localStorage.removeItem("campaign_general");
    localStorage.removeItem("campaign_targeting");
    localStorage.removeItem("campaign_creatives");
    localStorage.removeItem("campaign_rules");
    setIsDiscardedNew(true);
    navigate("/campaigns")
  }

  return hasDraft() ?
    (
      <Modal
        title="Load Draft?"
        visible={visible && !editId && !isDiscardedNew}
        wrapClassName={styles.campaignTypeModal}
        width={650}
        closable={false}
        footer={[
          <Button key="back" onClick={handleDiscard}>
            Start Over
          </Button>,
          <Button key="submit" type="primary" onClick={() => setVisible(false)}>
            Load Draft
          </Button>
        ]}
      >
        <p>A previous draft of your work is available. What would you like to do with drafted {campaignType.toLowerCase()} campaign?</p>
      </Modal>
    ) : (
      <Modal
        title=""
        visible={visible && !campaignType && !editId}
        wrapClassName={styles.campaignTypeModal}
        width={650}
        footer={false}
        onCancel={() => navigate("/campaigns")}
      >
        <h1 style={{ marginTop: "0.8rem", textAlign: "center" }}>
          Select Campaign Format
        </h1>
        {!userInfo.permissions ? (
          <div className={styles.loadingSelectCampaign}>
            <Spin spinning={!userInfo.permissions} />
          </div>
        ) : (
          <Row style={{ justifyContent: "center" }}>
            {userInfo.permissions.premium ? (
              <Col xl={12} lg={12} md={24} className={styles.campaignTypesDivs}>
                <div
                  className={styles.campaignTypeSection}
                  onClick={() =>
                    balanceInvalid()
                      ? openWarningNotification({ message: balanceInvalid() })
                      : handleCampaignSelect("Premium")
                  }
                >
                  <div>
                    <h2>Premium Push</h2>
                    <h3 className={styles.campaignTypesDesc}>
                      Most popular ad format
                    </h3>
                    <img
                      onLoad={e => {
                        e.target.style.opacity = 1;
                      }}
                      src="../images/premium_push_creative_thmb.png"
                      alt="push"
                    />
                  </div>
                  <div>
                    <div className={styles.formatDescription}>
                      <span />
                      <p>
                        It is a message sent to device after a user subscribed to receive notifications on our publisher's <span>premium</span> websites in the US.
                      </p>
                    </div>
                    <Button
                      type="primary"
                      className={styles.campaignTypesSelectBtn}
                    >
                      Select Premium Push
                    </Button>
                  </div>
                  <div className={styles.adTypeHightlight}>
                    <StarFilled style={{ fontSize: "1.1rem" }} />
                    <StarFilled style={{ fontSize: "2rem" }} />
                    <StarFilled style={{ fontSize: "1.1rem" }} />
                  </div>
                </div>
              </Col>
            ) : null}
            {userInfo.permissions.push ? (
              <Col xl={12} lg={12} md={24} className={styles.campaignTypesDivs}>
                <div
                  className={styles.campaignTypeSection}
                  onClick={() =>
                    balanceInvalid()
                      ? openWarningNotification({ message: balanceInvalid() })
                      : handleCampaignSelect("Push")
                  }
                >
                  <div>
                    <h2>Push Notification</h2>
                    <h3 className={styles.campaignTypesDesc}>
                      Most popular ad format
                    </h3>
                    <img
                      onLoad={e => {
                        e.target.style.opacity = 1;
                      }}
                      src="../images/push_creative_thmb.png"
                      alt="push"
                    />
                  </div>
                  <div>
                    <div className={styles.formatDescription}>
                      <span />
                      <p>
                        It is a message sent to device after a user subsrcibed to
                        receive notifiacitons from a publisher website.
                      </p>
                    </div>
                    <Button
                      type="primary"
                      className={styles.campaignTypesSelectBtn}
                    >
                      Select Push Notification
                    </Button>
                  </div>
                </div>
              </Col>
            ) : null}
            {userInfo.permissions.contextual ? (
              <Col xl={12} lg={12} md={24} className={styles.campaignTypesDivs}>
                <div
                  className={styles.campaignTypeSection}
                  onClick={() =>
                    balanceInvalid()
                      ? openWarningNotification({ message: balanceInvalid() })
                      : handleCampaignSelect("Contextual Ad")
                  }
                >
                  <div>
                    <h2>Contextual Ads</h2>
                    <h3 className={styles.campaignTypesDesc}>
                      Seamless ad format
                    </h3>
                    <img
                      onLoad={e => {
                        e.target.style.opacity = 1;
                      }}
                      src="../images/smartlink.svg"
                      alt="smartlink"
                    />
                  </div>
                  <div>
                    <div className={styles.formatDescription}>
                      <span />
                      <p>
                        Automatically scan the page for keywords and present users
                        with the most-fitting ads on website.
                      </p>
                    </div>
                    <Button
                      type="primary"
                      className={styles.campaignTypesSelectBtn}
                    >
                      Select Contextual Ads
                    </Button>
                  </div>
                </div>
              </Col>
            ) : null}

            {/* <Col xl={8} md={24} className={styles.campaignTypesDivs}>
          <div className={styles.campaignTypeSection}>
            <div>
              <h2>Native</h2>
              <img
                onLoad={(e) => {
                  e.target.style.opacity = 1;
                }}
                src="../images/native_creative_thmb.png"
                alt="native"
              />
            </div>
            <div>
              <h3 className={styles.campaignTypesDesc}>Same look and feel as web site</h3>
              <p>It is an ad that matches the look, feel and function of the media format in which they appear.</p>
              <Button type="primary" className={styles.campaignTypesSelectBtn} onClick={() => balanceInvalid() ? handleCampaignSelect('Native') : openWarningNotification({message: balanceInvalid()})}>
                Select
              </Button>
            </div>
          </div>
        </Col> */}
            {userInfo.permissions.smartlink ? (
              <Col xl={12} lg={12} md={24} className={styles.campaignTypesDivs}>
                <div
                  className={styles.campaignTypeSection}
                  onClick={() =>
                    balanceInvalid()
                      ? openWarningNotification({ message: balanceInvalid() })
                      : handleCampaignSelect("Smartlink")
                  }
                >
                  <div>
                    <h2>OnClick Ads</h2>
                    <h3 className={styles.campaignTypesDesc}>
                      Cheap and high volume traffic
                    </h3>
                    <img
                      onLoad={e => {
                        e.target.style.opacity = 1;
                      }}
                      src="../images/smartlink.svg"
                      alt="smartlink"
                    />
                  </div>
                  <div>
                    <div className={styles.formatDescription}>
                      <span />
                      <p>
                        It is a full screen ad, opened in a browser upon a click
                        from a user, anywhere on a Publisher website.
                      </p>
                    </div>

                    <Button
                      type="primary"
                      className={styles.campaignTypesSelectBtn}
                    >
                      Select OnClick Ads
                    </Button>
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        )}
      </Modal>
    );
};
