import React from "react";
import Lottie from "react-lottie";
import coinisLoader from "./coinis_loading.json";
import styles from "./CoinisLoader.module.css";

export const CoinisLoader = () => {
  return (
    <div className={styles.coinisLoaderWrapper}>
      <Lottie
        id="offerAnimation"
        options={{
          loop: true,
          autoplay: true,
          animationData: coinisLoader,
        }}
        height={400}
        // width={350}
      />
    </div>
  );
};
