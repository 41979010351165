import { Button, Input, Switch } from "antd";
import React, { useEffect } from "react";
import styles from "../CreativeBuilder.module.css";
import { ImageUploader } from "./ImageUploader";
import { IconUploader } from "./IconUploader";
import { useState } from "react";
import { Headlines } from "./Headlines";
import { MACROS } from "../../../../../utils";
import { MacroInfoModal } from "./MacroInfoModal";
import { AiGeneratorModal } from "../components/AiGeneratorModal";
import { CallToAction } from "./CallToAction";

export const GeneratorForm = ({
  type,
  images,
  setImages,
  icon,
  setIcon,
  headlines,
  setHeadlines,
  landingUrl,
  setLandingUrl,
  description,
  setDescription,
  setImageModalVisible,
  c2aText,
  setC2aText,
  c2aUrl,
  setC2aUrl,
  c2aIcon,
  setC2aIcon,
  formBulk,
  headlineDescriptionAIGeneratorVisible,
  setHeadlineDescriptionAIGeneratorVisible,
  isReturnHeadlinesAndDescription,
  setIsReturnHeadlinesAndDescription,
}) => {
  const [whereTheCursorWas, setWhereTheCursorWas] = useState(undefined);
  const [macroInfoVisible, setMacroInfoVisible] = useState(false);
  const [callToAction, setCallToAction] = useState(false);

  useEffect(() => {
    if (!callToAction) {
      setC2aText("");
      setC2aUrl("");
      setC2aIcon([]);
    }
  }, [callToAction, setC2aText, setC2aUrl, setC2aIcon]);

  const handleMacrosClick = (macroContent) => {
    const newValue = whereTheCursorWas
      ? landingUrl.substring(0, whereTheCursorWas) +
      macroContent +
      landingUrl.substring(whereTheCursorWas, landingUrl.length)
      : landingUrl + macroContent;

    if (whereTheCursorWas !== undefined) {
      setWhereTheCursorWas((prevState) => prevState + macroContent.length);
    }
    setLandingUrl(newValue);
  };

  return (
    <div className={styles.builderForm}>
      <Button
        size="default"
        type="primary"
        ghost
        className={styles.creativesAutoAiGeneratorButton}
        onClick={() => {
          setIsReturnHeadlinesAndDescription({
            multiheadlines: true,
            headlines: true,
            description: true,
          });
          setHeadlineDescriptionAIGeneratorVisible(true);
        }}
      >
        Auto-generate ad headline and description
      </Button>
      <Headlines headlines={headlines} setHeadlines={setHeadlines} />
      <p className={styles.builderLabel}>
        Ad Description{" "}
        <span
          className={
            description && description.length > 200
              ? styles.dangerTextCounter
              : {}
          }
        >
          {description ? description.length : 0}/200
        </span>
      </p>
      <div>
        <Input
          placeholder="Ad Brand Text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={
            description && description.length > 200 && styles.dangerText
          }
        />
      </div>
      <ImageUploader
        images={images}
        setImages={setImages}
        setImageModalVisible={setImageModalVisible}
      />

      {(type === "Push" || type === "Premium") && (
        <>
          <IconUploader
            uploadType="bulk_icon"
            icon={icon}
            setIcon={setIcon}
            setImageModalVisible={setImageModalVisible}
          />
          <div style={{ marginTop: 20 }} />
          <Switch
            checked={callToAction}
            onChange={() => setCallToAction((prevValue) => !prevValue)}
          />
          <span className={styles.builderLabel} style={{ fontWeight: "bold" }}>
            &nbsp;&nbsp;Call to Action
          </span>
          {callToAction && (
            <CallToAction
              setImageModalVisible={setImageModalVisible}
              c2aText={c2aText}
              setC2aText={setC2aText}
              c2aUrl={c2aUrl}
              setC2aUrl={setC2aUrl}
              c2aIcon={c2aIcon}
              setC2aIcon={setC2aIcon}
            />
          )}
        </>
      )}
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}>* </span>Landing URL{" "}
        <span>- full click url including https://</span>
      </p>
      <div style={{ margin: "0 0 0.8rem 0" }}>
        <Input
          placeholder="https://examplelandingpage.com"
          value={landingUrl}
          style={{ borderRadius: "5px" }}
          onChange={(e) => setLandingUrl(e.target.value)}
        />
      </div>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}></span>Add Macros{" "}
        <span>- add supported macros to your URL</span>
      </p>
      <div>
        {MACROS.map((macro) => (
          <div
            key={macro.value}
            className={
              landingUrl.split(macro.value).length - 1
                ? styles.macroItemSelected
                : styles.macroItem
            }
            onClick={() => handleMacrosClick(macro.value)}
          >
            {macro.value}
          </div>
        ))}
      </div>
      <Button
        className={styles.macroInfoBtn}
        size="small"
        type="link"
        onClick={() => setMacroInfoVisible(true)}
      >
        Learn what macros do.
      </Button>
      <MacroInfoModal
        setVisible={setMacroInfoVisible}
        visible={macroInfoVisible}
      />
      <AiGeneratorModal
        campaignId={false}
        setVisible={setHeadlineDescriptionAIGeneratorVisible}
        visible={headlineDescriptionAIGeneratorVisible}
        isReturnHeadlinesAndDescription={isReturnHeadlinesAndDescription}
        setHeadlines={setHeadlines}
        setDescription={setDescription}
      />
    </div>
  );
};
