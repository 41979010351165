import { Select, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './StatisticsLineChart.module.css';
import Highcharts from 'highcharts';
import moment from 'moment';
import { openWarningNotification } from '../../../../utils/notifications';
const { Option } = Select;

const defaultOptions = {
  chart: {
    zoomType: 'x'
  },
  title: {
    text: ''
  },
  xAxis: [
    {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: 'Date'
      }
    },
    {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: 'Date'
      },
      opposite: true
    }
  ],
  yAxis: [],
  tooltip: {
    shared: true
  },
  legend: {
    floating: false,
    layout: 'vertical',
    navigation: {
      activeColor: '#3E576F',
      animation: true,
      arrowSize: 3,
      inactiveColor: '#CCC',
      style: {
        fontWeight: 'bold',
        color: '#333',
        fontSize: '12px'
      }
    },
    margin: 0,
    labelFormatter: function () {
      let max = this.xData[this.xData.length - 1];
      let min = this.xData[0];
      return Highcharts.dateFormat('%b, %e', new Date(min)) + ' - ' + Highcharts.dateFormat('%b, %e', new Date(max)) + ' ' + this.name;
    },
    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
  },
  series: []
};

export const StatisticsLineChart = ({ loading, chartData, setDateRange }) => {
  const addNewYAxis = useCallback((name, data, index, xAxis = 0, options) => {
    var text;
    switch (name) {
      case 'im':
        text = 'impressions';
        break;
      case 'dw':
        text = 'downloads';
        break;
      case 'pl':
        text = 'conversions';
        break;
      case 'ri':
        text = 'real impressions';
        break;
      case 'pf':
        text = 'fired';
        break;
      case 'p1':
        text = 'PX 1';
        break;
      case 'cr':
        text = 'conversion rate';
        break;
      case 'p2':
        text = 'PX 2';
        break;
      default:
        text = name;
    }
    if (index > options.yAxis.length - 1) {
      let format = '{value}';
      if (name === 'spent' || name === 'earned') {
        format = '$ ' + format;
      }

      const colorIndex = options.series.length;
      const color = Highcharts.getOptions().colors[colorIndex];

      options.yAxis.push({
        labels: {
          format,
          style: {
            color
          }
        },
        title: {
          text: text,
          style: {
            color
          }
        },
        opposite: true
      });
    }

    options.series.push({
      name: text,
      type: 'spline',
      xAxis,
      yAxis: index,
      data
    });
  }, []);

  const metrics = useMemo(() => {
    return ['spent', 'leads', 'impressions', 'clicks'];
  }, []);

  const parseChartData = useCallback((chartData) => {
    // checking if response data is for the chart or the table
    if (chartData && chartData.values) {
      let options = { ...defaultOptions, series: [], yAxis: [] };
      let yAxisIndex = 0;
      if (metrics.length > 0) {
        metrics.forEach((metric, metricIndex) => {
          const values = chartData.values.map((value) => [value[0], value[metricIndex + 1]]);
          addNewYAxis(metric, values, yAxisIndex, 0, options);
          yAxisIndex++;
          if (yAxisIndex > options.yAxis.length) {
            yAxisIndex = options.yAxis.length;
          }
        });
      } else {
        openWarningNotification({ message: `There is no data for this filters`, duration: 8 });
      }
      if (options.series.length > 0) {
        Highcharts.chart('chart-wrapper', options);
      }
    } else {
      if (localStorage.getItem('ssp-user-load')) {
        openWarningNotification({ message: `There is no data for this filters`, duration: 8 });
      } else {
        localStorage.setItem('ssp-user-load', 'up');
      }
    }
  }, [addNewYAxis, metrics]);

  useEffect(() => {
    Highcharts.chart('chart-wrapper', defaultOptions);
    chartData !== null && parseChartData(chartData);
  }, [chartData, parseChartData]);

  const handleDateRangeChange = (value) => {
    switch (value) {
      case 'today':
        setDateRange({ from: moment(), to: moment() });
        break;
      case 'yesterday':
        setDateRange({ from: moment().subtract(1, 'days'), to: moment().subtract(1, 'days') });
        break;
      case '7days':
        setDateRange({ from: moment().subtract(6, 'days'), to: moment() });
        break;
      case '30days':
        setDateRange({ from: moment().subtract(30, 'days'), to: moment() });
        break;
      case 'thisMonth':
        setDateRange({ from: moment().startOf('month'), to: moment().endOf('month') });
        break;
      case 'lastMonth':
        setDateRange({ from: moment().subtract(1, 'months').startOf('month'), to: moment().subtract(1, 'months').endOf('month') });
        break;
      default:
        setDateRange({ from: moment(), to: moment() });
        break;
    };
  }

  return (
    <Spin spinning={loading}>
      <div className={styles.statisticsLineHeader}>
        <h4>Statistics</h4>
        <Select defaultValue="thisMonth" style={{ width: 150 }} onChange={handleDateRangeChange}>
          <Option value="today">Today</Option>
          <Option value="yesterday">Yesterday</Option>
          <Option value="7days">Last 7 Days</Option>
          <Option value="30days">Last 30 Days</Option>
          <Option value="thisMonth">This Month</Option>
          <Option value="lastMonth">Last Month</Option>
        </Select>
      </div>
      <div id="chart-wrapper" style={{ height: '500px' }} className={styles.lineChartContainer} />
    </Spin>
  );
};
