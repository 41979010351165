import { Checkbox, Radio, Row, Slider, Space, Col } from "antd";
import React, { useContext } from "react";
import { CampaignContext } from "../../../contexts/campaignContext";
import { TargetingContext } from "../../../contexts/targetingContext";
import { hourMarks, validHourMarks } from "../utils";
import styles from "./UserFreshnessTargeting.module.css";
import { ValidHourSlider } from "../components/ValidHourSlider";

export const UserFreshnessTargeting = () => {
  const { agesAll } = useContext(TargetingContext);
  const { targeting, dispatchTargeting } = useContext(CampaignContext);

  const handleFreshnessTypeChange = (e) => {
    if (e.target.value === "day") {
      dispatchTargeting({ type: "user_age", payload: null });
      dispatchTargeting({ type: "age", payload: { items: ["1"] } });
    } else {
      dispatchTargeting({
        type: "user_age",
        payload: { from: 0, to: 1 },
      });
      dispatchTargeting({ type: "age", payload: null });
    }
    dispatchTargeting({ type: "freshness_type", payload: e.target.value });
  };

  const handleHourUpdate = (values) => {
    if (values[0] - values[1] === 0) return;
    if (
      (Number(targeting.user_age.from) === values[0] || validHourMarks.includes(values[0])) &&
      (Number(targeting.user_age.to) === values[1] || validHourMarks.includes(values[1]))
    ) {
      dispatchTargeting({
        type: "user_age",
        payload: { from: values[0], to: values[1] },
      });
    }
  };

  return (
    <div className={styles.userFreshnessContainer}>
      <div className={styles.radioButtonSelection}>
        <Radio.Group value={targeting.freshness_type} onChange={handleFreshnessTypeChange}>
          <Space direction="vertical">
            <Radio value="hour">
              Target users by hours <span className={styles.radioSuperFresh}>Super Fresh</span>
            </Radio>
            <Radio value="day">Target users by days</Radio>
          </Space>
        </Radio.Group>
      </div>
      {targeting.freshness_type === "day" ? (
        <Checkbox.Group
          style={{ width: "100%" }}
          value={targeting.age.items}
          onChange={(items) => dispatchTargeting({ type: "age", payload: { items } })}
        >
          <Row>
            {agesAll.map((item) => {
              return (
                <Col className={styles.freshnessCheck} key={item.value} span={12}>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      ) : (
        <div className={styles.targetingBoxHour}>
          {targeting.valid_hour ? (
            <ValidHourSlider
              min={0}
              max={4}
              value={[targeting.user_age.from, targeting.user_age.to]}
              onChange={handleHourUpdate}
            />
          ) : (
            <Slider
              min={0}
              max={24}
              value={[targeting.user_age.from, targeting.user_age.to]}
              onChange={handleHourUpdate}
              range
              step={null}
              marks={hourMarks}
              trackStyle={[{ backgroundColor: "#FAAD14" }]}
              // railStyle={{ backgroundColor: "#cc2211" }}
              handleStyle={{
                //   backgroundColor: "#00",
                border: "2px solid #1890ff",
              }}
            />
          )}
          {/* <div className={styles.targetingBoxHourText}>
            <span>{targeting.user_age.from}</span>
            <span>{targeting.user_age.to}</span>
          </div> */}
        </div>
      )}
    </div>
  );
};
