import React, { useContext, useEffect, useState } from "react";

import styles from "./CampaignRules.module.css";
import { Input, InputNumber, Select, Row, Col, Radio, Button, Divider, Tooltip } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  ReloadOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { CampaignContext } from "../../../contexts/campaignContext";
import { campaignOptions } from "../utils";
import { RulePresetModal } from "../components/RulePresetModal";
import { parseRuleForForm } from "../../rule-administration/utils/ruleUtils";

const SUB_RULE_OBJ = () => ({
  metric: "",
  num: "",
  condition: "",
});

const RULE_OBJ = () => ({
  sub_rules: [SUB_RULE_OBJ()],
  selected_metrics: [],
  time_range: "24h",
  action: "pause",
  action_value: 10,
  action_limit: null,
});

export const CampaignRules = ({ form, isCampaignEdit }) => {
  const {
    rules,
    setRules,
    presetRules,
    // bidDataLoading
  } = useContext(CampaignContext);

  const [presetRuleModalVisible, setPresetRuleModalVisible] = useState(false);

  const handleAddSubRule = (ruleIndex) => {
    setRules((prevRulesList) =>
      prevRulesList.map((rule, index) =>
        index === ruleIndex
          ? {
              ...rule,
              sub_rules: [
                ...rule.sub_rules,
                {
                  metric: "",
                  num: "",
                  condition: "",
                },
              ],
            }
          : rule
      )
    );
  };

  const handleAddRule = () => {
    setRules((prevRulesList) => [...prevRulesList, RULE_OBJ()]);
  };

  const addRulePreset = (rule) => {
    const parsedRule = parseRuleForForm(rule);
    setRules((prevRulesList) => [...prevRulesList, parsedRule]);
  };

  const handleDeleteSubRule = (ruleIndex, subRuleIndex) => {
    setRules((prevRulesList) =>
      prevRulesList.map((rule, index) => {
        if (index === ruleIndex) {
          const updatedSubRules = rule.sub_rules.filter((_, subIndex) => subIndex !== subRuleIndex);
          const updatedSelectedMetrics = rule.selected_metrics.filter(
            (_, metricIndex) => metricIndex !== subRuleIndex
          );

          return {
            ...rule,
            sub_rules: updatedSubRules,
            selected_metrics: updatedSelectedMetrics,
          };
        } else {
          return rule;
        }
      })
    );
  };

  const handleChangeRule = (name, value, ruleIndex, subRuleIndex) => {
    if (subRuleIndex !== undefined) {
      // Update subrule field
      setRules((prevRulesList) =>
        prevRulesList.map((rule, index) => {
          if (index === ruleIndex) {
            rule.error = null;
            const updatedRule = { ...rule };
            const subRule = updatedRule.sub_rules[subRuleIndex];

            if (name === "metric") {
              // Remove the previously selected metric if it exists
              const prevMetricIndex = updatedRule.selected_metrics.indexOf(subRule.metric);
              if (prevMetricIndex !== -1) {
                updatedRule.selected_metrics.splice(prevMetricIndex, 1);
              }
              // Add the newly selected metric
              updatedRule.selected_metrics.push(value);
            }

            // Update the subrule field
            subRule[name] = value;
            return updatedRule;
          } else {
            return rule;
          }
        })
      );
    } else {
      // Update main rule field
      setRules((prevRulesList) =>
        prevRulesList.map((rule, index) => {
          const alterObj =
            name === "action" && value === "pause"
              ? { action_limit: null, action_value: null }
              : {};
          return index === ruleIndex ? { ...rule, [name]: value, ...alterObj } : rule;
        })
      );
    }
  };

  const handleResetRule = (ruleIndex) => {
    setRules((prevRulesList) =>
      prevRulesList.map((rule, index) => (index === ruleIndex ? { ...RULE_OBJ() } : rule))
    );
  };

  const handleDeleteRule = (ruleIndex) => {
    setRules((prevRulesList) => prevRulesList.filter((_, index) => index !== ruleIndex));
  };

  useEffect(() => {
    !isCampaignEdit && setRules([]);
  }, [isCampaignEdit, setRules]);

  return (
    <>
      <p className={styles.headTabInfo}>Campaign rules</p>
      <div className={styles.generalWrapper}>
        {rules.length === 0 && (
          <p className={styles.noRules}>You currently have no rules attached to this campaign.</p>
        )}
        {rules.map((rule, ruleIndex) => (
          <div key={ruleIndex} className={styles.ruleWrapper}>
            <Row gutter={[16, 8]}>
              <Col className={styles.flexCustomWidth}>
                <span>If</span>
                <Input value={"Campaign"} disabled className={styles.ruleInput} />
              </Col>
            </Row>
            {rule.sub_rules.map((subRule, subRuleIndex) => (
              <Row wrap={true} justify="start" gutter={[16, 8]} key={subRuleIndex}>
                <Col className={styles.flexCustomWidth}>
                  <span>has</span>
                  <Select
                    placeholder="Select metric"
                    value={subRule.metric ? subRule.metric : undefined}
                    name={`metric-${ruleIndex}-${subRuleIndex}`}
                    className={styles.ruleInput}
                    onChange={(value) => handleChangeRule("metric", value, ruleIndex, subRuleIndex)}
                    options={campaignOptions.metrics(rule)}
                  />
                </Col>
                <Col
                  flex="auto"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  className={styles.start}
                >
                  <Col xs={7} sm={5} md={5} lg={6} xl={5} xxl={4}>
                    <span>with values</span>
                  </Col>
                  <Col xs={7} sm={5} md={5} lg={5} xl={5} xxl={6}>
                    <Radio.Group
                      value={subRule.condition}
                      name={`condition-${ruleIndex}-${subRuleIndex}`}
                      size="middle"
                      onChange={(e) =>
                        handleChangeRule("condition", e.target.value, ruleIndex, subRuleIndex)
                      }
                    >
                      <Radio.Button value="lower">
                        <LeftOutlined />
                      </Radio.Button>
                      <Radio.Button value="greater">
                        <RightOutlined />
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                  <Col xs={9} sm={6} md={6} lg={5} xl={6} xxl={5}>
                    <span>than</span>
                    <InputNumber
                      controls={false}
                      // status={!subRule.num && "error"}
                      placeholder="Value"
                      value={subRule.num}
                      name={`num-${ruleIndex}-${subRuleIndex}`}
                      className={styles.ruleInput}
                      onChange={(value) => handleChangeRule("num", value, ruleIndex, subRuleIndex)}
                    />
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={9} className={styles.end}>
                    <Button
                      onClick={() => handleDeleteSubRule(ruleIndex, subRuleIndex)}
                      disabled={rule.sub_rules.length === 1}
                    >
                      <DeleteOutlined />
                    </Button>
                    {subRuleIndex === rule.sub_rules.length - 1 && (
                      <Button
                        className={styles.addSubRule}
                        onClick={() => handleAddSubRule(ruleIndex)}
                        disabled={
                          rule.sub_rules.length === 6 ||
                          subRule.metric === "" ||
                          subRule.num === "" ||
                          subRule.condition === ""
                        }
                      >
                        + ADD
                      </Button>
                    )}
                  </Col>
                </Col>
              </Row>
            ))}
            <Row wrap={true} justify="start" gutter={[16, 8]} className={styles.start}>
              <Col className={styles.flexCustomWidth}>
                <span>within</span>
                <Select
                  value={rule.time_range}
                  name={`time_range-${ruleIndex}`}
                  className={styles.ruleInput}
                  onChange={(value) => handleChangeRule("time_range", value, ruleIndex)}
                  options={campaignOptions.timeRanges}
                />
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} className={styles.start}>
                <Col xs={4} sm={2} md={3} lg={3} xl={5} xxl={4}>
                  <span>then</span>
                </Col>
                <Col xs={20} sm={9} md={11} lg={11} xl={9}>
                  <Select
                    value={rule.action}
                    name={`action-${ruleIndex}`}
                    className={styles.ruleInput}
                    onChange={(value) => handleChangeRule("action", value, ruleIndex)}
                    options={campaignOptions.actions}
                  />
                </Col>
                {(rule.action === "increase_bid" || rule.action === "decrease_bid") && (
                  <Col xs={24} sm={12} md={10} lg={10} xl={6}>
                    <div className={styles.bidUpdateContainer}>
                      <Tooltip title="-5%">
                        <Button
                          size="medium"
                          className={styles.buttonLeft}
                          disabled={rule.action_value <= 5}
                          onClick={() =>
                            handleChangeRule("action_value", rule.action_value - 5, ruleIndex)
                          }
                        >
                          <MinusOutlined />
                        </Button>
                      </Tooltip>
                      <div className={styles.bidBox}>
                        <InputNumber
                          min={5}
                          max={rule.action === "decrease_bid" ? 99 : 100}
                          value={rule.action_value}
                          // className={styles.errorBid}
                          onChange={(value) => handleChangeRule("action_value", value, ruleIndex)}
                        />
                      </div>
                      <Tooltip title="+5%">
                        <Button
                          size="medium"
                          className={styles.buttonRight}
                          disabled={rule.action_value >= (rule.action === "decrease_bid" ? 95 : 96)}
                          onClick={() =>
                            handleChangeRule("action_value", rule.action_value + 5, ruleIndex)
                          }
                        >
                          <PlusOutlined />
                        </Button>
                      </Tooltip>
                    </div>
                  </Col>
                )}
              </Col>
            </Row>
            <Row
              wrap={true}
              justify="end"
              gutter={[16, 8]}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Col className={styles.customOffset} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Col
                  className={rule.action === "pause" && styles.hiddenTxt}
                  xs={5}
                  sm={4}
                  md={4}
                  lg={5}
                  xl={5}
                  xxl={4}
                >
                  <span>stop at</span>
                </Col>
                {rule.action !== "pause" && (
                  <Col xs={7} sm={8} lg={13} xl={13}>
                    <InputNumber
                      prefix="$"
                      controls={false}
                      value={rule.action_limit}
                      name={`action_limit-${ruleIndex}`}
                      onChange={(value) => handleChangeRule("action_limit", value, ruleIndex)}
                    />
                  </Col>
                )}
                <Col xs={12} sm={12} lg={6} xl={6} xxl={7} className={styles.end}>
                  <Tooltip title="Delete rule">
                    <Button onClick={() => handleDeleteRule(ruleIndex)}>
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Reset rule">
                    <Button onClick={() => handleResetRule(ruleIndex)}>
                      <ReloadOutlined />
                    </Button>
                  </Tooltip>
                </Col>
              </Col>
            </Row>
            {rule.error && (
              <div className={styles.errorRule}>
                <p>
                  <ExclamationCircleOutlined /> {rule.error}
                </p>
              </div>
            )}
          </div>
        ))}
        <Divider>
          <div className={styles.ruleBtns}>
            <Button disabled={rules.length === 3} onClick={() => handleAddRule()}>
              + Add New Rule
            </Button>
            <Button disabled={rules.length === 3} onClick={() => setPresetRuleModalVisible(true)}>
              Use Rule Presets
            </Button>
          </div>
        </Divider>
        <p className={styles.italicText}>You can add up to 3 rules.</p>
      </div>
      <RulePresetModal
        addRulePreset={addRulePreset}
        presetRuleModalVisible={presetRuleModalVisible}
        setPresetRuleModalVisible={setPresetRuleModalVisible}
        presetRules={presetRules}
      />
    </>
  );
};
