import React from 'react';
import styles from '../Dashboard.module.css';

export const Insights = () => {
  return (
    <div className={styles.insightsContainer}>
      <h4>INSIGHTS</h4>
      <div className={styles.insightsList}>
        <p>
          <span className={styles.insightsDot} />
          Increase your opportunities to reach the right audience by adding additional targeting.
        </p>
      </div>
    </div>
  );
};
