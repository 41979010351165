import React from 'react';
import { Radio, Button, Row, Col, DatePicker } from 'antd';
import moment from 'moment';
import styles from '../BillingPaymentHistory.module.css';

const { RangePicker } = DatePicker;

export const CampaignFilters = ({ filters, submit, dateRange, setDateRange }) => {
  return (
    <>
      <div className={styles.filtersContainer}>
        <Radio.Group className={styles.dateRadioSelect} defaultValue="today" size="large">
          <Radio.Button value="today" onClick={() => setDateRange({ from: moment(), to: moment() })}>
            Today
          </Radio.Button>
          <Radio.Button
            value="yesterday"
            onClick={() => setDateRange({ from: moment().subtract(1, 'days'), to: moment().subtract(1, 'days') })}
          >
            Yesterday
          </Radio.Button>
          <Radio.Button value="7days" onClick={() => setDateRange({ from: moment().subtract(6, 'days'), to: moment() })}>
            Last 7 Days
          </Radio.Button>
          <Radio.Button value="30days" onClick={() => setDateRange({ from: moment().subtract(30, 'days'), to: moment() })}>
            Last 30 Days
          </Radio.Button>
          <Radio.Button value="thisMonth" onClick={() => setDateRange({ from: moment().startOf('month'), to: moment().endOf('month') })}>
            This Month
          </Radio.Button>
          <Radio.Button
            value="lastMonth"
            onClick={() =>
              setDateRange({ from: moment().subtract(1, 'months').startOf('month'), to: moment().subtract(1, 'months').endOf('month') })
            }
          >
            Last Month
          </Radio.Button>
        </Radio.Group>
        <div style={{ marginTop: '1%', paddingBottom: '0.5rem' }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              {/* <span className={styles.spanMarginBottom}>Date</span> */}
              <RangePicker
                style={{ width: '100%' }}
                allowClear={false}
                // className={styles.rangeDatePicker}
                onChange={(momentDates, dates) => {
                  setDateRange({ from: momentDates[0], to: momentDates[1] });
                }}
                value={[moment(dateRange.from), moment(dateRange.to)]}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <Button
                className={styles.applyFiltersBtn}
                onClick={() => submit(filters)}
                type="primary"
                size="medium"
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
