import React, { useState, useEffect, useMemo } from "react";

import { Table } from "./components/Table";
import styles from "./RuleAdministration.module.css";
import { staticColumns } from "./utils/columns";
import { useFetch } from "../../utils/hooks";
import { presetRulesService } from "../../services/presetRules";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { RuleForm } from "./components/RuleForm";
import {
  RULE_OBJ,
  isSingleRuleValid,
  parseRuleForForm,
  parseRulesForRequest,
} from "./utils/ruleUtils";
import { openSuccessNotification, openWarningNotification } from "../../utils/notifications";

export const RuleAdministration = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectedColumns, setSelectedColumns] = useState([...staticColumns]);
  const [currentRule, setCurrentRule] = useState(null);
  const [formLoading, setFormLoading] = useState(false);

  const loadRulePresets = (cancelToken) =>
    presetRulesService.getPresetRules({
      currentPage,
      pageSize,
      cancelToken,
    });

  const [
    {
      data: { results: campaigns, summary: sumOfData, count: totalRows },
      isFetching,
    },
    getRulePresets,
  ] = useFetch(loadRulePresets);

  const handleRuleSave = async () => {
    if (isSingleRuleValid(currentRule, setCurrentRule)) {
      setFormLoading(true);
      try {
        const parsedRule = parseRulesForRequest(currentRule);
        const resData = await (parsedRule.id
          ? presetRulesService.updateRule(parsedRule.id, parsedRule)
          : presetRulesService.createRule(parsedRule));
        if (resData.success) {
          setCurrentRule(null);
          getRulePresets();
          openSuccessNotification({ message: "Rule saved successfully" });
        }
      } catch (err) {
        openWarningNotification({ message: "Please check your rule settings" });
      } finally {
        setFormLoading(false);
      }
    } else {
      openWarningNotification({ message: "Please check your rule settings" });
    }
  };

  const actionColumn = useMemo(
    () => ({
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div className={styles.actionBtns}>
          <Popconfirm
            title="Are you sure?"
            onConfirm={async () => {
              try {
                await presetRulesService.deleteRule(record.id);
                getRulePresets();
                openSuccessNotification({ message: "Rule deleted successfully" });
              } catch (err) {
                openWarningNotification({ message: "Please check your rule settings" });
              }
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button className={styles.actionBtnCol} icon={<DeleteOutlined />} />
          </Popconfirm>
          <Button
            className={styles.actionBtnCol}
            icon={<EditOutlined />}
            onClick={() => {
              try {
                const parsedRule = parseRuleForForm(record);
                setCurrentRule(parsedRule);
              } catch (err) {
                console.log(err);
              }
            }}
          />
        </div>
      ),
    }),
    [getRulePresets]
  );

  useEffect(() => {
    setSelectedColumns([...staticColumns, actionColumn]);
    getRulePresets();
  }, [currentPage, pageSize, getRulePresets, actionColumn]);

  return (
    <div className={styles.campaignsTable}>
      <h4>Rule Administration</h4>
      <p>Find and manage all of your preset rules</p>
      <Button
        icon={<PlusOutlined />}
        className={styles.addRuleBtn}
        type="primary"
        onClick={() => {
          setCurrentRule(RULE_OBJ());
        }}
      >
        Add New Rule Preset
      </Button>
      <Table
        selectedColumns={selectedColumns}
        campaigns={campaigns}
        sumOfData={sumOfData}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
      />

      <RuleForm
        formLoading={formLoading}
        currentRule={currentRule}
        setCurrentRule={setCurrentRule}
        handleRuleSave={handleRuleSave}
      />
    </div>
  );
};
