import { callApi } from "./api";

class AuthService {
  user = null;

  async getUserProfile() {
    if (this.user) {
      this.callSmartlook(this.user[0]);
      return this.user;
    } else {
      this.user = await callApi({ url: "/api/accounts/" });
      this.callSmartlook(this.user[0]);
      return this.user;
    }
  }

  async callSmartlook(user) {
    window.smartlook && window.smartlook("identify", user.company);
  }

  logout() {
    window.location.href = "/logout";
  }
}

export const auth = new AuthService();
