import React, { useEffect, useReducer, useState } from "react";
import { Drawer } from "antd";
import styles from "./Generator.module.css";
import { CreativeVariations } from "./components/CreativeVariations";
import { CreativeBuilder } from "./components/CreativeBuilder";
import { CreativePreview } from "./components/CreativePreview";
import { reducerGenCreatives } from "../../utils/reducers";
import { Form } from "antd";
import { calculateId } from "../../utils";

export const Generator = ({
  type,
  generatorVisible,
  setGeneratorVisible,
  creativesLeft,
  MAX_CREATIVES,
}) => {
  const [genCreatives, dispatchGenCreatives] = useReducer(
    reducerGenCreatives,
    []
  );
  const [currentCreative, setCurrentCreative] = useState(false);
  // generator variables
  const [headlines, setHeadlines] = useState([""]);
  const [images, setImages] = useState([]);
  const [icon, setIcon] = useState([]);
  const [landingUrl, setLandingUrl] = useState("");
  const [description, setDescription] = useState("");

  // call to action
  const [c2aText, setC2aText] = useState("");
  const [c2aUrl, setC2aUrl] = useState("");
  const [c2aIcon, setC2aIcon] = useState([]);

  const [formBulk] = Form.useForm();

  useEffect(() => {
    if (generatorVisible && generatorVisible !== "NEW") {
      const updateTypeObj = generatorVisible.id
        ? { gen_id: generatorVisible.id }
        : {};
      dispatchGenCreatives({
        type: "create",
        payload: { ...generatorVisible, ...updateTypeObj },
      });
      setCurrentCreative(generatorVisible.gen_id || generatorVisible.id);
    } else {
      handleClearAll();
    } // eslint-disable-next-line
  }, [generatorVisible]);

  useEffect(() => {
    if (genCreatives.length === 0 && images.length && headlines.length) {
      handleClearAll(true);
    } // eslint-disable-next-line
  }, [genCreatives]);

  const generateCreatives = (
    titles,
    images,
    landing_url,
    description,
    icon,
    c2aText,
    c2aUrl,
    c2aIcon
  ) => {
    const payload = titles.flatMap((title) =>
      images.map((image) => {
        const gen_id = calculateId();
        const actions =
          c2aText || c2aUrl
            ? {
                actions: [
                  { title: { en: c2aText }, icon: c2aIcon[0], url: c2aUrl },
                ],
              }
            : {};
        return {
          gen_id,
          title: { en: title },
          description: { en: description },
          image: {
            en: image,
          },
          icon: icon[0],
          landing_url,
          ...actions,
        };
      })
    );
    dispatchGenCreatives({ type: "generate", payload });
  };

  // optimized update (won't call generateCreatives function)
  const handleUpdateCreatives = (payload) => {
    dispatchGenCreatives({ type: "generator_update", payload });
  };

  const handleRegenerate = () => {
    generateCreatives(
      headlines,
      images,
      landingUrl,
      description,
      icon,
      c2aText,
      c2aUrl,
      c2aIcon
    );
    formBulk.setFieldsValue({ headlines, description, landingUrl });
    setCurrentCreative(false);
  };

  const handleClearAll = (fromVariations) => {
    setHeadlines([""]);
    setImages([]);
    setLandingUrl("");
    setDescription("");
    setIcon([]);
    formBulk.resetFields();
    if (!fromVariations) {
      dispatchGenCreatives({ type: "reset" });
    }
    setCurrentCreative(false);
  };

  const editCreative = (id, payload) => {
    dispatchGenCreatives({ type: "edit", id, payload });
  };

  const limitExceeded = genCreatives.length > creativesLeft;

  return (
    <Drawer
      className={styles.creativeDrawer}
      title="Generate Creatives"
      visible={generatorVisible}
      placement="right"
      width={"calc(100% - 200px)"}
      footer={false}
      onClose={() => setGeneratorVisible(false)}
    >
      <div className={styles.creativeGridContainer}>
        {generatorVisible === "NEW" && (
          <CreativeVariations
            type={type}
            limitExceeded={limitExceeded}
            creativesLeft={creativesLeft}
            currentCreative={currentCreative}
            genCreatives={genCreatives}
            dispatchGenCreatives={dispatchGenCreatives}
            setCurrentCreative={setCurrentCreative}
            handleClearAll={handleClearAll}
            handleRegenerate={handleRegenerate}
            MAX_CREATIVES={MAX_CREATIVES}
          />
        )}
        <CreativeBuilder
          type={type}
          setGeneratorVisible={setGeneratorVisible}
          genCreatives={genCreatives}
          editCreative={editCreative}
          generateCreatives={generateCreatives}
          handleUpdateCreatives={handleUpdateCreatives}
          currentCreative={currentCreative}
          dispatchGenCreatives={dispatchGenCreatives}
          headlines={headlines}
          setHeadlines={setHeadlines}
          images={images}
          setImages={setImages}
          icon={icon}
          setIcon={setIcon}
          landingUrl={landingUrl}
          setLandingUrl={setLandingUrl}
          description={description}
          setDescription={setDescription}
          c2aText={c2aText}
          setC2aText={setC2aText}
          c2aUrl={c2aUrl}
          setC2aUrl={setC2aUrl}
          c2aIcon={c2aIcon}
          setC2aIcon={setC2aIcon}
          formBulk={formBulk}
          handleClearAll={handleClearAll}
          generatorVisible={generatorVisible}
        />
        <CreativePreview
          type={type}
          setGeneratorVisible={setGeneratorVisible}
          currentCreative={currentCreative}
          genCreatives={genCreatives}
          limitExceeded={limitExceeded}
          dispatchGenCreatives={dispatchGenCreatives}
          isEdit={generatorVisible !== "NEW"}
        />
      </div>
    </Drawer>
  );
};
