import { parseRuleActions, parseRuleConditions } from "./ruleUtils";
import styles from "../RuleAdministration.module.css";

export const initialSelectedColumns = {
  date: true,
  debit: true,
  credit: true,
  balance: true,
  note: true,
};

export const staticColumns = [
  {
    title: "Rule Name",
    dataIndex: "name",
    render: (text) => <div className={styles.columnNameHighlight}>{text}</div>,
  },
  {
    title: "Rule conditions",
    dataIndex: "",
    render: (_, record) => parseRuleConditions(record),
  },
  {
    title: "Rule actions",
    dataIndex: "action",
    render: (_, record) => parseRuleActions(record),
  },
  {
    title: "Time frame",
    dataIndex: "time_range",
    render: (item) => `Last ${item}`,
  },
];

export const allColumns = [];
