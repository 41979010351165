import classNames from "classnames";
import React, { useState } from "react";
import { useContext } from "react";
import { CampaignContext } from "../../../contexts/campaignContext";
import { useHeadingsData } from "../../../utils/hooks/useHeadingsData";
import { useIntersectionObserver } from "../../../utils/hooks/useInteresectionObserver";
import styles from "./TableOfContents.module.css";

export const TableOfContents = () => {
  const [activeId, setActiveId] = useState();
  const { campaignType } = useContext(CampaignContext);
  useIntersectionObserver(setActiveId, campaignType);

  const { nestedHeadings } = useHeadingsData(campaignType);

  return (
    <div className={styles.tableOfContentsContainer}>
      <ul>
        {nestedHeadings.map(heading => (
          <li
            key={heading.id}
            className={classNames(styles.tableContentItem, {
              [styles.tableContentItemActive]: heading.id === activeId,
            })}
          >
            <a
              href={`#${heading.id}`}
              onClick={e => {
                e.preventDefault();
                document.querySelector(`#${heading.id}`).scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              {heading.title}
            </a>
            {heading.items.length > 0 && (
              <ul>
                {heading.items.map(child => (
                  <li
                    key={child.id}
                    className={classNames(styles.tableContentItem, {
                      [styles.tableContentItemActive]: child.id === activeId,
                    })}
                  >
                    <a
                      href={`#${child.id}`}
                      onClick={e => {
                        e.preventDefault();
                        document.querySelector(`#${child.id}`).scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      {child.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
