import React from "react";
import styles from "./CategoriesSelect.module.css";
import { CategoryKeyword } from "./CategoryKeyword";

export const CategoriesSelect = ({
  targeting,
  shownCategories,
  setSelectedCategories,
}) => {
  return (
    <div className={styles.targetingCheckboxParentDiv}>
      {shownCategories.map(item => (
        <CategoryKeyword
          key={item.key}
          selectedValues={targeting}
          setSelectedCategories={setSelectedCategories}
          label={item.name}
          value={item.key}
        />
        // <CategoryGroup
        //   key={item.category_name}
        //   selectedValues={targeting}
        //   setSelectedCategories={setSelectedCategories}
        //   category_name={item.category_name}
        //   subcategories={item.subcategories}
        // />
      ))}
    </div>
  );
};
