import { callApi } from "./api";
const handleOrdering = (sorting) => {
  const ordering = [];
  for (const key in sorting) {
    const val = sorting[key];
    if (val) {
      if (val === "descend") {
        ordering.push("-" + key);
      } else {
        ordering.push(key);
      }
    }
  }
  return ordering;
};
const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: currentPage,
});

class ReportsService {
  async getCountries() {
    return await callApi({
      url: "/api/countries/",
    });
  }
  async getOperatingSystems() {
    return await callApi({
      url: "/api/os/",
    });
  }
  async getBrowsers() {
    return await callApi({
      url: "/api/browsers/",
    });
  }

  async getStatsPaged({ selectedFilters, currentPage, pageSize, sorting, cancelToken, details }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    //If action is true, request is for stats for nested table
    let colParameter = details ? "" : "";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });
    if (checkedColumns["creator_first_name"]) {
      colParameter += ",creator_last_name";
    }

    // Remove ',' from columns if request is for nested table -- dont need campaign_id for request, error on backend
    // if (details) {
    colParameter = colParameter.substring(1);
    // }

    // if (checkedColumns['lp_title'] && !checkedColumns['lp_id']) {
    //   colParameter += ',lp_id';
    // }
    const ordering = handleOrdering(sorting);
    const query = getQueryString(
      {
        ...getOffsetLimitFromPagination({ currentPage, pageSize }),
        columns: colParameter,
        date_from: dateRange.from.format("YYYY-MM-DD"),
        date_to: dateRange.to.format("YYYY-MM-DD"),
        os: filters.os && filters.os.join(","),
        browser: filters.browsers && filters.browsers.join(","),
        country: filters.countries && filters.countries.join(","),
        campaign_id: filters.campaigns && filters.campaigns.join(","),
        campaign_type: filters.campaign_types && filters.campaign_types.join(","),
        source: filters.source && filters.source,
        creative_id: filters.creative_id && filters.creative_id,
        status: filters.status && filters.status,
        ordering: ordering && ordering.join(","),
        details: details ? true : "",
      },
      true
    );

    return await callApi({
      url: `/api/campaigns/stats/?resType=table&${query}`,
      cancelToken,
    });
  }

  async getChartStatsPaged({ selectedFilters, currentPage, pageSize, sorting, cancelToken }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "date,hour";

    Object.keys(checkedColumns).forEach(function (key) {
      if (
        checkedColumns[key] &&
        [
          "revenue",
          "cpc",
          "cpm",
          "cr",
          "impressions",
          "cpa",
          "clicks",
          "conversions",
          "expected_revenue",
        ].includes(key)
      ) {
        colParameter += "," + key;
      }
    });
    if (checkedColumns["creator_first_name"]) {
      colParameter += ",creator_last_name";
    }
    // if (checkedColumns['lp_title'] && !checkedColumns['lp_id']) {
    //   colParameter += ',lp_id';
    // }
    const ordering = handleOrdering(sorting);
    const query = getQueryString(
      {
        columns: colParameter,
        date_from: dateRange.from.format("YYYY-MM-DD"),
        date_to: dateRange.to.format("YYYY-MM-DD"),
        os: filters.os && filters.os.join(","),
        browser: filters.browser && filters.browser.join(","),
        country: filters.countries && filters.countries.join(","),
        campaign_id: filters.campaigns && filters.campaigns.join(","),
        ordering: ordering && ordering.join(","),
        status: filters.status && filters.status,
      },
      true
    );
    return await callApi({
      url: `/api/campaigns/stats/?resType=chart&${query}`,
      cancelToken,
    });
  }

  async getCreativesStatsPaged({ dateRange, checkedColumns, campaign_id }) {
    const query = getQueryString(
      {
        columns: checkedColumns.join(","),
        date_from: dateRange.from.format("YYYY-MM-DD"),
        date_to: dateRange.to.format("YYYY-MM-DD"),
        campaign_id,
      },
      true
    );

    return await callApi({
      url: `/api/campaigns/stats/?resType=table&${query}`,
    });
  }

  async exportReports(selectedFilters, currentPage, pageSize, sorting) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "campaign_id";
    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });
    if (checkedColumns["creator_first_name"]) {
      colParameter += ",creator_last_name";
    }
    // if (checkedColumns['lp_title'] && !checkedColumns['lp_id']) {
    //   colParameter += ',lp_id';
    // }
    const ordering = handleOrdering(sorting);
    const query = getQueryString(
      {
        ...getOffsetLimitFromPagination({ currentPage, pageSize }),
        columns: colParameter,
        date_from: dateRange.from.format("YYYY-MM-DD"),
        date_to: dateRange.to.format("YYYY-MM-DD"),
        os: filters.os && filters.os.join(","),
        browser: filters.browser && filters.browser.join(","),
        country: filters.countries && filters.countries.join(","),
        campaign_id: filters.campaigns && filters.campaigns.join(","),
        ordering: ordering && ordering.join(","),
      },
      true
    );
    window.location = `/api/campaigns/stats/?resType=csv&${query}`;
  }

  async updatePricingValue(data) {
    return await callApi({
      url: "api/campaigns/price/",
      method: "POST",
      data,
    });
  }
  async changeTargetingStatus(data) {
    return await callApi({
      url: "api/campaigns/targeting/",
      method: "POST",
      data,
    });
  }
  async updateCreatives(data) {
    return await callApi({
      url: "/api/creatives/status/update/",
      method: "POST",
      data,
    });
  }
}

export const reportsService = new ReportsService();
