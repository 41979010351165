import React, { useContext, useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import styles from "./Stripe.module.css";
import UserContext from "../../../../contexts/userContext";

export default function CheckoutForm({ clientSecretInit }) {
  const { userInfo } = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://advertiser.coinis.com/api/stripe/callback/",
        payment_method_data: {
          billing_details: {
            name: `${userInfo.partner_name}`,
          },
        },
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "validation_error") {
      setMessage(error.message);
      // } else if (error.type === "card_error") {
    } else {
      const payment_intent = clientSecretInit.split("_secret")[0];
      window.location.href = `/api/stripe/callback/?redirect_status=failed&payment_intent_client_secret=${clientSecretInit}&payment_intent=${payment_intent}`;
    }

    setIsLoading(false);
  };

  return (
    <form
      className={styles.paymentForm}
      id="payment-form"
      style={{ maxWidth: "740px" }}
      onSubmit={handleSubmit}
    >
      <PaymentElement />
      <button
        className={styles.stripePayButton}
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isLoading ? (
            <div className={styles.spinner} id="spinner"></div>
          ) : (
            "Pay now"
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div className={styles.paymentMessage}>{message}</div>}
    </form>
  );
}
