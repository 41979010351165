import React, { useEffect, useState, useContext } from "react";
import { List, Spin } from "antd";
import VirtualList from "rc-virtual-list";
import { NotificationBarListHeader } from "./components/NotificationBarListHeader";
// import { NotificationBarListFooter } from "./components/NotificationBarListFooter";
import { BarNotificationItem } from "./components/BarNotificationItem";
import NotificationContext from "../../../contexts/notificationContext";

import styles from "./BarNotification.module.css";

export const BarNotification = ({
  userNotifications,
  userNotificationsCount,
  loadingNotifications,
  userNotificationPage,
  setUserNotificationPage
}) => {
  const { showUnread, setShowUnread } = useContext(NotificationContext);
  const [notifications, setNotifications] = useState([]);

  const containerHeight = 400;

  useEffect(() => {
    setNotifications([]);
    setUserNotificationPage(1)
  }, [
    showUnread,
    setUserNotificationPage
  ])

  useEffect(() => {
    setNotifications(notifications => notifications.concat(userNotifications));
  }, [
    setNotifications,
    userNotifications
  ]);

  const appendData = () => {
    if (userNotificationsCount > notifications.length) {
      setUserNotificationPage(userNotificationPage + 1);
    }
  };

  const onScroll = e => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop >= containerHeight
      && e.currentTarget.scrollHeight - e.currentTarget.scrollTop <= containerHeight + .5) {
      appendData();
    }
  };

  return (
    <List
      className={styles.barNotificationList}
      bordered
      header={
        <NotificationBarListHeader
          showUnread={showUnread}
          setShowUnread={setShowUnread}
          userNotifications={userNotifications}
          setNotifications={setNotifications}
          setUserNotificationPage={setUserNotificationPage}
        />
      }
    // footer={
    //   <NotificationBarListFooter
    //     loadingNotifications={loadingNotifications}
    //   />
    // }
    >
      <Spin spinning={loadingNotifications}>
        <VirtualList
          data={notifications}
          height={containerHeight}
          itemKey="id"
          onScroll={onScroll}
          className={styles.virtualListWrapper}
        >
          {notifications.length === 0 ?
            <List.Item>
              <p style={{ marginTop: "10px", color: "#918e8e" }}>
                You currently don't have notifications.
              </p>
            </List.Item>
            :
            notification => (
              <BarNotificationItem
                item={notification}
                showUnread={showUnread}
              />
            )
          }
        </VirtualList>
      </Spin>
    </List >
  )
};
