import React from "react";
import styles from "../AddFunds.module.css";

export const TabsSelection = ({
  currentPayment,
  setCurrentPayment,
  currentPrice,
  setCurrentPrice,
  customPrice,
}) => {
  return (
    <div className={styles.methodTypeContainer}>
      <div
        className={styles.methodTypeSelect}
        style={
          currentPayment === "card" ? { borderColor: "#4190f7", backgroundColor: "#e8f4ff" } : {}
        }
        onClick={() => setCurrentPayment("card")}
      >
        <img src="/images/debitFinance.png" alt="debit" />
      </div>
      <div
        className={styles.methodTypeSelect}
        style={
          currentPayment === "paypal" ? { borderColor: "#4190f7", backgroundColor: "#e8f4ff" } : {}
        }
        onClick={() => setCurrentPayment("paypal")}
      >
        <img src="/images/paypalFinance.svg" alt="paypal" />
      </div>
      <div
        className={styles.methodTypeSelect}
        style={currentPayment === "payoneer" ? { borderColor: "#4190f7" } : {}}
        onClick={() => setCurrentPayment("payoneer")}
      >
        <img src="/images/payoneerFinance.png" alt="payoneer" />
      </div>
      <div
        className={styles.methodTypeSelect}
        style={
          currentPayment === "wire" ? { borderColor: "#4190f7", backgroundColor: "#e8f4ff" } : {}
        }
        onClick={() => {
          setCurrentPayment("wire");
          if ((currentPrice === "custom" && customPrice < 1000) || parseInt(currentPrice) < 1000) {
            setCurrentPrice("1000");
          }
        }}
      >
        <img src="/images/bankFinance.svg" alt="wire" />
      </div>
      {/* <div
        className={styles.methodTypeSelect}
        style={currentPayment === "tether-erc20" ? { borderColor: "#4190f7", backgroundColor: "#e8f4ff" } : {}}
        onClick={() => {
          setCurrentPayment("tether-erc20")
          if ((currentPrice === "custom" && customPrice < 500) || parseInt(currentPrice) < 500) {
            setCurrentPrice("1000");
          }
        }}
      >
        <img src="/images/usdtFinance.png" alt="tether-erc20" />
      </div> */}
    </div>
  );
};
