import React from "react";
import { Button, Select, Typography } from "antd";
import styles from "./SpecificCopyPaste.module.css";
import { CopyOutlined, GlobalOutlined } from "@ant-design/icons";
import { openSuccessNotification, openWarningNotification } from "../../../utils/notifications";
import CopyToClipboard from "react-copy-to-clipboard";

const { Text } = Typography;

export const SpecificCopyPaste = ({ type, value, onChange }) => {
  const onPasteHandle = (pastedValue) => {
    if (pastedValue !== "") {
      let delimiters = ["\n", " ", ",", ";"];
      let delimiterCheck = 0;
      let delimiter = "";
      let pasted = [];
      delimiters.forEach((delimiterTemp) => {
        if (pastedValue.includes(delimiterTemp)) {
          delimiter = delimiterTemp;
          delimiterCheck = delimiterCheck + 1;
        }
      });

      if (delimiterCheck > 1) {
        openWarningNotification({
          message:
            "It seems that your list contains some irregular characters. Please make sure that your list items are delimited either with a comma, a semicolon, a space or a new line.",
          duration: 16,
        });
      } else {
        if (delimiterCheck) {
          pasted = pastedValue.split(delimiter).map((item) => item.trim());
        } else {
          pasted = [pastedValue];
        }
      }
      const uniqueSet = new Set([...pasted, ...value.items]);
      onChange({ op: value.op, items: [...uniqueSet] });
    }
  };

  return (
    <div>
      <p className={type === "subid" ? styles.infoBtmSubIDs : styles.infoBtm}>
        You can bulk insert your {type === "subid" ? "SubIDs" : "IPs"} by pasting them below.
        Delimiters are <Text keyboard>Space</Text> , <Text keyboard>,</Text> ,{" "}
        <Text keyboard>;</Text> , <Text keyboard>↵</Text>.
      </p>
      <div className={styles.targetingBox}>
        <Select
          mode={"tags"}
          clearIcon={<GlobalOutlined />}
          style={{ width: "100%" }}
          onChange={(items) => onChange({ op: value.op, items })}
          getPopupContainer={(trigger) => trigger.parentNode}
          value={value.items}
          onPaste={(e) => {
            e.preventDefault();
            onPasteHandle(e.clipboardData.getData("Text").trim());
          }}
        />
        <CopyToClipboard
          text={value.items.join(" ")}
          onCopy={() =>
            openSuccessNotification({
              message: "Successfully copied to clipboard!",
            })
          }
        >
          <Button icon={<CopyOutlined />} className={styles.clearBtn} />
        </CopyToClipboard>
        <Button className={styles.clearBtn} onClick={() => onChange({ op: value.op, items: [] })}>
          Clear All
        </Button>
      </div>
    </div>
  );
};
