import React, { useEffect } from "react";
import { Button, Radio, DatePicker, Select, Row, Col } from "antd";
import moment from "moment";

import styles from "./Show.module.css";
// import { mediaCampaignsService } from '../../../services/campaigns';
// import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import { generateCampagins, generateCampaignTypes } from "../utils";

import { useContext } from "react";
import UserContext from "../../../contexts/userContext";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const CampaignFilters = ({
  setDateRange,
  dateRange,
  campaignsList,
  filters,
  setFilters,
  onSubmit,
}) => {
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    onSubmit(filters, dateRange);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.filtersContainer}>
      <Radio.Group
        className={styles.dateRadioSelect}
        defaultValue="today"
        size="large"
      >
        <Radio.Button
          value="today"
          onClick={() => setDateRange({ from: moment(), to: moment() })}
        >
          Today
        </Radio.Button>
        <Radio.Button
          value="yesterday"
          onClick={() =>
            setDateRange({
              from: moment().subtract(1, "days"),
              to: moment().subtract(1, "days"),
            })
          }
        >
          Yesterday
        </Radio.Button>
        <Radio.Button
          value="7days"
          onClick={() =>
            setDateRange({ from: moment().subtract(6, "days"), to: moment() })
          }
        >
          Last 7 Days
        </Radio.Button>
        <Radio.Button
          value="30days"
          onClick={() =>
            setDateRange({ from: moment().subtract(30, "days"), to: moment() })
          }
        >
          Last 30 Days
        </Radio.Button>
        <Radio.Button
          value="thisMonth"
          onClick={() =>
            setDateRange({
              from: moment().startOf("month"),
              to: moment().endOf("month"),
            })
          }
        >
          This Month
        </Radio.Button>
        <Radio.Button
          value="lastMonth"
          onClick={() =>
            setDateRange({
              from: moment().subtract(1, "months").startOf("month"),
              to: moment().subtract(1, "months").endOf("month"),
            })
          }
        >
          Last Month
        </Radio.Button>
      </Radio.Group>
      <div className={styles.filtersContainerBottom}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={6} xxl={4}>
            <RangePicker
              style={{ width: "100%", marginTop: "0.7rem" }}
              allowClear={false}
              className={styles.rangeDatePicker}
              onChange={(momentDates, dates) => {
                setDateRange({ from: momentDates[0], to: momentDates[1] });
              }}
              value={[moment(dateRange.from), moment(dateRange.to)]}
            />
          </Col>
          {/* <Input prefix={<NotificationOutlined />} className={styles.inputTextSearch} placeholder="Campaign name or ID" /> */}

          <Col xs={24} sm={24} md={24} lg={12} xxl={8}>
            <Select
              mode="multiple"
              onChange={value => {
                setFilters({ ...filters, campaigns: value });
              }}
              filterOption={(inputValue, option) =>
                option.children
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                option.value.includes(inputValue.toLowerCase())
              }
              style={{ width: "100%", marginTop: "0.7rem" }}
              placeholder="Select campaigns"
              value={filters.campaigns}
              allowClear
            >
              {generateCampagins(campaignsList || [])}
            </Select>
          </Col>

          {/* <VirtualizedSelect
          options={mediaCampaignsService.generateOptions(campaignsList)}
          onChange={(value) => {
            setFilters({ ...filters, campaigns: value });
          }}
          multi={true}
          selectValue={filters.campaigns}
          placeholder={'Select campaigns'}
        /> */}
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Select
              size="medium"
              className={styles.selectStatuses}
              placeholder="All Statuses"
              style={{ width: "100%", marginTop: "0.7rem" }}
              allowClear
              onChange={value => {
                setFilters({ ...filters, status: value });
              }}
              value={filters.status}
            >
              <Option value="active">Active</Option>
              <Option value="paused">Paused</Option>
              <Option value="pending">Pending</Option>
              <Option value="disabled">Disabled</Option>
              <Option value="suspended">Suspended</Option>
              <Option value="budget-reached">Budget cap</Option>
              {/* <Option value="implementing">Implementing</Option>
              <Option value="ready">Ready</Option>
              <Option value="daily-limit,hourly-limit,monthly-limit,daily,hourly,monthly">Budget cap</Option> */}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Select
              size="medium"
              className={styles.selectFormats}
              placeholder="All Formats"
              style={{ width: "100%", marginTop: "0.7rem" }}
              allowClear
              onChange={value => {
                setFilters({ ...filters, type: value });
              }}
            >
              {generateCampaignTypes(userInfo.permissions || [])}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Button
              className={styles.applyFiltersBtn}
              onClick={() => onSubmit(filters, dateRange)}
              type="primary"
            >
              Apply Filters
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
