import React from "react";
import { Form, Input } from "antd";
import styles from "../CreativeBuilder.module.css";

import { IconUploader } from "./IconUploader";

export const CallToActionSingle = ({
  singleC2aIcon,
  setSingleC2aIcon,
  setImageModalVisible,
}) => {
  return (
    <div className={styles.callToAction}>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}>* </span>Action button text
      </p>
      <div style={{ margin: "0 0 0.8rem 0" }}>
        <Form.Item name="c2aText">
          <Input placeholder="Button text" style={{ borderRadius: "5px" }} />
        </Form.Item>
      </div>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}>* </span>Action button URL
      </p>
      <div style={{ margin: "0 0 0.8rem 0" }}>
        <Form.Item name="c2aUrl">
          <Input placeholder="Button URL" style={{ borderRadius: "5px" }} />
        </Form.Item>
      </div>
      <IconUploader
        optional={true}
        uploadType="single_action_icon"
        icon={singleC2aIcon}
        setIcon={setSingleC2aIcon}
        setImageModalVisible={setImageModalVisible}
      />
    </div>
  );
};
