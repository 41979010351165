import React, { useState } from "react";
import { Button, Divider, Popconfirm, Radio, Space } from "antd";
import {
  SyncOutlined,
  ClearOutlined,
  InsertRowBelowOutlined,
  InsertRowRightOutlined,
} from "@ant-design/icons";
import { CreativeSample } from "./components/CreativeSample";
import styles from "./CreativeVariations.module.css";

export const CreativeVariations = ({
  type,
  genCreatives,
  dispatchGenCreatives,
  setCurrentCreative,
  currentCreative,
  creativesLeft,
  limitExceeded,
  handleClearAll,
  handleRegenerate,
  MAX_CREATIVES,
}) => {
  const [gridVariationBig, setGridVariationBig] = useState(false);

  const generateCreatives = creatives => {
    return creatives.map(creative => (
      <CreativeSample
        type={type}
        limitExceeded={limitExceeded}
        key={creative.gen_id}
        creative={creative}
        dispatchGenCreatives={dispatchGenCreatives}
        currentCreative={currentCreative}
        setCurrentCreative={setCurrentCreative}
        setCurrentLastCreative={setCurrentLastCreative}
      />
    ));
  };

  const setCurrentLastCreative = () => {
    if (genCreatives.length > 1) {
      setCurrentCreative(genCreatives[genCreatives.length - 2].gen_id);
    }
  };

  return (
    <div
      className={styles.creativeVariations}
      style={gridVariationBig ? { flex: "2" } : {}}
    // onClick={() => setCurrentCreative(null)}
    >
      <div
        className={`${styles.headerTitle} ${limitExceeded && styles.maximumTrigger
          }`}
      >
        <p>
          {limitExceeded ? (
            `Too many creatives! Please delete ${genCreatives.length - creativesLeft
            } creatives!`
          ) : (
            <>
              Maximum number of allowed creatives is 15.{" "}
              {MAX_CREATIVES - creativesLeft} saved.
              <br />
              <span>
                You have drafted {genCreatives.length} creatives and you’ve got{" "}
                {creativesLeft} more to add.
              </span>
            </>
          )}
        </p>
      </div>
      <Divider />
      <div className={styles.variationActions}>
        <Space>
          <Popconfirm
            placement="bottomLeft"
            title="All changes will be lost. Are you sure?"
            onConfirm={() => {
              handleRegenerate();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" icon={<SyncOutlined />} type="primary" ghost className={styles.primaryGhostBtn}>
              Regenerate
            </Button>
          </Popconfirm>
          <Popconfirm
            placement="bottomLeft"
            title="All changes will be lost. Are you sure?"
            onConfirm={() => {
              handleClearAll();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" icon={<ClearOutlined />} type="primary" ghost className={styles.primaryGhostBtn}>
              Clear All
            </Button>
          </Popconfirm>
        </Space>
        <Radio.Group
          size="middle"
          value={gridVariationBig}
          onChange={e => setGridVariationBig(e.target.value)}
          buttonStyle="solid"
        >
          <Radio.Button value={false}>
            <InsertRowRightOutlined />
          </Radio.Button>
          <Radio.Button value={true}>
            <InsertRowBelowOutlined />
          </Radio.Button>
        </Radio.Group>
      </div>
      <div
        className={styles.variationsGrid}
        style={gridVariationBig ? { gridTemplateColumns: "repeat(3,1fr)" } : {}}
      >
        {generateCreatives(genCreatives)}
      </div>
    </div>
  );
};
