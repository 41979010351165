import { callApi } from "./api";

class PresetRulesService {
  async getPresetRules({ currentPage, pageSize }) {
    return await callApi({
      url: `/api/rules/presets/?page=${currentPage}&page_size=${pageSize}`,
      method: "GET",
    });
  }

  async createRule(data) {
    return await callApi({
      url: "/api/rules/",
      method: "POST",
      data,
    });
  }

  async updateRule(id, data) {
    return await callApi({
      url: `/api/rules/${id}/`,
      method: "PUT",
      data,
    });
  }

  async deleteRule(id) {
    return await callApi({
      url: `/api/rules/${id}/`,
      method: "DELETE",
    });
  }
}

export const presetRulesService = new PresetRulesService();
