export const validateIPaddress = (ipaddress) => {
  const validateArray = ipaddress.map((address) => {
    return (
      /^(?:(?:2[0-4]\d|25[0-5]|1\d{2}|[1-9]?\d)\.){3}(?:2[0-4]\d|25[0-5]|1\d{2}|[1-9]?\d)$/.test(
        address
      ) ||
      /^(25[0-5]|2[0-4][0-9]|[01]?[1-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-4]|2[0-4][0-9]|[01]?[1-9][0-9]?)\/([1-9]|1[0-9]|2[0-9]|3[0-2]|(((128|192|224|240|248|252|254)\.0\.0\.0)|(255\.(0|128|192|224|240|248|252|254)\.0\.0)|(255\.255\.(0|128|192|224|240|248|252|254)\.0)|(255\.255\.255\.(0|128|192|224|240|248|252|254))))$/.test(
        address
      )
    );
  });
  return !validateArray.includes(false);
};

export const verticalCampaigns = {
  Mobile: [
    "Mobile App - Smartlink",
    "Mobile App - Utility & VPN",
    "Mobile App - Dating",
    "Mobile App - Antivirus",
    "Mobile App - Casino & Gambling",
    "Mobile App - Other",
    "Mobile App - Games",
  ],
  Popular: [
    "Surveys & Sweeps",
    "Sports Betting",
    "Crypto Offers",
    "Dating",
    "Pin Submit",
    "Casino & Gambling",
    "Mainstream VOD/Streaming",
    "Lead Generation",
    "Smartlink",
  ],
  Desktop: [
    "Desktop - Games",
    "Desktop - Software",
    "Desktop - Antivirus",
    "Desktop - Utility & VPN",
    "Desktop - Smartlink",
    "Desktop - Browser Extensions",
  ],
  Insurance: ["Loans", "Investing", "Real Estate", "Binary Options/Trading"],
  Other: [
    "Careers",
    "Services & Repairs",
    "Education/Personal development",
    "Hobbies & Interests",
    "Shopping and travel",
    "Travel booking sites",
    "Shopping",
    "Health and Beauty",
    "Pharmacy",
    "Recreational Drugs & Weeds",
    "Nutra & Diet",
  ],
};

export const validHourMarks = [0, 1, 3, 12, 24];

export const hourMarks = {
  0: "0h",
  1: "1h",
  2: " ",
  3: "3h",
  4: " ",
  5: " ",
  6: " ",
  7: " ",
  8: " ",
  9: " ",
  10: " ",
  11: " ",
  12: "12h",
  13: " ",
  14: " ",
  15: " ",
  16: " ",
  17: " ",
  18: " ",
  19: " ",
  20: " ",
  21: " ",
  22: " ",
  23: " ",
  24: "24h",
};

export const weekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
export const dayHours = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

export const trackers = [
  {
    id: 1,
    imgPath: "images/icon-binom.png",
    selected: false,
    trackerName: "Binom",
  },
  {
    id: 2,
    imgPath: "images/icon-voluum.png",
    selected: true,
    trackerName: "Voluum",
  },
  {
    id: 3,
    imgPath: "images/icon-affise.png",
    selected: false,
    trackerName: "Affise",
  },
];

export const handleSaveCampaign = (
  type,
  campaign,
  targeting,
  osAll,
  browserAll,
  connectionAll,
  typeDeviceAll,
  manufacturerAll,
  isEdit,
  creatives,
  rules
) =>
  new Promise((resolve) => {
    let targetingFiltered = {};
    for (const [key, value] of Object.entries(targeting)) {
      if (!!value) {
        switch (key) {
          case "os":
            if (value.items.length !== osAll.length) {
              const versionFix = value.items.map((item) => {
                if (!item.versions || item.versions.length === 0) {
                  return { id: item.id };
                }
                return item;
              });
              targetingFiltered = { ...targetingFiltered, [key]: { items: versionFix } };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            }
            break;
          case "browsers":
            if (value.items.length !== browserAll.length) {
              const versionFix = value.items.map((item) => {
                if (!item.versions || item.versions.length === 0) {
                  return { id: item.id };
                }
                return item;
              });
              targetingFiltered = { ...targetingFiltered, [key]: { items: versionFix } };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            }
            break;
          case "hour":
            if (value.items.length === 1 && value.items[0] === "24") {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case "day":
            if (value.items.length === 7) {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case "user_age":
            targetingFiltered = { ...targetingFiltered, [key]: value };
            break;
          case "age":
            if (value.items.length > 0) {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case "device_types":
            if (value.items.length === typeDeviceAll.length) {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case "device_manufacturers":
            if (value.items.length === manufacturerAll.length) {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case "categories":
            const items = [].concat.apply([], Object.values(value.items));
            // if (item.length === 0) {
            //   item = ['IAB24'];
            // }
            targetingFiltered = {
              ...targetingFiltered,
              [key]: { ...value, items },
            };
            break;
          case "subids":
            if (value.items || value.lists) {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case "time":
            targetingFiltered = {
              ...targetingFiltered,
              [key]: { ...value, items: parseTableToTime(value.items) },
            };
            break;
          default:
            targetingFiltered = { ...targetingFiltered, [key]: value };
        }
      }
    }

    if (targeting.freshness_type === "hour") {
      targetingFiltered.age = null;
    } else {
      targetingFiltered.user_age = null;
    }

    const parsedCreatives = parseCreatives(creatives, isEdit, type);
    const parsedRules = parseRulesForRequest(rules);

    const campaignObj = {
      ...campaign,
      targeting: targetingFiltered,
      creatives: parsedCreatives,
      rules: parsedRules,
    };

    if (campaign.limit && campaign.limit["limit_value"]) {
      if (isEdit) {
        campaignObj.limits = {
          ...campaign.limit,
          limit_value: Number(campaign.limit["limit_value"]),
          action: "update",
        };
      } else {
        campaignObj.limits = {
          ...campaign.limit,
          limit_value: Number(campaign.limit["limit_value"]),
        };
      }
    }

    delete campaignObj.limit;
    resolve(campaignObj);
  });

// We want to add create action if creatives are genereated from the generator component (case: EDIT)
const parseCreatives = (originalCreatives, isEdit, type) => {
  const creatives = originalCreatives.map((item) => {
    return { ...item };
  });
  return creatives
    .map((item) => {
      if (item.actions === null) {
        delete item.actions;
      }
      if ((type === "Push" || type === "Premium") && item.description) {
        if (item.description.en) {
          item.body = { en: item.description.en };
        }
        delete item.description;
      } else if (type === "Native" && item.description && !item.description.en) {
        delete item.description;
      }
      if (isEdit && item.gen_id && !item.action) {
        const temporary = item.status ? {} : { status: "active" };
        return { ...item, action: "create", ...temporary };
      }
      return item;
    })
    .filter((item) => !isEdit || (isEdit && item.action));
};

export const hasVersions = (items) => {
  const versions = items.reduce(
    (sum, item) => (item.versions ? [...sum, ...item.versions] : sum),
    []
  );
  return !!versions.length;
};

// format =>  "* hour,hour,hour * * day,day"
export const parseTableToTime = (value) => {
  const timeItem = value
    .map((item, id) => {
      return !item.every((value) => !value)
        ? `* ${item
            .map((selected, id) => (selected ? id : selected))
            .filter((selected) => selected !== false)} * * ${id === 6 ? 0 : id + 1}`
        : false;
    })
    .filter((value) => value);
  return timeItem;
};

export const campaignOptions = {
  metrics: (rule) => [
    {
      value: "roi",
      label: "ROI",
      disabled: rule.selected_metrics.includes("roi"),
    },
    {
      value: "e_cpa",
      label: "eCPA",
      disabled: rule.selected_metrics.includes("e_cpa"),
    },
    {
      value: "clicks",
      label: "Clicks",
      disabled: rule.selected_metrics.includes("clicks"),
    },
    {
      value: "spent",
      label: "Spent",
      disabled: rule.selected_metrics.includes("spent"),
    },
    {
      value: "conversions",
      label: "Conversions",
      disabled: rule.selected_metrics.includes("conversions"),
    },
    {
      value: "impressions",
      label: "Impressions",
      disabled: rule.selected_metrics.includes("impressions"),
    },
  ],
  timeRanges: [
    {
      value: "1h",
      label: "Last hour",
    },
    {
      value: "24h",
      label: "Last 24 hours",
    },
    {
      value: "7d",
      label: "Last 7 days",
    },
    {
      value: "30d",
      label: "Last 30 days",
    },
  ],
  actions: [
    {
      value: "pause",
      label: "Pause",
    },
    {
      value: "increase_bid",
      label: "Increase bid by n%",
    },
    {
      value: "decrease_bid",
      label: "Decrease bid by n%",
    },
  ],
};

const parseRulesForRequest = (originalRules) => {
  const outputArray = [];

  originalRules.forEach((rule) => {
    const outputItem = {
      clicks_num: null,
      clicks_condition: null,
      roi_num: null,
      roi_condition: null,
      e_cpa_num: null,
      e_cpa_condition: null,
      spend_num: null,
      spend_condition: null,
      conversions_num: null,
      conversions_condition: null,
      impressions_num: null,
      impressions_condition: null,
      action: rule.action,
      action_value: rule.action !== "pause" ? rule.action_value : null,
      action_limit: rule.action !== "pause" ? rule.action_limit : null,
      time_range: rule.time_range,
    };

    rule.sub_rules.forEach((subRule) => {
      const metricName = subRule.metric;
      const condition = subRule.condition;
      const num = subRule.num;

      if (metricName !== "") {
        outputItem[`${metricName}_num`] = num;
        outputItem[`${metricName}_condition`] = condition;
      }
    });

    outputArray.push(outputItem);
  });

  return outputArray;
};

export const parseRulesForForm = (rules) => {
  const parsedRules = [];

  rules.forEach((outputItem) => {
    const subRules = [];
    const selectedMetrics = [];

    for (const key in outputItem) {
      if (key.endsWith("_num")) {
        const metricName = key.replace("_num", "");
        const condition = outputItem[`${metricName}_condition`];
        const num = outputItem[key];

        if (num !== null && condition !== null) {
          subRules.push({
            metric: metricName,
            condition: condition,
            num: num,
          });
          selectedMetrics.push(metricName);
        }
      }
    }

    const inputItem = {
      sub_rules: subRules,
      selected_metrics: selectedMetrics,
      time_range: outputItem.time_range || "24h",
      action: outputItem.action,
      action_value: parseFloat(outputItem.action_value) || null,
      action_limit: parseFloat(outputItem.action_limit) || null,
    };

    campaignOptions.metrics(inputItem);
    parsedRules.push(inputItem);
  });

  return parsedRules;
};

const isSubruleCompleted = (subrules) => {
  let completed = true;
  subrules.forEach((subrule) => {
    if (!subrule.metric || !subrule.num || !subrule.condition) {
      completed = false;
    }
  });
  return completed;
};

export const isRuleValid = (rules, setRules, floor_bid, campaign_bid) => {
  let valid = true;
  const validatedRules = rules.map((rule) => {
    if (rule.action !== "pause" && !rule.action_limit) {
      valid = false;
      return { ...rule, error: "Action limit is required!" };
    } else if (!isSubruleCompleted(rule.sub_rules)) {
      valid = false;
      return { ...rule, error: "Rule conditions are not completed!" };
    } else if (floor_bid && rule.action_limit && rule.action_limit < floor_bid) {
      valid = false;
      return { ...rule, error: "Action limit can't be less then floor bid!" };
    } else if (rule.action === "decrease_bid" && rule.action_limit > campaign_bid) {
      valid = false;
      return { ...rule, error: "Action limit can't be more then campaign bid!" };
    } else if (rule.action === "increase_bid" && rule.action_limit < campaign_bid) {
      valid = false;
      return { ...rule, error: "Action limit can't be less then campaign bid!" };
    }
    // else if (rule.action === "decrease_bid") {
    //   //revidirati
    //   const newBid = campaign_bid - (rule.action_value / 100) * campaign_bid;
    //   if ((floor_bid && newBid < floor_bid) || newBid < rule.action_limit) {
    //     valid = false;
    //     return { ...rule, error: "Action limit can't be more then the bid after rule execution!" };
    //   }
    // } else if (rule.action === "increase_bid") {
    //   const newBid = campaign_bid - (rule.action_value / 100) * campaign_bid;
    //   if ((floor_bid && newBid < floor_bid) || newBid > rule.action_limit) {
    //     valid = false;
    //     return { ...rule, error: "Action limit can't be less then the bid after rule execution!" };
    //   }
    // }

    return rule;
  });
  setRules(validatedRules);
  return valid;
};
