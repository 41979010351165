import React, { useEffect, useState } from "react";
import styles from "./CreativeBuilder.module.css";
import { NewImageModal } from "./components/NewImageModal";

import { GeneratorForm } from "./components/GeneratorForm";
import { SingleCreativeForm } from "./components/GeneratorSingleForm";
import { Divider } from "antd";

export const CreativeBuilder = ({
  type,
  setGeneratorVisible,
  generateCreatives,
  handleUpdateCreatives,
  currentCreative,
  editCreative,
  genCreatives,
  headlines,
  setHeadlines,
  images,
  setImages,
  icon,
  setIcon,
  landingUrl,
  setLandingUrl,
  description,
  setDescription,
  c2aText,
  setC2aText,
  c2aUrl,
  setC2aUrl,
  c2aIcon,
  setC2aIcon,
  formBulk,
  generatorVisible,
  handleClearAll,
}) => {
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [singleImage, setSingleImage] = useState([]);
  const [singleIcon, setSingleIcon] = useState([]);
  const [singleC2aIcon, setSingleC2aIcon] = useState([]);
  const [
    headlineDescriptionAIGeneratorVisible,
    setHeadlineDescriptionAIGeneratorVisible,
  ] = useState(false);

  const [isReturnHeadlinesAndDescription, setIsReturnHeadlinesAndDescription] =
    useState({
      multiheadlines: true,
      headlines: true,
      description: true,
    });

  // creatives update (regenerate all creatives)
  useEffect(() => {
    if (headlines.length && images.length) {
      generateCreatives(
        headlines,
        images,
        landingUrl,
        description,
        icon,
        c2aText,
        c2aUrl,
        c2aIcon
      );
    } // eslint-disable-next-line
  }, [headlines, images]);

  // optimized update (won't call generateCreatives function)
  useEffect(() => {
    handleUpdateCreatives({
      landingUrl,
      description,
      icon: icon[0],
      c2aText,
      c2aUrl,
      c2aIcon: c2aIcon[0],
    }); // eslint-disable-next-line
  }, [landingUrl, description, icon, c2aText, c2aUrl, c2aIcon]);

  return (
    <div className={styles.creativeBuilder}>
      <div className={styles.headerTitle}>
        <p>Creative Details</p>
      </div>
      <Divider />
      {currentCreative === false ? (
        <GeneratorForm
          type={type}
          images={images}
          setImages={setImages}
          icon={icon}
          setIcon={setIcon}
          headlines={headlines}
          setHeadlines={setHeadlines}
          landingUrl={landingUrl}
          setLandingUrl={setLandingUrl}
          setImageModalVisible={setImageModalVisible}
          description={description}
          setDescription={setDescription}
          c2aText={c2aText}
          setC2aText={setC2aText}
          c2aUrl={c2aUrl}
          setC2aUrl={setC2aUrl}
          c2aIcon={c2aIcon}
          setC2aIcon={setC2aIcon}
          formBulk={formBulk}
          headlineDescriptionAIGeneratorVisible={
            headlineDescriptionAIGeneratorVisible
          }
          setHeadlineDescriptionAIGeneratorVisible={
            setHeadlineDescriptionAIGeneratorVisible
          }
          isReturnHeadlinesAndDescription={isReturnHeadlinesAndDescription}
          setIsReturnHeadlinesAndDescription={
            setIsReturnHeadlinesAndDescription
          }
        />
      ) : currentCreative ? (
        <SingleCreativeForm
          type={type}
          setGeneratorVisible={setGeneratorVisible}
          genCreatives={genCreatives}
          currentCreative={currentCreative}
          editCreative={editCreative}
          singleImage={singleImage}
          setSingleImage={setSingleImage}
          singleIcon={singleIcon}
          setSingleIcon={setSingleIcon}
          singleC2aIcon={singleC2aIcon}
          setSingleC2aIcon={setSingleC2aIcon}
          headlines={headlines}
          setHeadlines={setHeadlines}
          setLandingUrl={setLandingUrl}
          setImageModalVisible={setImageModalVisible}
          generatorVisible={generatorVisible}
          handleClearAll={handleClearAll}
          headlineDescriptionAIGeneratorVisible={
            headlineDescriptionAIGeneratorVisible
          }
          setHeadlineDescriptionAIGeneratorVisible={
            setHeadlineDescriptionAIGeneratorVisible
          }
          isReturnHeadlinesAndDescription={isReturnHeadlinesAndDescription}
          setIsReturnHeadlinesAndDescription={
            setIsReturnHeadlinesAndDescription
          }
        />
      ) : null}
      <NewImageModal
        type={type}
        imageModalVisible={imageModalVisible}
        setImageModalVisible={setImageModalVisible}
        setImages={setImages}
        setIcon={setIcon}
        setC2aIcon={setC2aIcon}
        setSingleC2aIcon={setSingleC2aIcon}
        setSingleImage={setSingleImage}
        setSingleIcon={setSingleIcon}
        images={images}
        singleImage={singleImage}
        singleIcon={singleIcon}
      />
    </div>
  );
};
