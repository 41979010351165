import { callApi } from './api';
import moment from 'moment';

const handleOrdering = (sorting) => {
  const ordering = [];
  for (const key in sorting) {
    const val = sorting[key];
    if (val) {
      if (val === "ascend") {
        ordering.push("-" + key);
      } else {
        ordering.push(key);
      }
    }
  }
  return ordering;
};

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return '';
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== '';
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return query ? (withoutQuestionMark ? query : `?${query}`) : '';
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize
});

class FinanceAccountStatements {
  async getStatementsPaged({ selectedFilters, currentPage, columns, pageSize, cancelToken, sorting }) {
    const { filters, dateRange } = selectedFilters;
    let colParameter = 'platform,id';

    Object.keys(columns).forEach(function (key) {
      if (columns[key]) {
        colParameter += ',' + key;
      }
    });
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: moment(dateRange.from).format('YYYY-MM-DD'),
      date_to: moment(dateRange.to).format('YYYY-MM-DD'),
      statement_type: filters.type && filters.type,
      platform: filters.platform && filters.platform,
      ordering: ordering && ordering.join(",")
    });
    const response = await callApi({
      url: `/api/finance/account_statement/${query}`,
      cancelToken
    });
    return { results: response.data.items, summary: response.data.items_totals, count: response.data.total };
  }
}

export const financeAccountStatementsService = new FinanceAccountStatements();
