import React from 'react';
import styles from '../Lists.module.css';
import { PlusOutlined } from '@ant-design/icons';

import { Button, Table, Pagination } from 'antd';

export const UserLists = ({
    columns,
    lists,
    isFetching,
    actionPending,
    totalRows,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    setIsEdit,
    setIsGlobal,
    setFormVisible,
    listType
}) => {

    const handleAddNewList = () => {
        setIsEdit(false);
        setIsGlobal(false);
        setFormVisible(true);
    }

    return (
        <>
            <div className={styles.userListsContainer}>
                <div>
                    <h3>Lists</h3>
                    <p>You can find all of your  {listType === 'subid' ? 'SubID' : 'IP'} lists below.</p>
                    <Button
                        onClick={handleAddNewList}
                        type="primary"
                        className={styles.newListButton}
                        icon={<PlusOutlined className={styles.plusIcon} />}
                    >
                        Add New {listType === 'subid' ? 'SubID' : 'IP'} List
                    </Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={lists}
                    loading={isFetching || actionPending}
                    pagination={false}
                    rowKey={({ id }) => id}
                    footer={() => {
                        return (
                            <Pagination
                                className="ant-table-pagination ant-table-pagination-right"
                                total={totalRows}
                                current={currentPage}
                                pageSize={pageSize}
                                showSizeChanger
                                pageSizeOptions={[5, 10, 15, 20, 30]}
                                onChange={(currentPage, pageSize) => {
                                    setCurrentPage(currentPage);
                                    setPageSize(pageSize);
                                }}
                                onShowSizeChange={(currentPage, pageSize) => {
                                    setCurrentPage(currentPage);
                                    setPageSize(pageSize);
                                }}
                            />
                        );
                    }}
                    scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
                />
            </div>
        </>
    );
};
