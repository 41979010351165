import React, { useContext, useEffect } from "react";
import { Modal, Form, Col, Button, Select, Tooltip, Input } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { calculateId, isValidDomain } from "../../utils";
import { openWarningNotification } from "../../../../../../utils/notifications";
import { CampaignContext } from "../../../../../../contexts/campaignContext";

export const SmartlinkModal = ({ smartlinkVisible, setSmartlinkVisible }) => {
  const { dispatchCreatives } = useContext(CampaignContext);
  const [form] = Form.useForm();

  const handleSaveCreative = values => {
    if (smartlinkVisible && smartlinkVisible !== true) {
      const editObject = {
        ...smartlinkVisible,
        ...values,
      };
      if (
        smartlinkVisible.gen_type === "clone" ||
        smartlinkVisible.gen_type === "clone_new"
      ) {
        delete editObject.gen_type;
        dispatchCreatives({ type: "create", payload: editObject });
      } else if (smartlinkVisible.id) {
        dispatchCreatives({ type: "update", payload: editObject });
      } else {
        dispatchCreatives({ type: "update_new", payload: editObject });
      }
    } else {
      const parsedValues = values.landing_url
        .filter(item => isValidDomain(item))
        .map(item => {
          return {
            landing_url: item,
            gen_id: calculateId(),
          };
        });
      if (parsedValues.length > 0) {
        dispatchCreatives({ type: "bulk_create", payload: parsedValues });
      }
      if (parsedValues.length < values.landing_url.length) {
        openWarningNotification({
          message: "Invalid Landing URLs have been removed!",
        });
      }
    }
    form.resetFields();
    setSmartlinkVisible(false);
  };

  const onPasteHandle = pastedValue => {
    const pasted = pastedValue.split("\n").map(item => item.trim());
    const existing = form.getFieldValue("landing_url")
      ? form.getFieldValue("landing_url")
      : [];
    const uniqueSet = new Set([...pasted, ...existing]);
    form.setFieldsValue({
      landing_url: [...uniqueSet],
    });
  };

  useEffect(() => {
    if (smartlinkVisible && smartlinkVisible !== true) {
      form.setFieldsValue({ landing_url: smartlinkVisible.landing_url });
    }
  }, [smartlinkVisible, form]);

  return (
    <Modal
      title="Campaign Type"
      visible={smartlinkVisible}
      footer={false}
      onCancel={() => setSmartlinkVisible(false)}
    >
      <Form form={form} onFinish={handleSaveCreative} layout="vertical">
        <Form.Item
          label={
            <div style={{ width: "100%" }}>
              <p style={{ display: "inline" }}>Landing URL(s) - </p>{" "}
              <span style={{ color: "#A6A6A6" }}>
                {" "}
                full click url including https://{" "}
              </span>
              <Tooltip title="You can add or copy multiple URLs, make sure each URL is in a separate row!">
                <InfoCircleTwoTone />
              </Tooltip>
            </div>
          }
          name="landing_url"
          // rules={[
          //   {
          //     validator: (_, value, callback) => {
          //       if (isValidDomain(value)) {
          //         callback();
          //       } else {
          //         callback('Landing URL is not valid!');
          //       }
          //     }
          //   }
          // ]}
        >
          {smartlinkVisible === true ? (
            <Select
              open={false}
              mode="tags"
              getPopupContainer={trigger => trigger.parentNode}
              onPaste={e => {
                e.preventDefault();
                onPasteHandle(e.clipboardData.getData("Text"));
              }}
            />
          ) : (
            <Input />
          )}
        </Form.Item>
        <Col style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Col>
      </Form>
    </Modal>
  );
};
