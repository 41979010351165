import { callApi } from './api';

class AiCreativeHeaderDescriptionGeneratorService {
  async getHeaderAndDescription(data) {
    return await callApi({
      url: "/api/creatives/generate/",
      method: "POST",
      data,
    });
  }
}

export const aiCreativeHeaderDescriptionGeneratorService = new AiCreativeHeaderDescriptionGeneratorService();
