export const subIdColumnPreset = {
  source: true,
  impressions: true,
  clicks: true,
  leads: true,
  spam_clicks: true,
  spent: true,
  cr: true,
  ctr: true
  // cpm: true,
  // ecpa: true
};
export const countryColumnPreset = {
  country: true,
  impressions: true,
  clicks: true,
  leads: true,
  spam_clicks: true,
  spent: true,
  cr: true,
  ctr: true
  // cpm: true,
  // ecpa: true
};
export const osColumnPreset = {
  os: true,
  impressions: true,
  clicks: true,
  leads: true,
  spam_clicks: true,
  spent: true,
  cr: true,
  ctr: true
  // cpm: true,
  // ecpa: true
};
export const osVersionColumnPreset = {
  os_version: true,
  impressions: true,
  clicks: true,
  leads: true,
  spam_clicks: true,
  spent: true,
  cr: true,
  ctr: true
  // cpm: true,
  // ecpa: true
};
export const browserColumnPreset = {
  browser: true,
  impressions: true,
  clicks: true,
  leads: true,
  spam_clicks: true,
  spent: true,
  cr: true,
  ctr: true
  // cpm: true,
  // ecpa: true
};
export const browserVersionColumnPreset = {
  browser_version: true,
  impressions: true,
  clicks: true,
  leads: true,
  spam_clicks: true,
  spent: true,
  cr: true,
  ctr: true
  // cpm: true,
  // ecpa: true
};
export const iabCategoriesColumnPreset = {
  iab_name: true,
  iab_id: true,
  impressions: true,
  clicks: true,
  leads: true,
  spam_clicks: true,
  spent: true,
  cr: true,
  ctr: true
  // cpm: true,
  // ecpa: true
};


export const creativeColumnPresetSmartlink = {
  status: false,
  creative_id: true,
  title: false,
  description: false,
  image: false,
  icon: false,
  landing_url: true,
  impressions: true,
  clicks: true,
  leads: true,
  spam_clicks: false,
  spent: true,
  cr: false,
  ctr: false,
};
export const creativeColumnPresetContextual = {
  status: false,
  creative_id: true,
  title: true,
  description: false,
  image: false,
  icon: false,
  landing_url: true,
  impressions: true,
  clicks: true,
  leads: true,
  spam_clicks: false,
  spent: true,
  cr: false,
  ctr: false,
};
export const creativeColumnPreset = {
  status: true,
  creative_id: true,
  title: true,
  description: true,
  image: true,
  icon: true,
  landing_url: true,
  impressions: true,
  clicks: true,
  leads: true,
  spam_clicks: false,
  spent: true,
  cr: false,
  ctr: true,
};