import React from "react";
import { Table as AntTable, Pagination } from "antd";
import styles from "../RuleAdministration.module.css";

export const Table = ({
  campaigns,
  sumOfData,
  totalRows,
  currentPage,
  pageSize,
  loadingCampaigns,
  onPaginationChange,
  selectedColumns,
}) => {
  const dolarSignRefactor = (str) => {
    if (str.startsWith("-")) {
      return "- $ " + parseFloat(str.slice(1)).toFixed(2);
    } else {
      return "$ " + parseFloat(str).toFixed(2);
    }
  };

  const sumOfDataRefactor = () => {
    if (sumOfData) {
      let sumOfDataRefactored = { ...sumOfData };
      if (sumOfData.debit) {
        sumOfDataRefactored.debit = dolarSignRefactor(sumOfData.debit);
      }
      if (sumOfData.credit) {
        sumOfDataRefactored.credit = dolarSignRefactor(sumOfData.credit);
      }
      return sumOfDataRefactored;
    }
  };

  const refactorTableData = (tableData) => {
    const tableDataRefactored = tableData
      ? tableData.map((el, index) => {
          const refactor = { ...el };
          refactor.debit = refactor.debit && dolarSignRefactor(refactor.debit);
          refactor.credit = refactor.credit && dolarSignRefactor(refactor.credit);
          refactor.balance = refactor.balance && dolarSignRefactor(refactor.balance);
          return {
            key: index,
            ...refactor,
          };
        })
      : "";
    return tableDataRefactored;
  };

  return (
    <AntTable
      className={styles.antTableContent}
      columns={selectedColumns}
      dataSource={refactorTableData(campaigns)}
      loading={loadingCampaigns}
      pagination={false}
      rowKey={({ id }) => id}
      footer={() => {
        return (
          <Pagination
            className="ant-table-pagination ant-table-pagination-right"
            total={totalRows}
            current={currentPage}
            pageSize={pageSize}
            showSizeChanger
            pageSizeOptions={["10", "20", "30", "50", "100"]}
            onChange={onPaginationChange}
            onShowSizeChange={onPaginationChange}
            showTotal={(total) => {
              if (total)
                return (
                  <div>
                    Showing {(currentPage - 1) * pageSize + 1} to{" "}
                    {total < currentPage * pageSize ? total : currentPage * pageSize} of {total}{" "}
                    entries.
                  </div>
                );
            }}
          />
        );
      }}
      summary={() => {
        const sumOfDataRefactored = sumOfDataRefactor();
        return sumOfData ? (
          <tr className="ant-table-row ant-table-row-level-0">
            {selectedColumns.map((el, index) => (
              <td className="ant-table-cell" key={index} index={index}>
                {sumOfDataRefactored[el.dataIndex]}
              </td>
            ))}
          </tr>
        ) : null;
      }}
      scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
    />
  );
};
