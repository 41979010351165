import React, { useState } from "react";
import {
  Button,
  Select,
  Modal,
  Spin,
  Upload,
  Form,
  Input,
  Tooltip,
} from "antd";
import { UploadOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import styles from "../CreativeBuilder.module.css";
import {
  openErrorNotification,
  openWarningNotification,
} from "../../../../../../../../../utils/notifications";
import { mediaCampaignsService } from "../../../../../../../../../services/campaigns";

const { Dragger } = Upload;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const NewImageModal = ({
  imageModalVisible,
  setImageModalVisible,
  setImages,
  setIcon,
  setSingleIcon,
  setSingleImage,
  setC2aIcon,
  setSingleC2aIcon,
  images,
  singleImage,
}) => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [form] = Form.useForm();

  const updateImageList = (value, type) => {
    if (type === "bulk") {
      setImages((state) => {
        if (state.length > 4) {
          openWarningNotification({
            message: "You can't add more than 5 creatives!",
          });
          return state;
        }
        return [...state, value];
      });
    } else if (type === "single") {
      setSingleImage([...singleImage, value]);
    } else if (type === "bulk_icon") {
      setIcon([value]);
    } else if (type === "single_icon") {
      setSingleIcon([value]);
    } else if (type === "bulk_action_icon") {
      setC2aIcon([value]);
    } else if (type === "single_action_icon") {
      setSingleC2aIcon([value]);
    }
  };

  const handleImageDrop = async (e) => {
    const type =
      imageModalVisible === "bulk_icon" || imageModalVisible === "single_icon" || imageModalVisible === "bulk_action_icon" || imageModalVisible === "single_action_icon"
        ? "icon"
        : "image";
    setUploadLoading(true);
    try {
      const base64 = await getBase64(e);
      const base64_url = base64.split(";")[1].slice(7);
      const res = await mediaCampaignsService.uploadCreativeImg({
        language: "en",
        base64_url,
        type,
      });
      if (res.success) {
        updateImageList(res.data.url, imageModalVisible);
      } else {
        openErrorNotification({ message: res.message });
      }
      setImageModalVisible(false);
      return false;
    } catch (e) {
      openErrorNotification({
        message: "Error occurred while trying to add new image!",
      });
    } finally {
      setUploadLoading(false);
    }
    return false;
  };

  const handleUploadLink = async (values) => {
    setUploadLoading(true);
    try {
      const type =
        imageModalVisible === "bulk_icon" || imageModalVisible === "single_icon" || imageModalVisible === "bulk_action_icon" || imageModalVisible === "single_action_icon"
          ? "icon"
          : "image";

      if (!Array.isArray(values.url)) {
        values.url = [values.url];
      }

      const promiseList = values.url.map((url) =>
        mediaCampaignsService.uploadCreativeImg({
          language: "en",
          format: "url",
          url,
          type,
        })
      );

      const res = await Promise.all(promiseList);
      res.forEach((item) => {
        if (!item.success) {
          openErrorNotification({ message: item.message });
        }
      });
      const uploadedUrls = res
        .filter((item) => item.success)
        .map((item) => item.data.url);

      if (imageModalVisible === "bulk") {
        if (images.length + promiseList.length > 5) {
          openWarningNotification({
            message: "You can't add more than 5 images!",
          });
          return;
        }
        setImages([...images, ...uploadedUrls]);
      } else if (imageModalVisible === "single") {
        setSingleImage(uploadedUrls);
      } else if (imageModalVisible === "bulk_icon") {
        setIcon(uploadedUrls);
      } else if (imageModalVisible === "single_icon") {
        setSingleIcon(uploadedUrls);
      } else if (imageModalVisible === "bulk_action_icon") {
        setC2aIcon(uploadedUrls);
      } else if (imageModalVisible === "single_action_icon") {
        setSingleC2aIcon(uploadedUrls);
      }
    } catch (e) {
      console.log(e);
      openErrorNotification({
        message: "An error occurred in the upload. Fix your input!",
      });
    } finally {
      setImageModalVisible(false);
      form.resetFields();
      setUploadLoading(false);
    }
  };

  const onPasteHandle = (pastedValue) => {
    const pasted = pastedValue.split("\n").map((item) => item.trim());
    const existing = form.getFieldValue("landing_url")
      ? form.getFieldValue("landing_url")
      : [];
    const uniqueSet = new Set([...pasted, ...existing]);
    form.setFieldsValue({
      url: [...uniqueSet],
    });
  };

  return (
    <Modal
      title="Add New Image"
      visible={imageModalVisible}
      style={{ top: 120 }}
      width={700}
      onCancel={() => setImageModalVisible(false)}
      footer={null}
    >
      <Spin spinning={uploadLoading}>
        <Dragger
          name="file"
          multiple={imageModalVisible === "bulk"}
          beforeUpload={handleImageDrop}
          fileList={[]}
        >
          <p className="ant-upload-text">
            Drag image here or browse through your device
          </p>
          <div className={styles.buttonUpload}>
            <UploadOutlined style={{ fontSize: "1.4rem" }} />{" "}
            <span>Upload Image</span>
          </div>
        </Dragger>

        <div className={styles.pastUrlBox}>
          <p className={styles.labelUrl}>
            You may also enter and paste image URLs below:{" "}
            <Tooltip title="You can add or copy multiple URLs, make sure each URL is in a separate row!">
              <InfoCircleTwoTone />
            </Tooltip>
          </p>
          <Form form={form} onFinish={handleUploadLink} layout="inline">
            <Form.Item name="url" style={{ width: "80%", margin: 0 }}>
              {imageModalVisible === "bulk" ? (
                <Select
                  open={false}
                  mode="tags"
                  placeholder="Add image URLs here"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onPaste={(e) => {
                    e.preventDefault();
                    onPasteHandle(e.clipboardData.getData("Text"));
                  }}
                />
              ) : (
                <Input placeholder="Add image URL here" />
              )}
            </Form.Item>
            <Form.Item style={{ width: "20%", margin: 0 }}>
              <Button
                type="primary"
                className={styles.postbackBtn}
                htmlType="submit"
              >
                Save URL
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};
