import React, { useEffect, useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import "./OtherTrackerOrCpaNetwork.css";
import { Input, Button } from "antd";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../../utils/notifications";
import { trackingService } from "../../../services/tracking";

export const OtherTrackerOrCpaNetwork = ({ selectedTracker }) => {
  const [s2s_url, updateUrl] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    const getS2sUrl = async () => {
      try {
        const { data } = await trackingService.getUrl();
        updateUrl(data.auto);
      } catch (e) {
        console.log(e);
        openErrorNotification({ message: "Something went wrong" });
      }
    };
    getS2sUrl();
  }, []);

  useEffect(() => {
    setCurrentUrl(s2s_url[selectedTracker]);
  }, [s2s_url, selectedTracker]);

  // const data = [
  //   {
  //     key: '1',
  //     dateandtime: 'Dec 17 15:19:41 UTC0',
  //     country: 'US',
  //     os: 'Mac OS X',
  //     browser: 'Chrome',
  //     param1: 'Param1',
  //     param2: 'Param2',
  //     param3: 'Param3'
  //   },
  //   {
  //     key: '2',
  //     dateandtime: 'Dec 17 05:19:41 UTC0',
  //     country: 'GB',
  //     os: 'Windows',
  //     browser: 'Edge',
  //     param1: 'Param1',
  //     param2: 'Param2',
  //     param3: 'Param3'
  //   },
  //   {
  //     key: '3',
  //     dateandtime: 'Dec 17 15:19:41 UTC0',
  //     country: 'ME',
  //     os: 'Mac OS X',
  //     browser: 'Chrome',
  //     param1: 'Param1',
  //     param2: 'Param2',
  //     param3: 'Param3'
  //   }
  // ];

  // const columns = [
  //   {
  //     title: 'Date&Time',
  //     dataIndex: 'dateandtime',
  //     key: 'dateandtime'
  //   },
  //   {
  //     title: 'Country',
  //     dataIndex: 'country',
  //     key: 'country'
  //   },
  //   {
  //     title: 'OS',
  //     dataIndex: 'os',
  //     key: 'os'
  //   },
  //   {
  //     title: 'Browser',
  //     dataIndex: 'browser',
  //     key: 'browser'
  //   },
  //   {
  //     title: 'Param1',
  //     dataIndex: 'param1',
  //     key: 'param1'
  //   },
  //   {
  //     title: 'Param2',
  //     dataIndex: 'param2',
  //     key: 'param2'
  //   },
  //   {
  //     title: 'Param3',
  //     dataIndex: 'param3',
  //     key: 'param3'
  //   }
  // ];
  const suffix = (
    <CopyToClipboard
      text={currentUrl}
      onCopy={() =>
        openSuccessNotification({
          message: "Successfully copied to clipboard!",
        })
      }
    >
      <Button type="primary" size="large">
        COPY
      </Button>
    </CopyToClipboard>
  );
  return (
    <>
      <div>
        Copy this S2S Postback URL and paste to the relevant field in your
        tracker or CPA Network account
      </div>
      <br />

      <Input
        className="S2Surl"
        suffix={suffix}
        size="large"
        value={currentUrl}
        readOnly={selectedTracker !== "general"}
        onChange={e => setCurrentUrl(e.target.value)}
      />
      <br />
      <br />

      {selectedTracker === "general" && (
        <div className="formSmallWritings">
          Do not forget to change{" "}
          <span className="enhance">params in curly brackets</span> to proper
          parameter names.
        </div>
      )}
      <br />
      {/* <div>Copy this S2S Postback URL and paste to the relevant field in your tracker or CPA Network account</div>
      <br />

      <div className="testConversionTracking">
        <div className="formTitles">Test Conversion Tracking</div>
        <div>Enter the target URL used in the campaign you want to test</div>
        <Input placeholder="https://power.vuer.net/asdasfasgasf/" prefix={<SearchOutlined />} onSearch={(value) => console.log(value)} />
        <br />
        <div className="formSmallWritings">
          Do not forget to add ${'{'}SUB_ID{'}'}{' '}
        </div>
        <br />
        <Button type="primary">Test Conversion Tracking</Button>
        <br />
        <br />
        <ol type="1" className="formSmallWritings">
          <li>Push the "Test Conversion Tracking" button.</li>
          <li>Our system will generate a testing banner with your target URL. Click on banner.</li>
          <li>Go to your landing and complete a conversion.</li>
          <li>If the S2S postback settings are correct, our system will soon register a conversion.</li>
        </ol>
        <hr />
        <div className="formTitles noConversions">No Conversions</div>
        <div className="formSmallWritings">registered in the last 24 hours</div>
        <Button type="link">
          <ReloadOutlined />
          Update information
        </Button>
        <hr />
        <Table dataSource={data} columns={columns}></Table>
      </div> */}
    </>
  );
};
