import { callApi } from "./api";

class FinanceService {
  async submitVisaMasterAmount(data) {
    const TotalAmount = data.amount.toFixed(2);
    return await callApi({
      url: "/api/finance/cc/init/",
      method: "POST",
      data: { TotalAmount },
    });
  }

  async calculateSubtotal(data) {
    return await callApi({
      url: "/api/finance/conversion/",
      method: "POST",
      data,
      cancelToken: data.cancelToken,
    });
  }

  async initStripe(amount, OriginalAmount, promo_code) {
    const TotalAmount = amount.toFixed(2);
    return await callApi({
      url: "/api/finance/stripe/init/",
      method: "POST",
      data: { TotalAmount, OriginalAmount, promo_code },
    });
  }

  async generateProInvoice(amount, OriginalAmount, currency, promo_code) {
    const TotalAmount = amount.toFixed(2);
    return await callApi({
      url: "/api/finance/wire/init/",
      method: "POST",
      data: { TotalAmount, OriginalAmount, currency, promo_code },
    });
  }

  async initERC20(amount, OriginalAmount, currency, promo_code) {
    const TotalAmount = amount.toFixed(2);
    return await callApi({
      url: "/api/finance/usdt_erc/init/",
      method: "POST",
      data: { TotalAmount, OriginalAmount, currency, promo_code },
    });
  }

  async getPayoneer(amount, OriginalAmount, promo_code) {
    const TotalAmount = amount.toFixed(2);
    return await callApi({
      url: "/api/payoneer/init/",
      method: "POST",
      data: { TotalAmount, OriginalAmount, promo_code },
    });
  }

  async submitPayPalAmount(amount, OriginalAmount, promo_code) {
    const TotalAmount = amount.toFixed(2);
    return await callApi({
      url: "/api/finance/paypal/init/",
      method: "POST",
      data: { TotalAmount, OriginalAmount, promo_code },
    });
  }

  async submitWireTransferAmount(data) {
    const TotalAmount = data.amount.toFixed(2);
    window.location.href = `/api/finance/cash/init?amount=${TotalAmount}`;
  }

  async getPayeeInfo() {
    return await callApi({
      url: "/api/finance/cash/payee_info/",
      method: "GET",
    });
  }

  async getPayments() {
    return await callApi({
      url: "/api/finance/payments/",
      method: "GET",
    });
  }
  async getAccountStatement() {
    return await callApi({
      url: "/api/finance/account_statement/",
      method: "GET",
    });
  }
  async getAccountStatementFilters(
    rangeFrom = "",
    rangeTo = "",
    transactionType,
    search = ""
  ) {
    return await callApi({
      url: `/api/finance/account_statement/?date_range=${rangeFrom},${rangeTo}${transactionType ? `&transaction_type=${transactionType}` : ""
        }${search ? `&search=${search}` : ""}`,
      method: "GET",
    });
  }
  async getInvoiceHistory() {
    return await callApi({
      url: "/api/finance/invoice_history/",
      method: "GET",
    });
  }
  async getInvoiceHistoryFilters(
    rangeFrom = "",
    rangeTo = "",
    transactionType,
    search = ""
  ) {
    return await callApi({
      url: `/api/finance/invoice_history/?date_range=${rangeFrom},${rangeTo}${transactionType ? `&transaction_type=${transactionType}` : ""
        }${search ? `&search=${search}` : ""}`,
      method: "GET",
    });
  }
  async getPaymentDetails(id) {
    return await callApi({
      url: `/api/transactions/${id}/`,
      method: "GET",
    });
  }
}

export const financeService = new FinanceService();
