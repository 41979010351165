import React from "react";
import { Form, Input, Radio, Select, Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import styles from "./GeneralConfiguration.module.css";

import { verticalCampaigns } from "../utils";

export const GeneralConfiguration = ({ updateCampaignDraft }) => {

  let selectVertical = Object.keys(verticalCampaigns).map(key => (
    <Select.OptGroup
      key={key}
      value={key}
      getPopupContainer={trigger => trigger.parentNode}
    >
      {verticalCampaigns[key].map(value => (
        <Select.Option key={key + value} value={value}>
          {value}
        </Select.Option>
      ))}
    </Select.OptGroup>
  ));

  return (
    <div className={styles.generalWrapper}>
      <div>
        <span className={styles.formLabel}>Campaign Name</span>
        <div className={styles.formInputValidate}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter campaign name.",
              },
            ]}
          >
            <Input
              className={styles.campaignNameInput}
              showCount
              maxLength={150}
              placeholder="Enter campaign name."
              onChange={() => updateCampaignDraft()}
            />
          </Form.Item>
        </div>
        <div className={styles.formInputVertical}>
          <span className={styles.formLabel}>Prelender</span>
          <Form.Item name="has_prelander" className={styles.statusRadioButton}>
            <Radio.Group buttonStyle="solid" onChange={() => updateCampaignDraft()}>
              <Radio.Button className={styles.radioButtonLabels} value={true}>
                Yes
              </Radio.Button>
              <Radio.Button className={styles.radioButtonLabels} value={false}>
                No
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <div>
        <span className={styles.formLabel}>Campaign Vertical</span>
        <div className={styles.formInputValidate}>
          <Form.Item
            name="vertical"
            rules={[
              {
                required: true,
                message: "Please choose campaign vertical.",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Choose your campaign vertical."
              dropdownStyle={{ fontWeight: "bold" }}
              className={styles.campaignVerticalSelect}
              getPopupContainer={trigger => trigger.parentNode}
              onChange={() => updateCampaignDraft()}
            >
              {selectVertical}
            </Select>
          </Form.Item>
        </div>
        <div className={styles.formInputVertical}>
          <span className={styles.formLabel}>
            Status after approval
            <Tooltip title="This is the status of your campaign after it has been approved. New campaigns are selected for approval, as well as edited campaigns when you make changes to the creatives.">
              <InfoCircleTwoTone style={{ marginLeft: 5, cursor: 'pointer' }} />
            </Tooltip>
          </span>
          <Form.Item
            name="status_after_approval"
            className={styles.statusRadioButton}
          >
            <Radio.Group buttonStyle="solid" onChange={() => updateCampaignDraft()}
            >
              <Radio.Button className={styles.radioButtonLabels} value="active">
                Active
              </Radio.Button>
              <Radio.Button className={styles.radioButtonLabels} value="paused">
                Paused
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};
