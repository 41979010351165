import { Radio, Select, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CampaignContext } from "../../../contexts/campaignContext";
import { TargetingContext } from "../../../contexts/targetingContext";
import styles from "./FrequencyCapTargeting.module.css";

const { Option } = Select;

export const FrequencyCapTargeting = () => {
  const { frequencyImpressionAll } = useContext(TargetingContext);
  const { campaign, dispatchCampaign } = useContext(CampaignContext);

  const [customDisabled, setCustomDisabled] = useState(true);

  //   const frequency_cap = campaign?.general?.frequency_cap?.impression?.expiry_time;

  //   if(frequency_cap){
  //     payload = {...payload, campaign: {...campaign, general: campaign?.general}}
  //   }

  useEffect(() => {
    campaign?.general?.frequency_cap?.impression?.expiry_time &&
      setCustomDisabled(false);
  }, [campaign.general]);

  const handleFrequencyCapUpdate = frequencyCap => {
    dispatchCampaign({
      type: "frequency_cap_impression",
      payload: frequencyCap,
    });
    // dispatchTargeting({ type: "browser", payload });
  };

  return (
    <div>
      <div className={styles.radioButtonSelection}>
        <Radio.Group
          value={customDisabled}
          onChange={e => {
            if (e.target.value) {
              dispatchCampaign({
                type: "frequency_cap_impression",
                payload: null,
              });
            }
            setCustomDisabled(e.target.value);
          }}
        >
          <Space direction="vertical">
            <Radio value={true}>Don't apply frequency cap</Radio>
            <Radio value={false}>Set frequency cap</Radio>
          </Space>
        </Radio.Group>
      </div>
      {!customDisabled && (
        <div className={styles.specificItemSelect}>
          <span>Your ad will be displayed to the same visitor once per: </span>
          <Select
            value={campaign?.general?.frequency_cap?.impression?.expiry_time}
            onChange={handleFrequencyCapUpdate}
            style={{ width: "80px", marginLeft: "10px" }}
            placeholder="select"
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().includes(input.toLowerCase())
            // }
            getPopupContainer={trigger => trigger.parentNode}
          >
            {frequencyImpressionAll.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
};
