import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import { Table as AntTable, Pagination, Button } from "antd";
import { PlusOutlined, ExportOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { ActionsTableCol } from "./ActionsTableCol";
import { StatusTableCol } from "./StatusTableCol";
import { BudgetCol } from "./BudgetCol";
// import { CampaignNameCol } from "./CampaignNameCol";
import { Link } from "react-router-dom";
import { CampaignTypeCol } from "./CampaignTypeCol";
import { DateCreatedCol } from "./DateCreatedCol";

import styles from "./Show.module.css";
import { mediaCampaignsService } from "../../../services/campaigns";
import { CampaignFilters } from "./CampaignsFilters";
import { ColumnsGroup } from "./ColumnsGroup";
import UserContext from "../../../contexts/userContext";
import { openErrorNotification, openSuccessNotification } from "../../../utils/notifications";
import { CpcCol } from "./CpcCol";
import { numberWithSpaces } from "../utils";
import { useNavigate } from "react-router";
import { TableTitle } from "../components/TableTitle";

export const Table = ({
  dateRange,
  setDateRange,
  onSubmit,
  sorting,
  setSorting,
  campaigns,
  exportCampaings,
  sumOfData,
  totalRows,
  pageConfig,
  // pageConfig.currentPage,
  // pageSize,
  loadingCampaigns,
  setPageConfig,
  onActivate,
  onPause,
  onDelete,
  checkedColumns,
  setCheckedColumns,
  selectedFilters,
  onActivateAllCampaigns,
  setBudgetModalVisible,
  setCampaignId,
  setBudgetInfo,
  noModalFlag,
  setCampaignModalVisible,
}) => {
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const [disabledExists, setDisabledExists] = useState(false);
  const [enabling, setEnabling] = useState(false);

  const [actionPending, toggleActionPending] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [filters, setFilters] = useState({});

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting, setSorting]
  );

  const staticColumns = useMemo(() => {
    return [
      {
        title: "Status",
        dataIndex: "status",
        align: "center",
        className: styles.statusColumn,
        render: (__, record) => {
          return (
            <StatusTableCol
              status={record.status}
              id={record.id}
              statusAfterCap={record.status_after_cap}
              onActivate={onActivate}
              onPause={onPause}
            />
          );
        },
      },
    ];
  }, [onActivate, onPause]);

  const actionTableColumn = useMemo(() => {
    return {
      title: "Actions",
      key: "actions",
      fixed: "right",
      align: "center",
      render: ({ id, name, status }) => {
        return (
          <ActionsTableCol
            id={id}
            title={name}
            status={status}
            onActivate={onActivate}
            onPause={onPause}
            onDelete={onDelete}
            toggleActionPending={toggleActionPending}
          />
        );
      },
    };
  }, [onActivate, onDelete, onPause]);

  const allColumns = useMemo(() => {
    return [
      {
        title: "Campaign details",
        dataIndex: "name",
        align: "left",
        render: (__, record) => {
          return (
            <>
              <div className={styles.campaignTypeAndName}>
                <CampaignTypeCol campaignType={record.type} />
                <Link to={`/campaigns/${record.id}`} target="_blank">
                  <div className={styles.campaignName}>{record.name}</div>
                </Link>
              </div>
              <div className={styles.campaignId}>
                <span>{record.id}</span>
              </div>
            </>
          );
        },
      },
      {
        title: "Budget",
        dataIndex: "budget",
        render: (budget, record) => {
          return (
            <BudgetCol
              budget={budget}
              setBudgetInfo={setBudgetInfo}
              id={record.id}
              setCampaignId={setCampaignId}
              setBudgetModalVisible={setBudgetModalVisible}
            />
          );
        },
      },
      {
        title: "CPC",
        dataIndex: "base_price",
        align: "center",
        render: (cpc, record) => {
          return <CpcCol cpc={cpc} campaignId={record.id} />;
        },
      },
      {
        title: (
          <TableTitle
            text={"Impressions"}
            order={sorting["impressions"] ? sorting["impressions"] : false}
            value={"impressions"}
            onSortingClick={handleSortingClick}
          />
        ),
        dataIndex: "impressions",
        render: (impressions) => (impressions ? impressions : "-"),
      },
      {
        title: (
          <TableTitle
            text={"Clicks"}
            order={sorting["clicks"] ? sorting["clicks"] : false}
            value={"clicks"}
            onSortingClick={handleSortingClick}
          />
        ),
        dataIndex: "clicks",
        render: (clicks) => (clicks ? clicks : "-"),
      },
      {
        title: "CTR",
        dataIndex: "ctr",
        render: (ctr) => (ctr ? ctr : "-"),
      },
      {
        title: (
          <TableTitle
            text={"Spent"}
            order={sorting["spent"] ? sorting["spent"] : false}
            value={"spent"}
            onSortingClick={handleSortingClick}
          />
        ),
        dataIndex: "spent",
        render: (spent) => (spent ? "$ " + parseFloat(spent).toFixed(4) : "-"),
      },
      {
        title: (
          <TableTitle
            text={"Conversions"}
            order={sorting["leads"] ? sorting["leads"] : false}
            value={"leads"}
            onSortingClick={handleSortingClick}
          />
        ),
        dataIndex: "leads",
        render: (leads) => (leads ? leads : "-"),
      },
      {
        title: (
          <TableTitle
            text={"Date created"}
            order={sorting["created"] ? sorting["created"] : false}
            value={"created"}
            onSortingClick={handleSortingClick}
          />
        ),
        dataIndex: "created",
        render: (date_created) => {
          return (
            <DateCreatedCol
              date={date_created && moment(date_created).format("DD MMM YYYY hh:mm:ss")}
            />
          );
        },
      },
      // {
      //   title: 'CTR',
      //   dataIndex: 'ctr'
      // },
      // {
      //   title: 'Cost',
      //   dataIndex: 'cose'
      // }
    ];
  }, [handleSortingClick, sorting, setBudgetInfo, setBudgetModalVisible, setCampaignId]);

  const [selectedColumns, setSelectedColumns] = useState([
    ...staticColumns,
    ...allColumns,
    actionTableColumn,
  ]);

  useEffect(() => {
    const fetchCampaignsList = async () => {
      try {
        const { data } = await mediaCampaignsService.getAllCampaignsAutocomplete();
        if (data) {
          setCampaignsList(data);
        }
      } catch (e) {
        console.log(e);
      } finally {
      }
    };
    fetchCampaignsList();
  }, []);

  useEffect(() => {
    const status = selectedFilters?.filters?.status;
    if (status) {
      setFilters({ ...filters, status });
    }
    // eslint-disable-next-line
  }, [selectedFilters]);

  useEffect(() => {
    const disabledCampaigns = async () => {
      try {
        const res = await mediaCampaignsService.disabledCampaigns();
        setDisabledExists(res.success);
      } catch (e) {
        console.log(e);
      } finally {
      }
    };
    disabledCampaigns();
  }, []);

  const balanceInvalid = () => {
    const limit = parseFloat(userInfo.credit_limit);
    const balance = parseFloat(userInfo.total_balance);
    if (!(limit ? limit - 10 > -balance : balance > 10)) {
      return "Please top up your credit in order to start campaigns.";
    }
    return false;
  };

  const activateAllDisabled = async () => {
    setEnabling(true);
    try {
      const res = await mediaCampaignsService.activateAllDisabled();
      if (res.success) {
        setDisabledExists(false);
        openSuccessNotification({ message: res.message });
        onActivateAllCampaigns();
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setEnabling(false);
    }
  };

  const sumOfDataRefactor = () => {
    if (sumOfData) {
      let sumOfDataRefactored = { ...sumOfData };
      if (sumOfData.clicks) {
        sumOfDataRefactored.clicks = numberWithSpaces(sumOfData.clicks);
      }
      if (sumOfData.ctr) {
        sumOfDataRefactored.ctr = parseFloat(sumOfData.ctr).toFixed(2) + " %";
      }
      if (sumOfData.impressions) {
        sumOfDataRefactored.impressions = numberWithSpaces(sumOfData.impressions);
      }
      if (sumOfData.leads) {
        sumOfDataRefactored.leads = numberWithSpaces(sumOfData.leads);
      }
      if (sumOfData.spent) {
        sumOfDataRefactored.spent = "$ " + parseFloat(sumOfData.spent).toFixed(4);
      }
      return sumOfDataRefactored;
    }
  };

  useEffect(() => {
    const filteredColumns = allColumns.filter((item) => {
      return Object.keys(checkedColumns).some((key) => {
        return checkedColumns[key] && key === item.dataIndex;
      });
    });
    setSelectedColumns([...staticColumns, ...filteredColumns, actionTableColumn]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, allColumns, actionTableColumn, staticColumns]);

  return (
    <div className={styles.campaignsTable}>
      <div className={styles.titleContent}>
        <h3>Campaigns</h3>
        <Button
          type="primary"
          className={styles.newCampaignBtn}
          onClick={() =>
            noModalFlag &&
            !(
              localStorage.getItem("campaign_general") ||
              localStorage.getItem("campaign_targeting") ||
              localStorage.getItem("campaign_creatives") ||
              localStorage.getItem("campaign_rules")
            )
              ? navigate("/campaigns/new", { state: { type: noModalFlag } })
              : setCampaignModalVisible(true)
          }
          icon={<PlusOutlined className={styles.plusIconCamp} />}
        >
          Start New Campaign
        </Button>
      </div>
      <CampaignFilters
        setDateRange={setDateRange}
        onSubmit={onSubmit}
        dateRange={dateRange}
        campaignsList={campaignsList}
        filters={filters}
        setFilters={setFilters}
      />
      <div className={styles.extActionGroup}>
        <div>
          {!disabledExists || balanceInvalid() ? (
            ""
          ) : (
            <Button loading={enabling} type="primary" onClick={() => activateAllDisabled()}>
              Activate all your disabled campaigns
            </Button>
          )}
        </div>
        <div className={styles.extActionRightGroup}>
          <Button onClick={() => exportCampaings(filters, dateRange)}>
            <ExportOutlined /> Export to CSV
          </Button>
          {/* <Button className={styles.columnsBtn}>
            <MenuOutlined rotate={90} /> Columns
          </Button> */}
          <ColumnsGroup
            className={styles.columnsBtn}
            onChange={(value) => {
              setCheckedColumns(value);
            }}
            showUpdateButton={true}
            onUpdate={() => {
              onSubmit(filters, dateRange);
            }}
            checkedColumns={checkedColumns}
          />
          <Button
            onClick={() => {
              onSubmit(filters, dateRange);
            }}
          >
            Reload <ReloadOutlined />
          </Button>
        </div>
      </div>

      <AntTable
        className={styles.antTableContent}
        columns={selectedColumns}
        dataSource={campaigns}
        loading={loadingCampaigns || actionPending}
        pagination={false}
        rowKey={({ id }) => id}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalRows}
              current={pageConfig.currentPage}
              pageSize={pageConfig.pageSize}
              showSizeChanger
              pageSizeOptions={["10", "20", "30", "50", "100"]}
              onChange={(currentPage, pageSize) => {
                setPageConfig((prevConfig) => {
                  // if pageSize is changed, we need to reset current page to 1
                  if (prevConfig.pageSize !== pageSize) {
                    return { currentPage: 1, pageSize };
                  }
                  return { ...prevConfig, currentPage };
                });
              }}
              showTotal={(total) => {
                if (total)
                  return (
                    <div>
                      Showing {(pageConfig.currentPage - 1) * pageConfig.pageSize + 1} to{" "}
                      {total < pageConfig.currentPage * pageConfig.pageSize
                        ? total
                        : pageConfig.currentPage * pageConfig.pageSize}{" "}
                      of {total} entries.
                    </div>
                  );
              }}
            />
          );
        }}
        summary={() => {
          const sumOfDataRefactored = sumOfDataRefactor();
          return sumOfData ? (
            <tr className="ant-table-row ant-table-row-level-0">
              {selectedColumns.map((el, index) => (
                <td className="ant-table-cell" key={index} index={index}>
                  {sumOfDataRefactored[el.dataIndex]}
                </td>
              ))}
            </tr>
          ) : null;
        }}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
      />
    </div>
  );
};
