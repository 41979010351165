import React from 'react';
import { Table } from "antd";

import styles from '../Lists.module.css';

export const GlobalBlacklist = ({ columns, list, isFetching, actionPending, listType }) => {

  const emptyRow = [{
    "id": 1,
    "name": "Global Blacklist",
    "content": [],
    "date_created": "",
    "campaigns_count": 0,
    "campaigns": [],
    "is_global": true,
    "editable": true
  }];

  return (
    <div className={styles.globalBlacklistContainer}>
      <div>
        <h3>Global {listType === 'subid' ? 'SubID' : 'IP'} Blacklist</h3>
        <p>Global {listType === 'subid' ? 'SubID' : 'IP'} blacklist is applied to all campaigns and all edits are reflected on all campaigns, unless you specifically exclude it from campaign targeting.</p>
      </div>
      <Table
        columns={columns}
        dataSource={list ? [list] : emptyRow}
        loading={isFetching || actionPending}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
      />
    </div >
  );
};
