import React from "react";
import { Slider } from "antd";
import { validHourMarks } from "../utils";

export const ValidHourSlider = ({ min, max, value, onChange }) => {
  const hourMarks = Object.assign(
    {},
    validHourMarks.map((hour) => `${hour}h`)
  );

  const updateValues = (values) => {
    const from = validHourMarks[values[0]];
    const to = validHourMarks[values[1]];
    onChange([from, to]);
  };

  const parseValues = (values) => {
    const from = validHourMarks.indexOf(Number(values[0]));
    const to = validHourMarks.indexOf(Number(values[1]));
    return [from, to];
  };

  return (
    <div>
      <Slider
        min={min}
        max={max}
        value={parseValues(value)}
        onChange={updateValues}
        range
        step={null}
        marks={hourMarks}
        trackStyle={[{ backgroundColor: "#FAAD14" }]}
        // railStyle={{ backgroundColor: "#cc2211" }}
        handleStyle={{
          //   backgroundColor: "#00",
          border: "2px solid #1890ff",
        }}
      />
    </div>
  );
};
