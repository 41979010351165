import { CloseOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React, { useMemo } from "react";
import styles from "./VersionItem.module.css";

const { Option } = Select;

export const VersionItem = ({
  item,
  allItems,
  listIndex,
  updateVersions,
  updateVersionType,
  deleteItem,
}) => {
  const itemDetails = useMemo(
    () => allItems.find((itemObj) => itemObj.id.toString() === item.id.toString()),
    [item.id, allItems]
  );

  const availableVersions = useMemo(
    () => (itemDetails && itemDetails.versions ? Object.entries(itemDetails.versions) : []),
    [itemDetails]
  );

  return (
    <div className={styles.itemVersionContainer}>
      <span className={styles.closeIcon} onClick={() => deleteItem(item.id)}>
        <CloseOutlined />
      </span>
      <p>{itemDetails?.name}</p>
      {availableVersions.length ? (
        <div className={styles.itemVersionContent}>
          <div>
            <span>Version type:</span>
            <Select
              value={item.op ? item.op : "all"}
              onChange={(value) => updateVersionType(value, listIndex)}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Option value="all">All versions</Option>
              <Option value="in">Exact</Option>
              <Option value="gte">Minimal</Option>
            </Select>
          </div>
          <div>
            <span>Select specific version:</span>
            <Select
              disabled={!item.op}
              value={item.op === "in" ? item.versions || [] : item.versions && item.versions[0]}
              mode={item.op === "in" && "multiple"}
              onChange={(value) => updateVersions(value, listIndex, item.op)}
              optionFilterProp="children"
              getPopupContainer={(trigger) => trigger.parentNode}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              maxTagCount="responsive"
            >
              {availableVersions.map((item) => (
                <Option key={item[0]} value={item[0]}>
                  {item[1]}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      ) : (
        <span className={styles.noVersionsAvailable}>No versions available.</span>
      )}
      {/* <div>
        <span>Selected version(s):</span>
      </div> */}
    </div>
  );
};
