import { Input, InputNumber, Radio, Select, Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import styles from "../AddFunds.module.css";
import React from "react";

const { Option } = Select;

export const FundsForm = ({
  currentPayment,
  currentPrice,
  setCurrentPrice,
  customPrice,
  setCustomPrice,
  currency,
  setCurrency,
  promoCode,
  setPromoCode,
  calculatedSubtotal,
}) => {
  return (
    <div className={styles.fundsForm}>
      <p>Payment Information</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "15px",
        }}
      >
        {currentPayment && currentPayment === "wire" ? (
          <span className={styles.currencyLabel}>Select currency:</span>
        ) : null}
        {currentPayment && currentPayment === "wire" ? (
          <Select
            value={currency}
            onChange={(value) => setCurrency(value)}
            size="large"
            style={{ width: "130px", fontSize: "0.8rem" }}
          >
            <Option value="USD">USD ($)</Option>
            <Option value="EUR">EUR (€)</Option>
          </Select>
        ) : null}
      </div>

      <Radio.Group
        buttonStyle="solid"
        value={currentPrice}
        onChange={(e) => setCurrentPrice(e.target.value)}
        size="large"
      >
        {currentPayment !== "wire" && (
          <>
            {currentPayment !== "tether-erc20" &&
              <Radio.Button className={styles.fundPriceBtn} value="100">
                {currency === "USD" ? "$" : currency === "USDT" ? "USD₮ " : "€"}100.00
              </Radio.Button>
            }
            <Radio.Button className={styles.fundPriceBtn} value="500">
              {currency === "USD" ? "$" : currency === "USDT" ? "USD₮ " : "€"}500.00
              {currentPayment !== "tether-erc20" && <span className={styles.optionHighlight}>
                Common choice <img src="/images/star-icon.svg" alt="" />
              </span>}
            </Radio.Button>
            {currentPayment !== "tether-erc20" && <br />}
          </>
        )}

        <Radio.Button className={styles.fundPriceBtn} value="1000">
          {currency === "USD" ? "$" : currency === "USDT" ? "USD₮ " : "€"}1,000.00
          {(currentPayment === "wire" || currentPayment === "tether-erc20") && (
            <span className={styles.optionHighlight}>
              Common choice <img src="/images/star-icon.svg" alt="" />
            </span>
          )}
        </Radio.Button>
        {currentPayment === "tether-erc20" && <>
          <br />
          <Radio.Button className={styles.fundPriceBtn} value="2500">
            {currency === "USD" ? "$" : currency === "USDT" ? "USD₮ " : "€"}2,500.00
          </Radio.Button>
        </>}
        <Radio.Button className={styles.fundPriceBtn} value="5000">
          {currency === "USD" ? "$" : currency === "USDT" ? "USD₮ " : "€"}5,000.00
        </Radio.Button>
        <br />
      </Radio.Group>
      <div className={styles.inputElementContainer}>
        <InputNumber
          size="large"
          placeholder="Custom Value"
          value={customPrice}
          min={currentPayment === "wire" ? 1000 : currentPayment === "tether-erc20" ? 500 : 100}
          onChange={(value) => {
            if (value) {
              setCurrentPrice("custom");
            } else {
              setCurrentPrice("100");
            }
            setCustomPrice(value);
          }}
          className={styles.inputNumberFund}
        />
        <span className={styles.optionHighlightPrice}>
          Minimum deposit: {currency === "USD" ? "$" : currency === "USDT" ? "USD₮ " : "€"}{currentPayment === "wire" ? '1,000.00' : currentPayment === "tether-erc20" ? '500.00' : '100.00'}
        </span>
      </div>

      <div className={styles.inputElementContainer}>
        <span className={styles.currencyLabel}>
          Promo code:{" "}
          <Tooltip title="When you insert a promo code, you get a special bonus which will help you run your campaigns. Spend it wisely.">
            <InfoCircleTwoTone />
          </Tooltip>
        </span>
        <Input
          className={styles.inputNumberFund}
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          placeholder="Enter code"
        />
        {calculatedSubtotal &&
          !calculatedSubtotal.promo_code_valid &&
          calculatedSubtotal.promo_code_message !== "" ? (
          <span className={styles.optionHighlightPromoError}>
            {calculatedSubtotal.promo_code_message}
          </span>
        ) : null}

        {currentPayment && currentPayment === "wire" ? (
          <span className={styles.currencyLabel}>Select currency:</span>
        ) : null}
        {currentPayment && currentPayment === "wire" ? (
          <span className={styles.optionHighlightPrice}>
            The above values are shown in USD, but final payoff below will be
            calculated in your selected value.
          </span>
        ) : null}
      </div>
    </div>
  );
};
