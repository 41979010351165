import React from 'react';
import { Select } from 'antd';
const { Option } = Select;

export const generateCampagins = (arr) => {
    return arr.map((campaign) => (
        <Option key={campaign.id} value={campaign.id}>
            {campaign.name}
        </Option>
    ));
};

const formatCampaignTypes = (input) => {
const mapping = {
    push: { label: "Push", value: "Push" },
    contextual: { label: "Contextual Ad", value: "Contextual Ad" },
    smartlink: { label: "Smartlink", value: "Smartlink" },
    premium: { label: "Premium Push", value: "Premium" }
};
const result = [];
for (const key in input) {
    if (input[key] && mapping[key]) {
        result.push(mapping[key]);
    }
}
return result;
}

export const generateCampaignTypes = (permissions) => {
return formatCampaignTypes(permissions).map((campaign) => (
    <Option key={campaign.value} value={campaign.value}>
    {campaign.label}
    </Option>
));
};

export const numberWithSpaces = x => {
    if (!x) {
        return x;
    }
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (parts[1]) {
        let decimalOne = parts[1][0] ? parts[1][0] : 0;
        let decimalTwo = parts[1][1] ? parts[1][1] : 0;
        parts[1] = decimalOne + decimalTwo;
    }
    return parts.join(".");
};
