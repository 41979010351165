import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, Divider, Dropdown, Menu, Tooltip } from 'antd';
import { LoadingOutlined, CloseOutlined, MenuOutlined } from '@ant-design/icons';

import { auth } from '../../services/auth';
import { DownOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import UserContext from '../../contexts/userContext';
// import { NewsTicker } from './NewsTicker';
// import { newsService } from '../services/news';
import { BarNotificationIcon } from './BarNotificationIcon';
import { AgreementBox } from './AgreementBox';

export const Header = ({ collapsed, setCollapsed, isSmallScreen, setAgreementModalVisible }) => {
  // const [news, setNews] = useState([]);
  // useEffect(() => {
  //   const getNews = async () => {
  //     try {
  //       const data = []; //await newsService.getActive();
  //       setNews(data);
  //     } catch {}
  //   };
  //   getNews();
  //   const intervalId = setInterval(getNews, 15000);

  //   return () => clearInterval(intervalId);
  // }, []);

  // const [isLoading, setLoading] = useState(false);
  const { userInfo } = useContext(UserContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const formatRevenue = (revenue) => {
    if (revenue === 0) {
      return '$ 0.00';
    }
    return '$ ' + Number(revenue).toLocaleString(undefined, { maximumFractionDigits: 2 });
  };

  const iconStyles = {
    background: '#1179BF',
    display: 'flex',
    position: 'absolute',
    zIndex: 100,
    top: 12,
    transition: '.2s',
    left: collapsed ? 0 : 200,
    cursor: 'pointer',
    padding: '3px 5px',
    borderRadius: '0 3px 3px 0'
  };

  const isAgreementRequested = userInfo.agreement && userInfo.agreement.status === "in_progress";
  const isAgreementSigned = userInfo.agreement && (userInfo.agreement.status === "signed" || userInfo.agreement.status === "skip");

  return (
    <Layout.Header style={{ background: '#fff', padding: 0, height: '64px' }}>
      {isSmallScreen ? (
        <span style={{ ...iconStyles }} onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <MenuOutlined style={{ fontSize: 32, color: '#fff' }} /> : <CloseOutlined style={{ fontSize: 32, color: '#fff' }} />}
        </span>
      ) : (
        <span></span>
      )}
      <Row style={{ flexWrap: 'nowrap', maxHeight: '100%' }} className="header-main">
        {isAgreementSigned ? "" :
          <Col className="header-right">
            <AgreementBox
              setAgreementModalVisible={setAgreementModalVisible}
              isAgreementRequested={isAgreementRequested}
              docuSign={userInfo.docusign}
            />
            <Divider type="vertical" style={{ fontSize: '35px' }} />
          </Col>
        }
        {userInfo && userInfo.credit_limit ? (
          <>
            <Col className="header-wallet">
              <Tooltip title="This is your credit limit.">
                <img className="wallet-limit" src="/images/limitCredit.png" alt="wallet" />
                <span className="credit-label">{formatRevenue(userInfo.credit_limit)}</span>
              </Tooltip>
            </Col>
            <Divider type="vertical" style={{ fontSize: '35px' }} />
          </>
        ) : null}
        <Col className="header-wallet">
          <Tooltip title="This is your current balance. It changes according to your campaigns spent and your own payments. Click here to Add funds.">
            <a target="_blank" rel="noopener noreferrer" href="/finance/add-funds">
              <img className="wallet" src="/images/coinis-wallet.svg" alt="wallet" />
              <span className="coins-value">
                {userInfo && userInfo.total_balance !== null ? formatRevenue(userInfo.total_balance) : <LoadingOutlined spin />}
              </span>
            </a>
          </Tooltip>
        </Col>
        <Col className="header-right">
          <Divider type="vertical" style={{ fontSize: '35px' }} />
          <BarNotificationIcon
            dropdownVisible={dropdownVisible}
            setDropdownVisible={setDropdownVisible}
          />
        </Col>
        <Col className="header-right">
          <Divider type="vertical" style={{ fontSize: '35px' }} />
          <Dropdown
            overlayStyle={{ width: '200px' }}
            overlayClassName="header-dropdown"
            overlay={
              <Menu>
                <Menu.Item key="2">
                  <Link to={'/my-profile'}>Edit Profile</Link>
                </Menu.Item>

                <Menu.Item key="3" onClick={() => auth.logout()}>
                  Logout
                </Menu.Item>
              </Menu>
            }
          >
            <span className="header-right-user">
              <div className="header-username">
                {userInfo.first_name ? (
                  <>
                    <span>
                      <span className="header-highligh">
                        {userInfo.first_name} {userInfo.last_name}
                      </span>{' '}
                    </span>
                    <span>{userInfo.email}</span>
                  </>
                ) : (
                  <LoadingOutlined spin />
                )}
              </div>
              <DownOutlined className="down-arrow" />{' '}
              {userInfo.first_name ? (
                <img className="profile-avatar" src={userInfo.image ? userInfo.image : '/images/no-profile.jpg'} alt="avatar" />
              ) : null}
            </span>
          </Dropdown>
          {/* <Dropdown
            overlayClassName="headerDropdown"
            overlay={
              <Menu>
                <Menu.Item key="2">
                  <Link to={'/my-profile'}>Edit Profile</Link>
                </Menu.Item>
                <Menu.Item key="3" onClick={() => auth.logout()}>
                  Logout
                </Menu.Item>
              </Menu>
            }
          >
            <span className="headerRightUser">
              <div className="headerUsername">
                {
                  userInfo.first_name ? (
                    <>
                      <span>
                        <span className="headerHighligh">
                          {userInfo.first_name} {userInfo.last_name}
                        </span>{" "}
                      </span>
                      <span>{userInfo.email}</span>
                    </>
                  ) : (
                    <LoadingOutlined spin />
                  )}
              </div>
              <DownOutlined className="downArrow" />{" "}
              {userInfo ? (
                <img
                  className="profileAvatar"
                  src={userInfo.image ? userInfo.image : "/images/user.png"}
                  alt="avatar"
                />
              ) : null}
            </span>
          </Dropdown> */}
        </Col>
        <div style={{ width: '20px' }}></div>
      </Row>
    </Layout.Header>
  );
};
