import { Row, Button } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./TimingTargeting.module.css";
import { CampaignContext } from "../../../contexts/campaignContext";
import { TimesTable } from "../components/TimesTable";
import { getTimeInitialValues } from "../../../utils/campaignContextUtils";

export const TimingTargeting = () => {
  const { targeting, dispatchTargeting } = useContext(CampaignContext);
  const [selectionType, setSelectionType] = useState("all");

  const firstRender = useRef(true);

  useEffect(() => {
    if (
      firstRender.current &&
      targeting.time.items
        .reduce((previousValue, currentValue) => [...previousValue, ...currentValue], [])
        .some((item) => item)
    ) {
      setSelectionType("custom");
      firstRender.current = false;
    }
  }, [targeting.time]);

  const handleChangeDay = (dayId, value) => {
    const payload = [...targeting.time.items];
    for (let i = 0; i < 24; i++) {
      payload[dayId][i] = value;
    }
    dispatchTargeting({
      type: "time",
      payload,
    });
    setSelectionType("custom");
  };

  const handleChangeHour = (hourId, value) => {
    const payload = [...targeting.time.items];
    for (let i = 0; i < 7; i++) {
      payload[i][hourId] = value;
    }
    dispatchTargeting({
      type: "time",
      payload,
    });
    setSelectionType("custom");
  };

  const handleChangeSpecificHour = (dayId, hourId, value) => {
    const payload = [...targeting.time.items];
    payload[dayId][hourId] = value;
    dispatchTargeting({
      type: "time",
      payload,
    });
    setSelectionType("custom");
  };

  const handleValueChange = (value) => {
    const initialValues = getTimeInitialValues();
    switch (value) {
      case "all":
        dispatchTargeting({
          type: "time",
          payload: initialValues,
        });
        setSelectionType(value);
        break;
      case "workdays":
        dispatchTargeting({
          type: "time",
          payload: initialValues.map((item, id) => {
            if (id < 5) {
              return item.map(() => true);
            }
            return item;
          }),
        });
        setSelectionType(value);
        break;
      case "weekends":
        dispatchTargeting({
          type: "time",
          payload: initialValues.map((item, id) => {
            if (id > 4) {
              return item.map(() => true);
            }
            return item;
          }),
        });
        setSelectionType(value);
        break;
      case "workinghours":
        dispatchTargeting({
          type: "time",
          payload: initialValues.map((item, id) => {
            if (id < 5) {
              return item.map((value, hourId) => !!(hourId > 7 && hourId < 17));
            }
            return item;
          }),
        });
        setSelectionType(value);
        break;
      default:
        setSelectionType(value);
        return null;
    }
  };

  return (
    <div>
      <Row style={{ alignItems: "center" }}>
        <div className={styles.headTabInfo}>
          <div>
            <p>Days & Time</p>
          </div>
          {/* <p>
            Target specific time of the day and day in the week. If you don't
            make a selection, campaign will be active 24/7.
          </p> */}
        </div>
      </Row>
      <div className={styles.actionTimeButtons}>
        <Button
          size="small"
          onClick={() => handleValueChange("all")}
          type={selectionType === "all" ? "primary" : "default"}
          value="all"
        >
          All days & time
        </Button>
        <Button
          size="small"
          onClick={() => handleValueChange("workdays")}
          type={selectionType === "workdays" ? "primary" : "default"}
          value="workdays"
        >
          Workdays
        </Button>
        <Button
          size="small"
          onClick={() => handleValueChange("weekends")}
          type={selectionType === "weekends" ? "primary" : "default"}
          value="weekends"
        >
          Weekends
        </Button>
        <Button
          size="small"
          onClick={() => handleValueChange("workinghours")}
          type={selectionType === "workinghours" ? "primary" : "default"}
          value="workinghours"
        >
          Working Hours
        </Button>
        <Button
          size="small"
          onClick={() => handleValueChange("custom")}
          type={selectionType === "custom" ? "primary" : "default"}
          value="custom"
        >
          Custom
        </Button>
      </div>
      <TimesTable
        handleChangeSpecificHour={handleChangeSpecificHour}
        handleChangeDay={handleChangeDay}
        handleChangeHour={handleChangeHour}
        targeting={targeting}
      />
      <p className={styles.timesInfoLabel}>All times are displayed in UTC+00:00 time zone.</p>
    </div>
  );
};
