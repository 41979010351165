import React from "react";
import { PlayCircleOutlined } from "@ant-design/icons";
import styles from "../ContextualGen.module.css";

export const ContextualPreview = ({ contextualAds }) => {
  const previewAds = contextualAds.slice(0, 4);
  return (
    <div className={styles.creativePreview}>
      <div className={styles.headerTitle}>
        <p>Preview</p>
      </div>
      <div className={styles.adPreviewScreen}>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
          praesentium, consequuntur quo quaerat aliquid, eum eaque atque
          possimus eveniet deleniti modi veritatis officia. Nisi quis, vero
          distinctio dicta voluptatum rerum.
        </p>
        <p className={styles.headtitle}>Related Topics (Ads)</p>
        <div className={styles.adPreviewContentBox}>
          {previewAds.map((item, i) => (
            <div className={styles.previewAd} key={i}>
              <span>{item.headline}</span>
              <PlayCircleOutlined
                style={{ color: "#448EF7", fontSize: "1.2rem" }}
              />
            </div>
          ))}
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
          praesentium, consequuntur quo quaerat aliquid, eum eaque atque
          possimus eveniet deleniti modi veritatis officia. Nisi quis, vero
          distinctio dicta voluptatum rerum.
        </p>
      </div>
    </div>
  );
};
