import { callApi, callApiStatus } from "./api";

class UserProfileService {
  async getUserDetails() {
    return await callApi({ url: "/api/accounts/" });
  }
  async getCompanyDetails(id) {
    return await callApi({ url: `/api/companies/${id}/` });
  }
  dataForPost(data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) =>
      value ? formData.append(key, value) : null
    );
    return formData;
  }
  async editUserDetails(data, id) {
    data.birth_date = data.birth_date
      ? data.birth_date.format("YYYY-MM-DD")
      : null;
    const userData = this.dataForPost(data);
    return await callApi({
      url: `api/accounts/${id}/`,
      method: "PATCH",
      data: userData,
    });
  }
  async editCompanyDetails(data, id) {
    return await callApi({
      url: `api/companies/${id}/`,
      method: "PATCH",
      data,
    });
  }
  async changePassword(data, id) {
    delete data.confirm_password;
    return await callApi({
      url: `api/accounts/${id}/change_password/`,
      method: "PUT",
      data,
    });
  }
  async getActiveMethods() {
    return await callApi({
      url: 'api/auth/mfa/user-active-methods/',
      method: "GET",
    });
  }
  async activateMethod(method) {
    return await callApi({
      url: `api/auth/${method}/activate/`,
      method: "POST",
    });
  }
  async generateQR(data) {
    return await callApi({
      url: `api/qr-code/`,
      method: "POST",
      data
    });
  }
  async confirmActivation(data, method) {
    return await callApi({
      url: `api/auth/${method}/activate/confirm/`,
      method: "POST",
      data
    });
  }
  async deactivateMethod(method) {
    return await callApiStatus({
      url: `api/auth/${method}/deactivate/`,
      method: "POST",
    });
  }
  async deactivate2FA() {
    return await callApiStatus({
      url: `api/remove-mfa-set/`,
      method: "DELETE",
    });
  }
  async changePrimaryMethod(data) {
    return await callApiStatus({
      url: `api/auth/mfa/change-primary-method/`,
      method: "POST",
      data
    });
  }
  async requestEmail(data) {
    return await callApi({
      url: `api/auth/code/request/`,
      method: "POST",
      data
    });
  }
  async regenerateBackupCodes(method) {
    return await callApi({
      url: `api/auth/${method}/codes/regenerate/`,
      method: "POST",
    });
  }
}

export const userProfileService = new UserProfileService();
