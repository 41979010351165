import { useEffect, useState } from "react";
import { CoinisLoader } from "../components/CoinisLoader";
import { auth } from "../services/auth";

export const ProtectedRoute = ({ children }) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      try {
        await auth.getUserProfile();
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    load();
  }, []);

  return isLoading ? <CoinisLoader /> : children;
};
