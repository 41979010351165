import React, { useContext, useState } from "react";
import { Modal, Form, Col, Button, Input, Spin } from "antd";
import { CampaignContext } from "../../../../../../contexts/campaignContext";
import { mediaCampaignsService } from "../../../../../../services/campaigns";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../../../../../utils/notifications";

export const URLModal = ({
  isClone,
  urlModalVisible,
  setUrlModalVisible,
  handleCreativeUrlUpdate,
}) => {
  const [form] = Form.useForm();
  const { campaign } = useContext(CampaignContext);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setUrlModalVisible(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    if (isClone || !campaign.general.id) {
      handleCreativeUrlUpdate(values);
      setLoading(false);
      form.resetFields();
      setUrlModalVisible(false);
      return;
    }
    try {
      const res = await mediaCampaignsService.changeAllCreativesUrl(
        campaign.general.id,
        values
      );
      if (res.success) {
        handleCreativeUrlUpdate(values);
        openSuccessNotification({ message: res.message });
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
      openErrorNotification({ message: "Could not update LandingURL!" });
    } finally {
      setLoading(false);
      form.resetFields();
      setUrlModalVisible(false);
    }
  };

  return (
    <Modal
      title="Change all creatives URL"
      centered="true"
      visible={urlModalVisible}
      footer={false}
      onCancel={() => !loading && setUrlModalVisible(false)}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Landing URL"
            name="landing_url"
            rules={[{ required: true }]}
          >
            <Input placeholder="Enter landing URL" />
          </Form.Item>
          <p>
            Confirming this change will change the landingURL for all creatives
            attached to this campaign.
          </p>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ marginRight: 16 }}
              type="primary"
              ghost
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Form>
      </Spin>
    </Modal>
  );
};
