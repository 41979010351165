import React, { useState, useEffect, useReducer, useMemo, useCallback } from 'react';
import moment from 'moment';

import { Table } from './components/Table';
import styles from './BillingPaymentHistory.module.css';
import { CampaignFilters } from './components/CampaignsFilters';
import { filtersReducer } from './utils/reducers';
import { ActionsTableCol } from './components/ActionsTableCol';
import { financeInvoiceHistoryService } from '../../../services/financeInvoiceHistory';
import { useFetch } from '../../../utils/hooks';
import { TableTitle } from '../components/TableTitle';

export const InvoiceHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectedFilters, dispatchSelectedFilters] = useReducer(filtersReducer, {});
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(30, 'days'),
    to: moment()
  });
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState({});

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (sorting[value] === order) {
          const newOrdering = { ...sorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...sorting, [value]: order };
      });
    },
    [sorting, setSorting]
  );

  const columns = [
    {
      title: (
        <TableTitle
          text={'Date'}
          order={sorting['date'] ? sorting['date'] : false}
          value={'date'}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: 'date'
    },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'status',
      render: (type) => <div className={styles.statusContainer}><div className={`${styles.status} ${styles['status' + type]}`}>{type.replace('_', ' ')}</div></div>
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id'
    },
    {
      title: 'Payment Method',
      dataIndex: 'payment_method'
    },
    {
      title: (
        <TableTitle
          text={'Amount'}
          order={sorting['amount'] ? sorting['amount'] : false}
          value={'amount'}
          onSortingClick={handleSortingClick}
        />
      ),
      dataIndex: 'amount'
    }
  ];

  const loadMediaCampaigns = (cancelToken) =>
    financeInvoiceHistoryService.getPaymentsPaged({
      selectedFilters,
      columns,
      currentPage,
      pageSize,
      cancelToken,
      sorting
    });

  const [
    {
      data: { results: campaigns, count: totalRows },
      isFetching
    },
    getMediaCampaigns
  ] = useFetch(loadMediaCampaigns);

  const actionTableColumn = useMemo(() => {
    return {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      align: 'center',
      render: ({ status, invoice, id }) => (
        <ActionsTableCol id={id} status={status} invoice={invoice} dispatchSelectedFilters={dispatchSelectedFilters} filters={filters} />
      )
    };
  }, [filters]);

  useEffect(() => {
    dispatchSelectedFilters({
      payload: {
        filters: {},
        dateRange: { from: moment().subtract(30, 'days'), to: moment() }
      }
    });
  }, []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getMediaCampaigns();
    }
  }, [currentPage, pageSize, getMediaCampaigns, selectedFilters, sorting]);

  // const exportCampaings = (filters, dateRange) => {
  //   mediaCampaignsService.exportMediaCampaigns({
  //     filters,
  //     dateRange,
  //     checkedColumns,
  //   });
  // };

  return (
    <div className={styles.campaignsTable}>
      <h4>Payment History</h4>
      <p>You can access your payment history in the table below.</p>
      {/* <div className={styles.billingHeadInfo}>
        <div>
          <span>Balance</span>
          <span>$ 00,00</span>
        </div>
        <div>
          <span>Minimum payout</span>
          <span>5 USD</span>
        </div>
      </div> */}
      <CampaignFilters
        submit={(filters) => {
          dispatchSelectedFilters({
            type: '',
            payload: { filters, dateRange }
          });
        }}
        filters={filters}
        setFilters={setFilters}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Table
        tableColumns={[...columns, actionTableColumn]}
        campaigns={campaigns}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getMediaCampaigns={getMediaCampaigns}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        selectedFilters={selectedFilters}
        date
      />
    </div>
  );
};
