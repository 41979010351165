import React, { useContext } from "react";
import { Select, Radio, InputNumber } from "antd";

import styles from "./CampaignLink.module.css";

import { TrackingInfo } from "../components/TrackingInfo";
import { TargetingContext } from "../../../contexts/targetingContext";
import { CampaignContext } from "../../../contexts/campaignContext";
import { trackers } from "../utils";
import { useState } from "react";
import { useEffect } from "react";

export const CampaignLink = () => {
  const { trackingUrls } = useContext(TargetingContext);
  const { campaign, dispatchCampaign } = useContext(CampaignContext);
  const [manualInputVisible, setManualInputVisible] = useState(false);

  //   useEffect(() => {
  //     setIsManual(false);
  //     if (campaign.general) {
  //       const { conversion_tracking_type } = campaign.general;
  //       const initialValues = {
  //         conversion_tracking_type: conversion_tracking_type
  //           ? conversion_tracking_type
  //           : "Default",
  //         conversion_tracking_value:
  //           campaign.general && campaign.general.conversion_tracking_value
  //             ? campaign.general.conversion_tracking_value
  //             : null,
  //       };
  //       setTrackerType(initialValues.conversion_tracking_type);
  //       setTrackerValue(initialValues.conversion_tracking_value);
  //       setIsManual(
  //         !!(campaign.general && campaign.general.conversion_tracking_value)
  //       );
  //       form.setFieldsValue(initialValues);
  //     }
  //   }, [campaign, form, setIsManual]);

  //   useEffect(() => {
  //     if (!!campaign.limit) {
  //       form.setFieldsValue({
  //         limit_value: campaign.limit.limit_value,
  //         period: campaign.limit.period ? campaign.limit.period : "day",
  //       });
  //     }
  //   }, [campaign.limit, form]);

  // useEffect(() => {
  //   if (!isManual) {
  //     setTrackerValue(null);
  //   }
  // }, [isManual]);

  useEffect(() => {
    campaign.general.conversion_tracking_value && setManualInputVisible(true);
  }, [campaign.general.conversion_tracking_value]);

  return (
    <div className={styles.generalWrapper}>
      <div className={styles.generalContentWrapper}>
        <p>Conversion tracking:</p>
        <div className={styles.campaignLinkContainer}>
          <div className={styles.campaignLinkSelect}>
            <Select
              placeholder="Choose conversion tracking"
              dropdownStyle={{ fontWeight: "bold" }}
              style={{ width: "100%" }}
              value={campaign.general.conversion_tracking_type}
              onChange={payload =>
                dispatchCampaign({
                  type: "conversion_tracking_type",
                  payload,
                })
              }
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Select.Option value="Default">Default</Select.Option>

              {trackers.map(el => (
                <Select.Option key={el.trackerName} value={el.trackerName}>
                  <img
                    src={"../" + el.imgPath}
                    alt="tracker"
                    width="20px"
                    height="20px"
                  />{" "}
                  {el.trackerName}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className={styles.conversionValueBox}>
            {/* <Form.Item
              className={styles.autoManualPricing}
              label="Value ($)"
              rules={[
                {
                  required: true,
                  message: "Please select conversion tracking",
                },
              ]}
            > */}
            <Radio.Group
              buttonStyle="solid"
              onChange={e => {
                setManualInputVisible(e.target.value);
                dispatchCampaign({
                  type: "conversion_tracking_value",
                  payload: e.target.value ? 0.01 : false,
                });
              }}
              value={manualInputVisible}
            >
              <Radio.Button className={styles.radioButtonLabels} value={false}>
                Auto
              </Radio.Button>
              <Radio.Button className={styles.radioButtonLabels} value={true}>
                Manual
              </Radio.Button>
            </Radio.Group>
            {/* </Form.Item> */}
            {manualInputVisible ? (
              // <Form.Item
              //   label=""
              //   style={{ display: "inline-block", marginLeft: 30 }}
              //   className={styles.manualValueInput}
              //   name="conversion_tracking_value"
              // >
              <InputNumber
                type="text"
                placeholder="Enter amount"
                style={{ height: 32, marginLeft: 20 }}
                value={campaign.general.conversion_tracking_value}
                onChange={payload => {
                  dispatchCampaign({
                    type: "conversion_tracking_value",
                    payload: payload ? payload : 0,
                  });
                }}
                step={0.01}
              />
            ) : (
              // </Form.Item>
              <div className={styles.manualAmountBox} />
            )}
          </div>
        </div>
        <TrackingInfo
          trackingUrls={trackingUrls}
          trackerType={campaign.general.conversion_tracking_type}
          trackerValue={campaign.general.conversion_tracking_value}
        />
      </div>
    </div>
  );
};
