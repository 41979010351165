import { Form } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { mediaCampaignsService } from "../services/campaigns";
import {
  getTimeInitialValues,
  parseOldTimeFormat,
  parseTimeToTable,
} from "../utils/campaignContextUtils";
import { openErrorNotification } from "../utils/notifications";
import { calculateId } from "../pages/campaign-form/components/NewCreatives/utils";
import { parseRulesForForm, validHourMarks } from "../pages/campaign-form/utils";
import { presetRulesService } from "../services/presetRules";

export const CampaignContext = React.createContext({});

const getInitialValues = () => ({
  countries: { items: [], lists: [], op: "in" },
  regions: { items: [], op: "in" },
  os: { items: [] },
  browsers: { items: [] },
  age: { items: ["1"], op: "in" },
  user_age: { time_unit: "hour", from: 0, to: 1 },
  freshness_type: "day",
  valid_hour: true,
  day: { items: [], op: "in" },
  hour: { items: [], op: "in" },
  ip: { items: [], lists: [], op: "nin" },
  connections: { items: [], op: "in" },
  device_types: { items: [], op: "in" },
  device_manufacturers: { items: [], op: "in" },
  categories: { items: [], op: "in" },
  keyword: { items: [] },
  subids: { items: [], lists: [], op: "nin" },
  time: { items: getTimeInitialValues(), op: "in" },
  traffic_type: "Both",
});

const initialCampaignValues = {
  general: {
    type: "",
    conversion_tracking_type: "Default",
    frequency_cap: { impression: null },
    has_prelander: false,
    status_after_approval: "active",
    period: "day",
    conversion_tracking_value: false,
  },
  limit: {},
  pricing: {},
};

const targetingReducer = (state, action) => {
  switch (action.type) {
    case "os":
    case "browsers":
    case "freshness_type":
    case "valid_hour":
      return { ...state, [action.type]: action.payload };
    case "categories":
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          items: {
            ...state[action.type].items,
            [action.category]: [...action.payload.items],
          },
        },
      };
    case "keyword":
      return {
        ...state,
        [action.type]: {
          items: action.payload.items,
        },
      };
    case "category_reset":
      return {
        ...state,
        keyword: { items: [] },
      };
    case "category_op":
      return { ...state, category: { ...state.category, ...action.payload } };
    case "time":
      return {
        ...state,
        [action.type]: { op: "in", items: action.payload },
      };
    case "countries":
    case "regions":
    case "day":
    case "hour":
    case "ip":
    case "age":
    case "connections":
    case "device_types":
    case "device_manufacturers":
    case "subids":
    case "user_age":
      return {
        ...state,
        [action.type]: { ...state[action.type], ...action.payload },
      };
    case "traffic_type":
      return {
        ...state,
        [action.type]: action.payload,
      };
    case "edit_targeting":
      return { ...state, ...action.payload };
    case "reset":
      return getInitialValues();
    default:
      return state;
  }
};

const campaignReducer = (state, action) => {
  switch (action.type) {
    case "general":
    case "creatives":
    case "limit":
    case "pricing":
      return { ...state, [action.type]: action.payload };
    case "frequency_cap_impression":
      const impression =
        action.payload === null
          ? null
          : {
              expiry_time: action.payload,
              max_count: "1",
            };
      return {
        ...state,
        general: {
          ...state.general,
          frequency_cap: {
            impression,
          },
        },
      };
    case "edit_campaign":
      return { ...state, ...action.payload };
    case "reset":
      return initialCampaignValues;
    case "type":
    case "conversion_tracking_type":
    case "conversion_tracking_value":
      return {
        ...state,
        general: { ...state.general, [action.type]: action.payload },
      };
    default:
      return state;
  }
};

const creativesReducer = (state, action) => {
  switch (action.type) {
    case "create":
      return [...state, { ...action.payload }];
    case "update":
      const typeOfEdit = action.payload.id ? { action: "update" } : {};
      const editCreativeId = state.findIndex((item) => item.id === action.payload.id);
      const parsedEdit = [...state];
      parsedEdit[editCreativeId] = { ...action.payload, ...typeOfEdit };
      return parsedEdit;
    case "bulk_update_url":
      return state.map((creative) => ({ ...creative, ...action.payload }));
    case "pause_play":
      const idCreative = state.findIndex((item) => item.id === action.payload.id);
      const editparsed = [...state];
      editparsed[idCreative] = {
        ...editparsed[idCreative],
        status: action.payload.status,
      };
      return editparsed;
    case "update_new":
      const editNewId = state.findIndex((item) => item.gen_id === action.payload.gen_id);
      const parsedNewEdit = [...state];
      parsedNewEdit[editNewId] = action.payload;
      return parsedNewEdit;
    case "delete":
      const deleteId = state.findIndex((item) => item.id === action.payload);
      const parsed = [...state];
      parsed[deleteId] = { ...parsed[deleteId], action: "delete" };
      return parsed;
    case "delete_new":
      return state.filter((item) => item.gen_id !== action.payload);
    case "clone":
      const cloneId = state.findIndex((item) => item.id === action.payload.id);
      const clone = parseCreativeToClone({
        ...state[cloneId],
        stamp: action.payload.stamp,
      });
      const parsedClone = [...state, clone];
      return parsedClone;
    case "clone_new":
      const cloneNewId = state.findIndex((item) => item.gen_id === action.payload.id);
      const cloneNew = parseCreativeToClone({
        ...state[cloneNewId],
        stamp: action.payload.stamp,
      });
      const parsedNewClone = [...state, cloneNew];
      return parsedNewClone;
    case "undo_delete":
      const undoId = state.findIndex((item) => item.id === action.payload);
      const undoParsed = [...state];
      delete undoParsed[undoId].action;
      return undoParsed;
    case "bulk_create":
      return [...state, ...action.payload];
    case "load":
      return action.payload;
    case "clone_load":
      return action.payload.map((item) => {
        delete item.id;
        delete item.statistics;
        delete item.status;
        item.gen_id = calculateId();
        return item;
      });
    case "load_push":
      return action.payload.map((item) => {
        if (item.body) {
          item.description = { en: item.body.en };
          delete item.body;
        }
        return item;
      });
    case "clone_load_push":
      return action.payload.map((item) => {
        if (item.body) {
          item.description = { en: item.body.en };
          delete item.body;
        }
        delete item.id;
        delete item.statistics;
        delete item.status;
        item.gen_id = calculateId();
        return item;
      });
    case "reset":
      return [];
    default:
      return state;
  }
};

const editSetTargeting = (targeting, dispatch) => {
  const payload = Object.keys(targeting.targeting)
    .filter(
      (key) => Object.keys(getInitialValues()).includes(key) && targeting.targeting[key] !== null
    )
    .reduce((obj, key) => {
      switch (key) {
        case "countries":
        case "id":
        case "ip":
        case "subids":
          // convert null values into array
          for (const innerKey of Object.keys(targeting.targeting[key])) {
            if (targeting.targeting[key][innerKey] === null) {
              targeting.targeting[key][innerKey] = [];
            }
          }
          obj[key] = targeting.targeting[key];
          break;
        case "time":
          obj[key] = parseTimeToTable(targeting.targeting[key]);
          break;
        default:
          obj[key] = targeting.targeting[key];
      }

      return obj;
    }, {});
  if (payload.day || payload.hour) {
    payload.time = parseOldTimeFormat(payload.day, payload.hour);
    delete payload.day;
    delete payload.hour;
  }
  dispatch({ type: "edit_targeting", payload });
};

const editSetGeneral = (campaign, dispatch) => {
  const payload = Object.keys(campaign)
    .filter((key) => Object.keys(initialCampaignValues).includes(key) && campaign[key] !== null)
    .reduce((obj, key) => {
      obj[key] = campaign[key];
      return obj;
    }, {});
  dispatch({ type: "edit_campaign", payload });
};

export const parseCreativeToClone = ({
  description,
  image,
  title,
  landing_url,
  icon,
  stamp,
  actions,
}) => {
  const descObj = description ? { description } : {};
  const clone = {
    ...descObj,
    image,
    title: title ? { en: title.en + "(clone)" } : null,
    landing_url,
    gen_id: stamp,
    icon,
    actions,
  };
  return clone;
};

export const CampaignContextProvider = ({ children, ...props }) => {
  const { id } = useParams();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const isClone = searchParams.get("type") === "clone";
  const [editLoading, setEditLoading] = useState(false);
  const [existingPricing, setExistingPricing] = useState(false);
  const [campaign, dispatchCampaign] = useReducer(campaignReducer, initialCampaignValues);
  const [targeting, dispatchTargeting] = useReducer(targetingReducer, getInitialValues());
  const [creatives, dispatchCreatives] = useReducer(creativesReducer, []);
  const [rules, setRules] = useState([]);

  const [presetRules, setPresetRules] = useState([]);

  // suggested & floor bid
  const [bidData, setBidData] = useState({});
  const [bidDataLoading, setBidDataLoading] = useState(false);

  const [formRef] = Form.useForm();

  const [isDiscardedNew, setIsDiscardedNew] = useState(false);

  const resetForm = useCallback(() => {
    dispatchTargeting({ type: "reset" });
    dispatchCreatives({ type: "reset" });
    dispatchCampaign({ type: "reset" });
    setRules([]);
    formRef.resetFields();
  }, [dispatchTargeting, dispatchCreatives, dispatchCampaign, formRef]);

  const fetchEditCampagin = useCallback(
    async (id) => {
      setEditLoading(true);
      try {
        const res = await mediaCampaignsService.getCampaignsInfo(id);
        if (!res.success) {
          openErrorNotification({
            message: "There is no campaign by that campaign id.",
          });
          navigate("/campaigns/new");
          return;
        } else {
          if (res.data.rules) {
            setRules(parseRulesForForm(res.data.rules));
          }
        }
        if (res.data.pricing) {
          setExistingPricing(res.data.pricing.price);
        }
        if (
          res.data.targeting &&
          res.data.targeting.targeting &&
          res.data.targeting.targeting.user_age
        ) {
          dispatchTargeting({ type: "freshness_type", payload: "hour" });
          if (
            !validHourMarks.includes(Number(res.data.targeting.targeting.user_age.from)) &&
            !validHourMarks.includes(Number(res.data.targeting.targeting.user_age.to))
          ) {
            dispatchTargeting({ type: "valid_hour", payload: false });
          }
        } else {
          dispatchTargeting({ type: "freshness_type", payload: "day" });
        }

        if (res.data?.targeting) {
          editSetTargeting(res.data.targeting, dispatchTargeting);
        }

        editSetGeneral(res.data, dispatchCampaign);
        const cloneObj = isClone ? { name: res.data?.general?.name + "-clone" } : {};
        formRef.setFieldsValue({
          ...res.data.general,
          ...cloneObj,
          price: res.data.pricing.price,
        });
        if (res.data.creatives) {
          if (res.data.general.type === "Push" || res.data.general.type === "Premium") {
            dispatchCreatives({
              type: isClone ? "clone_load_push" : "load_push",
              payload: res.data.creatives,
            });
          } else {
            dispatchCreatives({
              type: isClone ? "clone_load" : "load",
              payload: res.data.creatives,
            });
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setEditLoading(false);
      }
    },
    [formRef, navigate, isClone]
  );

  useEffect(() => {
    const storedGeneral = JSON.parse(localStorage.getItem("campaign_general"));
    const storedTargeting = JSON.parse(localStorage.getItem("campaign_targeting"));
    const storedCreatives = JSON.parse(localStorage.getItem("campaign_creatives"));
    const storedRules = JSON.parse(localStorage.getItem("campaign_rules"));

    if (Number(id)) {
      fetchEditCampagin(id);
    } else if (storedGeneral && storedTargeting && storedCreatives) {
      dispatchCampaign({ type: "edit_campaign", payload: storedGeneral });
      dispatchTargeting({ type: "edit_targeting", payload: storedTargeting });
      dispatchCreatives({ type: "load", payload: storedCreatives });
      setRules(storedRules);
    } else {
      dispatchCampaign({ type: "type", payload: state?.type || "" });
    }

    return () => {
      // > -1 is if found
      if (window.location.href.indexOf("new") > -1) {
        resetForm();
      }
    };
  }, [id, fetchEditCampagin, state, resetForm]);

  useEffect(() => {
    if (pathname === "/campaigns" || isClone || Number(id)) return;
    localStorage.setItem("campaign_general", JSON.stringify(campaign));
    localStorage.setItem("campaign_targeting", JSON.stringify(targeting));
    localStorage.setItem("campaign_creatives", JSON.stringify(creatives));
    localStorage.setItem("campaign_rules", JSON.stringify(rules));
  }, [creatives, rules, targeting, campaign, pathname, isClone, id]);

  useEffect(() => {
    const getPresetRules = async () => {
      try {
        const res = await presetRulesService.getPresetRules({ currentPage: 1, pageSize: 100 });
        if (res?.results) {
          setPresetRules(res.results);
        }
      } catch (err) {
        console.log(err);
      }
    };

    getPresetRules();
  }, []);

  const hasDraft = () => {
    return (
      localStorage.getItem("campaign_general") !==
        JSON.stringify({
          ...initialCampaignValues,
          general: {
            ...initialCampaignValues.general,
            has_prelander: false,
            status_after_approval: "active",
            period: "day",
            conversion_tracking_value: false,
          },
        }) ||
      localStorage.getItem("campaign_creatives") !== JSON.stringify([]) ||
      localStorage.getItem("campaign_rules") !== JSON.stringify([])
    );
  };

  return (
    <CampaignContext.Provider
      value={{
        campaign,
        existingPricing,
        dispatchCampaign,
        targeting,
        dispatchTargeting,
        rules,
        setRules,
        editLoading,
        creatives,
        editId: Number(id),
        dispatchCreatives,
        campaignType: campaign.general.type,
        formRef,
        isClone,
        resetForm,
        getInitialValues,
        initialCampaignValues,
        hasDraft,
        editSetGeneral,
        isDiscardedNew,
        setIsDiscardedNew,
        // suggested & floor bid
        bidData,
        setBidData,
        bidDataLoading,
        setBidDataLoading,
        presetRules,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
