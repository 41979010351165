import { Form, message } from "antd";
import React, { useEffect } from "react";
import { useMemo } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { CampaignContext } from "../../../contexts/campaignContext";
import { TargetingContext } from "../../../contexts/targetingContext";
import { newCampaignService } from "../../../services/newCampaign";
import { openErrorNotification, openSuccessNotification } from "../../../utils/notifications";
import { AdditionalTargeting } from "../sections/AdditionalTargeting";
import { BrowserTargeting } from "../sections/BrowserTargeting";
import { BudgetAndBids } from "../sections/BudgetAndBids";
import { CampaignRules } from "../sections/CampaignRules";
import { CampaignLink } from "../sections/CampaignLink";
import { CategoriesTargeting } from "../sections/CategoriesTargeting";
import { DeviceTargeting } from "../sections/DeviceTargeting";
import { FrequencyCapTargeting } from "../sections/FrequencyCapTargeting";
import { GeneralConfiguration } from "../sections/GeneralConfiguration";
import { GeoTargeting } from "../sections/GeoTargeting";
import { OsTargeting } from "../sections/OsTargeting";
import { SubidPlacements } from "../sections/SubidPlacements";
import { TimingTargeting } from "../sections/TimingTargeting";
import { UserFreshnessTargeting } from "../sections/UserFreshnessTargeting";
import { handleSaveCampaign, isRuleValid } from "../utils";
import styles from "./ContentForm.module.css";
import { CreativesTab } from "./NewCreatives";

export const ContentForm = ({ isClone, loading }) => {
  const naviagate = useNavigate();
  const {
    formRef,
    editId,
    campaign,
    campaignType,
    targeting,
    creatives,
    setRules,
    rules,
    dispatchCampaign,
    dispatchTargeting,
    dispatchCreatives,
    bidData,
  } = useContext(CampaignContext);
  const { setLoading, browserAll, osAll, connectionAll, manufacturerAll, typeDeviceAll } =
    useContext(TargetingContext);

  const isCreativeValid = useMemo(() => {
    return creatives.filter((item) => item.action !== "delete").length !== 0;
  }, [creatives]);

  const handleFormSubmit = (values) => {
    if (!isCreativeValid) {
      document.querySelector(`#campaign-creatives`).scrollIntoView({
        behavior: "smooth",
      });
      message.warning("Creatives are required, please add new Creative!");
      return;
    }

    if (!isRuleValid(rules, setRules, bidData.floor_bid, campaign?.pricing?.price)) {
      document.querySelector(`#campaign-rules`).scrollIntoView({
        behavior: "smooth",
      });
      message.error("Rules you entered are not valid, please check again!");
      return;
    }

    //budget&bids
    Number(editId) && !isClone
      ? dispatchCampaign({
          type: "limit",
          payload: {
            limit_value: values.limit_value,
            period: values.period,
            id: campaign.limit.id,
          },
        })
      : dispatchCampaign({
          type: "limit",
          payload: { limit_value: values.limit_value, period: values.period },
        });
    //budget&bids-end

    const cmpGeneral = {
      ...campaign.general,
      ...values,
      conversion_tracking_value: campaign.general.conversion_tracking_value || false,
    };
    const cmpLimit = {
      ...campaign.limit,
      limit_value: values.limit_value || campaign.limit.limit_value,
      period: values.period || campaign.limit.period,
      id: campaign.limit.id,
    };
    const cmpPricing = {
      ...campaign.pricing,
      price: parseFloat(values.price),
    };

    const campaignSaveObj = {
      general: cmpGeneral,
      limit: cmpLimit,
      pricing: cmpPricing,
    };

    addOrEditCamp({ campaignSaveObj });
  };

  useEffect(()=> {
    if (campaignType === "Premium"){
      dispatchTargeting({ type: "age", payload: { items: ["11"] } });
    }
  }, [campaignType, dispatchTargeting])

  const addOrEditCamp = async ({ campaignSaveObj }) => {
    setLoading(true);
    const parsedCmp = campaignSaveObj ? campaignSaveObj : campaign;
    try {
      const data = await handleSaveCampaign(
        campaignType,
        parsedCmp,
        targeting,
        osAll,
        browserAll,
        connectionAll,
        typeDeviceAll,
        manufacturerAll,
        !!Number(editId) && !isClone,
        creatives,
        rules
      );

      const res =
        !!Number(editId) && !isClone
          ? await newCampaignService.editCampaign(data, Number(editId))
          : await newCampaignService.createCampaign(data);
      // setPreventLeave(false);
      if (res.success) {
        openSuccessNotification({ message: res.message });
        dispatchCampaign({ type: "reset" });
        dispatchTargeting({ type: "reset" });
        dispatchCreatives({ type: "reset" });
        localStorage.removeItem("campaign_general");
        localStorage.removeItem("campaign_targeting");
        localStorage.removeItem("campaign_creatives");
        localStorage.removeItem("campaign_rules");
        naviagate("/campaigns");
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
      openErrorNotification({ message: "Something went wrong!" });
    } finally {
      setLoading(false);
    }
  };

  const updateCampaignDraft = () => {
    const values = formRef.getFieldsValue();
    dispatchCampaign({
      type: "general",
      payload: {
        ...campaign.general,
        ...values,
        conversion_tracking_value: campaign.general.conversion_tracking_value || false,
      },
    });
  };

  return (
    <>
      <div className={styles.contentFormContainer}>
        <Form
          layout="horizontal"
          form={formRef}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
          }}
          initialValues={{
            has_prelander: false,
            status_after_approval: "active",
            ...JSON.parse(localStorage.getItem("campaign_general"))?.general,
          }}
          onFinish={handleFormSubmit}
        >
          <div>
            <h5 id="campaign-general" className={styles.formSectionTitle}>
              1. General Configuration <span />
            </h5>
            <div className={styles.formSectionContainer}>
              <GeneralConfiguration updateCampaignDraft={updateCampaignDraft} />
            </div>
          </div>
          <div>
            <h5 id="campaign-targeting" className={styles.formSectionTitle}>
              2. Targeting <span />
            </h5>

            <div className={styles.formSectionContainer}>
              <h6 id="campaign-targeting-geos">
                <img
                  src="/images/ssp-geos.png"
                  alt="geo_targeting"
                  className={styles.sectionIcon}
                />
                Geo Targeting
              </h6>
              <GeoTargeting />
            </div>

            <div className={styles.formSectionContainer}>
              <h6 id="campaign-targeting-device-type">
                <img
                  src="/images/ssp-device-type.png"
                  alt="device_targeting"
                  className={styles.sectionIcon}
                />
                Device Type
              </h6>
              <DeviceTargeting />
            </div>

            <div className={styles.formSectionContainer}>
              <h6 id="campaign-targeting-os">
                <img src="/images/ssp-os.png" alt="os_targeting" className={styles.sectionIcon} />
                Operating Systems
              </h6>
              <OsTargeting />
            </div>

            <div className={styles.formSectionContainer}>
              <h6 id="campaign-targeting-browser">
                <img
                  src="/images/ssp-browsers.png"
                  alt="browser_targeting"
                  className={styles.sectionIcon}
                />
                Browsers
              </h6>
              <BrowserTargeting />
            </div>
            {campaignType === "Push" && (
              <div className={styles.formSectionContainer}>
                <h6 id="campaign-targeting-user-freshness">
                  <img
                    src="/images/ssp-user-freshness.png"
                    alt="freshness_targeting"
                    className={styles.sectionIcon}
                  />
                  User Freshness
                </h6>
                <UserFreshnessTargeting />
              </div>
            )}
            <div className={styles.formSectionContainer}>
              <h6 id="campaign-targeting-days-time">
                <img
                  src="/images/ssp-timing.png"
                  alt="timing_targeting"
                  className={styles.sectionIcon}
                />
                Timing
              </h6>
              <TimingTargeting />
            </div>
            {campaignType === "Push" && (
              <div className={styles.formSectionContainer}>
                <h6 id="campaign-targeting-frequency-cap">
                  <img
                    src="/images/ssp-frequency-cap.png"
                    alt="frequency_targeting"
                    className={styles.sectionIcon}
                  />
                  Frequency Cap
                </h6>
                <FrequencyCapTargeting />
              </div>
            )}
            {campaignType === "Contextual Ad" && (
              <div className={styles.formSectionContainer}>
                <h6 id="campaign-targeting-iab">
                  <img
                    src="/images/ssp-iab-categories.png"
                    alt="geo_targeting"
                    className={styles.sectionIcon}
                  />
                  IAB Categories
                </h6>
                <CategoriesTargeting />
              </div>
            )}

            <div className={styles.formSectionContainer}>
              <h6 id="campaign-targeting-subid">
                <img
                  src="/images/ssp-subids-placements.png"
                  alt="ssp-subids-placements"
                  className={styles.sectionIcon}
                />
                SubIDs & Placements
              </h6>
              <SubidPlacements />
            </div>
            <div className={styles.formSectionContainer}>
              <h6 id="campaign-targeting-additional">
                {" "}
                <img
                  src="/images/ssp-additional-targeting.png"
                  alt="ssp-additional-targeting"
                  className={styles.sectionIcon}
                />
                Additional Targeting
              </h6>
              <AdditionalTargeting />
            </div>
          </div>
          <div>
            <h5 id="campaign-budget-bids" className={styles.formSectionTitle}>
              3. Budget & Bids <span />
            </h5>
            <div className={styles.formSectionContainer}>
              <BudgetAndBids />
            </div>
          </div>
          <div>
            <h5 id="campaign-creatives" className={styles.formSectionTitle}>
              4. Creatives <span />
            </h5>
            <div className={styles.formSectionContainer}>
              <CreativesTab type={campaignType} isCampaignEdit={Number(editId) && !isClone} />
            </div>
          </div>
          <div>
            <h5 id="campaign-rules" className={styles.formSectionTitle}>
              5. Rules <span />
            </h5>
            <div className={styles.formSectionContainer}>
              <CampaignRules form={formRef} />
            </div>
          </div>
          <div className={styles.lastContainerSection}>
            <h5 id="campaign-link" className={styles.formSectionTitle}>
              6. Tracking <span />
            </h5>
            <div className={styles.formSectionContainer}>
              <CampaignLink form={formRef} />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
