import React, { useCallback, useEffect, useState, useMemo } from 'react';

// import { ContactInfo } from './components/ContactInfo';
import { EarningsMap } from './components/EarningsMap';
import { HeadInfo } from './components/HeadInfo';
import { Insights } from './components/Insights';
import { StatisticsLineChart } from './components/StatisticsLineChart';
import { TopChannelsTable } from './components/TopChannelsTable';
import styles from './Dashboard.module.css';
import moment from 'moment';
// import { UsersDevicePie } from './components/UsersDevicePie';
import { ActivityLog } from './components/ActivityLog';
// import { NewsBlog } from './components/NewsBlog';
import { dashboardService } from '../../services/dashboard';
import { reportsService } from '../../services/reports';
import { Spin, Button } from 'antd';
import { CustomLoader } from './components/CustomLoader';
import { openErrorNotification, openSuccessNotification, openWarningNotification } from '../../utils/notifications';

export const Dashboard = () => {
  const metrics = useMemo(() => {
    return ['spent', 'leads', 'impressions', 'clicks'];
  }, []);
  const [dateRange, setDateRange] = useState({ from: moment().startOf('month'), to: moment().endOf('month') });
  const [chartData, setChartData] = useState(null);
  // const [contactInfo, setContactInfo] = useState({});
  const [allCountries, setAllCountries] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [mapData, setMapData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingXML, setLoadingXML] = useState(false);

  const mapColors = useMemo(() => {
    return ['#A3A1FB', '#50D8BB', '#FFA167', '#FF7CC3', '#81C9FF', '#FF8474'];
  }, []);

  const parseTopCountries = useCallback((topCountries) => {
    if (topCountries) {
      const formatedCountries = topCountries.map((country, index) => {
        return { [country.country]: mapColors[index] };
      });

      const mapData = Object.assign({}, ...formatedCountries);
      setMapData(mapData);
      setTopCountries(topCountries);
    }
  }, [mapColors]);

  useEffect(() => {
    const fetchStatsChart = async () => {
      setLoading(true);
      try {
        const [chart, countriesData, data] = await Promise.all([
          dashboardService.getStatisticData(dateRange, metrics),
          reportsService.getCountries(),
          dashboardService.getEarningsMapData(dateRange)
        ]);
        setAllCountries(countriesData.data);
        parseTopCountries(data.table ? data.table.sort((a, b) => b.spent - a.spent).slice(0, 6) : []);
        // setContactInfo(contact.data);
        if (!chart) {
          !localStorage.getItem('ssp-user-load')
            ? localStorage.setItem('ssp-user-load', 'true')
            : openWarningNotification({ message: `There is no data for this filters`, duration: 8 });
          setChartData(null);
        } else setChartData(chart);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchStatsChart();
  }, [dateRange, parseTopCountries, metrics]);

  const requestXML = async () => {
    setLoadingXML(true);
    try {
      const res = await dashboardService.getXML();
      res.success ? openSuccessNotification({ message: res.message }) : openErrorNotification({ message: res.message });
    } catch (e) {
      console.log(e);
      openErrorNotification({ message: 'Error while trying to request XML.' });
    } finally {
      setLoadingXML(false);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleContainer}>
        <h4>Dashboard</h4>
        <Button type="primary" onClick={requestXML} loading={loadingXML}>
          Request XML/JSON Integration
        </Button>
      </div>
      <HeadInfo />
      <Spin spinning={loading} indicator={<CustomLoader />}>
        <StatisticsLineChart loading={false} chartData={chartData} dateRange={dateRange} setDateRange={setDateRange} />
        <div className={styles.dashBottomContent}>
          <div style={{ flex: 2 }}>
            <TopChannelsTable dateRange={dateRange} />
            <ActivityLog />
          </div>
          <div style={{ flex: 1 }}>
            <EarningsMap mapData={mapData} loading={false} topCountries={topCountries} allCountries={allCountries} />
            <Insights />
          </div>
          {/* <ContactInfo contactInfo={contactInfo} loading={loading} /> */}
          {/* <NewsBlog /> */}
        </div>
      </Spin>
    </div>
  );
};

// {/* <UsersDevicePie dateRange={dateRange} /> */}
