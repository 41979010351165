import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPaginationLists } from '../utils/query';

const handleOrdering = (sorting) => {
  const ordering = [];
  for (const key in sorting) {
    const val = sorting[key];
    if (val) {
      if (val === "ascend") {
        ordering.push("-" + key);
      } else {
        ordering.push(key);
      }
    }
  }
  return ordering;
};

class ListsService {
  async getListsPaged({ currentPage, pageSize, cancelToken, listType, sorting }) {
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPaginationLists({ currentPage, pageSize }),
      type: listType,
      ordering: ordering && ordering.join(",")
    });
    const response = await callApi({ url: `/api/list/${query}`, cancelToken });
    return { results: response.results, global_list: response.global_list, count: response.count };
  }

  async getAllLists() {
    return await callApi({
      url: '/api/list/',
      method: 'GET',
    });
  }

  async getSubIDLists() {
    return await callApi({
      url: '/api/list/?type=subid',
      method: 'GET',
    });
  }

  async getIPLists() {
    return await callApi({
      url: '/api/list/?type=ip',
      method: 'GET',
    });
  }

  async addList(data) {
    return await callApi({
      url: 'api/list/',
      method: 'POST',
      data
    });
  }

  async editList(id, data) {
    return await callApi({
      url: `api/list/${id}/`,
      method: 'PATCH',
      data
    });
  }

  async editGlobalList(data) {
    return await callApi({
      url: 'api/list/update_global_list/',
      method: 'POST',
      data
    });
  }

  async deleteList(id) {
    return await callApi({
      url: `api/list/${id}/`,
      method: 'DELETE'
    });
  }
}

export const listsService = new ListsService();
