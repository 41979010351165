import { Tag } from "antd";
import React from "react";
import styles from "./previewRender.module.css";

import { UnorderedListOutlined } from "@ant-design/icons";

export const renderCountries = (countries, countriesAll) => {
  const parsedCountries = countriesAll
    .filter((country) => countries?.items?.includes(country.value))
    .map((country) => (
      <span key={country.value} className={styles.targetingInfoValue}>
        {country.label}
      </span>
    ));
  return <div style={{ marginBottom: 6 }}>{parsedCountries}</div>;
};
export const renderRegions = (regions, regionsAll) => {
  const parsedRegions = regionsAll
    .filter((region) => regions.items.includes(region.id))
    .map((region) => (
      <span key={region.id} className={styles.targetingInfoValue}>
        {region.name}
      </span>
    ));
  return <div style={{ marginBottom: 6 }}>{parsedRegions}</div>;
};

export const renderCountryList = (listTargeting, countryListAll) => {
  const parsedList = countryListAll
    .filter((list) => listTargeting.lists.includes(list.id))
    .map((list) => (
      <span key={list.id} className={styles.targetingInfoValueList}>
        {list.name} <Tag color="blue">List</Tag>
      </span>
    ));
  return (
    <div>
      <span
        className={styles.targetingInfoType}
        style={{
          color: listTargeting.op === "in" ? "#95B79A" : "#D95C5C",
        }}
      >
        {listTargeting.op}d
      </span>
      {listTargeting.list && listTargeting.list.length > 0 && parsedList}
    </div>
  );
};
export const renderIpList = (listTargeting, ipListAll) => {
  const parsedList = ipListAll
    .filter((list) => listTargeting.lists.includes(list.id))
    .map((list) => (
      <span key={list.id} className={styles.targetingInfoValueList}>
        {list.name} <Tag color="blue">List</Tag>
      </span>
    ));
  return (
    <div>
      <span
        className={styles.targetingInfoType}
        style={{
          color: listTargeting.op === "in" ? "#95B79A" : "#D95C5C",
        }}
      >
        {listTargeting.op + "d"}
      </span>
      {listTargeting.list && listTargeting.list.length > 0 && parsedList}
    </div>
  );
};
export const renderSubidList = (listTargeting, subidListAll) => {
  const parsedList = subidListAll
    .filter((list) => listTargeting.lists.includes(list.id))
    .map((list) => (
      <span key={list.id} className={styles.targetingInfoValueList}>
        {list.name} <Tag color="blue">List</Tag>
      </span>
    ));
  return (
    <div>
      <span
        className={styles.targetingInfoType}
        style={{
          color: listTargeting.op === "in" ? "#95B79A" : "#D95C5C",
        }}
      >
        {listTargeting.op + "d"}
      </span>
      {listTargeting.list && listTargeting.list.length > 0 && parsedList}
    </div>
  );
};

export const renderOS = (targetedOs, osAll) => {
  const parsedOs = osAll
    .filter((os) => targetedOs.some((target) => Number(target.id) === Number(os.id)))
    .map((item) => (
      <span key={item.id} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));
  return (
    <div>
      {osAll.length === parsedOs.length ? (
        <span className={styles.targetingInfoType}>All Operating Systems</span>
      ) : (
        parsedOs
      )}
    </div>
  );
};
export const renderBrowsers = (targetBrowsers, browsersAll) => {
  const parsedBrowsers = browsersAll
    .filter((browser) => targetBrowsers.some((target) => Number(target.id) === Number(browser.id)))
    .map((item) => (
      <span key={item.id} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));
  return (
    <div>
      {browsersAll.length === parsedBrowsers.length ? (
        <span className={styles.targetingInfoType}>All Browsers</span>
      ) : (
        parsedBrowsers
      )}
    </div>
  );
};

export const renderAges = (targetedAges, agesAll) => {
  const parseAges = () => {
    return agesAll
      .filter((age) => targetedAges.items.includes(age.value))
      .map((item) => (
        <span key={item.value} className={styles.targetingInfoValue}>
          {item.label}
        </span>
      ));
  };
  return (
    <div>
      {targetedAges.items.length === agesAll.length ? (
        <span className={styles.targetingInfoType}>All Types</span>
      ) : (
        parseAges()
      )}
    </div>
  );
};

export const renderDays = (targetedDays) => {
  return (
    <div>
      {targetedDays.items.length === 7 ? (
        <span className={styles.targetingInfoType}>Every Day</span>
      ) : (
        <div>
          <span
            className={styles.targetingInfoType}
            style={{
              color: targetedDays.op === "in" ? "#95B79A" : "#D95C5C",
            }}
          >
            {targetedDays.op}d
          </span>
          <span
            className={
              targetedDays.items.includes("monday")
                ? styles.statusActiveSpan
                : styles.statusInactiveSpan
            }
          >
            MON
          </span>
          <span
            className={
              targetedDays.items.includes("tuesday")
                ? styles.statusActiveSpan
                : styles.statusInactiveSpan
            }
          >
            TUE
          </span>
          <span
            className={
              targetedDays.items.includes("wednesday")
                ? styles.statusActiveSpan
                : styles.statusInactiveSpan
            }
          >
            WED
          </span>
          <span
            className={
              targetedDays.items.includes("thursday")
                ? styles.statusActiveSpan
                : styles.statusInactiveSpan
            }
          >
            THU
          </span>
          <span
            className={
              targetedDays.items.includes("friday")
                ? styles.statusActiveSpan
                : styles.statusInactiveSpan
            }
          >
            FRI
          </span>
          <span
            className={
              targetedDays.items.includes("saturday")
                ? styles.statusActiveSpan
                : styles.statusInactiveSpan
            }
          >
            SAT
          </span>
          <span
            className={
              targetedDays.items.includes("sunday")
                ? styles.statusActiveSpan
                : styles.statusInactiveSpan
            }
          >
            SUN
          </span>
        </div>
      )}
    </div>
  );
};

export const renderHours = (targetedHours) => {
  const hours = {
    0: "00:00-01:00",
    1: "01:00-02:00",
    2: "02:00-03:00",
    3: "03:00-04:00",
    4: "04:00-05:00",
    5: "05:00-06:00",
    6: "06:00-07:00",
    7: "07:00-08:00",
    8: "08:00-09:00",
    9: "09:00-10:00",
    10: "10:00-11:00",
    11: "11:00-12:00",
    12: "12:00-13:00",
    13: "13:00-14:00",
    14: "14:00-15:00",
    15: "15:00-16:00",
    16: "16:00-17:00",
    17: "17:00-18:00",
    18: "18:00-19:00",
    19: "19:00-20:00",
    20: "20:00-21:00",
    21: "21:00-22:00",
    22: "22:00-23:00",
    23: "23:00-00:00",
  };
  const parseHours = () => {
    return targetedHours.items.map((hour) => (
      <span key={hour} className={styles.targetingInfoValue}>
        {hours[Number(hour)]}
      </span>
    ));
  };
  return (
    <div>
      {targetedHours.items.length === 1 && targetedHours.items[0] === "24" ? (
        <span className={styles.targetingInfoType}>24 hours a Day</span>
      ) : (
        <>
          <span
            className={styles.targetingInfoType}
            style={{
              color: targetedHours.op === "in" ? "#95B79A" : "#D95C5C",
            }}
          >
            {targetedHours.op}d
          </span>
          {parseHours()}
        </>
      )}
    </div>
  );
};
export const renderConnections = (targetConnections, connections) => {
  const parseConnections = () => {
    return connections
      .filter((connection) => targetConnections.items.includes(connection.value.toString()))
      .map((item) => (
        <span key={item.value} className={styles.targetingInfoValue}>
          {item.label}
        </span>
      ));
  };
  return (
    <div>
      {targetConnections.items.length === connections.length ? (
        <span className={styles.targetingInfoType}>All Connections</span>
      ) : (
        <>
          <span
            className={styles.targetingInfoType}
            style={{
              color: targetConnections.op === "in" ? "#95B79A" : "#D95C5C",
            }}
          >
            {targetConnections.op + "d"}
          </span>
          {parseConnections()}
        </>
      )}
    </div>
  );
};

export const renderDeviceTypes = (targetedDeviceTypes, typesAll) => {
  const parseDeviceTypes = () => {
    return targetedDeviceTypes.items.map((type) => (
      <span key={type} className={styles.targetingInfoValue}>
        {type}
      </span>
    ));
  };
  return (
    <div>
      {targetedDeviceTypes.items.length === typesAll.length ? (
        <span className={styles.targetingInfoType}>All Types</span>
      ) : (
        parseDeviceTypes()
      )}
    </div>
  );
};

export const renderIpIsp = (targetedIpIsp, ipListAll) => {
  return (
    <div>
      {parseLists(targetedIpIsp, ipListAll)}
      {parseItems(targetedIpIsp)}
    </div>
  );
};

export const renderDeviceManufacturers = (targetedDeviceManufacturers, manufacturersAll) => {
  const parseDeviceManufacturers = manufacturersAll
    .filter((manufacturer) => targetedDeviceManufacturers.items.includes(manufacturer.value))
    .map((item) => (
      <span key={item.value} className={styles.targetingInfoValue}>
        {item.label}
      </span>
    ));

  return (
    <div>
      {targetedDeviceManufacturers.items.length === manufacturersAll.length ? (
        <span className={styles.targetingInfoType}>All Manufacturers</span>
      ) : (
        parseDeviceManufacturers
      )}
    </div>
  );
};
export const renderTrafficTypes = (targetedTrafficType) => {
  return (
    <div>
      {targetedTrafficType === "Both" ? (
        <span className={styles.targetingInfoValue}>All Traffic Types</span>
      ) : (
        <span className={styles.targetingInfoValue}>{targetedTrafficType}</span>
      )}
    </div>
  );
};

export const renderCategories = (targetedKeywords, categoriesAll) => {
  const parseTargetedCategories = categoriesAll
    .filter((category) => (targetedKeywords.items[0]?.split("|") || []).includes(category.key))
    .map((item) => (
      <span key={item.name} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));

  return <div>{parseTargetedCategories}</div>;
};

const parseItems = (targetedSubID) => {
  return targetedSubID.items.map((subId, id) => (
    <span key={id} className={styles.targetingInfoSubidValue}>
      {subId.replace(/\.\*$/, "")}
    </span>
  ));
};

const parseLists = (targetedSubID, subidListAll) => {
  return targetedSubID.lists.map((id) => {
    let match = subidListAll.filter((item) => item.id === id);
    return (
      match &&
      match[0] && (
        <span key={id} className={styles.targetingInfoSubidList}>
          <UnorderedListOutlined />
          {match[0].name}
        </span>
      )
    );
  });
};

export const renderSubIDPlacements = (targetedSubID, subidListAll) => {
  return (
    <div>
      {parseLists(targetedSubID, subidListAll)}
      {parseItems(targetedSubID)}
    </div>
  );
};

export const renderConnectionsBidding = (targetConnections, connections) => {
  const parsedConnections = connections
    .filter((connection) => targetConnections.includes(connection.value.toString()))
    .map((item) => (
      <span key={item.value} className={styles.targetingInfoValue}>
        {item.label}
      </span>
    ));
  return parsedConnections;
};
export const renderCountriesBidding = (countries, countriesAll) => {
  const parsedCountries = countriesAll
    .filter((country) => countries.includes(country.value))
    .map((country) => (
      <span key={country.value} className={styles.targetingInfoValue}>
        {country.label}
      </span>
    ));
  return parsedCountries;
};

export const renderOSBidding = (targetedOs, osAll) => {
  const parsedOs = osAll
    .filter((os) => targetedOs.some((target) => Number(target) === Number(os.id)))
    .map((item) => (
      <span key={item.id} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));
  return parsedOs;
};
export const renderBrowsersBidding = (targetBrowsers, browsersAll) => {
  const parsedBrowsers = browsersAll
    .filter((browser) => targetBrowsers.some((target) => Number(target) === Number(browser.id)))
    .map((item) => (
      <span key={item.id} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));
  return parsedBrowsers;
};
