import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import UserContext from '../../../contexts/userContext';
// import { auth } from '../../../services/auth';
import { dashboardService } from '../../../services/dashboard';
import styles from '../Dashboard.module.css';

export const ActivityLog = () => {
  const { userInfo } = useContext(UserContext);
  const [activityList, setActivityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activityLogPage] = useState(1);

  useEffect(() => {
    const fetchActivityLog = async () => {
      setLoading(true);
      try {
        const response = await dashboardService.getActivityLog(activityLogPage);
        setActivityList(response.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchActivityLog();
  }, [activityLogPage]);

  const renderActivityList = (activities) => {
    return activities.map((activity, index) => {
      return (
        <div key={index} className={styles.singleActivity}>
          <div>
            <img src={userInfo.image} alt="user_img" />
          </div>
          <div>
            <p>
              <span>
                {userInfo.first_name} {userInfo.last_name}&nbsp;
              </span>
              {activity.message.toLowerCase()}
            </p>
            <p>{activity.date}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <Spin wrapperClassName={styles.activityLogContainer} spinning={loading}>
      <h4>Latest Activity</h4>
      <div className={styles.activityList}>{renderActivityList(activityList)}</div>
    </Spin>
  );
};
