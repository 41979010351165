export const numberWithSpaces = x => {
    if (!x) {
        return x;
    }
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (parts[1]) {
        let decimalOne = parts[1][0] ? parts[1][0] : 0;
        let decimalTwo = parts[1][1] ? parts[1][1] : 0;
        parts[1] = decimalOne + decimalTwo;
    }
    return parts.join(".");
};