import { Button, message, Radio, Select, Space } from "antd";
import classNames from "classnames";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CampaignContext } from "../../../contexts/campaignContext";
import { TargetingContext } from "../../../contexts/targetingContext";
import styles from "./GeoTargeting.module.css";

const { Option } = Select;

export const GeoTargeting = () => {
  const { countriesAll, regionsAll } = useContext(TargetingContext);
  const { campaignType, targeting, dispatchTargeting, editId } = useContext(CampaignContext);

  const [geoOption, setGeoOption] = useState(campaignType === "Premium" ? "country" : "worldwide");
  const [regionOption, setRegionOption] = useState("all_regions");

  useEffect(() => {
    if (editId) return;
    if (campaignType === "Premium") {
      dispatchTargeting({
        type: "countries",
        payload: { items: ["US"], op: "in" },
      });
    } else {
      dispatchTargeting({
        type: "countries",
        payload: { items: [], op: "in" },
      });
    }
  }, [campaignType, dispatchTargeting, editId]);

  useEffect(() => {
    setGeoOption(!targeting.countries?.items?.length ? "worldwide" : "country");
    setRegionOption(!targeting.regions?.items?.length ? "all_regions" : "specific_regions");
  }, [targeting.countries, targeting.regions]);

  const availableRegions = useMemo(() => {
    if (targeting.countries?.items?.length) {
      const countryCode = targeting.countries?.items?.[0];
      return regionsAll[countryCode];
    }
    return [];
  }, [regionsAll, targeting.countries]);

  const handleCountryChange = (items) => {
    setRegionOption("all_regions");
    if (targeting.regions.items.length) {
      dispatchTargeting({
        type: "regions",
        payload: { items: [], op: "in" },
      });
      message.warning("Region targeting cleared.");
    }
    dispatchTargeting({
      type: "countries",
      payload: { items: [items], op: "in" },
    });
  };

  const handleRegionChange = (regionId, isSelected) => {
    const items = isSelected
      ? targeting.regions.items.filter((item) => item !== regionId)
      : [...targeting.regions.items, regionId];
    dispatchTargeting({
      type: "regions",
      payload: { items, op: "in" },
    });
  };

  return (
    <div>
      <div>
        {campaignType !== "Premium" && (
          <>
            <Button
              onClick={() => setGeoOption("country")}
              type={geoOption === "country" && "primary"}
              className={styles.btnOption}
            >
              Country
            </Button>
            <Button
              onClick={() => {
                setGeoOption("worldwide");
                setRegionOption("all_regions");
                dispatchTargeting({
                  type: "countries",
                  payload: { items: [], op: "in" },
                });
                dispatchTargeting({
                  type: "regions",
                  payload: { items: [], op: "in" },
                });
              }}
              type={geoOption === "worldwide" && "primary"}
              className={styles.btnOption}
            >
              Worldwide
            </Button>
          </>
        )}
      </div>
      <div className={styles.selectContainer}>
        <span className={campaignType !== "Premium" && styles.paddingTop}>
          I want to include selected country in my targeting:
        </span>
        <Select
          disabled={geoOption === "worldwide" || campaignType === "Premium"}
          value={targeting.countries?.items?.[0]}
          onChange={handleCountryChange}
          showSearch
          placeholder="Enter country name"
          style={{ width: "50%" }}
          optionFilterProp="children"
          getPopupContainer={(trigger) => trigger.parentNode}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {countriesAll.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
        <div className={styles.radioButtonSelection}>
          <Radio.Group
            disabled={!targeting.countries?.items?.[0]}
            value={regionOption}
            onChange={(e) => {
              if (e.target.value === "all_regions") {
                dispatchTargeting({
                  type: "regions",
                  payload: { items: [], op: "in" },
                });
              }
              setRegionOption(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Radio value="all_regions">Target all country regions</Radio>
              <Radio value="specific_regions">Target specific regions</Radio>
            </Space>
          </Radio.Group>
        </div>
        {regionOption === "specific_regions" && (
          <div>
            {!availableRegions ? (
              <span className={styles.regionMessage}>
                No regions available for selected country.
              </span>
            ) : (
              availableRegions.map((region) => {
                const isSelected = targeting.regions.items.includes(region.id);
                return (
                  <div
                    key={region.id}
                    className={classNames(styles.targetingTagSelect, {
                      [styles.targetingTagSelected]: isSelected,
                    })}
                    onClick={() => handleRegionChange(region.id, isSelected)}
                  >
                    {region.name}
                  </div>
                );
              })
            )}
          </div>
        )}
        {/* <Tag>{targeting.countries.item[0]}</Tag> */}
      </div>
    </div>
  );
};
