import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, Spin, Row, Col, Tag, Collapse, Tooltip, Modal } from 'antd';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { userProfileService } from '../../../services/userProfile';
import { EyeInvisibleOutlined, EyeTwoTone, LockFilled, CopyOutlined } from '@ant-design/icons';
import { openSuccessNotification, openWarningNotification } from '../../../utils/notifications';

import styles from '../Profile.module.css';

const { Panel } = Collapse;

const Method = {
  App: 'app',
  Email: 'email'
}

export const ChangePassword = ({ userDetails }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const firstInputRef = useRef(null);
  const firstInputModalRef = useRef(null);
  const [error, setError] = useState(false);
  const [changePrimaryLoading, setChangePrimaryLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [defaultModalVisible, setDefaultModalVisible] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null);
  const [primaryMethod, setPrimaryMethod] = useState(null);
  const [loading2FA, setLoading2FA] = useState(false);
  const [loadingActivate2FA, setLoadingActivate2FA] = useState(false);
  const [loadingDeactivate, setLoadingDeactivate] = useState(false);
  const [loadingQR, setLoadingQR] = useState(false);
  const [qrData, setQrData] = useState(null);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [isQrActive, setIsQrActive] = useState(false);
  const [isEmailActive, setIsEmailActive] = useState(false);
  const [regenerateLoading, setRegenerateLoading] = useState(false);

  const [backupCodesModalVisible, setBackupCodesModalVisible] = useState(false);
  const [backupFile, setBackupFile] = useState({ codes: [], name: '' });

  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [modalCode, setModalCode] = useState(['', '', '', '', '', '']);

  const { id } = userDetails;

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await userProfileService.changePassword(values, id);
      openSuccessNotification({ message: 'Password changed successfully!' });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const validatePasswordConfirm = () => {
    let newPassword = form.getFieldValue('new_password');
    let confirmPassword = form.getFieldValue('confirm_password');
    if (newPassword !== confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  const validateNewPassword = (value) => {
    return /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/.test(value);
  };

  const handlePanelChange = async (key) => {
    setSelectedValue(key);
  };

  const getActive2FAMethods = async () => {
    setLoading2FA(true);
    setIsEmailActive(false);
    setIsQrActive(false);
    setPrimaryMethod(null);
    try {
      const res = await userProfileService.getActiveMethods();
      res.forEach((method) => {
        if (method.name === Method.Email) {
          setIsEmailActive(true);
          method.is_primary && setPrimaryMethod(method.name)
        } else if (method.name === Method.App) {
          setIsQrActive(true);
          method.is_primary && setPrimaryMethod(method.name)
        }
      })
    } catch (e) {
      console.log(e);
    } finally {
      setLoading2FA(false);
      setCode(['', '', '', '', '', '']);
      setSelectedValue(null);
    }
  };

  useEffect(() => {
    getActive2FAMethods();
  }, []);

  const showQRCode = async (selectedValue) => {
    setLoadingQR(true);
    try {
      const res = await userProfileService.activateMethod(selectedValue);
      if (res.details) {
        const qr = await userProfileService.generateQR({ "qr_link": res.details })
        if (qr.success) {
          openSuccessNotification({ message: qr.message });
          setQrData(qr.data);
          firstInputRef && firstInputRef.current.focus();
        } else {
          openWarningNotification({ message: qr.message });
        }
      } else {
        openWarningNotification({ message: 'Something went wrong, try again later.' });
      }
    } catch (e) {
      openWarningNotification({ message: 'Something went wrong, try again later.' });
      console.log(e);
    } finally {
      setLoadingQR(false);
    }
  }

  const sendEmail = async (selectedValue) => {
    setLoadingEmail(true);
    try {
      const res = await userProfileService.activateMethod(selectedValue);
      openSuccessNotification({ message: res.details });
      firstInputRef && firstInputRef.current.focus();
    } catch (e) {
      openWarningNotification({ message: 'Something went wrong, try again later.' });
      console.log(e);
    } finally {
      setLoadingEmail(false);
    }
  }

  const handleInputChange = (index, value, isModal) => {
    // Use the correct state based on the isModal parameter
    const targetCode = isModal ? modalCode : code;

    if (value >= 0 && value <= 9) {
      const newCode = [...targetCode];
      newCode[index] = value;

      // Update the correct state
      if (isModal) {
        setModalCode(newCode);
      } else {
        setCode(newCode);
      }

      // Move focus to the next input field if available
      if (index < 5 && value !== '') {
        document.getElementById(`input-${isModal ? 'modal-' : ''}${index + 1}`).focus();
      }
    }
  };

  const handleInputKeyDown = (index, e, isModal) => {
    // Use the correct state based on the isModal parameter
    const targetCode = isModal ? modalCode : code;

    if (e.key === 'Backspace' && targetCode[index] === '') {
      // Move focus to the previous input field on Backspace if available
      if (index > 0) {
        document.getElementById(`input-${isModal ? 'modal-' : ''}${index - 1}`).focus();
      }
    }
  };

  const handlePaste = (e, isModal) => {
    const pastedData = e.clipboardData.getData('text');

    // Check if pasted data is a 6-digit string
    if (/^\d{6}$/.test(pastedData)) {
      const newCode = pastedData.split('').slice(0, 6);

      // Update the correct state based on isModal
      if (isModal) {
        setModalCode(newCode);
      } else {
        setCode(newCode);
      }
    } else if (pastedData.length === 1) {
      // If pasted data is a single digit, paste it into the current input field
      const currentInput = document.activeElement;
      const currentIndex = parseInt(currentInput.id.replace(`input-${isModal ? 'modal-' : ''}`, ''), 10);

      if (!isNaN(currentIndex) && currentIndex >= 0 && currentIndex <= 5) {
        handleInputChange(currentIndex, pastedData, isModal);
      }
    }

    e.preventDefault(); // Prevent default paste behavior
  };

  const downloadText = (text, filename) => {
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  const activate2FA = async (selectedValue) => {
    if (code.every((value) => value !== '')) {
      if (!selectedValue) {
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 1000)
        openWarningNotification({ message: 'Please select 2FA method.' });
      } else {
        setLoadingActivate2FA(true)
        try {
          const res = await userProfileService.confirmActivation({ code: code.join('') }, selectedValue);
          if (res.backup_codes) {
            setBackupFile({ codes: res.backup_codes, name: `backup_codes_${selectedValue}.txt` });
            openSuccessNotification({ message: 'Activation successful!' });
            getActive2FAMethods();
            setQrData(null);
            setBackupCodesModalVisible(true);
          } else {
            openWarningNotification({ message: res.code });
          }
        } catch (e) {
          openWarningNotification({ message: e.response.data.code });
          console.log(e);
        } finally {
          setLoadingActivate2FA(false)
        }
      }
    } else {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 1000)
      openWarningNotification({ message: 'Please fill all fields before confirming.' });
    }
  }

  const deactivate2FA = async () => {
    setLoadingDeactivate(true);
    try {
      const res = await userProfileService.deactivate2FA();
      if (res.status === 204) {
        openSuccessNotification({ message: "Successfully deleted user's 2fa methods set." });
        getActive2FAMethods();
      } else {
        openWarningNotification({ message: 'Something went wrong, try again later.' });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingDeactivate(false);
    }
  }

  const deactivateMethod = async (method) => {
    setLoadingDeactivate(true);
    try {
      const res = await userProfileService.deactivateMethod(method);
      if (res.status === 204) {
        openSuccessNotification({ message: "Successfully deactivated method." });
        getActive2FAMethods();
      } else {
        openWarningNotification({ message: 'Something went wrong, try again later.' });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingDeactivate(false);
    }
  }

  const handleOpenModal = async () => {
    setDefaultModalVisible(true);
    setModalLoading(true);
    try {
      const res = await userProfileService.requestEmail({ method: Method.Email });
      openSuccessNotification({ message: res.details })
    } catch (e) {
      openWarningNotification({ message: 'Something went wrong, try again later.' });
      console.log(e);
    } finally {
      setModalLoading(false);
    }
  }

  const changePrimaryMethod = async (primaryMethod) => {
    const method = primaryMethod === Method.App ? Method.Email : Method.App;
    if (modalCode.every((value) => value !== '')) {
      setChangePrimaryLoading(true);
      try {
        await userProfileService.changePrimaryMethod({ method, code: modalCode.join('') });
      } catch (e) {
        openWarningNotification({ message: e.response.data.detail.code });
        console.log(e);
      } finally {
        setDefaultModalVisible(false);
        getActive2FAMethods();
        setChangePrimaryLoading(false);
      }
    } else {
      openWarningNotification({ message: 'Please fill all fields before confirming.' });
    }
  }

  useEffect(() => {
    if (defaultModalVisible) {
      firstInputModalRef.current.focus();
      setModalCode(['', '', '', '', '', '']);
    }
  }, [defaultModalVisible]);


  const regenerateAndDownload = async (method) => {
    setRegenerateLoading(true);
    try {
      const res = await userProfileService.regenerateBackupCodes(method);
      if (res.backup_codes) {
        const codesText = res.backup_codes.join('\n');
        downloadText(codesText, `backup_codes_${primaryMethod}.txt`);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRegenerateLoading(false);
    }
  }

  return (
    <div className={styles.securityTabContainer}>
      <h2>
        <LockFilled /> Edit Security Information
      </h2>
      <Row gutter={[16, 16]}>
        <Col md={24} lg={12}>
          <div className={styles.securityTab}>
            <Spin spinning={loading}>
              <Form
                form={form}
                name="userForm"
                onFinish={onSubmit}
                onFinishFailed={({ errorFields }) => {
                  form.scrollToField(errorFields[0].name);
                }}
              >
                <Form.Item
                  label="Change password"
                  name="new_password"
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (!validateNewPassword(value))
                          throw new Error('Password must contain uppercase letter, lowercase letter and a number!');
                      }
                    },
                    {
                      required: true,
                      message: 'Please type new password!'
                    }
                  ]}
                >
                  <Input.Password
                    placeholder="New Password"
                    size="middle"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm password"
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: 'Please type password confirmation!'
                    },
                    {
                      validator: async (rule, value) => {
                        if (validatePasswordConfirm()) throw new Error("Passwords don't match!");
                      }
                    }
                  ]}
                >
                  <Input.Password
                    size="middle"
                    placeholder="Confirm Password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                <Form.Item className={styles.updatePassButton}>
                  <Button type="primary" htmlType="submit">
                    Update Password
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </Col>
        <Col md={24} lg={10}>
          <Spin spinning={loading2FA}>
            <div className={styles.twoFactorContainer}>
              <h2>Set up 2-Factor authentication</h2>
              <div className={styles.firstStep}>
                <h3>1. Select an authentication method</h3>
                <p>Select your preferred authentication method from the options below.</p>
                <Collapse
                  accordion
                  onChange={handlePanelChange}
                  activeKey={selectedValue}
                >
                  <Panel
                    header={
                      <>
                        <h3>
                          Authentication app
                          {!isQrActive && <Tag className={styles.recommendedTag} >RECOMMENDED</Tag>}
                          {isQrActive && <Tag className={styles.activeTag} >ACTIVE</Tag>}
                          {isEmailActive && primaryMethod === Method.App ?
                            <Tag className={styles.defaultTag} >DEFAULT</Tag> :
                            isEmailActive && isQrActive && <Button onClick={(e) => { e.stopPropagation(); handleOpenModal() }} className={styles.makeDefault} size='small' >MAKE DEFAULT</Button>}
                        </h3>
                        <p>Use the Google Authenticator app to generate one-time security codes.</p>
                      </>
                    }
                    key="app"
                  >
                    {!qrData ?
                      <Tooltip title={isQrActive && isEmailActive && primaryMethod === Method.App ? "Default method can't be deactivated." : ""}>
                        <Button
                          loading={isQrActive ? loadingDeactivate : loadingQR}
                          disabled={isQrActive && isEmailActive && primaryMethod === Method.App}
                          type={isQrActive ? "danger" : "primary"}
                          ghost
                          onClick={isQrActive && isEmailActive ? () => deactivateMethod(selectedValue) : isQrActive && !isEmailActive ? () => deactivate2FA() : () => showQRCode(selectedValue)}
                        >
                          {isQrActive ? 'Deactivate' : 'Show QR code'}
                        </Button>
                      </Tooltip>
                      :
                      <div className={styles.qrContainer}>
                        <div className={styles.imageWrapper}>
                          <img src={qrData.qr_path} alt="qr code" />
                          <p>Scan this QR code with Google Authenticator.</p>
                        </div>
                        <div className={styles.keyWrapper}>
                          <p>If you have any issues with scanning the QR code, enter the code below into the app manually.</p>
                          <Input disabled value={qrData.secret_key} />
                          <CopyToClipboard
                            text={qrData.secret_key}
                            onCopy={() =>
                              openSuccessNotification({
                                message: "Successfully copied to clipboard!",
                              })
                            }
                          >
                            <span className={styles.copyLabel} type="primary">Copy code</span>
                          </CopyToClipboard>
                          <p>Once you’re done, proceed to next step.</p>
                        </div>
                      </div>}
                  </Panel>
                </Collapse>
                <Collapse
                  accordion
                  onChange={handlePanelChange}
                  activeKey={selectedValue}
                >
                  <Panel
                    header={
                      <>
                        <h3>E-mail verification
                          {isEmailActive && <Tag className={styles.activeTag} >ACTIVE</Tag>}
                          {isQrActive && primaryMethod === Method.Email ?
                            <Tag className={styles.defaultTag} >DEFAULT</Tag> :
                            isQrActive && isEmailActive && <Button onClick={(e) => { e.stopPropagation(); setDefaultModalVisible(true) }} className={styles.makeDefault} size='small' >MAKE DEFAULT</Button>}
                        </h3>
                        <p>Security codes will be sent to registered email.</p>
                      </>
                    }
                    key="email"
                  >
                    <Tooltip title={isQrActive && isEmailActive && primaryMethod === Method.Email ? "Default method can't be deactivated." : ""}>
                      <Button
                        loading={isEmailActive ? loadingDeactivate : loadingEmail}
                        disabled={isQrActive && isEmailActive && primaryMethod === Method.Email}
                        type={isEmailActive ? "danger" : "primary"}
                        ghost
                        onClick={isEmailActive && isQrActive ? () => deactivateMethod(selectedValue) : isEmailActive && !isQrActive ? () => deactivate2FA() : () => sendEmail(selectedValue)}
                      >
                        {isEmailActive ? 'Deactivate' : 'Send e-mail'}
                      </Button>
                    </Tooltip>
                  </Panel>
                </Collapse>
              </div>
              <Modal
                centered
                wrapClassName={styles.changeDefaultModal}
                title="Enter authentication code"
                visible={defaultModalVisible}
                footer={false}
                onCancel={() => setDefaultModalVisible(false)}
              >
                <Spin spinning={modalLoading}>
                  <p>You are about to change your default authentication method. To proceed, please enter 6-digit code from your default authentical method.</p>
                  <div className={styles.codeWrapper} >
                    <div>
                      {modalCode.map((value, index) => (
                        <Input
                          ref={index === 0 ? firstInputModalRef : null}
                          key={index}
                          value={value}
                          onChange={(e) => handleInputChange(index, e.target.value, true)}
                          onKeyDown={(e) => handleInputKeyDown(index, e, true)}
                          maxLength={1}
                          id={`input-modal-${index}`}
                          onPaste={(e) => handlePaste(e, true)}
                        />
                      ))}
                    </div>
                    <Button loading={changePrimaryLoading} type='primary' onClick={() => changePrimaryMethod(primaryMethod)}>Change default</Button>
                  </div>
                </Spin>
              </Modal>
              <div className={`${styles.secondStep} ${isEmailActive && isQrActive && styles.secondStepDisabled}`}>
                <h3>2. Enter 6-digit code</h3>
                <p>After you receive a 6-digit code via your preferred method, enter it below.</p>
                <div className={styles.codeWrapper} >
                  <div>
                    {code.map((value, index) => (
                      <Input
                        ref={index === 0 ? firstInputRef : null}
                        disabled={isEmailActive && isQrActive}
                        key={index}
                        value={value}
                        onChange={(e) => handleInputChange(index, e.target.value, false)}
                        onKeyDown={(e) => handleInputKeyDown(index, e, false)}
                        maxLength={1}
                        id={`input-${index}`}
                        onPaste={(e) => handlePaste(e, false)}
                      />
                    ))}
                  </div>
                  <Button
                    className={error ? styles.errorCode : ''}
                    disabled={isEmailActive && isQrActive}
                    loading={loadingActivate2FA}
                    type='primary'
                    onClick={() => activate2FA(selectedValue)}
                  >
                    Confirm & activate 2FA
                  </Button>
                </div>
              </div>
              <div className={styles.thirdStep}>
                <h3>3. Back-up Codes</h3>
                <p>If you are for any reason unable to log in with available authentication methods, you can enter one of the back-up codes. Each code can only be used once.</p>
                <p>Clicking “Regenerate & Download” will render any existing back-up codes obsolete.</p>
                <Tooltip title={!isEmailActive && !isQrActive ? "You currently have no active methods" : ""}>
                  <Button
                    loading={regenerateLoading}
                    disabled={!isEmailActive && !isQrActive}
                    onClick={() => regenerateAndDownload(primaryMethod)}
                    type="primary"
                    ghost
                  >
                    Regenerate & Download
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Spin>
        </Col>
      </Row>
      <Modal
        wrapClassName={styles.modalBackupCodes}
        closable={false}
        visible={backupCodesModalVisible}
        zIndex={1200}
        width={700}
        footer={
          <>
            <div>
              <CopyToClipboard
                text={backupFile.codes.join(', ')}
                onCopy={() =>
                  openSuccessNotification({
                    message: "Successfully copied to clipboard!",
                  })
                }
              >
                <Button type="primary" ghost icon={<CopyOutlined />}>
                  Copy codes
                </Button>
              </CopyToClipboard>
              <Button type="primary" ghost onClick={() => {
                const codesText = backupFile.codes.join('\n');
                downloadText(codesText, backupFile.name);
              }}>
                Download codes
              </Button>
            </div>
            <Button type="primary" onClick={() => setBackupCodesModalVisible(false)}>
              Go To Platform
            </Button>
          </>
        }
      >
        <div className={styles.backupCodesContainer}>
          <h2>Backup Codes</h2>
          <p>You can find your back-up below. If you are for any reason unable to log in with available authentication methods, you can enter one of these codes.</p>
          <p className={styles.note}>Make sure to copy them or download the info provided below, because you will not be able to access these exact codes again.</p>
          <div className={styles.codesWrapper}>
            {backupFile.codes.map((code, index) => (
              <span key={index}>{code}</span>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};
