import React, { useContext } from 'react';
import Icon from '@ant-design/icons';
import { Alert } from 'antd';
import Marquee from 'react-fast-marquee';
import ReactMarkdown from 'react-markdown';
import NotificationContext from '../../contexts/notificationContext';

export const BroadcastNotification = () => {
  const { broadcastNotifications } = useContext(NotificationContext);

  const LinkRender = (props) => {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  };

  return broadcastNotifications && broadcastNotifications.length > 0 ? (
    <div style={{ width: '100%' }}>
      <Alert
        message={
          broadcastNotifications.length > 1 ? (
            <Marquee pauseOnHover gradient={false}>
              {broadcastNotifications &&
                broadcastNotifications.map((broadcastNotification, key) => {
                  return (
                    <div key={key} style={{ marginLeft: '1.5rem' }}>
                      {broadcastNotification.icon !== '' ? (
                        <Icon
                          style={{
                            verticalAlign: 'middle',
                            marginRight: '0.4rem'
                          }}
                          component={() => <img src={broadcastNotification.icon} alt="" />}
                        />
                      ) : (
                        ''
                      )}
                      <ReactMarkdown className="broadcastNotification" components={{ a: LinkRender }}>
                        {broadcastNotification.description}
                      </ReactMarkdown>
                      {key < broadcastNotifications.length - 1 ? <span style={{ marginLeft: '1.5rem' }}>|</span> : ''}
                    </div>
                  );
                })}
            </Marquee>
          ) : (
            <div style={{ marginLeft: '1.5rem' }}>
              {broadcastNotifications[0].icon !== '' ? (
                <Icon
                  style={{ verticalAlign: 'middle', marginRight: '0.4rem' }}
                  component={() => <img src={broadcastNotifications[0].icon} alt="" />}
                />
              ) : (
                ''
              )}
              <ReactMarkdown className="broadcastNotification" components={{ a: LinkRender }}>
                {broadcastNotifications[0].description}
              </ReactMarkdown>
            </div>
          )
        }
      />
    </div>
  ) : null;
};
