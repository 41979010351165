import classNames from "classnames";
import styles from "./TimesTable.module.css";
import React from "react";
import { Checkbox } from "antd";
import { weekdaysMap } from "../../../utils/campaignContextUtils";

export const TimeRow = ({
  dayId,
  handleChangeSpecificHour,
  handleChangeDay,
  hours,
}) => {
  return (
    <tr className={styles.timeRow}>
      <td colSpan="3" align="right" className={styles.rowTitle}>
        {weekdaysMap[dayId]}
      </td>
      <td align="center">
        <Checkbox
          checked={hours.every(item => item)}
          onChange={e => handleChangeDay(dayId, e.target.checked)}
        />
      </td>
      {hours.map((item, id) => (
        <td
          onClick={() => handleChangeSpecificHour(dayId, id, !item)}
          key={id}
          className={classNames(styles.timeHour, {
            [styles.timeHourSelected]: item,
          })}
        ></td>
      ))}
    </tr>
  );
};
