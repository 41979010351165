import React, { useContext, useEffect, useState } from "react";

import styles from "./CategoriesTargeting.module.css";
import { CategoriesSelect } from "../components/CategoriesSelect";

import { CampaignContext } from "../../../contexts/campaignContext";
import { TargetingContext } from "../../../contexts/targetingContext";
import { Button, Input } from "antd";

export const CategoriesTargeting = () => {
  const { categoriesAll } = useContext(TargetingContext);
  const { targeting, dispatchTargeting } = useContext(CampaignContext);

  const [shownCategories, setShownCategories] = useState([]);

  const setSelectedCategories = (keywords) => {
    const items = keywords ? [keywords] : [];
    dispatchTargeting({ type: "keyword", payload: { items } });
  };

  useEffect(() => {
    setShownCategories(categoriesAll);
    // dispatchTargeting({ type: "category_op", payload: { op: "in" } });
  }, [categoriesAll, dispatchTargeting]);

  const handleCategoriesSearch = (e) => {
    setShownCategories(
      categoriesAll.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()))
    );
  };

  const categoriesCount = [].concat.apply([], Object.values(targeting.categories.items)).length;

  return (
    <div className={styles.categoriesTargetingContainer}>
      <div
        className={`${styles.targetingSelectDivV2} ${
          categoriesCount > 20 && styles.validationIssue
        }`}
      >
        <Input
          className={styles.categoriesSearchInput}
          placeholder="Search IAB Categories"
          onChange={handleCategoriesSearch}
        />
        <Button
          className={styles.iabResetBtn}
          onClick={() => dispatchTargeting({ type: "category_reset" })}
        >
          Reset Targeting
        </Button>
      </div>
      <CategoriesSelect
        targeting={targeting.keyword?.items?.[0]?.split("|") || []}
        setSelectedCategories={setSelectedCategories}
        categoriesAll={categoriesAll}
        shownCategories={shownCategories}
      />
      <p>
        You can select up to 20 IAB categories for the targeting of your campaign. A more specific
        selection will ensure you have a better CTR.
      </p>
    </div>
  );
};
