import { Button, message } from "antd";
import React, { useContext } from "react";
import { CampaignContext } from "../../../contexts/campaignContext";
import styles from "./DeviceTargeting.module.css";

export const DeviceTargeting = () => {
  const { dispatchTargeting, targeting } = useContext(CampaignContext);

  const setSelectedDevices = (items) => {
    dispatchTargeting({ type: "device_types", payload: { items } });
  };

  const isBoth =
    targeting.device_types.items.length === 2 || targeting.device_types.items.length === 0;
  const isMobile =
    targeting.device_types.items.length === 1 && targeting.device_types.items[0] === "mobile";
  const isDesktop =
    targeting.device_types.items.length === 1 && targeting.device_types.items[0] === "desktop";

  return (
    <div>
      <Button
        onClick={() => {
          !isBoth && setSelectedDevices(["mobile", "desktop"]);
        }}
        type={isBoth && "primary"}
        className={styles.btnOption}
      >
        Both
      </Button>
      <Button
        onClick={() => {
          if (!isDesktop) {
            setSelectedDevices(["desktop"]);
            if (targeting.os.items.length) {
              message.warning("Operating system targeting cleared.");
              dispatchTargeting({ type: "os", payload: { items: [] } });
            }
          }
        }}
        type={isDesktop && "primary"}
        className={styles.btnOption}
      >
        Desktop
      </Button>
      <Button
        onClick={() => {
          if (!isMobile) {
            setSelectedDevices(["mobile"]);
            if (targeting.os.items.length) {
              message.warning("Operating system targeting cleared.");
              dispatchTargeting({ type: "os", payload: { items: [] } });
            }
          }
        }}
        type={isMobile && "primary"}
        className={styles.btnOption}
      >
        Mobile
      </Button>
    </div>
  );
};
