import React from "react";
import styles from "../AddFunds.module.css";
import { Spin } from "antd";

export const FundSubtotal = ({
  loading,
  calculatedSubtotal,
  currency,
  currentPayment,
}) => {
  const displayCurrency = currency => {
    switch (currency) {
      case "USD":
        return "$";
      case "USDT":
        return "₮";
      case "EUR":
        return "€";
      default:
        return "";
    }
  };
  return (
    <Spin spinning={loading} wrapperClassName={styles.spinWrapper}>
      <div className={styles.subtotalContainer}>
        <div>
          <span>Selected value:</span>
          {(currentPayment === "wire" || currentPayment === "tether-erc20") ? <></> : <span>Service fee:</span>}
          {currentPayment === "tether-erc20" && <span>In USD:</span>}
          <span>Total amount to pay:</span>
        </div>
        <div>
          <span>
            {calculatedSubtotal &&
              calculatedSubtotal.value &&
              displayCurrency(currency) + calculatedSubtotal.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </span>
          {(currentPayment === "wire" || currentPayment === "tether-erc20") ? <></> : (
            <span>
              {calculatedSubtotal &&
                calculatedSubtotal.fee &&
                displayCurrency(currency) + calculatedSubtotal.fee.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
          )}
          {currentPayment === "tether-erc20" && (
            <span>
              {calculatedSubtotal && calculatedSubtotal.total && calculatedSubtotal.rate &&
                "$" + (calculatedSubtotal.total * calculatedSubtotal.rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
          )}
          <span className={styles.totalPriceValue}>
            {calculatedSubtotal &&
              calculatedSubtotal.total &&
              displayCurrency(currency) + calculatedSubtotal.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </span>
        </div>
      </div>
      {currentPayment === "wire" ? (
        <span className={styles.optionHighlightPrice}>
          Take into consideration that the service fee depends on your bank fees
          that may apply, and thus the end value may vary.
        </span>
      ) : currentPayment === "tether-erc20" ? (
        <span className={styles.optionHighlightPrice}>
          {"Conversion rate: ₮1 = $" + calculatedSubtotal.rate.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span>
      ) : <></>}
      {/* {currentPayment === 'wire' && (
        <Table
          columns={[
            { title: 'Wire transfer information', dataIndex: 'label' },
            { title: 'Click on text to copy values below', dataIndex: 'info' }
          ]}
          dataSource={[
            {
              label: 'Total amount',
              info: calculatedSubtotal && calculatedSubtotal.total && displayCurrency(currency) + calculatedSubtotal.total.toFixed(2)
            },
            {
              label: 'Account holder',
              info: 'COINIS DOO'
            },
            {
              label: 'IBAN',
              info: 'ME2551000000000969991'
            },
            {
              label: 'SWIFT',
              info: 'CKBCMEPG'
            },
            {
              label: 'Bank name',
              info: 'Crnogorska Komercijalna Banka'
            },
            {
              label: 'Bank address',
              info: 'Bulevar Svetog Petra Cetinjskog, Podgorica, Montenegro'
            },
            {
              label: 'Payment description',
              info: 'Prepayment reference RF17A0115128SM (static)'
            }
          ]}
          rowKey="label"
          pagination={false}
        />
      )} */}
    </Spin>
  );
};
