import {
  HomeOutlined,
  NotificationOutlined,
  ReadOutlined,
  SwapOutlined,
  UserOutlined,
  CreditCardOutlined,
  BarChartOutlined,
  UnorderedListOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { CampaignContextProvider } from "../contexts/campaignContext";
import { ApiIntegration } from "../pages/api-integration";
import { CampaignForm } from "../pages/campaign-form";
import { Dashboard } from "../pages/dashboard";
import { MediaCampaigns } from "../pages/campaigns";
import { MyProfile } from "../pages/my-profile";
import { Tracking } from "../pages/tracking";
import { AccountStatements } from "../pages/finance/account-statements";
import { InvoiceHistory } from "../pages/finance/invoice-history";
import { AddFunds } from "../pages/finance/add-funds";
import { Reports } from "../pages/reports";
import { Lists } from "../pages/lists";
import { PaymentDetails } from "../pages/finance/payment-details";
import { RuleAdministration } from "../pages/rule-administration";

export const appRoutes = [
  {
    title: "Dashboard",
    path: "/dashboard",
    element: <Dashboard />,
    icon: <HomeOutlined />,
  },
  {
    title: "Campaigns",
    path: "/campaigns",
    element: (
      <CampaignContextProvider>
        <MediaCampaigns />
      </CampaignContextProvider>
    ),
    icon: <NotificationOutlined />,
    permission_name: "view_list",
  },
  {
    title: "Campaigns",
    path: "/campaigns/:id",
    element: (
      <CampaignContextProvider>
        <CampaignForm />
      </CampaignContextProvider>
    ),
    icon: <NotificationOutlined />,

    skipRender: true,
  },
  {
    title: "Reports",
    path: "/reports",
    element: <Reports />,
    icon: <BarChartOutlined />,
  },
  {
    title: "Lists",
    path: "/lists",
    element: <Lists />,
    icon: <UnorderedListOutlined />,
  },
  {
    title: "Tracking",
    path: "/tracking",
    element: <Tracking />,
    icon: <SwapOutlined />,
  },
  {
    title: "Rule Administration",
    path: "/rule-administration",
    element: <RuleAdministration />,
    icon: <FieldTimeOutlined />,
  },
  {
    title: "API Integration",
    path: "/integration-api",
    element: <ApiIntegration />,
    icon: <ReadOutlined />,
  },
  {
    title: "Payment Details",
    path: "/payment-details/:id",
    element: <PaymentDetails />,
    skipRender: true,
  },
  // {
  //   title: "Payment Details",
  //   path: "/payment-details/:id",
  //   element: PaymentDetails,
  //
  //   skipRender: true,
  // },
  // // {
  // //   title: 'Lists',
  // //   path: '/lists',
  // //   element: Lists,
  // //   icon: <BarsOutlined />,
  // //
  // // },
  // {
  //   title: "Tracking",
  //   path: "/tracking",
  //   element: Tracking,
  //   icon: <SwapOutlined />,
  //
  // },

  {
    title: "Finance",
    path: "/finance",
    element: () => <h1>Finance</h1>,
    icon: <CreditCardOutlined />,

    children: [
      {
        title: "Add Funds",
        path: "/add-funds",
        element: <AddFunds />,
      },
      {
        title: "Account Statement",
        path: "/account-statements",
        element: <AccountStatements />,
      },
      {
        title: "Invoice History",
        path: "/invoice-history",
        element: <InvoiceHistory />,
      },
    ],
  },
  {
    title: "My Profile",
    path: "/my-profile",
    element: <MyProfile />,
    icon: <UserOutlined />,
  },
  // {
  //   title: "API Integration",
  //   path: "/integration-api",
  //   element: ApiIntegration,
  //   icon: <ReadOutlined />,
  //
  // },
  // {
  //   title: "My Profile",
  //   path: "/my-profile",
  //   icon: <UserOutlined />,
  //   element: Profile,
  //
  // },
];
