import React from "react";
import { Button, Modal, Tooltip } from "antd";

import {
  EditOutlined,
  CopyOutlined,
  LineChartOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { mediaCampaignsService } from "../../../services/campaigns";
import {
  openSuccessNotification,
  openErrorNotification,
} from "../../../utils/notifications";
import styles from "./Show.module.css";

const allowedActions = ["active", "paused", "disabled"];

export const ActionsTableCol = ({
  id,
  title,
  status,
  onActivate,
  onPause,
  onDelete,
  toggleActionPending,
}) => {
  const activateCampaign = async () => {
    try {
      const res = await mediaCampaignsService.activateCampaign(id);
      if (res.success) {
        openSuccessNotification({ message: res.message });
      }
      else {
        openErrorNotification({ message: res.message })
      }
      await onActivate();
    } catch (e) {
      openErrorNotification({ message: "Could not activate a campaign!" });
    }
  };

  const pauseCampaign = async () => {
    try {
      const res = await mediaCampaignsService.pauseCampaign(id);
      if (res.success) {
        openSuccessNotification({ message: res.message });
      }
      else {
        openErrorNotification({ message: res.message })
      }
      await onPause();
    } catch (e) {
      openErrorNotification({ message: "Could not pause a campaign!" });
    }
  };

  const deleteCampaign = async () => {
    toggleActionPending(true);
    try {
      await mediaCampaignsService.deleteCampaign(id);
      openSuccessNotification({ message: "Campaign successfully deleted!" });
      await onDelete();
    } catch (e) {
      openErrorNotification({ message: "Could not delete a campaign!" });
    } finally {
      toggleActionPending(false);
    }
  };

  const confirmActivateCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to activate ${title}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => activateCampaign(),
      onCancel() { },
    });
  };

  const confirmPauseCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to pause ${title}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => pauseCampaign(),
      onCancel() { },
    });
  };

  const confirmDeleteCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to delete ${title}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => deleteCampaign(id),
      onCancel() { },
    });
  };

  return (
    <div className={styles.actions}>
      <Tooltip title="Edit">
        <Button
          onClick={() => {
            window.open(`/campaigns/${id}`, "_blank", "noopener,noreferrer");
          }}
          style={{ color: "#737373" }}
          icon={<EditOutlined />}
        ></Button>
      </Tooltip>
      <Tooltip title="Clone">
        <Button
          onClick={() => {
            window.open(
              `/campaigns/${id}?type=clone`,
              "_blank",
              "noopener,noreferrer"
            );
          }}
          style={{ color: "#737373" }}
          icon={<CopyOutlined />}
        ></Button>
      </Tooltip>
      <Tooltip title="Statistics">
        <Button
          onClick={() => {
            window.open(`/reports?id=${id}`, "_blank", "noopener,noreferrer");
          }}
          style={{ color: "#737373" }}
          icon={<LineChartOutlined />}
        ></Button>
      </Tooltip>
      {status.toLowerCase() === "active" ? (
        <Tooltip title="Pause">
          <Button
            onClick={confirmPauseCampaign}
            style={{ color: "#737373" }}
            disabled={!allowedActions.includes(status.toLowerCase())}
            icon={<PauseCircleOutlined />}
          ></Button>
        </Tooltip>
      ) : (
        <Tooltip title={status.toLowerCase() !== "active" ? "Activate" : ""}>
          <Button
            style={{ color: "#737373" }}
            disabled={!allowedActions.includes(status.toLowerCase())}
            onClick={confirmActivateCampaign}
            icon={<PlayCircleOutlined />}
          ></Button>
        </Tooltip>
      )}
      <Tooltip title="Delete">
        <Button
          onClick={confirmDeleteCampaign}
          style={{ color: "#737373" }}
          icon={<DeleteOutlined />}
        ></Button>
      </Tooltip>
    </div>
  );
};
